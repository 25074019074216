import React from 'react'
import { useWorkspaceContext } from '../../contexts/workspace'
import { FloatingDarkModeStyled } from './FloatingDarkMode.styles'

interface FloatingDarkModeProps {
  float?: boolean
}

const FloatingDarkMode = ({ float }: FloatingDarkModeProps) => {
  const { darkMode, handleDarkMode }: any = useWorkspaceContext()
  return (
    <FloatingDarkModeStyled float={float} darkMode={darkMode}>
      <div className="darkmode">
        <input
          className="switch"
          type="checkbox"
          id="darkmodeSwitch"
          onChange={handleDarkMode}
          checked={darkMode}
        />
      </div>
    </FloatingDarkModeStyled>
  )
}

export default FloatingDarkMode
