import React, { useEffect, Suspense } from 'react'
import './App.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'swiper/css/bundle'
import { Router, Redirect } from '@reach/router'
import ReactGA from 'react-ga'
import { GlobalStyles } from './styles/GlobalStyles'
// import { Login } from './pages/Login'
import { useAuthDispatch, useAuthState } from './contexts/auth'
import {
  // MainZyrebox,
  // Home,
  // LandingMain,
  // SettingsZyrebox,
  // CreateZyrebox,
  Login,
  Register,
  InvitePage,
  InviteRoom,
  Error404,
  // Subscribe,
  // LandingTutorials,
  LandingPricing,
  SubscribeLanding,
  LandingContactUs,
  ForgotPassword,
  // ConfigZyrebox,
  ResetPassword,
  Terms,
} from './containers'
import LayoutLanding from './layouts/LayoutLanding'
import { Loader } from './common'
import Layout from './shared/Layout'
import { useWorkspaceContext } from './contexts/workspace'

//firebase
import { messaging } from './firebase/init-firebase'
import { onMessage } from 'firebase/messaging'
import { useNotificationContext } from './contexts/NotificationContext'
import { GET_CURREN_USER } from './graphQL/Queries'
import { useQuery } from '@apollo/client'
import { navigate } from '@reach/router'

const LandingMain = React.lazy(() => import('./containers/LandingMain'))
const MainZyrebox = React.lazy(() => import('./containers/MainZyrebox'))
const Home = React.lazy(() => import('./containers/Home'))
const SettingsZyrebox = React.lazy(() => import('./containers/SettingsZyrebox'))
const CreateZyrebox = React.lazy(() => import('./containers/CreateZyrebox'))
const Subscribe = React.lazy(() => import('./containers/Subscribe'))
const Checkout = React.lazy(() => import('./containers/Checkout'))
const ConfigZyrebox = React.lazy(() => import('./containers/ConfigZyrebox'))
const AuthRedirecting = React.lazy(() => import('./containers/AuthRedirecting'))
const Support = React.lazy(() => import('./containers/Support'))

function App() {
  const { darkMode } = useWorkspaceContext()
  const { openNotification } = useNotificationContext()

  const dispatch = useAuthDispatch()

  useQuery(GET_CURREN_USER, {
    onCompleted: data => {
      // user = data.getUser
      dispatch({ type: 'UPDATE_USER', payload: data.getUser })
    },
    onError: err => {
      if (err.message.includes('Invalid/Expired token')) {
        dispatch({ type: 'LOGOUT' })
        navigate('/login')
      } else {
        console.err(err)
      }
    },
  })

  useEffect(() => {
    ReactGA.initialize('UA-201057624-1')
    onMessage(messaging, message => {
      openNotification({
        message: message.notification.title,
        description: message.notification.body,
        icon: <img src={message.data.icon} width="30" />,
      })
      const notification = document.getElementById('notificationSound')
      notification
        ?.play()
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(play => console.log('play'))
        .catch(err => console.log(err))
    })
  }, [openNotification])
  AOS.init()
  const { user } = useAuthState()

  return (
    <Suspense
      fallback={
        <div className={darkMode ? 'fallback-loader-dark' : 'fallback-loader'}>
          <Loader />
        </div>
      }
    >
      <GlobalStyles />
      <Layout>
        <Router>
          <Error404 default />
          <LandingMain path="/landing" />
          {/* <LandingTutorials path='/tutorials' /> */}
          <LandingPricing path="/pricing" />
          <LandingContactUs path="/contact" />
          <LayoutLanding path="/terms">
            <Terms path="/" />
          </LayoutLanding>
          <AuthRedirecting path="/auth-redirecting" />
          {/*  <AccountConfig path='/' /> */}
          {!user && <Login path="/login" />}
          {!user && <Register path="/register" />}
          {!user && <ForgotPassword path="/forgot" />}
          {!user && <ResetPassword path="/resetPassword" />}
          {!user && <Redirect from="/home" to="/login" noThrow />}
          {!user && <Redirect from="/create-zyrebox" to="/login" noThrow />}
          {!user && <Redirect from="/config" to="/login" noThrow />}
          {!user && <Redirect from="/support" to="/login" noThrow />}
          {!user && <Redirect from="/" to="/landing" noThrow />}
          {user && <Redirect from="/login" to="/home" noThrow />}
          {user && <Redirect from="/register" to="/home" noThrow />}
          {user && <Redirect from="/forgot" to="/home" noThrow />}
          {user && <Redirect from="/resetPassword" to="/home" noThrow />}
          {user && <Redirect from="/" to="/home" noThrow />}
          {/* {!user && <Redirect from='/workspace/:idWorkspace' to='/login' noThrow />} */}
          <Home path="/home" />
          <CreateZyrebox path="/create-zyrebox" />
          <MainZyrebox path="/workspace/:idWorkspace" />
          <SettingsZyrebox path="/workspace/edit/:idWorkspace" />
          <InvitePage path="/invite/:inviteId" />
          <Subscribe path="/subscription" />
          <LayoutLanding path="/checkout">
            <Checkout path="/" />
          </LayoutLanding>
          <InviteRoom path="/room-as-invited/:roomcred" />
          <ConfigZyrebox path="/config" />
          <Support path="/support" />
          <SubscribeLanding path="/subscribedLanding" />
        </Router>
      </Layout>
    </Suspense>
  )
}

export default App
