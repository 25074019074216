import { gql } from '@apollo/client'

export const NEW_CALL = gql`
  subscription newCall {
    newCall {
      id
      room {
        roomName
        id
        roomPassword
      }
      from {
        id
        email
        name
        lastName
        profilePhoto
      }
      to {
        id
        email
        lastName
        name
        subscription
        messagingToken
        profilePhoto
      }
      workspace
      status
    }
  }
`

export const CHANGE_CALL_STATUS = gql`
  subscription changeStatusCall {
    changeStatusCall {
      id
      room {
        id
        roomName
        roomPassword
        isStatic
      }
      from {
        id
        email
        name
        lastName
        messagingToken
        profilePhoto
      }
      to {
        id
        email
        lastName
        name
        messagingToken
        profilePhoto
      }
      status
      createdAt
    }
  }
`
export const SEND_EXISTING_CALL = gql`
  subscription sendExisitingCall {
    sendExisitingCall {
      id
      room {
        roomName
        id
        roomPassword
      }
      from {
        id
        email
        name
        lastName
        messagingToken
        profilePhoto
      }
      to {
        id
        email
        lastName
        name
        subscription
        messagingToken
        profilePhoto
      }
      workspace
      status
    }
  }
`
export const CHANGED_STATUS = gql`
  subscription changedStatus {
    changedStatus {
      user {
        id
      }
      workspace {
        id
      }
    }
  }
`

export const WORKSPACE_EDITED = gql`
  subscription workspaceEdited {
    workspaceEdited {
      id
      name
      logo
      companyName
      description
      subscribed
      maxMembers
      subscribedAt
      owner {
        id
        email
        name
        lastName
        profilePhoto
        phoneNumber
        messagingToken
        subscription
      }
      members {
        id
        email
        name
        lastName
        profilePhoto
        phoneNumber
        messagingToken
        subscription
      }
    }
  }
`

export const MEMBER_DELETED = gql`
  subscription membersDeleted {
    memberDeleted {
      id
      name
      logo
      companyName
      description
      subscribed
      maxMembers
      subscribedAt
      owner {
        id
        email
        name
        lastName
        profilePhoto
        phoneNumber
        messagingToken
        subscription
      }
      members {
        id
        email
        name
        lastName
        profilePhoto
        phoneNumber
        messagingToken
        subscription
      }
    }
  }
`
export const MEMBER_ADDED = gql`
  subscription memberAdded {
    memberAdded {
      id
      name
      logo
      companyName
      description
      subscribed
      maxMembers
      subscribedAt
      owner {
        id
        email
        name
        lastName
        profilePhoto
        phoneNumber
        messagingToken
        subscription
      }
      members {
        id
        email
        name
        lastName
        profilePhoto
        phoneNumber
        messagingToken
        subscription
      }
    }
  }
`

export const MEMBER_ADDED_BY_LINK = gql`
  subscription memberAddedByLink {
    memberAddedByLink {
      id
      name
      logo
      companyName
      description
      subscribed
      maxMembers
      subscribedAt
      owner {
        id
        email
        name
        lastName
        profilePhoto
        phoneNumber
        messagingToken
        subscription
      }
      members {
        id
        email
        name
        lastName
        profilePhoto
        phoneNumber
        messagingToken
        subscription
      }
    }
  }
`
export const ROOM_CREATED = gql`
  subscription roomCreated {
    roomCreated {
      id
      name
      logo
      roomName
      roomPassword
      isStatic
      workspace {
        id
        name
        logo
      }
    }
  }
`
export const ROOM_DELETED = gql`
  subscription roomDeleted {
    roomDeleted {
      id
      name
      logo
      roomName
      roomPassword
      isStatic
    }
  }
`
