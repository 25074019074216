import styled from 'styled-components'

export const HeroContainer = styled.div`
  .hero__container--in {
    position: relative;
    @media only screen and (max-width: 750px) {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .hero__container--in--title {
    font-style: normal;
    line-height: 72px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #ffffff;
    margin-top: 100px;
  }
  .hero__container--in--text {
    font-family: HK Grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    color: #9eb3f5;
    margin-top: 34px;
  }
  .hero__container-in--display {
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(73, 73, 73, 0.25);
    border-radius: 0px 0px 10px 10px;
    margin-top: 69px;
    justify-items: center;
  }
  .hero__container--in--display-video {
    justify-items: center;
    width: 100%;
  }

  .hero__container--in--display-title {
    font-family: HK Grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #869ab8;
    padding-left: 10px;
  }
  .hero__container--in--display-description {
    font-family: HK Grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    color: #161c2d;
    padding-left: 10px;
    padding-bottom: 20px;
  }
`
