import React, {
  Reducer,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'
import { GenericAction } from '../types/contextTypes'
import { notification } from 'antd'
import type { NotificationPlacement } from 'antd/es/notification'

const NotificationContext = React.createContext<NotificationContextType>(
  undefined as unknown as NotificationContextType
)

type NotificationType = 'success' | 'info' | 'warning' | 'error'

type NotificationToastPayload = {
  type: NotificationType
  message: string
  description: string
  onClickAction?: () => void
  placement: NotificationPlacement
  icon?: React.ReactNode
}

type PositionTypes =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'leftbottom'
  | 'rightbottom'
  | 'topleft'
  | 'topright'

interface NotificationPayload {
  position: PositionTypes
  child: React.ReactNode
  duration: number
}

interface NotificationState {
  isOpen: boolean
  position: PositionTypes
  child: React.ReactNode
}

export enum NotificationActionsTypes {
  OPEN_NOTIFICATION = 'NTF_openNotification',
  CLOSE_NOTIFICATION = 'NTF_closeNotification',
  SET_NOTIFICATION_CHILD = 'NTF_setNotificationChild',
}

type NotificationAction =
  | GenericAction<NotificationActionsTypes.CLOSE_NOTIFICATION>
  | GenericAction<NotificationActionsTypes.OPEN_NOTIFICATION>
  | GenericAction<
      NotificationActionsTypes.SET_NOTIFICATION_CHILD,
      NotificationPayload
    >

const NotificationDafultState: NotificationState = {
  isOpen: false,
  position: 'top',
  child: <></>,
}

export type NotificationContextType = Readonly<NotificationState> & {
  dispatch: React.Dispatch<NotificationAction>
  showCommonAlert: (notification: NotificationPayload) => void
  openNotification: (payload: NotificationToastPayload) => void
}

const NotificationReducer: Reducer<NotificationState, NotificationAction> = (
  state,
  action
) => {
  switch (action.type) {
    case NotificationActionsTypes.OPEN_NOTIFICATION:
      return {
        ...state,
        isOpen: true,
      }
    case NotificationActionsTypes.CLOSE_NOTIFICATION:
      return {
        ...state,
        isOpen: false,
      }
    case NotificationActionsTypes.SET_NOTIFICATION_CHILD:
      return {
        ...state,
        position: action.payload.position,
        child: action.payload.child,
      }
    default:
      return state
  }
}

export function NotificationProvider({ children }: any) {
  const [state, dispatch] = useReducer(
    NotificationReducer,
    NotificationDafultState
  )

  const showCommonAlert = useCallback<
    NotificationContextType['showCommonAlert']
  >((notification: NotificationPayload) => {
    dispatch({ type: NotificationActionsTypes.OPEN_NOTIFICATION })
    dispatch({
      type: NotificationActionsTypes.SET_NOTIFICATION_CHILD,
      payload: notification,
    })
    setTimeout(() => {
      dispatch({ type: NotificationActionsTypes.CLOSE_NOTIFICATION })
    }, notification.duration)
  }, [])

  const openNotification = useCallback(
    ({
      type,
      message,
      description,
      onClickAction,
      placement = 'bottomRight',
      icon,
    }: NotificationToastPayload) => {
      const notificationType = type ? type : 'open'
      notification[notificationType]({
        message: message,
        description: description,
        onClick: () => {
          if (onClickAction) {
            onClickAction()
          }
        },
        placement,
        icon,
      })
    },
    []
  )
  const value = useMemo<NotificationContextType>(
    () => ({
      ...state,
      showCommonAlert,
      openNotification,
      dispatch,
    }),
    [state, showCommonAlert, dispatch, openNotification]
  )

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}

export function useNotificationContext(): NotificationContextType {
  return useContext(NotificationContext)
}
