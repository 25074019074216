import React, { useEffect, useState } from 'react'
import {
  Sidebar,
  ZyreboxInfo,
  MyTeam,
  GroupSpaces,
  Integrations,
  AdvanceSettings,
} from '../../components/SettingsZyrebox'
import { SettingsZyreboxStyled } from './Settings.styles'
import { IoHome } from 'react-icons/io5'
import { FaUsers, FaBuffer, FaCubes } from 'react-icons/fa'
import { useWorkspaceContext } from '../../contexts/workspace'
import { useLocation } from '@reach/router'
import { ImCog } from 'react-icons/im'
import { useLazyQuery } from '@apollo/client'
import { GET_ONE_WORKSPACE } from '../../graphQL/Queries'
import { useAuthState } from '../../contexts/auth'
import { LanguageFloat, Loader, YouDontBelong } from '../../common'
import { useWindow } from '../../hooks/useWindow'
import { ResponsiveDefault } from '..'

const SettingsZyrebox = ({ idWorkspace }: any) => {
  const [width] = useWindow()
  const {
    handleWorkspace,
    workspace,
    impl: { SettingsZyreboxImpl },
  }: any = useWorkspaceContext()
  const [haveAccess, setHaveAccess] = useState(false)
  const { user } = useAuthState()
  const [currentIndex, setCurrentindex] = useState(0)
  const location = useLocation()
  const search = location.search
  const { darkMode }: any = useWorkspaceContext()

  const [getWorkspace, { loading, error }] = useLazyQuery(GET_ONE_WORKSPACE, {
    onCompleted: data => {
      handleWorkspace(data.getOneWorkspace)
      setHaveAccess(data.getOneWorkspace.owner.id === user.id)
    },
    onError: err => console.log(err),
  })

  const settingsList = [
    {
      id: 0,
      title: SettingsZyreboxImpl.tabs.zyreboxInfo.title,
      search: '?zyrebox-info',
      icon: <IoHome size="25px" />,
      component: <ZyreboxInfo impl={SettingsZyreboxImpl.tabs.zyreboxInfo} />,
    },
    {
      id: 1,
      title: SettingsZyreboxImpl.tabs.myTeam.title,
      search: '?my-team',
      icon: <FaUsers size="25px" />,
      component: <MyTeam impl={SettingsZyreboxImpl.tabs.myTeam} />,
    },
    {
      id: 2,
      title: SettingsZyreboxImpl.tabs.groupSpaces.title,
      search: '?group-spaces',
      icon: <FaBuffer size="25px" />,
      component: <GroupSpaces impl={SettingsZyreboxImpl.tabs.groupSpaces} />,
    },
    {
      id: 3,
      title: SettingsZyreboxImpl.tabs.integrations.title,
      search: '?integrations',
      icon: <FaCubes size="25px" />,
      component: <Integrations impl={SettingsZyreboxImpl.tabs.integrations} />,
    },
    {
      id: 4,
      title: SettingsZyreboxImpl.tabs.advanced.title,
      search: '?advanced',
      icon: <ImCog size="25px" />,
      component: <AdvanceSettings impl={SettingsZyreboxImpl.tabs.advanced} />,
    },
  ]
  const handleCurrentIndex = (index: number) => {
    setCurrentindex(index)
  }
  useEffect(() => {
    if (!workspace.id) {
      getWorkspace({ variables: { id: idWorkspace } })
    } else {
      setHaveAccess(workspace.owner.id === user.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace])
  if (loading)
    return (
      <div className="global-loader-wraper">
        <Loader />
      </div>
    )
  if (error) return <p>{error.graphQLErrors[0].message}</p>
  if (width < 600) {
    return <ResponsiveDefault />
  }
  return (
    <div>
      {haveAccess ? (
        <SettingsZyreboxStyled darkMode={darkMode}>
          <Sidebar
            menuItems={settingsList}
            currentIndex={currentIndex}
            handleCurrentIndex={handleCurrentIndex}
            className="settings-sidebar"
            idWorkspace={idWorkspace}
            search={search}
            impl={SettingsZyreboxImpl.sidebar}
          />
          <div className="settings-container">
            <div className="settings-container--wraper">
              {settingsList[currentIndex].component}
            </div>
          </div>
        </SettingsZyreboxStyled>
      ) : (
        <YouDontBelong />
      )}
      <LanguageFloat />
    </div>
  )
}

export default SettingsZyrebox
