import React, { useEffect, useState } from 'react'
import { FaSignOutAlt } from 'react-icons/fa'
import { Text, Button, Avatar, FloatingDarkMode } from '../../../common'
import { SidebarStyled } from './Sidebar.styles'
import { useAuthDispatch } from '../../../contexts/auth'
import { ImageLogoContainer } from './Sidebar.styles'
import { useMutation } from '@apollo/client'
import { UPLOAD_FILE } from '../../../graphQL/Mutations'
import { message } from 'antd'
import { uploadFileValidation } from '../../../helpers/fileUpload'

interface SidebarProps {
  currentIndex: number
  menuItems: any[]
  handleCurrentIndex: (index: number) => void
  className?: string
  idWorkspace: string
  search?: string
  currentUserData: any
  userId: any
  editUserDataFunc: (data: any) => void
  impl: any
}

const Sidebar = ({
  currentIndex,
  menuItems,
  handleCurrentIndex,
  className,
  search,
  currentUserData,
  userId,
  editUserDataFunc,
  impl,
}: SidebarProps): JSX.Element => {
  const dispatch = useAuthDispatch()

  const [logo, setLogo] = useState(currentUserData.profilePhoto)

  const [uploadFileMutation, { loading: loadingUpload }] = useMutation(
    UPLOAD_FILE,
    {
      onCompleted: data => {
        setLogo(data.uploadFile.url)
        editUserDataFunc({
          variables: {
            ...currentUserData,
            profilePhoto: data.uploadFile.url,
            phoneNumber:
              currentUserData.phoneNumber.indexOf('.') >= 0
                ? currentUserData.phoneNumber
                : currentUserData.phoneCode + '.' + currentUserData.phoneNumber,
            id: userId,
          },
        })
      },
      onError: err => {
        // setErrors(err.message)
        message.error(err.message, 5)
      },
    }
  )

  const handleUploadFile = (e: any) => {
    const file = e.target.files[0]
    if (!file) return
    uploadFileValidation(() => {
      uploadFileMutation({ variables: { file: file } })
    }, file)
  }
  useEffect(() => {
    if (search) {
      const item = menuItems.filter(item => item.search === search)
      handleCurrentIndex(item[0].id)
    }
  }, [search, handleCurrentIndex, menuItems])

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' })
    // navigate("/login");
    window.open(process.env.REACT_APP_BACKEND_URL + '/auth/logout', '_self')
  }

  const shortEmail = (email: string) => {
    const emailArray = email.split('@')
    return emailArray[0]
  }

  return (
    <SidebarStyled className={className}>
      <div className="sidebar-header">
        <div className="upload-image">
          <ImageLogoContainer>
            <Avatar
              htmlFor="file-input"
              size="100px"
              image={logo || currentUserData.profilePhoto}
              className="member__item--avatar"
              hasChangeMask={true}
              enableHoverMask={true}
              loadingUpload={loadingUpload}
            />
            <input id="file-input" type="file" onChange={handleUploadFile} />
          </ImageLogoContainer>
        </div>

        <Text
          type="h3"
          weight="800"
          color="#fff"
        >{`${currentUserData.name} ${currentUserData.lastName}`}</Text>
        <Text type="p" size="17px" weight="800" color="#6E839D">
          {currentUserData.email && shortEmail(currentUserData.email)}
        </Text>

        <FloatingDarkMode />
      </div>
      <div className="sidebar_items">
        {menuItems.map((item, key) => (
          <div
            className={
              currentIndex === key ? 'sidebar_item-active' : 'sidebar_item'
            }
            onClick={() => handleCurrentIndex(key)}
            key={key}
          >
            {item.icon}
            <p>{item.title}</p>
          </div>
        ))}
      </div>
      <div className="sidebar__footer">
        <Button color="warning" onClick={handleLogout} size="base" outlined>
          <FaSignOutAlt /> {impl.signoutBtn}
        </Button>
      </div>
    </SidebarStyled>
  )
}

export default Sidebar
