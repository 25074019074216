import { useMutation } from '@apollo/client'
import { Alert } from 'antd'
import React, { useEffect } from 'react'
import { ImPhone, ImPhoneHangUp } from 'react-icons/im'
import { useAuthState } from '../../../contexts/auth'
import { useNotificationContext } from '../../../contexts/NotificationContext'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { CHANGE_CALL_STATUS } from '../../../graphQL/Mutations'
import {
  IncomingCallContainer,
  UserImage,
  ActionsContainer,
} from './IncomingCall.styles'

interface IncomingCallProps {
  from: any
  to: any
  room: string
  status: string
  impl: any
}

const IncomingCall = ({ from, to, impl }: IncomingCallProps) => {
  const { user } = useAuthState()
  const { call, handleRoom, room: contextRoom }: any = useWorkspaceContext()
  const { showCommonAlert } = useNotificationContext()

  const ignitionSound = document.getElementById(
    'ringingSound'
  ) as HTMLMediaElement

  const [updateCall] = useMutation(CHANGE_CALL_STATUS, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCompleted: data => {},
    onError: err => {
      console.log(err)
    },
  })
  const answerCall = () => {
    if (!contextRoom.roomName) {
      updateCall({ variables: { idCall: call.id, status: 'answered' } })
      handleRoom({
        ...call.room,
        active: true,
      })
    } else {
      showCommonAlert({
        child: (
          <Alert
            message="Please, close the current room to answer the call."
            type="info"
          />
        ),
        position: 'topright',
        duration: 4000,
      })
    }
  }
  const closeCall = () => {
    updateCall({ variables: { idCall: call.id, status: 'not-answered' } })
  }
  useEffect(() => {
    if (call) {
      ignitionSound
        ?.play()
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(play => console.log('play'))
        .catch(err => console.log(err))
    }
    return () => {
      ignitionSound?.pause()
    }
  }, [call, ignitionSound])
  return (
    <IncomingCallContainer>
      {user.id === to.id ? (
        <UserImage image={from.profilePhoto} />
      ) : (
        <UserImage image={to.profilePhoto} />
      )}
      {user.id === to.id ? (
        <h4>{impl.incommig(from.name)}</h4>
      ) : (
        <h4>{impl.outcomming(to.name)}</h4>
      )}
      <ActionsContainer>
        {user.id === to.id && (
          <div onClick={answerCall} className="answer">
            <ImPhone size="25px" color="#42BA96" />
          </div>
        )}
        <div onClick={closeCall} className="hangUp">
          <ImPhoneHangUp size="25px" color="#DF4759" />
        </div>
      </ActionsContainer>
    </IncomingCallContainer>
  )
}

export default IncomingCall
