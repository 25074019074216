const TermsImpl = {
  TermsImpl: {
    termsMarksdown: `# TERMINOS Y CONDICIONES DEL USO DE **ZYREBOX**
  Es menester para la poder acceder a los servicios que se ofrecen en este sitio, que lea atentamente y se acepte los siguientes Términos y Condiciones que a continuación se redactan. 
  
  El uso de nuestros servicios, así como la compra de nuestros servicios implicará que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento. Todos los servicios que son ofrecidos por nuestro sitio web y pudieran ser creadas, cobradas, enviadas o presentados por una página web tercera y en tal caso estarían sujetas a sus propios Términos y Condiciones. Para adquirir un servicio, será necesario el registro por parte del usuario, con ingreso de datos personales fidedignos, definición de una contraseña y de caso sea necesario la validación de su identidad por medio de documentos que le serán requeridos.
  
  
  Es necesario que la persona que ingrese a [zyrebox.com], se someterá a los términos y condiciones descritos. Asimismo, debe tener en cuenta que estos términos y condiciones están sujetos a variaciones que se irán dando durante el tiempo, por lo que se recomienda estar atento a las notificaciones presentadas en el correo electrónico. Lo que no se encuentre suscrito en el presente documento, será complementado por la legislación vigente en la materia en la República del Perú.
  En caso de no estar conforme con los términos y condiciones descritos a continuación, solicitamos que se abstenga de utilizar la plataforma.
  
  
  ### ¿QUÉ ES **ZYREBOX**?
  **ZYREBOX** es una plataforma de comunicación a través de video llamadas y complementos requeridos en el uso del trabajo remoto. En la plataforma puede encontrar diferentes funcionalidades que permiten cubrir las herramientas requeridas del trabajo remoto. Usted puede conectar con su equipo de trabajo este donde este. En adelante se denominará a la página de **ZYREBOX**.com como **ZYREBOX** de ahora en adelante. 
  
  ### OBJETO DE LOS TÉRMINOS Y CONDICIONES
  El presente acuerdo pretende dar a conocer al usuario que visite y haga uso de la plataforma de **ZYREBOX** las condiciones y uso adecuado de la plataforma. Cuando usted utilice **ZYREBOX**, debe tener en cuenta el presente documento.
  
  ### REQUISITOS DEL SISTEMA
  El uso de los Servicios requiere uno o varios dispositivos compatibles, acceso a Internet (se pueden aplicar cargos) y cierto software (se pueden aplicar cargos) y puede ser necesario obtener actualizaciones o mejoras periódicas. Usted reconoce y acepta que estos requisitos del sistema, que pueden cambiar periódicamente, son su responsabilidad.
  
  ### ACCESO A LA PLATAFORMA
  El acceso a la página de **ZYREBOX** tiene carácter gratuito. Sin embargo, para acceder a servicios considerados de pago o paquetes superiores al gratuito, es necesario realizar un pago que se encuentra preestablecido. Los medios de pago se encuentran indicados en la plataforma.
  
  A su vez, el registro dentro de la plataforma es gratuito. Cabe señalar que la información proporcionada por el cliente/usuario, que sea registrada a través del registro, formulario y documentación, es real y verdadera. 
  
  **ZYREBOX** no se hará de responsable ante cualquier daño o perjuicio que pudiera sufrir el usuario como consecuencia del registro realizado en forma defectuosa u omisiva.El usuario acepta, por el hecho de ingresar sus datos en el Plataforma o en su defecto, registrarse en el mismo, **ZYREBOX** puede comunicarse con él por todas las vías y medios posibles, hasta donde la legislación aplicable sobre la materia lo permita.
  **ZYREBOX** se reserva el derecho de eliminar, sin previo aviso o notificación y sin ningún tipo de responsabilidad por ello, aquella información que: no cumplan con los parámetros establecidos; resulte información presuntamente falsa o inexacta; que contradigan los Términos y Condiciones; no reflejen el verdadero y exacto estado del usuario registrado.
  
  Los Usuarios son responsables, en cualquier caso, por la veracidad, exactitud, vigencia, legalidad y autenticidad de todos los datos e información personal ingresada.
  Para un funcionamiento óptimo de la plataforma y de los servicios que ofrece, es requerida una conexión a internet de alta velocidad, puesto que se hace uso del servicio de video llamadas.
  
  ### USO DE LA PLATAFOMA
  El usuario se compromete a usar de forma adecuada **ZYREBOX**, bajo los términos establecidos en el presente texto, así como la legislación complementaria que pueda existir, por lo que el usuario está obligado a:
  No modificar, traducir, reproducir, adaptar, crear o copiar alguna plataforma o derivado basado en **ZYREBOX** o relacionado al modelo de negocio presente.
  No reconocerse como trabajador de **ZYREBOX**, a menos que tenga autorización expresa por parte del Representante legal.
  
  No organizar, fomentar, crear o participar asociaciones en conjunto con grupos de usuarios con fines que contravengan las buenas costumbres ni vulneren el derecho de terceras personas. De ser así, **ZYREBOX** estará facultado de retirar a dichos usuarios y contactar con las autoridades de ser necesario. 
  
  La plataforma utilizada temporalmente para la comunicación por video llamadas es Jitsi Meeting(meet.jit.si), que es una herramienta de código abierto(open-source) y es empleada como medio para realizar video llamadas. Cabe resaltar que dicha plataforma no depende ni tiene ningún acuerdo con **ZYREBOX**, por lo que las actualizaciones que realice Jitsi Meeting quedarán bajo su responsabilidad y tendrán que ser aceptadas por el usuario para que pueda utilizar **ZYREBOX** sin ningún inconveniente. 
  No deberá realizar cualquier tipo de conducta que pueda dañar directa o indirectamente la plataforma o las cuentas de cualquiera de los usuarios de la plataforma. 
  No intentar interferir, piratear o descifrar alguna señal de los servidores utilizados por **ZYREBOX**, además tampoco podrá obstruir o interferir en la capacidad de otros usuarios en acceder a la plataforma.
  En caso que un usuario tenga conocimiento que otro usuario o servidor registrador en la plataforma, hubiera realizado cualquier acto no permitido por los términos y condiciones, deberá comunicar inmediatamente a **ZYREBOX**.
  
  ### EDAD MINIMA PARA ACCEDER AL SERVICIO
  Para acceder al servicio, debe tener por lo menos 18 años edad o en su defecto debe contar con el permiso de su tutor legal, padre o madre para usar **ZYREBOX**. Asegúrese de que su tutor legal, padre o madre lean estas condiciones con usted.
  Si es un padre, madre o tutor legal y le permite a su hijo usar los servicios, se aplicarán estas condiciones a usted, y usted será responsable de la actividad que realice el niño en los servicios.
  
  ### CARGOS Y CANCELACIÓN
  Usted acepta que **ZYREBOX** puede efectuar cobros en su tarjeta de crédito o por otro medio de pago que usted haya seleccionado. Se incluirán en dicho pago tanto el monto por la prestación del servicio, como también los impuestos requeridos. Según sea el país de origen, podrán realizarse cobros adicionales, según sea la localidad desde donde usted está solicitando el servicio. Los impuestos y las tarifas normativas que se cobran pueden modificarse sin previo aviso. Todos los pagos que nos realice en virtud de estos términos y condiciones se realizarán libres de toda deducción o retención, según lo requiera la ley. 
  
  En caso se requiera tal deducción o retención (incluidas, entre otras, las retenciones nacionales o transfronterizas) sobre cualquier pago, usted pagará los importes adicionales que sean necesarios para que el importe neto que recibamos nosotros sea igual al importe a pagar en ese momento de conformidad con estos términos y condiciones.
  
  Nosotros no proporcionamos los formularios fiscales que se soliciten razonablemente para reducir o eliminar el importe de cualquier retención o deducción de impuestos con respecto a los pagos realizados en virtud de los términos y condiciones.
  
  **ZYREBOX** se reserva el derecho de modificar precios en cualquier momento, lo que incluye cambiar de un servicio gratuito a uno de pago y cobrar Servicios que se ofrecieron previamente sin coste alguno. Sin embargo, **ZYREBOX** le avisará por anticipado y le ofrecerá cancelar su cuenta si **ZYREBOX** cambia el precio de un Servicio al cual usted está suscrito y no le cobrará ningún Servicio previamente gratuito, a no ser que se le hayan notificado las tarifas aplicables y usted haya aceptado abonarlas. Usted acepta que, en el caso de que **ZYREBOX** no pueda cobrar las tarifas correspondientes al uso de los servicios a través de su cuenta, **ZYREBOX** puede tomar cualquier otra medida que considere necesaria para cobrarle a el pago correspondiente y deberá asumir todos los costes y gastos relacionados con tal acción de cobro en los que **ZYREBOX** haya incurrido, lo que incluye comisiones por cobro, costas judiciales y honorarios de abogados. Puede cancelar su suscripción en cualquier momento. Si la cancela, no se le facturará ningún período de servicio adicional y el servicio se seguirá prestando hasta el final del Período de suscripción actual. Si la cancela, no recibirá rembolso por ningún servicio ya abonado.
  
  ### DERECHO DE PROPIEDAD
  **ZYREBOX** o cualquiera de sus proveedores, según corresponda, poseen todos los derechos de propiedad de los servicios y todos los nombres comerciales, marcas registradas, marcas de servicio, logotipos y nombres de dominio asociadas o expuestas con los servicios. No se permite enmarcar ni utilizar técnicas de enmarcado para incluir **ZYREBOX** ni otra información patentada (lo que incluye imágenes, textos, diseño de página y forma) de **ZYREBOX** sin consentimiento explícito por escrito. No se permite usar metaetiquetas o cualquier «texto oculto» con la marca de **ZYREBOX** sin el consentimiento explícito por escrito de la empresa.
  
  ### RESTRICCIÓN Y PROHIBICIÓN DE EMPLEO EN CASOS DE ALTO RIESGO
  Los servicios no están diseñados ni tienen licencia para usarlos en entornos peligrosos que requieran controles a prueba de fallos, lo que incluye, entre otros, el uso de instalaciones nucleares, sistemas de navegación/comunicación de aeronaves, control de tráfico aéreo y sistemas de soporte vital o de armamento. Los Servicios no se utilizarán para entornos de ALTO RIESGO ni en ellos puesto que no existe garantía que se desempeñen de forma correcta.
  
  ### MEDIDAS CAUTELARES
  Usted reconoce que cualquier uso de los Servicios contrario a este Acuerdo, o cualquier transferencia, sublicencia, copia o divulgación de información técnica o materiales relacionados con los Servicios puede causar perjuicios irreparables a **ZYREBOX**. Por lo que, bajo dichos motivos, **ZYREBOX** tiene la potestad de solicitar reparaciones equiparables, sin otorgar fianzas u otras garantías, lo que incluye, entre otras, medidas cautelares preliminares y permanentes.
  
  ### CLÁUSULA DE EXENCIÓN DE GARANTÍAS. 
  Al aceptar el servicio de **ZYREBOX**, usted los acepta de la forma en la cual se le están brindando, por lo que renuncia de forma expresa a solicitar garantías del cualquier tipo. **ZYREBOX** no otorga garantías de cualquier índole. **ZYREBOX** no brindan garantía que los servicios puedan funcionar de forma precisa o fiable puesto que está sometido a actualizaciones permanentes. En caso sea una actualización que requiera una restricción completa del suministro, se comunicará con antelación al usuario. El uso de los servicios brindados por **ZYREBOX** se realizan con cierto nivel de seguridad, sin embargo, no se puede garantizar que en todas las oportunidades el servicio se brinde de dicha manera. Usted será el único responsable de cualquier daño que le suceda a usted derivado del uso de los servicios. El riesgo total derivado del uso o prestación de los servicios recae en usted. **ZYREBOX** no asume ninguna responsabilidad por la retención de información de cualquier usuario o de las comunicaciones entre usuarios.
  
  ### INDEMINIZACIÓN
  Usted accede a indemnizar, defender y eximir a **ZYREBOX** y sus trabajadores por cada una de las reclamaciones de terceros, responsabilidad, daños o costes (incluidos, entre otros, honorarios de abogados) que se deriven del uso que usted realice de los servicios, el incumplimiento de los términos y condiciones por su parte o del incumplimiento o la infracción por su parte o la de otro usuario de su cuenta de cualquier propiedad intelectual u otro derecho de cualquier persona o entidad, o de la ley aplicable.
  
  ### COMPETENCIA Y JURISDICCIÓN
  Toda controversia derivada de los presentes términos y condiciones será resulta por el tribunal arbitral de la cámara de comercio de la ciudad de Arequipa. 
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  # POLITICA DE PRIVACIDAD
  ### DATOS RECOGIDOS
  Los datos personales son toda información de o acerca de una persona identificada o identificable, incluida información que **ZYREBOX** pueda asociar con la persona en cuestión. Podemos recopilar, o tratar en nombre de nuestros clientes:
  *	Información de cuenta y registro.
  *	Información de perfil del **ZYREBOX**.
  *	Integraciones de contactos y en calendarios.
  *	Uso de productos y sitios web
  *	Comunicaciones con **ZYREBOX**
  *	Información de los miembros del **ZYREBOX**.
  
  ### USO DE DATOS RECOPILADOS
  Ningún empleado de **ZYREBOX** acceden al contenido de las reuniones, seminarios web o mensajes (en concreto, audio, vídeo, archivos y mensajes), a no ser por autorización expresa del propietario del **ZYREBOX** o que sea necesario por motivos legales, de seguridad, soporte técnico o protección, como se explica a continuación. **ZYREBOX** utiliza datos personales para llevar a cabo las siguientes actividades:
  *Ofrecer servicios de **ZYREBOX**.
  Investigación y desarrollo de nuevos servicios.
  Validación de cuentas.
  Seguridad y protección de **ZYREBOX** y de sus usuarios.
  Comunicación con el usuario.
  Ámbito legal.
  ### A QUIEN SE COMPARTE LA INFORMACIÓN RECOPILADA
  **ZYREBOX** no proporciona la información recopilada con terceras personas y/o empresas. Solo podrán compartirse la información en 3 supuestos:
  * Por razones de investigación judicial o una orden Judicial con la finalidad de ayudar a la resolución de un hecho controvertido. 
  * Hay que tener en cuenta que las integraciones y extensiones de terceras compañías implementadas en **ZYREBOX** se rigen bajo políticas propias, por lo que hay la posibilidad que puedan recopilar la información que requieren. 
  * En caso **ZYREBOX** requiera su información, lo solicitará directamente al dueño del **ZYREBOX** y/o al usuario que se le requiere, indicándose la razón por la cual se requiere la información. 
  ### USUARIOS
  Los usuarios de **ZYREBOX** podrán a otras personas y miembros pertenecientes a sus equipos, incluidos terceros ajenos a la reunión, a su vez, la información que sea compartida.
  Propietario del **ZYREBOX**:  el propietario es la persona que se registra para tener una cuenta de **ZYREBOX**, el propietario del **ZYREBOX** designa a una o varias personas para pertenezcan a su equipo. El propietario del **ZYREBOX** puede invitar a otras personas (incluidos los invitados que no estén en su cuenta), siempre y cuando no pase el limite establecidos por equipo, caso contrario tendría que realizar el pago adicional por usuario correpondiente. 
  **ZYREBOX** ofrece a los usuarios de cuenta controles y funciones que pueden utilizar para determinar si se pueden crear o enviar ciertos tipos de contenido, como grabaciones o mensajes fuera de las reuniones, y qué aplicaciones de terceros se pueden utilizar, para las reuniones que se celebren en su cuenta. Dependiendo de su configuración, los usuarios de cuenta pueden acceder a los datos personales de las personas que se unan a las reuniones. En concreto, los usuarios de las cuentas pueden tener acceso a:
  * Lista de participantes
  * Información de registro
  * Mensajes durante las reuniones.
  * Mensajes enviados a todos en una reunión que se graba.
  * Grabaciones.
  * Transmisiones en directo.
  * Aplicaciones de terceros e integraciones.
  
  ### MENORES DE EDAD
  **ZYREBOX** no permite que menores de 18 años creen una cuenta, con excepción que sea bajo la autorización de su tutor. En ese caso el tutor será responsable de las actividades que realice el menor en la plataforma.
  
  
  # USO DE COOKIES
  El presente acuerdo señala que **ZYREBOX** utiliza cookies en nuestro sitio web, incluidos los servicios prestados en dichos sitios web (denominados de forma conjunta «Sitios web»).
  **ZYREBOX** utiliza las Cookies para habilitar la funcionalidad básica y comprender cuándo el sitio web de **ZYREBOX** y cómo el usuario interactúa con ellos, recopilar información sobre su dispositivo y configuración. Según su país de origen, se pueden aplicar diferentes políticas de cookies.
  Podemos definir a como cookies como un conjunto de datos de una página web que envía a tu ordenador cuando visitas una página determinada. No importa por el navegador que se entre, se solicitará necesariamente la aceptación en las mismas. Toda la información será solicitada al momento de ingresar a la plataforma. 
  ### CLASES DE COOKIES
  * Requeridas: Son las cookies necesarias para la navegación en el sitio web, con la finalidad de almacenar el inicio de sesión y guardar las configuraciones realizadas. También dentro de estas podemos encontrar a las cookies encargadas de la optimización del rendimiento técnico y encargándose de la presentación de la web según las condiciones de la red en ese momento.
  * Optimizadoras: Estas cookies ayudan a las métricas en cuanto al uso y rendimiento. Estas cookies están orientadas al uso dentro de los visitantes individuales a un sitio web. Estas cookies ayudan a entender a **ZYREBOX** como usan los usuarios la plataforma.
  ###CONTROL DE COOKIES
  Puede controlar las cookies directamente desde su navegador. Lo usual es que los navegadores acepten las cookies por defecto, pero todos tienen la opción de bloquearlas o eliminarlas. Para obtener las opciones para eliminar las cookies de su navegador, debe seguir la ruta predeterminada para cada uno. Para más información, visite el siguiente enlace: [https://www.aboutcookies.org/].
  ### NO RASTREAR
  Algunos navegadores web tienen una característica «No rastrear». Esta característica le permite indicarles a los sitios web que visita que no desea que se rastree su actividad en línea. Estas características aún no son uniformes en todos los navegadores. **ZYREBOX** no están configurado actualmente para responder a esas señales.
  Todas las actualizaciones que se realicen a las políticas de **ZYREBOX**, estarán publicadas en esta sección.
  
  
  `,
  },
}

export default TermsImpl
