const RegisterImpl = {
  RegisterImpl: {
    title: 'Register',
    nameInput: {
      label: 'Name',
      placeholder: 'Enter your name',
    },
    lastNameInput: {
      label: 'Last Name',
      placeholder: 'Enter your last name',
    },
    emailnput: {
      label: 'Email',
      placeholder: 'Enter your email address',
    },
    passwordnput: {
      label: 'Password',
      placeholder: 'Enter your password',
    },
    confirmPasswordnput: {
      label: 'Confirm your Password',
      placeholder: 'Confirm your password',
    },
    registerBtn: 'Register',
    acceptTermsLabel: 'I accept terms and conditions',
    requireCheckLabel: '*To continue, please accept the terms and conditions',
    loginBtn: 'Log In',
    haveAccount: 'Already have an account?',
  },
}

export default RegisterImpl
