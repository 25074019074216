import styled, { css } from 'styled-components'

export const BadgeStyled = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${props => props.fontSize || '12px'};
  line-height: ${props => props.lineHeight || '13px'};
  display: block;
  padding: ${props => props.padding || '4px 0px'};
  margin: ${props => props.margin || '0'};
  width: 100%;
  text-align: center;
  border-radius: 5px;
  .backside {
    display: none;
  }
  ${props =>
    props.backchildren &&
    css`
      &:hover {
        color: #fff;
        background-color: #232323;
        .frontside {
          display: none;
        }
        .backside {
          display: block;
        }
      }
    `}
  ${props =>
    props.color === 'primary'
      ? css`
          color: #232323;
          background-color: #e2e2e2;
          ${props =>
            props.backchildren &&
            css`
              &:hover {
                color: #fff;
                background-color: #232323;
              }
            `}
        `
      : props.color === 'pink'
      ? css`
          color: #fa2baa;
          background-color: #fed4ee;
          ${props =>
            props.backchildren &&
            css`
              &:hover {
                color: #fff;
                background-color: #232323;
              }
            `}
        `
      : css`
          color: #232323;
          background-color: #e2e2e2;
        `}
`
