import { navigate } from '@reach/router'
import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import images from '../../../assets/static/images'
import { Button, Text } from '../../../common'
import { SubscribeCACStyled } from './SubscribeCAC.styles'

interface SubscribeCACProps {
  implSubscribeCAC: any
}

const SubscribeCAC = ({
  implSubscribeCAC: {
    maintitle,
    subtitle,
    numberzyreboxcreated,
    zyreboxcreated,
    numberzyreboxteams,
    zyreboxteams,
    numbercallsdone,
    callsrealized,
    titlepartsubscribed,
    subtitlepartsubscribed,
    buttonsubscribedtopro,
  },
}: SubscribeCACProps) => {
  return (
    <div>
      <SubscribeCACStyled image={images.landingSectionImage}>
        <div className="skew-div">
          <div className="section-image"></div>
          <div className="subscribeText">
            <div className="subscribeCAC-content" data-aos="fade-up">
              <Text type="h1" color="#fff" align="center" size="55px">
                {maintitle}
              </Text>
              <Text type="h5" color="#6E839D" align="center">
                {subtitle}
              </Text>
            </div>
            <div className="subscribeCAC-content__numbers">
              <div>
                <Text type="h1" color="#fff" align="center" size="55px">
                  {numberzyreboxcreated}
                </Text>
                <Text type="h5" color="#6E839D" align="center">
                  {zyreboxcreated}
                </Text>
              </div>
              <div>
                <Text type="h1" color="#fff" align="center" size="55px">
                  {numberzyreboxteams}
                </Text>
                <Text type="h5" color="#6E839D" align="center">
                  {zyreboxteams}
                </Text>
              </div>
              <div>
                <Text type="h1" color="#fff" align="center" size="55px">
                  {numbercallsdone}
                </Text>
                <Text type="h5" color="#6E839D" align="center">
                  {callsrealized}
                </Text>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="subscribeCAC-buttontopro" data-aos="fade-up">
            <Text
              className="subscribeCAC-buttontopro_title"
              type="h1"
              color="#fff"
              align="center"
              size="55px"
            >
              {titlepartsubscribed}
            </Text>
            <Text
              className="subscribeCAC-buttontopro_subtitle"
              type="h5"
              color="#6E839D"
              align="center"
            >
              {subtitlepartsubscribed}
            </Text>
            <Button
              size="base"
              color="pink"
              onClick={() => {
                navigate('/checkout')
              }}
            >
              {buttonsubscribedtopro}
              <FaArrowRight />
            </Button>
          </div>
        </div>
      </SubscribeCACStyled>
    </div>
  )
}

export default SubscribeCAC
