import { navigate } from '@reach/router'
import React, { useEffect } from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { Button } from '../../../common'
import { SidebarStyled } from './Sidebar.styles'

interface SidebarProps {
  currentIndex: number
  menuItems: any[]
  handleCurrentIndex: (index: number) => void
  className?: string
  idWorkspace: string
  search?: string
  impl: any
}

const Sidebar = ({
  currentIndex,
  menuItems,
  handleCurrentIndex,
  className,
  idWorkspace,
  search,
  impl,
}: SidebarProps): JSX.Element => {
  const handleBackButton = () => {
    navigate(`/workspace/${idWorkspace}`)
  }

  useEffect(() => {
    if (search) {
      const item = menuItems.filter(item => item.search === search)
      handleCurrentIndex(item[0].id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])
  return (
    <SidebarStyled className={className}>
      <div className="sidebar-header">
        <Button color="light" onClick={handleBackButton} size="extraSmall">
          <FaChevronLeft />
          {impl.backlabel}
        </Button>
        {/* <img src={images.logoSettings} alt='logo-zyrebox'/> */}
        {/* <Text type='h3' weight='800'>{impl.sidebarTitle}</Text> */}
      </div>
      <div className="sidebar_items">
        {menuItems.map((item, key) => (
          <div
            className={
              currentIndex === key ? 'sidebar_item-active' : 'sidebar_item'
            }
            onClick={() => handleCurrentIndex(key)}
            key={key}
          >
            {item.icon}
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </SidebarStyled>
  )
}

export default Sidebar
