/* eslint-disable array-callback-return */
import { useQuery } from '@apollo/client'
import { navigate } from '@reach/router'
import React from 'react'
import { ZyreboxItem } from '..'
import images from '../../../assets/static/images'
import { Avatar, Loader, Text } from '../../../common'
import { useAuthState } from '../../../contexts/auth'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { GET_WORKSPACES_QUERY } from '../../../graphQL/Queries'
import { ListZyreboxStyled, LoaderContainer } from './ZyreboxList.styles'
interface ListZyreboxProps {
  setCountWorkspaces: any
  impl: any
}

const ListZyrebox = ({
  setCountWorkspaces,
  impl,
}: ListZyreboxProps): JSX.Element => {
  const {
    listOfWorkspaces,
    handListOfWorkspaces,
    handstaticWorkspaces,
    staticWorkspaces,
    darkMode,
  }: any = useWorkspaceContext()
  // const [workspaces, setWorkspaces] = useState<any[]>([])

  const { user } = useAuthState()
  const { loading } = useQuery(GET_WORKSPACES_QUERY, {
    onCompleted: data => {
      // setWorkspaces(data.getWorkspaces)
      handListOfWorkspaces(data.getWorkspaces)
      handstaticWorkspaces(data.getWorkspaces)
      const ownerWorkspaces = data.getWorkspaces.filter(
        (workspace: any) => user.id.toString() === workspace.owner.id.toString()
      )
      setCountWorkspaces(ownerWorkspaces.length)
    },
    onError: err => console.log(err),
  })
  const getNextRoute = (workspaces: any[]) => {
    const ownerWorkspaces = workspaces.filter(
      workspace => workspace.owner.id === user.id
    )
    if (ownerWorkspaces.length > 0 && user.subscription === false) {
      return '/subscription'
    } else {
      return '/create-zyrebox'
    }
  }
  // const isSubscribed = staticWorkspaces.length > 0 && user.subscription === false ? '/subscription' : '/create-zyrebox'
  const handleCreateWorkspace = () => {
    navigate(getNextRoute(staticWorkspaces))
  }
  return (
    <>
      {!loading ? (
        <ListZyreboxStyled items={listOfWorkspaces.length} darkMode={darkMode}>
          <div className="items-container">
            {listOfWorkspaces.map((workspace: any) => (
              <ZyreboxItem key={workspace.id} workspace={workspace} />
            ))}
            <div className="items-create">
              <div onClick={handleCreateWorkspace}>
                <Avatar
                  size="100px"
                  image={images.commonIcons.plus}
                  borderColor=" #94A5C0"
                  borderSize="1px"
                  backgroundColor="transparent"
                />
                <Text align="center" color=" #94A5C0" type="p" size="21px">
                  {impl.newZyrebox}
                </Text>
              </div>
            </div>
          </div>
        </ListZyreboxStyled>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </>
  )
}

export default ListZyrebox
