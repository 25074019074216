import { useMutation } from '@apollo/client'
import { message } from 'antd'
import React, { useState } from 'react'
import { FaCamera } from 'react-icons/fa'
import { Button, Input, Loader, Text } from '../../../common'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { EDIT_WORKSPACE, UPLOAD_FILE } from '../../../graphQL/Mutations'
import { uploadFileValidation } from '../../../helpers/fileUpload'
import { ImageLogoContainer, ZyreboxInfoStyled } from './ZyreboxInfo.styles'

interface ZyreboxInfoProps {
  impl: any
}

const ZyreboxInfo = ({ impl }: ZyreboxInfoProps): JSX.Element => {
  const { darkMode, workspace }: any = useWorkspaceContext()
  const [name, setName] = useState(workspace.name)
  const [logo, setLogo] = useState(workspace.logo)
  const [companyName, setCompanyName] = useState(workspace.companyName)
  const [description, setDescription] = useState(workspace.description)
  // const [imageRoute, setImageRoute] = useState('')
  const [uploadFileMutation, { loading: loadingUpload }] = useMutation(
    UPLOAD_FILE,
    {
      onCompleted: data => {
        console.log('[res]', data)
        setLogo(data.uploadFile.url)
      },
      onError: err => {
        console.log(err)
        message.error(err.message)
      },
    }
  )
  const handleUploadFile = (e: any) => {
    const file = e.target.files[0]
    if (!file) return
    uploadFileValidation(() => {
      uploadFileMutation({ variables: { file } })
    }, file)
  }
  const [editWorkspace, { loading }] = useMutation(EDIT_WORKSPACE, {
    onCompleted: data => {
      if (data) {
        message.success('Changes were saved successfully')
      }
    },
    onError: err => {
      message.error('Ups! An error has occurred while saving your changes')
      message.error(err.message)
    },
  })
  const handleDisabled = () => {
    if (
      name === workspace.name &&
      logo === workspace.logo &&
      companyName === workspace.companyName &&
      description === workspace.description
    ) {
      return true
    }
    return false
  }
  // TODO upload File and set Logo
  const handleSubmitCreate = (e: any) => {
    e.preventDefault()
    editWorkspace({
      variables: {
        name,
        companyName,
        description,
        logo,
        id: workspace.id,
      },
    })
  }
  return (
    <div>
      <ZyreboxInfoStyled>
        <Text color={darkMode ? '#fff' : '#161C2D'} type="h2">
          {impl.title}
        </Text>
        <Text color="#95A7C1" type="h4">
          {impl.subtitle}
        </Text>
        <div className="card">
          <div className="upload-image">
            <ImageLogoContainer image={logo}>
              <label htmlFor="file-input">
                {loadingUpload ? (
                  <Loader size="25px" border="3px" />
                ) : (
                  <>
                    <FaCamera color="#fff" />
                    <p>Change</p>
                  </>
                )}
              </label>
              <input id="file-input" type="file" onChange={handleUploadFile} />
            </ImageLogoContainer>
          </div>
          <form className="info-form" onSubmit={handleSubmitCreate}>
            <Input
              label={impl.nameInput.label}
              placeHolder={impl.nameInput.placeholder}
              value={name}
              onChange={value => setName(value)}
              maxLength={21}
              validationError={''}
            />
            <Input
              label={impl.companyInput.label}
              placeHolder={impl.companyInput.placeholder}
              value={companyName}
              maxLength={30}
              onChange={value => setCompanyName(value)}
              validationError={''}
            />
            <Input
              label={impl.descriptionInput.label}
              placeHolder={impl.descriptionInput.label}
              value={description}
              onChange={value => setDescription(value)}
              maxLength={210}
              validationError={''}
              textArea="textarea"
              rows={10}
              cols={40}
            />
            <div className="submit-btn">
              <div className="mr-2">
                {loading && <Loader size="25px" border="3px" />}
              </div>
              <Button color="primary" size="base" disabled={handleDisabled()}>
                {impl.submitLabel}
              </Button>
            </div>
          </form>
        </div>
      </ZyreboxInfoStyled>
    </div>
  )
}

export default ZyreboxInfo
