import { MySpacePopOverStyled } from './MySpacePopOver.styles'

interface MySpacePopOverProps {
  user: any
  impl: any
  handleDarkMode: () => void
  darkMode: any
}

export default function MySpacePopOver({
  user,
  impl,
  handleDarkMode,
  darkMode,
}: MySpacePopOverProps) {
  return (
    <MySpacePopOverStyled>
      <div className="avatar__user-info">
        <div className="username">{`${user.name} ${user.lastName}`}</div>
        <div className="email">{user.email}</div>
      </div>
      <div className="avatar__separator"></div>
      <div className="darkmode">
        <div className="label">{impl.darkmodeLabel}</div>
        <input
          className="switch"
          type="checkbox"
          id="darkmodeSwitch"
          onChange={handleDarkMode}
          checked={darkMode}
        />
      </div>
    </MySpacePopOverStyled>
  )
}
