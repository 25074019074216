import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  .container-div {
    margin: 0px 0px;
    grid-column: 3/9;
    @media screen and (max-width: 1200px) {
      grid-column: 2/10;
      margin: 0px 20px;
    }
    @media screen and (max-width: 600px) {
      grid-column: 1/11;
      margin: 0px 10px;
    }
  }
`
