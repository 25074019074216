const PricingImpl = {
  LandingPricingImpl: {
    maintitle: 'Subscribe and get all our features!',
    subtitle:
      "Get all the Benefits that you need for your team subscribing only you, you don't need to subscribe all the team.",
    monthly: 'Monthly',
    anualy: 'Anual',
    soon: "Features with(*)'ll be available soon",
    featurelist: {
      subscribeInfo: [
        {
          title: 'Startup',
          custom: false,
          monthPrice: '0',
          anualPrice: '0',
          yearlabel: 'year',
          monthlabel: 'month',
          include: 'include',
          features: [
            {
              name: 'Unlimited Calls',
            },
            {
              name: 'Unlimited Call minutes',
            },
            {
              name: 'Unlimited Messages',
            },
            {
              name: 'Members/team: 10',
            },
            {
              name: 'Group spaces: 2',
            },
            {
              name: 'Free team space',
            },
            {
              name: 'Whatsapp Integration',
            },
            {
              name: 'Video Conference provider: 1',
            },
            {
              name: 'Embed integrations: 3 *',
            },
            {
              name: 'API integrations: 3 *',
            },
            {
              name: 'Fast links: Unlimited *',
            },
          ],
          cac: {
            label: 'Start FREE',
            color: 'primary',
            soft: true,
            route: '/register',
          },
        },
        {
          title: 'Business',
          custom: true,
          monthPrice: '25',
          anualPrice: '250',
          yearlabel: 'team/year',
          monthlabel: 'team/month',
          include: 'include',
          features: [
            {
              name: 'All the benefits of the startup plan',
            },
            {
              name: 'Free team space',
            },
            {
              name: 'Full access space',
            },
            {
              name: 'Members: 10/team',
            },
            {
              name: 'Group spaces: Unlimited',
            },
            {
              name: 'Video Conference provider: All',
            },
            {
              name: 'Embed integrations: +50 *',
            },
            {
              name: 'API integrations: +50 *',
            },
            {
              name: 'Fast links: Unlimited *',
            },
          ],
          cac: {
            label: 'Subscribe to Business',
            color: 'primary',
            soft: false,
            route: '/checkout',
          },
        },
      ],
    },
    optionalpricing: {
      howworks: 'Do you need more?',
      customplan: 'Plan Custom',
      teams: 'Teams',
      teamsmontly: 'Team/month',
      customfeatures:
        'You can convert your team to Business and create all the teams you need it. Each team you purchase, you will have space for 10 members per team',
      aditionalmembers: 'Aditional members',
      membermontly: 'member/month',
      memberfeatures:
        ' If ou need more of 10 members in your teams en alguno de tus equipos, you can buy an aditional space for each one',
      add: 'Includes',
      allfeatures: 'All the features of the Business Suscripción',
      buttoniwant: ' I want it!',
    },
    faqImpl: {
      maintitle: 'Frequently asked questions',
      subtitle:
        'Let us help you answering the most frequently asked questions.',
      frecuentquestion: [
        {
          question: 'Must I have an account to use Zyrebox?',
          answer:
            'No, It is not required to have an account to use Zyrebox, in case you enter to the meeting as a guest throught a link provided by a user who has an account on the platform. If you want to create a meeting with Zyrebox, you must have an account on the platform.',
        },
        {
          question: 'How can I register in Zyrebox?',
          answer:
            'You can sign up for a free account at zyrebox.com/register. It does not require any payment. Yo only have to confirm your account through a confirmation sent to your associated email.',
        },
        {
          question: 'Does it cost anything to use Zyrebox',
          answer:
            'Zyrebox has a free version and without any charge. However, you can subscribe to the Business plan with funcionalities that adapt to the needs of the teams',
        },
        {
          question: 'Can I use Zyrebox on my smartphone?',
          answer:
            'Yes, you can but with limitations. But don´t worry, our mobile app will be avaible for your teams very soon',
        },
        {
          question: 'Can I add more than 10 people to my teams?',
          answer:
            'You can add as many people as you need, just add $2 your monthly subsription amount, for each additional member you want on your team',
        },
        {
          question: 'Can I use the integrations in my free account?',
          answer:
            'No. Solo puedes acceder a las integraciones en caso tengas una cuenta Business. Y todas las integraciones podrán ser utilizadas por los miembros del equipo sin coste alguno.',
        },
      ],
    },
  },
}

export default PricingImpl
