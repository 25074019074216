import styled from 'styled-components'

export const ToggleSwitchStyled = styled.div`
  .switch {
    appearance: none;
    height: ${({ height }) => height || '2em'};
    width: ${({ width }) => width || '4em'};
    background-color: ${({ backgroundColor }) => backgroundColor || '#c7c7c7'};
    cursor: pointer;
    border-radius: 10em;
    position: relative;
    transition: background-color 0.3s ease;
  }

  .switch::before {
    height: 100%;
    width: 50%;
    content: ' ';
    background-color: ${({ switchColor }) => switchColor || '#FFF'};
    position: absolute;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    /* border: #c7c7c7 solid 0.2em; */
    transition: transform 0.3s ease, border-color 0.3s ease;
  }

  .switch:checked {
    background-color: ${({ backgroundColorActive }) =>
      backgroundColorActive || '#0d374b'};
  }

  .switch:checked::before {
    background-color: ${({ switchColor }) => switchColor || '#fff'};
    transform: translateX(100%);
  }

  @media screen and (max-width: 500px) {
    .switch {
      outline: none;
    }
  }
`
