import { FC, ReactNode } from 'react'
import { Navbar } from '../../shared'
import Footer from '../../shared/Footer'
import Button from './../../common/Button/Button'
import { useWorkspaceContext } from './../../contexts/workspace'
import { useNavigate } from '@reach/router'
type Props = {
  children: ReactNode
}
const LayoutLanding: FC<Props> = ({ children }) => {
  const {
    impl: { CheckoutImpl },
  }: any = useWorkspaceContext()
  const navigate = useNavigate()
  return (
    <main className="bg-gray-50">
      <Navbar />
      <div className="mt-16 bg-black-500 p-2 text-center">
        <span className="text-white mr-6">{CheckoutImpl.promoMsg}</span>
        <Button
          size="small"
          color="secondary"
          outlined
          onClick={() => {
            navigate('/contact')
          }}
          textColor="#fff"
        >
          {CheckoutImpl.promoBtn}
        </Button>
      </div>
      <div className="container mx-auto pt-4 pb-40">
        <div className="grid grid-cols-12">
          <div className="col-start-3 col-span-8">{children}</div>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default LayoutLanding
