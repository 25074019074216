import React from 'react'
import Avatar from '../Avatar'
import { ImageLogoContainer } from './ImageUploaderAvatar.styles'

type ImageUploaderAvatarProps = {
  image: string
  loadingUpload: boolean
  handleUploadFile: (e: any) => void
  size: string
  className: string
  name: string
}

const ImageUploaderAvatar = ({
  image,
  loadingUpload,
  handleUploadFile,
  size,
  className,
  name,
}: ImageUploaderAvatarProps) => {
  return (
    <>
      <ImageLogoContainer>
        <Avatar
          htmlFor="file-input"
          size={size || '100px'}
          image={image}
          className={className}
          hasChangeMask={true}
          enableHoverMask={true}
          loadingUpload={loadingUpload}
        />
        <input
          id="file-input"
          type="file"
          onChange={handleUploadFile}
          name={name}
        />
      </ImageLogoContainer>
    </>
  )
}

export default ImageUploaderAvatar
