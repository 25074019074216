import styled from 'styled-components'

export const RegisterStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(100, 1fr);
  overflow: hidden;
  position: relative;
  .side-background {
    grid-column: 40/101;
    background: linear-gradient(
        190.57deg,
        rgba(23, 36, 66, 0.58) 69.1%,
        rgba(51, 94, 234, 0.5) 113.57%
      ),
      url(${props => props.backgroundImage}), #c4c4c4;
    background-size: cover;
    background-position: right center;
    height: 100vh;
    transform: skew(-7deg) translate(100px, 0px);
    -webkit-transform: skew(-7deg) translate(100px, 0px);
    -moz-transform: skew(-7deg) translate(100px, 0px);
    position: fixed;
    top: 0;
    left: 40%;
    width: 100%;
  }
  .alert {
    margin: 15px 0px;
    position: fixed;
    width: 20vw;
    top: 100px;
  }
  .main-content {
    grid-column: 1/101;
    margin: 50px 20px 50px 20px;
    @media (min-width: 900px) {
      margin: 50px 50px 50px 100px;
      grid-column: 1/35;
    }
    &__logo {
      margin-bottom: 70px;
    }
    &__title {
      margin-bottom: 30px;
    }
    .login-form {
      &__input {
        margin-bottom: 15px;
      }
      &__button {
        margin: 10px 0px;
      }
      &__checkbox {
        input {
          margin-right: 8px;
        }
        span {
          display: block;
          margin: 8px 0px;
        }
      }
    }
    &__footer {
      margin-top: 40px;
      margin-bottom: 15px;
      &--link {
        text-align: center;
      }
      &--footer {
        margin-top: 100px;
      }
    }
  }
`
