import { navigate } from '@reach/router'
import React, { useState } from 'react'
import {
  FaChevronDown,
  FaChevronUp,
  FaPlusCircle,
  FaShare,
} from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { Badge, Button, Loader, Text } from '../../../common'
import { useAuthState } from '../../../contexts/auth'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { SectionActions } from '../GroupSpaces/GroupSpaces.styles'
import { TeamListContainer } from './MyTeam.styles'
import Member from './Member'
import { Popover } from 'antd'
import { CIPHER_TEXT } from '../../../graphQL/Mutations'
import { useMutation } from '@apollo/client'

type MyTeamProps = {
  members: any[]
  impl: any
}

const MyTeam = ({ members, impl }: MyTeamProps): JSX.Element => {
  const { workspace }: any = useWorkspaceContext()
  const { user } = useAuthState()
  const [showList, setShowList] = useState(true)
  const [shareUrl, setShareUrl] = useState('')
  const handleShowList = () => {
    setShowList(!showList)
  }

  const handleAddMemberByMail = () => {
    navigate(`/workspace/edit/${workspace.id}?my-team`)
  }
  const [cipherText, { loading: loadingCipher }] = useMutation(CIPHER_TEXT, {
    onCompleted: data => {
      try {
        setShareUrl(
          process.env.REACT_APP_PUBLIC_URL + '/invite/' + data.cipherText
        )
        handleShareRoom(
          `${process.env.REACT_APP_PUBLIC_URL}/invite/${data.cipherText}`
        )
      } catch (err: any) {
        throw new Error(err)
      }
    },
  })
  const handleShareRoom = (url: string) => {
    const tempUrl = ''
    const textArea = document.createElement('textarea')
    textArea.value = url
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('Copy')
    textArea.remove()
    setShareUrl('Url Copied')
    setTimeout(() => {
      setShareUrl(tempUrl)
    }, 2000)
  }
  function generateInvitationUrl() {
    cipherText({
      variables: {
        payload: JSON.stringify({
          idWorkspace: workspace.id,
          open: true,
        }),
      },
    })
  }
  return (
    <div className="">
      <Badge
        color={'primary'}
        backchildren={
          <SectionActions>
            <div onClick={handleShowList} className="action-button">
              {showList ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {workspace.owner && user.id === workspace.owner.id && '|'}
            {workspace.owner && user.id === workspace.owner.id && (
              <Popover
                trigger="click"
                placement="right"
                content={
                  <div>
                    <Text type="h5" align="center">
                      {impl.addMember.title}
                    </Text>
                    <div className="actions-container">
                      <div className="grid place-items-center">
                        <Button
                          onClick={handleAddMemberByMail}
                          soft
                          color="primary"
                          size="extraSmall"
                        >
                          <MdEmail size="18px" />
                        </Button>
                        <Text
                          type="p"
                          align="center"
                          color="#869AB8"
                          weight="400"
                          size="12px"
                        >
                          {impl.addMember.methodOne}
                        </Text>
                      </div>
                      <div className="grid place-items-center">
                        <Button
                          onClick={generateInvitationUrl}
                          soft
                          color="primary"
                          size="extraSmall"
                        >
                          {loadingCipher ? (
                            <Loader size="25px" border="3px" />
                          ) : (
                            <>
                              {shareUrl === '' ? (
                                <FaShare size="18px" />
                              ) : (
                                'Copied'
                              )}
                            </>
                          )}
                          <span
                            id="copyTextInvite"
                            style={{ display: 'none' }}
                          ></span>
                        </Button>
                        <Text
                          type="p"
                          align="center"
                          color="#869AB8"
                          weight="400"
                          size="12px"
                        >
                          {impl.addMember.methodTwo}
                        </Text>
                      </div>
                    </div>
                  </div>
                }
              >
                <div className="action-button">
                  <FaPlusCircle />
                </div>
              </Popover>
            )}
          </SectionActions>
        }
      >
        {impl.badgeLabel}
      </Badge>
      <TeamListContainer showList={showList}>
        {members &&
          members.map(
            member =>
              user.id !== member.id && (
                <Member
                  member={member}
                  image={workspace.logo}
                  workspaceName={workspace.name}
                  key={member.id}
                  impl={impl.member}
                />
              )
          )}
      </TeamListContainer>
    </div>
  )
}

export default MyTeam
