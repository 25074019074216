import React from 'react'
import { Badge, Text } from '../../../common'
import { QnAStyled } from './QnA.styles'

interface faqImplProps {
  faqImpl: any
}

const QnA = ({
  faqImpl: { maintitle, subtitle, frecuentquestion },
}: faqImplProps) => {
  return (
    <div>
      <QnAStyled>
        <div className="qna lg:container sm:container md:container">
          <div className="qna__badge">
            <Badge color="primary">FAQ</Badge>
          </div>
          <div className="qna__header">
            <Text type="h1" align="center" size="36px">
              {maintitle}
            </Text>
            <Text type="h3" align="center" color="#869AB8" size="17px">
              {subtitle}
            </Text>
          </div>
          <div className="qna__questions">
            {frecuentquestion.map((question: any, key: number) => (
              <div key={key}>
                <Text type="p" color="#161C2D" size="21px">
                  {question.question}
                </Text>
                <Text type="p" color="#495B80" size="17px">
                  {question.answer}
                </Text>
              </div>
            ))}
          </div>
        </div>
      </QnAStyled>
    </div>
  )
}

export default QnA
