import React from 'react'
import { FaCamera } from 'react-icons/fa'
import { Loader } from '../../common'
import { AvatarStyled } from './Avatar.styles'

interface AvatarProps {
  image: string
  size: string
  haveStatus?: boolean
  currentStatus?: boolean
  onClick?: () => void
  backgroundColor?: string
  borderColor?: string
  borderSize?: string
  className?: string
  hasChangeMask?: boolean // If true, enable upload mask
  enableHoverMask?: boolean // If true, shows upload mask when hover
  loadingUpload?: boolean // If true, shows loader
  htmlFor?: string
}
const Avatar = ({
  image,
  size,
  haveStatus,
  currentStatus,
  onClick,
  backgroundColor,
  borderColor,
  borderSize,
  className,
  hasChangeMask,
  enableHoverMask,
  loadingUpload,
  htmlFor,
}: AvatarProps): JSX.Element => {
  return (
    <>
      <AvatarStyled
        image={image}
        size={size}
        currentStatus={currentStatus}
        onClick={onClick}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        borderSize={borderSize}
        className={className}
        hasChangeMask={hasChangeMask}
        enableHoverMask={enableHoverMask}
        loadingUpload={loadingUpload}
      >
        {haveStatus && (
          <div>
            <div />
          </div>
        )}
        <label htmlFor={htmlFor}>
          {loadingUpload ? (
            <Loader size="25px" border="3px" />
          ) : (
            <>
              {hasChangeMask && (
                <>
                  <FaCamera color="#fff" />
                  <p>Change</p>
                </>
              )}
            </>
          )}
        </label>
      </AvatarStyled>
    </>
  )
}

export default Avatar
