import React from 'react'
import { ActionStyled, ModalStyled } from './Modal.styles'

interface ModalProps {
  action: any
  children: any
  show: boolean
  handleModal: () => void
  className?: string
}

const Modal = ({
  action,
  children,
  show,
  handleModal,
  className,
}: ModalProps): JSX.Element => {
  return (
    <>
      <ActionStyled onClick={handleModal} className={className}>
        {action}
      </ActionStyled>
      <ModalStyled show={show}>
        <div className="modal-content">
          <span className="close" onClick={handleModal}>
            &times;
          </span>
          {children}
        </div>
      </ModalStyled>
    </>
  )
}

export default Modal
