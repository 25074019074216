export enum Languages {
  spanish = 'ES',
  english = 'EN',
}

export enum Status {
  online = 'online',
  offline = 'offline',
}

export enum TooltipPositions {
  top = 'top',
  right = 'right',
  left = 'left',
  bottom = 'bottom',
}
