import React from 'react'
import { AuthProvider } from './auth'
import { DrawerProvider } from './DrawerContext'
import { NotificationProvider } from './NotificationContext'
import { WorkspaceProvider } from './workspace'

export const GlobalContextProvider = ({ children }: any) => {
  return (
    <AuthProvider>
      <WorkspaceProvider>
        <NotificationProvider>
          <DrawerProvider>{children}</DrawerProvider>
        </NotificationProvider>
      </WorkspaceProvider>
    </AuthProvider>
  )
}
