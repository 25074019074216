import React from 'react'
import { IoAppsSharp } from 'react-icons/io5'
import { AppTriggerItem } from '../../../common'
import {
  ApplicationStyled,
  ContentAppStyled,
  AppsContainerStyled,
} from './Applications.styles'
import Text from '../../../common/Text/Text'
import { useAuthState } from '../../../contexts/auth'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { useMutation } from '@apollo/client'
import { CREATE_ROOM } from '../../../graphQL/Mutations'
import { Popover, Tooltip } from 'antd'

interface ContentAppsProps {
  apps: any[]
  workspace: any
}

interface AplicationsProps {
  impl: any
}

const ContentApps = ({ apps, workspace }: ContentAppsProps) => {
  return (
    <>
      <Text type="h4" align="center">
        Apps
      </Text>
      <ContentAppStyled>
        {workspace.owner &&
          apps.map((app, key) => <AppTriggerItem app={app} key={key} />)}
      </ContentAppStyled>
    </>
  )
}

const Aplications = ({ impl }: AplicationsProps): JSX.Element => {
  const { user } = useAuthState()
  const { workspace, room, handleRoom }: any = useWorkspaceContext()
  const [createRoom] = useMutation(CREATE_ROOM, {
    onCompleted: data => {
      const room = data.createRoom
      handleRoom({
        id: room.id,
        roomName: room.roomName,
        roomPassword: room.roomPassword,
        active: true,
      })
    },
    onError: err => {
      console.log(err)
    },
  })
  const handleCreateRoom = () => {
    createRoom({
      variables: {
        idWorkspace: workspace.id,
        idUser: user.id,
        isStatic: false,
      },
    })
  }
  const apps: any = [
    {
      name: impl.instantCall.label,
      src: impl.instantCall.icon,
      openFunction: handleCreateRoom,
      activeTrigger: room.active,
      free: true,
      blocked: false,
    },
    {
      name: 'Trello',
      src: 'https://cdn.worldvectorlogo.com/logos/trello.svg',
      openFunction: () => {},
      activeTrigger: false,
      free: false,
      blocked: true,
    },
    {
      name: 'Figma',
      src: 'https://cdn.worldvectorlogo.com/logos/figma-1.svg',
      openFunction: () => {},
      activeTrigger: false,
      free: false,
      blocked: true,
    },
    {
      name: 'Miro',
      src: 'https://cdn.worldvectorlogo.com/logos/miro-2.svg',
      openFunction: () => {},
      activeTrigger: false,
      free: false,
      blocked: true,
    },
    {
      name: 'ClickUp',
      src: 'https://clickup.com/landing/images/clickup-logo-gradient.png',
      openFunction: () => {},
      activeTrigger: false,
      free: false,
      blocked: true,
    },
  ]
  return (
    <AppsContainerStyled>
      <Popover
        trigger="click"
        placement="right"
        content={<ContentApps apps={apps} workspace={workspace} />}
      >
        <Tooltip placement="right" title={'Apps'}>
          <div>
            <ApplicationStyled>
              <IoAppsSharp />
            </ApplicationStyled>
          </div>
        </Tooltip>
      </Popover>
    </AppsContainerStyled>
  )
}

export default Aplications
