import React from 'react'
import { ContainerStyled } from './Container.styles'
interface ContainerProps {
  children: JSX.Element
}
const Container = ({ children }: ContainerProps): JSX.Element => {
  return (
    <ContainerStyled>
      <div className="container-div">{children}</div>
    </ContainerStyled>
  )
}

export default Container
