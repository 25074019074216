import React, { useState } from 'react'
import { Badge, Button, Container, Text } from '../../common'
import { Navbar } from '../../shared'
import { navigate } from '@reach/router'
import Footer from '../../shared/Footer'
import { FaArrowRight } from 'react-icons/fa'
import { LandingPricingStyles } from './LandingPricing.styles'
import { useWorkspaceContext } from '../../contexts/workspace'
import FeatureList from '../../components/LandingPricing/FeatureList/FeatureList'
import { QnA } from '../../components/LandingPricing'
import { LanguageFloat } from '../../common'

const LandingPricing = () => {
  const [anual, setAnual] = useState(false)
  const handleAnual = () => {
    setAnual(!anual)
  }
  const {
    impl: {
      LandingPricingImpl: {
        maintitle,
        subtitle,
        monthly,
        anualy,
        soon,
        featurelist,
        faqImpl,
        optionalpricing,
      },
    },
  }: any = useWorkspaceContext()
  return (
    <div>
      <Navbar />
      <LandingPricingStyles>
        <div className="header">
          <div className="pricing-background">
            <svg
              className="hero__container--curve"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 144.54 17.34"
              preserveAspectRatio="none"
              fill="#2B2A2A"
            >
              <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
            </svg>
          </div>
          <Container>
            <div className="pricing-content">
              <div className="pricing-content__header">
                <Text
                  type="h1"
                  color="#fff"
                  weight="500"
                  align="center"
                  className="pricing-content__header--title"
                  size="55px"
                >
                  {maintitle}
                </Text>
                <Text
                  type="h4"
                  color="#fff"
                  align="center"
                  className="pricing-content__header--subtitle"
                >
                  {subtitle}
                </Text>
                <div className="pricing-content__header--switch">
                  <span>{monthly}</span>
                  <input
                    className="switch"
                    type="checkbox"
                    id="darkmodeSwitch"
                    onChange={handleAnual}
                    checked={anual}
                  />
                  <span>{anualy}</span>
                </div>
              </div>
              <FeatureList
                anual={anual}
                subscribeInfo={featurelist.subscribeInfo}
              />
              <Text type="p" size="14px" align="center" color="#ffffff">
                {soon}
              </Text>
            </div>
          </Container>
        </div>
        <div className="pricing-special">
          <Text
            type="h1"
            color="#fff"
            weight="500"
            align="center"
            className="pricing-content__header--title"
            size="55px"
          >
            {optionalpricing.howworks}
          </Text>
          <Container>
            <div>
              <div className="pricing-special_title">
                <Badge color="pink" fontSize="20px">
                  {optionalpricing.customplan}
                </Badge>
              </div>
              <div className="pricing-special_divided">
                <div className="pricing-special_divided--section">
                  <p className="pricing-special_divided--section_title">
                    {optionalpricing.teams}
                  </p>
                  <p className="pricing-special_divided--section_price">$25</p>
                  <p className="pricing-special_divided--section_subtitle">
                    {optionalpricing.teamsmontly}
                  </p>
                  <p className="pricing-special_divided--section_description">
                    {optionalpricing.customfeatures}
                  </p>
                </div>
                <div className="pricing-special_divided--section">
                  <p className="pricing-special_divided--section_title">
                    {optionalpricing.aditionalmembers}
                  </p>
                  <p className="pricing-special_divided--section_price">$2</p>
                  <p className="pricing-special_divided--section_subtitle">
                    {optionalpricing.membermontly}
                  </p>
                  <p className="pricing-special_divided--section_description">
                    {optionalpricing.memberfeatures}.{' '}
                  </p>
                </div>
              </div>
              <div className="pricing-special_includes">
                {optionalpricing.add}
              </div>
              <div className="pricing-special_pro">
                <ul className="list text-base leading-10">
                  <li>{optionalpricing.allfeatures}</li>
                </ul>
              </div>
              <div className="text-center">
                <Button
                  className="pricing-special_button"
                  onClick={() => {
                    navigate('/checkout')
                  }}
                  size="small"
                  color="pink"
                >
                  {optionalpricing.buttoniwant} <FaArrowRight />
                </Button>
              </div>
            </div>
          </Container>
          <svg
            className="pricing-special_curvetwo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 144.54 17.34"
            preserveAspectRatio="none"
            fill="#F6F6F6"
          >
            <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
          </svg>
        </div>
        <QnA faqImpl={faqImpl} />
        <LanguageFloat />
      </LandingPricingStyles>
      <Footer />
    </div>
  )
}

export default LandingPricing
