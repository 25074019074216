import styled from 'styled-components'

export const HeroMainContainer = styled.div`
  position: relative;
  padding-top: 100px;

  background-color: #232323;
  /* min-height: 100vh; */
  @media only screen and (max-width: 640px) {
    padding-top: 60px;
  }
  .main__landing--curve {
    transform: scale(1) translate(0px, 4px);
    -webkit-transform: scale(1) translate(0px, 4px);
    -moz-transform: scale(1) translate(0px, 4px);
  }
  .main__landing {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-color: #232323;
    text-align: left;
    flex-direction: row-reverse;
    @media only screen and (max-width: 1024px) {
      flex-direction: column-reverse;
      padding: 10px;
    }
  }
  .main__message {
    display: flex;
    align-items: flex-start;
    justify-items: center;
    flex-direction: column;
    padding-top: 20px;
    @media only screen and (max-width: 1024px) {
      text-align: center;
      align-items: center;
    }
    @media only screen and (max-width: 640px) {
      padding-top: 0px;
    }
  }
  .main__message--title {
    color: white;
    font-size: 55px;
    font-weight: 500;
    padding-top: 20px;
    line-height: 1.1;
    @media only screen and (max-width: 1024px) {
      font-size: 46px;
      padding-top: 0px;
    }
    @media only screen and (max-width: 640px) {
      font-size: 36px;
    }
  }
  .main__message--p {
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;

    @media only screen and (max-width: 640px) {
      font-size: 18px;
      font-weight: 200;
    }
  }
  .main__landing--photo {
    width: 90%;
    max-height: fit-content;
    padding-top: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main__ad--title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #5e4dc8;
  }

  .mySwiper {
    position: relative;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0 auto;
    padding: 30px 50px;
    background-position: center;
    background-size: cover;
    display: block;
    width: 80%;
    height: 500px;
    @media only screen and (max-width: 1024px) {
      height: 350px;
    }
    @media only screen and (max-width: 640px) {
      height: 250px;
    }
    .swiper-slide {
      height: 100%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
`
