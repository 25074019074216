/* eslint-disable react-hooks/exhaustive-deps */
import { useSubscription } from '@apollo/client'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import {
  MEMBER_ADDED,
  MEMBER_ADDED_BY_LINK,
  MEMBER_DELETED,
  WORKSPACE_EDITED,
} from '../graphQL/Subscription'
import { WorkspaceType } from '../types/workspaceTypes'
import { Languages } from '../types/enums'
import ImplEN from '../utils/ImpEN'
import ImplES from '../utils/ImplES'

const WorkspaceContext = React.createContext({})

export function useWorkspaceContext() {
  return useContext(WorkspaceContext)
}

const initDarkMode = window.localStorage.getItem('darkMode') === 'true'
const initDisablePreJoin =
  window.localStorage.getItem('disablePreJoin') === 'true'
const implementation =
  window.localStorage.getItem('language') === Languages.english
    ? ImplEN
    : window.localStorage.getItem('language') === Languages.spanish
    ? ImplES
    : ImplEN

export function WorkspaceProvider({ children }: any) {
  const [language, setLanguage] = useState(Languages.english)
  const [impl, setImpl] = useState<any>({ ...implementation })
  const [darkMode, setDarkMode] = useState<any>(initDarkMode)
  const [activePopUpId, setActivePopUpId] = useState<string>('')
  const [disablePreJoin, setDisablePreJoin] = useState<any>(initDisablePreJoin)
  const [workspace, setWorkspace] = useState<any>({})
  const [staticRooms, setStaticRooms] = useState<any>([])
  const [listOfWorkspaces, setListOfWorkspaces] = useState<any>([])
  const [staticWorkspaces, setstaticWorkspaces] = useState<any>([])
  const [workspaceToSubscript, setWorkspaceToSubscript] = useState<any>(null)
  const [room, setRoom] = useState({
    id: '',
    roomName: '',
    roomPassword: '',
    active: false,
  })
  const [call, setCall] = useState({})
  const [subscribedUser, setSubscribedUser] = useState()
  const [subscribedWorkspace, setSubscribedWorkspace] = useState()

  // ? Handle Subscription Edit WorkspaceInfo
  const { data: workspaceEdited, error: workspaceEditedError } =
    useSubscription(WORKSPACE_EDITED)
  const { data: memberDeleted, error: memberDeletedError } =
    useSubscription(MEMBER_DELETED)
  const { data: memberAdded, error: memberAddedError } =
    useSubscription(MEMBER_ADDED)
  const { data: memberAddedByLink, error: memberAddedByLinkError } =
    useSubscription(MEMBER_ADDED_BY_LINK)
  useEffect(() => {
    if (workspace?.id) {
      if (workspaceEditedError) console.log(workspaceEditedError)
      if (workspaceEdited) {
        if (workspace.id === workspaceEdited.workspaceEdited.id) {
          handleWorkspace(workspaceEdited.workspaceEdited)
        }
      }
    }
  }, [workspaceEditedError, workspaceEdited])
  useEffect(() => {
    if (workspace?.id) {
      if (memberDeletedError) console.log(memberDeletedError)
      if (memberDeleted) {
        if (workspace.id === memberDeleted.memberDeleted.id) {
          handleWorkspace(memberDeleted.memberDeleted)
        }
      }
    }
  }, [memberDeleted, memberDeletedError])
  useEffect(() => {
    if (workspace?.id) {
      if (memberAddedError) console.log(memberAddedError)
      if (memberAdded) {
        if (workspace.id === memberAdded.memberAdded.id) {
          handleWorkspace(memberAdded.memberAdded)
        }
      }
    }
  }, [memberAdded, memberAddedError])
  useEffect(() => {
    if (workspace?.id) {
      if (memberAddedByLinkError) console.log(memberAddedByLinkError)
      if (memberAddedByLink) {
        if (workspace.id === memberAddedByLink.memberAddedByLink.id) {
          handleWorkspace(memberAddedByLink.memberAddedByLink)
        }
      }
    }
  }, [memberAddedByLink, memberAddedByLinkError])
  // TODO  Handle Subscription Edit Workspace ROOMS and Add Participant

  useEffect(() => {
    const storedLang = window.localStorage.getItem('language')
    // ? window.localStorage.getItem("language")
    // : Languages.english;
    if (!storedLang) {
      axios
        .get('https://ipapi.co/json/')
        .then((response: any) => {
          const data = response.data
          if (data.languages.slice(0, 2) === 'es') {
            handleLanguage(Languages.spanish)
          } else {
            handleLanguage(Languages.english)
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      if (storedLang === Languages.spanish) {
        handleLanguage(Languages.spanish)
      } else {
        handleLanguage(Languages.english)
      }
    }
  }, [])

  function handleDarkMode() {
    const newDarkmode = !darkMode
    window.localStorage.setItem('darkMode', newDarkmode ? 'true' : 'false')
    return setDarkMode(!darkMode)
  }
  function handleDisablePreJoin() {
    const newDisablePreJoin = !disablePreJoin
    window.localStorage.setItem(
      'disablePreJoin',
      newDisablePreJoin ? 'true' : 'false'
    )
    return setDisablePreJoin(!disablePreJoin)
  }

  function handleLanguage(option: Languages) {
    window.localStorage.setItem('language', option)
    if (option === Languages.english) {
      setImpl({ ...ImplEN })
    } else if (option === Languages.spanish) {
      setImpl({ ...ImplES })
    }
    return setLanguage(option)
  }
  function handleWorkspace(newWorkspace: WorkspaceType) {
    return setWorkspace(newWorkspace)
  }
  function handListOfWorkspaces(newListOfWorkspaces: any) {
    return setListOfWorkspaces(newListOfWorkspaces)
  }
  function handstaticWorkspaces(newListOfWorkspaces: any) {
    return setstaticWorkspaces(newListOfWorkspaces)
  }
  function handStaticRooms(staticRooms: any) {
    return setStaticRooms(staticRooms)
  }

  function handleRoom(newRooom: any) {
    return setRoom(newRooom)
  }

  function handleCall(newCall: any) {
    return setCall(newCall)
  }
  function handleSubscribedUser(subscribedUser: any) {
    return setSubscribedUser(subscribedUser)
  }
  function handleSubscribedWorkspace(workspace: any) {
    return setSubscribedWorkspace(workspace)
  }

  function handleActivePopUpId(id: string) {
    setActivePopUpId(id)
  }

  function handleWorkspaceToSubscript(workspace: WorkspaceType) {
    setWorkspaceToSubscript(workspace)
  }

  const value = {
    workspace,
    handleWorkspace,
    room,
    handleRoom,
    call,
    handleCall,
    subscribedUser,
    handleSubscribedUser,
    darkMode,
    handleDarkMode,
    listOfWorkspaces,
    handListOfWorkspaces,
    staticWorkspaces,
    handstaticWorkspaces,
    language,
    handleLanguage,
    staticRooms,
    handStaticRooms,
    impl,
    handleDisablePreJoin,
    disablePreJoin,
    handleActivePopUpId,
    activePopUpId,
    workspaceToSubscript,
    handleWorkspaceToSubscript,
    subscribedWorkspace,
    handleSubscribedWorkspace,
  }

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  )
}
