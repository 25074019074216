import styled, { css } from 'styled-components'

export const SidebarStyled = styled.div`
  width: 100%;
  background-color: #232323;
  border-radius: 20px;
  .sidebar-header {
    width: 100%;
    display: block;
    /* margin-top: 100px; */
    margin-bottom: 32px;
    .upload-image {
      grid-column: 1/20;
      display: flex;
      justify-content: center;
    }
    div {
      display: table;
      margin: 16px auto;
    }
    p {
      text-align: center;
      margin: 4px 16px;
    }
  }
  .sidebar_item {
    font-style: normal;
    font-size: 17px;
    line-height: 22px;
    color: #fff;
    display: flex;
    padding: 8px 24px;
    cursor: pointer;
    svg {
      margin-right: 20px;
    }
    &:hover {
      background-color: #fad776;
      color: #232323;
    }
  }

  .sidebar_item-active {
    color: #fad776;
    font-style: normal;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    padding: 8px 24px;
    cursor: pointer;
    svg {
      margin-right: 20px;
    }
    &:hover {
      background-color: #fad776;
      color: #232323;
    }
  }

  .sidebar__footer {
    margin: 25px auto;
    display: grid;
    place-items: center;
    button {
      svg {
        margin-right: 20px;
      }
    }
  }
`
export const ImageLogoContainer = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  align-items: flex-end;
  border-radius: 50px;
  > label {
    width: 100px;
    height: 100px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(22, 28, 45, 0.3);
    border-radius: 50px;
    cursor: pointer;
  }
  > input {
    display: none;
  }
  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-size: cover;
    `}
`
