import styled from 'styled-components'

export const IntegrationsStyled = styled.div`
  position: relative;
  margin-top: 70px;
  .card {
    padding: 50px;
    margin-top: 16px;
    margin-bottom: 50px;
    min-height: 600px;
    max-height: 700px;
    background: #ffffff;
    border-radius: 5px;
    text-align: center;
    .title-card {
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .group-form {
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 10px;
      margin-bottom: 30px;
      .group-form-item {
        width: 60px;
        height: 60px;
        font-size: 16px;
        align-self: center;
        justify-self: center;
        font-size: 17px;
        font-weight: bold;
      }
    }
    .group-form__unsubscribe {
      width: 50%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 1px;
      margin-bottom: 30px;
      .group-form-item__unsubscribe {
        align-self: center;
        justify-self: center;
        pointer-events: none;
        filter: grayscale(100%);
        opacity: 0.6;
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
    .footer-card {
      width: 70%;
      margin: 0 auto;
    }
  }
`
