import styled, { css } from 'styled-components'

export const InputStyled = styled.div`
  label {
    font-family: 'HKGrotesk', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    color: ${({ labelColor }) => labelColor || '#232323'};
    margin-bottom: 16px;
  }
  input::placeholder {
    font-family: 'HKGrotesk', sans-serif;
    color: #94a5c0;
  }
  input[type='text'] {
    margin-top: 8px;
    width: 100%;
    padding: 12px;
    max-height: inherit;
    border: 1px solid ${props => props.borderColor || '#F1F4F8'};
    box-sizing: border-box;
    border-radius: 5px;
    background-color: ${props => props.backgroundColor || 'transparent'};
    font-size: 17px;
    line-height: 22px;
    color: ${props => props.color || '#232323'};
    ${props =>
      props.validationError.length > 0 &&
      css`
        border: 1px solid #be3c4c;
      `}
  }
  input[type='text']:disabled {
    background-color: #e2e2e2;
  }
  input[type='text']:focus {
    border: 1px solid #fa27a9;

    ${props =>
      props.validationError.length > 0 &&
      css`
        border: 1px solid #be3c4c;
      `}
    font-family: 'HKGrotesk', sans-serif;
    font-style: normal;
    font-weight: normal;
  }
  input[type='password'] {
    margin-top: 8px;
    width: 100%;
    padding: 12px;
    max-height: inherit;
    border: 1px solid ${props => props.borderColor || '#F1F4F8'};
    box-sizing: border-box;
    border-radius: 5px;
    background-color: ${props => props.backgroundColor || 'transparent'};
    font-size: 17px;
    line-height: 22px;
    color: ${props => props.color || '#232323'};
    ${props =>
      props.validationError.length > 0 &&
      css`
        border: 1px solid #be3c4c;
      `}
  }
  input[type='password']:focus {
    border: 1px solid #fa27a9;

    ${props =>
      props.validationError.length > 0 &&
      css`
        border: 1px solid #be3c4c;
      `}
    font-family: 'HKGrotesk', sans-serif;
    font-style: normal;
    font-weight: normal;
  }
  input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
  textarea::placeholder {
    font-family: 'HKGrotesk', sans-serif;
    color: #94a5c0;
  }
  textarea {
    width: 100%;
    margin-top: 8px;
    padding: 16px;
    max-height: inherit;
    border: 1px solid ${props => props.borderColor || '#F1F4F8'};
    box-sizing: border-box;
    border-radius: 5px;
    background-color: ${props => props.backgroundColor || 'transparent'};
    font-size: 17px;
    line-height: 22px;
    color: ${props => props.color || '#232323'};
    ${props =>
      props.validationError.length > 0 &&
      css`
        border: 1px solid #be3c4c;
      `}
  }
  textarea:focus {
    border: 1px solid #fa27a9;

    ${props =>
      props.validationError.length > 0 &&
      css`
        border: 1px solid #be3c4c;
      `}
    font-family: 'HKGrotesk', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
  }
  textarea:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
  p {
    margin-top: 8px;
    color: #be3c4c;
  }
  .input-maxlength {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
    span {
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #94a5c0;
    }
  }
`
