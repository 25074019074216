import React from 'react'

const SettingsZyreboxImpl = {
  SettingsZyreboxImpl: {
    tabs: {
      zyreboxInfo: {
        title: 'Zyrebox Info',
        subtitle: 'Edit the info of your Zyrebox',
        nameInput: {
          label: 'Team Name',
          placeholder: 'Team Name',
        },
        companyInput: {
          label: 'Company Name (optional)',
          placeholder: 'Company Name (optional)',
        },
        descriptionInput: {
          label: 'Zyrebox Description',
          placeholder: 'Zyrebox Description',
        },
        submitLabel: 'Save changes',
      },
      myTeam: {
        title: 'My Team',
        subtitle: 'Invite or remove members in this team',
        addMemberInput: {
          label: 'Invite new members to your team',
          placeholder: 'Invite by Email',
        },
        inviteBtn: 'Invite',
        filterMemberInput: {
          label: 'My team members',
        },
        quantity: 'Members',
        tooltipInvite:
          'If the user invited has already an account, is going to be showed in the list below, if not, we will send an invitation email',
        tooltipSearch:
          'If you remove someone from your team, he/she will no longer be able to join your zyrebox unless you invite them again',
        teamListComponent: {
          yourself: 'you',
          inputPlaceholder:
            '&#xF002; Search member by name, last name or email',
          removeMember: {
            deleteMessage: (name: string) => {
              return (
                <>
                  Are you sure you want to remove <strong>{name}</strong> from
                  your team?
                </>
              )
            },
            removeBtn: 'REMOVE',
          },
        },
      },
      groupSpaces: {
        title: 'Group Spaces',
        subtitle: 'Create or remove group spaces in  your Zyrebox',
        addSpaceInput: {
          label: 'Create new Group Space',
          placeholder: 'Enter a name',
        },
        serchSpaceInput: {
          label: 'Spaces',
        },
        createBtn: 'Create',
        tooltipCreate:
          'Write a name, select an icon and create a new Group Space',
        tooltipSearch:
          'If you remove a group space from your zyrebox, your team will no longer be able to access.',
        quantity: 'Group Spaces:',
        subscribeMessage:
          'With your current plan you can only have 2 Group Spaces, subscribe now to unlocked this and create more groups.',
        subscribeButton: 'Subscribe to Pro',
      },
      integrations: {
        title: 'Integrations',
        subtitle:
          'Configure all your available integrations according to your plan.',
        unsubscribedTitle:
          'We are working on new integrations for you, to make an awesome remote work experience',
        unsubscribedText:
          'Subscribe your team and be the first one to try this integrations',
        planBtn: 'Subscribe my team',
      },
      advanced: {
        title: 'Advance Options',
        subtitle: 'Use this options carefully.',
        deleteTitle: 'Delete Zyrebox',
        deleteText:
          "This action can't be undone. Once you delete your Zyrebox you will lose all the data related including team information or integrations that you've made.",
        deleteBtn: 'Delete Zyrebox',
        deleteModalText: (name: string) => {
          return (
            <>
              Are you sure you want to delete <strong>{name}</strong>?
            </>
          )
        },
        deleteModalBtn: 'DELETE',
        planTitle: 'Improve my plan',
        planLabel: 'Current plan:',
        planBadgeBasic: 'Startup',
        planBadgePro: 'Business',
        planProMessage: 'Enjoy your Business Plan 🚀',
        planBtn: 'Subscribe to Business',
      },
    },
    sidebar: {
      backlabel: 'Back',
      sidebarTitle: 'Settings',
    },
  },
}

export default SettingsZyreboxImpl
