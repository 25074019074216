const SettingsZyreboxImpl = {
  SettingsZyreboxImpl: {
    tabs: {
      zyreboxInfo: {
        title: 'Mi Zyrebox',
        subtitle: 'Edita la información de tu Zyrebox',
        nameInput: {
          label: 'Nombre de Equipo',
          placeholder: 'Nombre de Equipo',
        },
        companyInput: {
          label: 'Nombre de Empresa (opcional)',
          placeholder: 'Nombre de Empresa (opcional)',
        },
        descriptionInput: {
          label: 'Descripción',
          placeholder: 'Descripción',
        },
        submitLabel: 'Guardar Cambios',
      },
      myTeam: {
        title: 'Mi Equipo',
        subtitle: 'Invita o retira miembros de este equipo',
        addMemberInput: {
          label: 'Invita nuevos miembros a tu equipo',
          placeholder: 'Invitar por correo',
        },
        inviteBtn: 'Invitar',
        filterMemberInput: {
          label: 'Miembros de mi equipo',
        },
        quantity: 'Miembros',
        tooltipInvite:
          'Si el usuario invitado tiene creada una cuenta previamente, podras verlo en la lista mostrada debajo. Caso contrario, le enviaremos una invitación por correo electrónico.',
        tooltipSearch:
          'Si eliminas a un usuario de tu equipo, este no se podrá unir a tu Zyrebox a menos que lo invites de nuevo.',
        teamListComponent: {
          yourself: 'Tu',
          inputPlaceholder:
            '&#xF002; Busca a un miembro por nombre, apellido o email',
          removeMember: {
            deleteMessage: (name: string) => {
              return (
                <>
                  ¿Estas seguro que deseas eliminar a <strong>{name}</strong> de
                  tu equipo?
                </>
              )
            },
            removeBtn: 'ELIMINAR',
          },
        },
      },
      groupSpaces: {
        title: 'Espacios Grupales',
        subtitle: 'Crea o elimina espacios grupales en tu Zyrebox',
        addSpaceInput: {
          label: 'Crear nuevo espacio grupal',
          placeholder: 'Ingresa un nombre',
        },
        serchSpaceInput: {
          label: 'Espacios',
        },
        createBtn: 'Crear',
        tooltipCreate:
          'Escribe un nombre, selecciona un icono y presiona en "Crear"',
        tooltipSearch:
          'Si eliminas un espacio grupal de tu Zyrbeox, tu equipo no podra acceder.',
        quantity: 'Espacios grupales:',
        subscribeMessage:
          'Con tu plan actual, solo podras crear hasta 2 espacios gurpales. Suscríbete ahora para poder crear más.',
        subscribeButton: 'Suscribirme a Pro',
      },
      integrations: {
        title: 'Mis integraciones',
        subtitle: 'Configura todas tus integraciones disponibles según tu plan',
        unsubscribedTitle:
          'Estamos trabajando en nuevas integraciones para ti, para hacer una increible experiencia de trabajo remoto',
        unsubscribedText:
          'Suscribe a tu equipo y se de los primeros en probar estas integraciones',
        planBtn: 'Suscribir mi equipo',
      },
      advanced: {
        title: 'Opciones Avanzadas',
        subtitle: 'Usa estas opciones con cuidado.',
        deleteTitle: 'Eliminar Zyrebox',
        deleteText:
          'Esta acción es irreversible. Una vez elimines tu Zyrebox, perderas toda la información relacionada incluyendo información de tu equipo o integraciones que hayas hecho.',
        deleteBtn: 'Eliminar Zyrebox',
        deleteModalText: (name: string) => {
          return (
            <>
              ¿Estas seguro que quieres borrar <strong>{name}</strong>?
            </>
          )
        },
        deleteModalBtn: 'ELIMINAR',
        planTitle: 'Mejorar mi plan',
        planLabel: 'Plan actual:',
        planBadgeBasic: 'Startup',
        planBadgePro: 'Business',
        planProMessage: 'Disfruta de plan Business 🚀',
        planBtn: 'Suscribirme a Business',
      },
    },
    sidebar: {
      backlabel: 'Volver',
      sidebarTitle: 'Configs',
    },
  },
}

export default SettingsZyreboxImpl
