import styled from 'styled-components'

export const JitsiMeetContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  left: 6vw;
  top: 2%;
  background-color: transparent;
  z-index: 3;
  > div {
    display: block;
  }
  @media (min-width: 1550px) {
    left: 5vw;
  }
  .top-alert {
    display: flex;
    align-items: center;
  }
  .top-alert-minimize {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .bottom-alert-minimize {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  .close-meeting-tooltip {
    .tooltipWraper {
      right: -130%;
    }
  }
  .close-shared-tooltip {
    .tooltipWraper {
      top: -130%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .tooltip-text {
    display: none;
  }
`

// export const ShareButton = styled.button`
//   color: #8b7efc;
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   font-weight: bold;
//   position: relative;
//   &:hover .tooltiptext {
//   visibility: visible;
//   opacity: 1;
// }
//   svg {
//     margin: 0px 5px;
//   }
//   .tooltiptext {
//   visibility: hidden;
//   width: 300px;
//   background-color: #555;
//   color: #fff;
//   text-align: center;
//   border-radius: 6px;
//   padding: 5px;
//   position: absolute;
//   z-index: 1;
//   bottom: 150%;
//   left: 40%;
//   margin-left: -75px;
//   opacity: 0;
//   transition: opacity 0.3s;
//   &::after {
//   content: "";
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   margin-left: -5px;
//   border-width: 5px;
//   border-style: solid;
//   border-color: #555 transparent transparent transparent;
// }
// }
// `

// export const ParticipantWraper = styled.div`
//   display: flex;
// `

// export const Participant = styled.div`
//   margin: 10px 10px;
//   width: 50px;
//   height: 50px;
//   border-radius: 100px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   svg{
//     display: none;
//   }
//   ${props => props.image && css`
//     background-image: url(${props.image});
//     background-size: cover;
//     &:hover{
//       background: linear-gradient(0deg, rgba(0, 76, 191, 0.67), rgba(0, 76, 191, 0.67)), url(${props.image}), #004CBF;
//       background-size: cover;
//       cursor: pointer;
//       svg{
//         display: block;
//       }
//     }
//   `}
// `
