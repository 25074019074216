import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import images from '../../assets/static/images'
import { Alert, Anchor, Button, Input, Loader, Text } from '../../common'
import { REQUEST_RESET_PASSWORD } from '../../graphQL/Mutations'
import { ForgotPasswordStyled } from './ForgotPassword.styles'
import ReactGA from 'react-ga'
import { useWindow } from '../../hooks/useWindow'
import { ResponsiveDefault } from '..'
import { validateEmail } from '../../helpers/validators'

const ForgotPassword = () => {
  const [width] = useWindow()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [sent, setSent] = useState(false)
  const [errors, setErrors] = useState({})
  const [requestResetPassword, { loading }] = useMutation(
    REQUEST_RESET_PASSWORD,
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      update: (_, { data: { login } }) => {
        setSent(true)
        /*dispatch({ type: 'LOGIN', payload: login })
      ReactGA.event({
        category: 'Inicio de sesion',
        action: `Nuevo Usuario: ${email}`
      })
      window.location.href = '/home'*/
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (err: any) => {
        setErrors({ error: 'Please enter a valid email.' })
        setTimeout(() => {
          setErrors({})
        }, 3000)
      },
    }
  )
  useEffect(() => {
    ReactGA.pageview('/forgot')
  }, [])
  const handleEmail = (value: string) => {
    // eslint-disable-next-line no-control-regex
    if (validateEmail(value)) {
      setEmailError('')
    } else {
      setEmailError('Please enter a valid email')
    }
    if (value === '') {
      setEmailError('')
    }
    setEmail(value)
  }
  const handleSumbit = (e: any) => {
    e.preventDefault()
    requestResetPassword({
      variables: {
        email,
      },
    })
  }
  const handleDisabled = () => {
    if (email !== '' && emailError === '') {
      return false
    }
    return true
  }
  if (width < 600) {
    return <ResponsiveDefault />
  }
  return (
    <div>
      <ForgotPasswordStyled backgroundImage={images.loginBackground}>
        <div className="main-content">
          <Anchor route="/landing" type="link">
            <img
              src={images.logoAuth}
              alt="logo"
              className="main-content__logo"
            />
          </Anchor>
          <Text type="h2" className="main-content__title">
            Forgot your password
          </Text>
          {sent ? (
            <Alert color="success" className="text--center">
              <Text
                type="h4"
                color="#fff"
                align="center"
                className="text--center"
              >
                We have sent you an email. <br />
                Check your spam tray.
              </Text>
            </Alert>
          ) : (
            <form onSubmit={handleSumbit} className="login-form">
              <Input
                value={email}
                onChange={handleEmail}
                validationError={emailError}
                label="Email"
                placeHolder="Enter your email address"
                className="login-form__input"
              />
              <Button
                size="base"
                color="primary"
                onClick={() => {}}
                disabled={loading || handleDisabled()}
                block
                className="login-form__button"
              >
                {loading ? (
                  <Loader size="25px" border="3px" />
                ) : (
                  'Send me a link'
                )}
              </Button>
            </form>
          )}
          {Object.keys(errors).length > 0 && (
            <div className="alert">
              {Object.values(errors).map((value: any) => (
                <Alert color="danger" key={value}>
                  <p>{value}</p>
                </Alert>
              ))}
            </div>
          )}

          <div className="main-content__footer">
            <Text type="p" align="center">
              Already have an account?
            </Text>
            <div className="main-content__footer--link">
              <Anchor route="/login" type="link">
                Login
              </Anchor>
            </div>
          </div>
        </div>
        <div className="side-background" />
      </ForgotPasswordStyled>
    </div>
  )
}

export default ForgotPassword
