import React from 'react'
import { LoaderStyled } from './Loader.styles'

interface LoaderProps {
  size?: string
  color?: string
  border?: string
}

const Loader = ({ size, color, border }: LoaderProps): JSX.Element => {
  return <LoaderStyled size={size} color={color} border={border} />
}

export default Loader
