import React, { useState } from 'react'
import { InputStyled } from './Input.styles'

interface InputProps {
  label?: string
  value: string
  placeHolder: string
  backgroundColor?: string
  validationError: string
  minLength?: number
  maxLength?: number
  disabled?: boolean
  borderColor?: string
  labelColor?: string
  color?: string
  textArea?: string
  rows?: number
  cols?: number
  className?: string
  type?: string | undefined
  onChange: (value: string) => void
}

const Input = ({
  label,
  placeHolder,
  backgroundColor,
  validationError,
  minLength,
  maxLength,
  disabled,
  labelColor,
  color,
  borderColor,
  value,
  textArea,
  rows,
  cols,
  onChange,
  className,
  type,
}: InputProps): JSX.Element => {
  const [chars, setChars] = useState(value.length)
  const handleInputValue = (e: any) => {
    if (maxLength) {
      setChars(e.target.value.length)
    }
    onChange(e.target.value)
  }
  return (
    <InputStyled
      backgroundColor={backgroundColor}
      color={color}
      borderColor={borderColor}
      validationError={validationError}
      className={className}
      labelColor={labelColor}
    >
      <label>{label}</label>
      {textArea ? (
        <textarea
          placeholder={placeHolder}
          minLength={minLength}
          maxLength={maxLength}
          disabled={disabled}
          value={value}
          rows={rows}
          cols={cols}
          onChange={handleInputValue}
        />
      ) : (
        <input
          type={type || 'text'}
          placeholder={placeHolder}
          minLength={minLength}
          maxLength={maxLength}
          disabled={disabled}
          value={value}
          onChange={handleInputValue}
        />
      )}
      {maxLength && (
        <div className="input-maxlength">
          <span>
            {chars}/{maxLength}
          </span>
        </div>
      )}
      <p>{validationError}</p>
    </InputStyled>
  )
}

export default Input
