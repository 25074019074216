import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import Button from '../Button'
import { JitsiMeetEmbedStyled } from './JitsiMeetEmbed.styles'

function JitsiMeetEmbed({
  // roomHeight,
  onLoad,
  roomName,
  roomPassword,
  email,
  displayName,
  avatarUrl,
  onMeetingEnd,
  manualClose,
  selfManualClose = false,
  disablePreJoin = false,
  setLoading
}) {


  const jitsiContainerStyle = {
    display: 'block',
    width: '100%',
    height: '100%',
  }

  useEffect(() => {
    function startConference() {
      setLoading(true)
      try {
        const domain = 'meet.jit.si'
        const options = {
          roomName: roomName,
          roomPassword: roomPassword,
          onload: () => {
            // ejecuta algo una vez haya cargado el iframe
            onLoad()
            setLoading(false)
          },
          // height: roomHeight,
          parentNode: document.getElementById('jitsi-container'),
          interfaceConfigOverwrite: {
            filmStripOnly: false,
            SHOW_JITSI_WATERMARK: false,
          },
          configOverwrite: {
            disableSimulcast: false,
            prejoinPageEnabled: !disablePreJoin,
          },
          userInfo: {
            email: email,
            displayName: displayName,
          },
        }

        const api = new window.JitsiMeetExternalAPI(domain, options)
        api.addEventListener('videoConferenceJoined', () => {
          console.log('Local User Joined')
          api.executeCommand('avatarUrl', avatarUrl)
          api.executeCommand('setVideoQuality', 1080)
        })
        api.addEventListener('readyToClose', () => {
          onMeetingEnd()
          api.dispose()
        })
        if (manualClose) {
          api.dispose()
        } else
          if (selfManualClose) {
            api.dispose()
          }
      } catch (error) {
        console.error('Failed to load Jitsi API', error)
      }
    }
    if (window.JitsiMeetExternalAPI) startConference()
    else window.alert('Jitsi Meet API script not loaded')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (


    <div id="jitsi-container" style={jitsiContainerStyle} />

  )
}
const JitsiMeetComponent = ({
  jistiStyles,
  // roomHeight,
  onLoad,
  loadingComponent,
  roomName,
  roomPassword,
  email,
  displayName,
  avatarUrl,
  onMeetingEnd,
  manualClose,
  canSelfClose = false,
  selfManualClose = false,
  handleSetManualClose = () => { },
  disablePreJoin = false,
}) => {
  const [loading, setLoading] = useState(false)
  const containerStyle = jistiStyles
  return (
    <>
      {
        !selfManualClose &&
        <JitsiMeetEmbedStyled style={containerStyle}>
          {canSelfClose && (
            <Button
              color="light"
              size="extraSmall"
              className="jitsi-close-btn"
              onClick={() => {
                handleSetManualClose()
              }}
            >
              <MdClose size="25px" />
            </Button>
          )}
          {loading && <div className="loader">{loadingComponent}</div>}{ }
          <JitsiMeetEmbed
            jistiStyles={jistiStyles}
            // roomHeight,
            onLoad={onLoad}
            loadingComponent={loadingComponent}
            roomName={roomName}
            roomPassword={roomPassword}
            email={email}
            displayName={displayName}
            avatarUrl={avatarUrl}
            onMeetingEnd={onMeetingEnd}
            manualClose={manualClose}
            canSelfClose={canSelfClose}
            selfManualClose={selfManualClose}
            handleSetManualClose={handleSetManualClose}
            disablePreJoin={disablePreJoin}
            setLoading={setLoading}
          />
        </JitsiMeetEmbedStyled>

      }
    </>
  );
};

export default JitsiMeetComponent
