import styled, { css } from 'styled-components'

export const GlobalAlertStyled = styled.div`
  position: absolute;
  ${({ position }) =>
    position === 'topright'
      ? css`
          top: 20px;
          right: 50px;
          z-index: 100;
        `
      : css``}
`
