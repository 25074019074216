import { FaCubes, FaLayerGroup } from 'react-icons/fa'
import { HiUserGroup } from 'react-icons/hi'
import { BsFillCameraVideoFill } from 'react-icons/bs'
import { BsFillChatDotsFill } from 'react-icons/bs'
import { AiFillAppstore } from 'react-icons/ai'
import React from 'react'

const LandingMainImpl = {
  LandingMainImpl: {
    hero: {
      mainTitle: 'The remote work revolution has already begun!',
      subTitle:
        'We create a platform easy and effective for your work teams and you.',
      textButton: 'Start FREE now!',
    },
    features: {
      featurelist: [
        {
          title: 'Team management',
          description:
            'Organize your teams like you want and keep contact with them.',
          icon: <FaLayerGroup size="40px" color="FA27A9" />,
        },
        {
          title: 'Grupal spaces',
          description: 'Create free spaces so that your teams can have them.',
          icon: <FaCubes size="40px" color="FA27A9" />,
        },
        {
          title: 'Grupal calls',
          description: 'Like another video-call software.',
          icon: <HiUserGroup size="40px" color="FA27A9" />,
        },
        {
          title: 'Calls one to one',
          description:
            'Call any member of your team without sent a link or interrumpt to your team.',
          icon: <BsFillCameraVideoFill size="40px" color="FA27A9" />,
        },
        {
          title: 'Self-managed chat',
          description:
            'Your chats will be with you wherever you go without losing nothing.',
          icon: <BsFillChatDotsFill size="40px" color="FA27A9" />,
        },
        {
          title: 'Integrate the platforms that you like(Coming soon)',
          description: 'Whatsapp, Figma, Trello, Miro y ClickUp',
          icon: <AiFillAppstore size="40px" color="FA27A9" />,
        },
      ],
      maintitle: 'Add all of you already know and all you would love to have.',
      subtitle:
        'We taked the best of the platforms that you already know and we gathering all the good in one place.',
    },
    switchsides: {
      firstmaintitle: 'Design to agile teams of high perfomance.',
      firstdescriptionone:
        'Organize your spaces whatever you prefer. You can use Scrum or any other method to define the groups spaces that you prefer to asign to specific meetings with your customers and your team.',
      firstdescriptiontwo:
        'Allow your team to keep comunication, optimizing their  performance',
      secondtitle: 'Manage several teams at the same time.',
      seconddescriptionone:
        'You can create several teams and manage them at the same time. The purpose is to maintain constant communication between you and your teams.',
      seconddescriptiontwo:
        'In addition, you can integrate WhatsApp as an external means of communication outside of our video calls and our chat.',
    },
    subscribecac: {
      maintitle: 'Be part of Zyrebox!',
      subtitle:
        'Be part of the Zyrebox experience. Our Numbers talk by themselves',
      numberzyreboxcreated: '+30',
      zyreboxcreated: 'Zyrebox Created',
      numberzyreboxteams: '+20',
      zyreboxteams: 'Teams in Zyrebox',
      numbercallsdone: '+700',
      callsrealized: 'Calls released',
      titlepartsubscribed: 'Take your office wherever you go!',
      subtitlepartsubscribed:
        'Our commitment is to take your teams to the next level',
      buttonsubscribedtopro: 'Subscribe to Business',
    },
  },
}

export default LandingMainImpl
