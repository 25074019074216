import styled, { css } from 'styled-components'

export const FloatingDarkModeStyled = styled.div`
  ${({ float }) =>
    float &&
    css`
      position: fixed;
      /* width:60px;
    height:60px; */
      bottom: 70px;
      left: 50px;
    `}
  .switch {
    appearance: none;
    height: 2.5em;
    width: 5em;
    background-color: #c7c7c7;
    cursor: pointer;
    border-radius: 8em;
    position: relative;
    transition: background-color 0.3s ease;
  }

  .switch::before {
    height: 100%;
    width: 50%;
    background-color: white;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    line-height: 1.3;
    /* border: #c7c7c7 solid 0.2em; */
    transition: transform 0.3s ease, border-color 0.3s ease;
    ${({ darkMode }) =>
      darkMode
        ? css`
            content: '🌞';
          `
        : css`
            content: '🌜';
          `};
  }

  .switch:checked {
    background-color: #0d374b;
  }

  .switch:checked::before {
    border-color: #0d374b;
    transform: translateX(100%);
  }

  @media screen and (max-width: 500px) {
    .switch {
      outline: none;
    }
  }
`
