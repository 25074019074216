import { gql } from '@apollo/client'

export const GET_WORKSPACES_QUERY = gql`
  query getWorkspaces {
    getWorkspaces {
      id
      name
      logo
      companyName
      description
      subscribed
      maxMembers
      subscribedAt
      owner {
        id
        email
        name
        lastName
        subscription
        profilePhoto
        messagingToken
        phoneNumber
      }
      members {
        name
        lastName
        email
        id
        profilePhoto
        messagingToken
        phoneNumber
      }
    }
  }
`

export const GET_ONE_WORKSPACE = gql`
  query getOneWorkspace($id: String!) {
    getOneWorkspace(id: $id) {
      id
      name
      logo
      companyName
      description
      subscribed
      maxMembers
      subscribedAt
      owner {
        id
        email
        lastName
        name
        subscription
        profilePhoto
        messagingToken
        phoneNumber
      }
      members {
        name
        lastName
        email
        id
        profilePhoto
        messagingToken
        phoneNumber
      }
    }
  }
`

export const GET_STATICS_ROOMS = gql`
  query getStaticRooms($idWorkspace: String!) {
    getStaticRooms(idWorkspace: $idWorkspace) {
      id
      roomName
      roomPassword
      isStatic
      name
      logo
      createdAt
    }
  }
`

export const GET_STATUS = gql`
  query getStatus($idUser: String!) {
    getStatus(idUser: $idUser) {
      user {
        id
      }
      workspace {
        id
      }
    }
  }
`

export const GET_CURREN_USER = gql`
  query getUser {
    getUser {
      id
      name
      lastName
      profilePhoto
      email
      phoneNumber
      messagingToken
      subscription
    }
  }
`
