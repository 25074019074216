import styled from 'styled-components'

export const LandingTutorialsStyled = styled.div`
  position: relative;
  .pricing-background {
    position: absolute;
    width: 100%;
    z-index: -1;
    background-color: #1b2a4e;
    height: 70vh;
    display: flex;
    align-items: flex-end;
  }
  .hero__container--curve {
    @media only screen and (max-width: 750px) {
      width: 750px;
    }
  }
`
