import React, { useCallback, useEffect, useState } from 'react'
import { useAuthState } from '../../contexts/auth'
import { useMutation } from '@apollo/client'
import { ADD_MEMBER_BY_LINK, DECIPHER_TEXT } from '../../graphQL/Mutations'
import { navigate } from '@reach/router'
import ReactGA from 'react-ga'
import { InvitePageStyled } from './InvitePage.styles'
import { Button, Loader, Text } from '../../common'
import { useWindow } from '../../hooks/useWindow'
import { ResponsiveDefault } from '..'

enum InviteActions {
  wrongInvitation = 'wrong-invitation',
  goWorkspace = 'go-workspace',
  workspaceFull = 'workspace-full',
}

const InvitePage = ({ inviteId }: any) => {
  const [width] = useWindow()
  const { user } = useAuthState()
  const [notAllowed, setNotAllowed] = useState(false)
  const [needToLogin, setNeedToLogin] = useState(false)
  const [workspaceFull, setWorkspaceFull] = useState(false)
  const [addMemberByLink] = useMutation(ADD_MEMBER_BY_LINK, {
    onCompleted: data => {
      if (data.addMemberByLink.action === InviteActions.wrongInvitation) {
        setNotAllowed(true)
      } else if (data.addMemberByLink.action === InviteActions.goWorkspace) {
        window.location.href = '/workspace/' + data.addMemberByLink.data
      } else if (data.addMemberByLink.action === InviteActions.workspaceFull) {
        setWorkspaceFull(true)
      }
    },
  })
  const [decipherText] = useMutation(DECIPHER_TEXT, {
    onCompleted: data => {
      const dataParsed = JSON.parse(data.decipherText)
      if (dataParsed.open) {
        addMemberByLink({
          variables: {
            id: dataParsed.idWorkspace,
            publicUrl: dataParsed.open,
            email: user.email,
          },
        })
      } else {
        addMemberByLink({
          variables: {
            id: dataParsed.idWorkspace,
            publicUrl: dataParsed.open,
            email: dataParsed.email,
          },
        })
      }
    },
  })
  const invitePeople = useCallback(async () => {
    if (!user) {
      setNeedToLogin(true)
    } else {
      await decipherText({
        variables: {
          payload: inviteId,
        },
      })
    }
  }, [decipherText, inviteId, user])
  useEffect(() => {
    invitePeople()
  }, [invitePeople])
  // _______Anaylicits
  useEffect(() => {
    ReactGA.pageview('/invite/')
  }, [])
  // _____
  if (width < 600) {
    return <ResponsiveDefault />
  }

  if (needToLogin) {
    return (
      <InvitePageStyled>
        <div className="need-auth">
          <Text type="h1" color="#fff" align="center">
            Ups! You need to login first
          </Text>
          <p className="text-base text-center text-white mb-4">
            Register or Login to your account and then click again on your
            invitation link 😉
          </p>
          <div className="flex justify-around gap-5">
            <Button
              onClick={() => {
                navigate('/login')
              }}
              color="pink"
              size="large"
            >
              Login
            </Button>
            <Button
              onClick={() => {
                navigate('/register')
              }}
              color="light"
              size="large"
            >
              Register
            </Button>
          </div>
        </div>
      </InvitePageStyled>
    )
  }
  return (
    <div>
      <InvitePageStyled>
        {notAllowed ? (
          <div className="need-permisions">
            <Text type="h1" color="#fff" align="center">
              Ups! This is not your invitation link :(
            </Text>
            <Text type="h3" color="#cfcfcf" align="center">
              Ask for the right link with your team leader.
            </Text>
            <Button
              onClick={() => {
                navigate('/home')
              }}
              color="pink"
              size="large"
            >
              Back to home
            </Button>
          </div>
        ) : workspaceFull ? (
          <div className="need-permisions">
            <Text type="h1" color="#fff" align="center">
              We are sorry :(
            </Text>
            <Text type="h3" color="#cfcfcf" align="center">
              This space have reached out the limit of team members
            </Text>
            <Button
              onClick={() => {
                navigate('/home')
              }}
              color="pink"
              size="large"
            >
              Back to home
            </Button>
          </div>
        ) : (
          <Loader />
        )}
      </InvitePageStyled>
    </div>
  )
}

export default InvitePage
