import styled, { css } from 'styled-components'

export const ButtonStyles = styled.button`
  font-family: 'HKGrotesk', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  border-radius: ${props => (props.pill ? '50px' : '5px')};
  cursor: pointer;
  div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  ${props =>
    props.size === 'large'
      ? css`
          padding: 22px 24px;
          min-width: 150px;
        `
      : props.size === 'base'
        ? css`
          padding: 16px 20px;
          min-width: 130px;
        `
        : props.size === 'small'
          ? css`
          padding: 12px 16px;
          min-width: 130px;
        `
          : props.size === 'extraSmall'
            ? css`
          padding: 6px 4px;
          min-width: 100px;
        `
            : css`
          padding: 0px 0px;
          min-width: 100px;
        `};
  ${props =>
    props.color === 'primary'
      ? css`
          background: #232323;
          color: #ffffff;
          border: none;
          ${props =>
          props.outlined &&
          css`
              background: transparent;
              color: #232323;
              border: 1px solid #232323;
            `}
          ${props =>
          props.soft &&
          css`
              background: #ebebeb;
              color: #232323;
              border: none;
            `}
      &:hover {
            background: #1d1d1d;
            ${props =>
          props.outlined &&
          css`
                background: #232323;
                color: #fff;
              `}
            ${props =>
          props.soft &&
          css`
                background: #dcdcdc;
              `}
          }
        `
      : props.color === 'pink'
        ? css`
          background: #fa27a9;
          color: #ffffff;
          border: none;
          ${props =>
            props.outlined &&
            css`
              background: transparent;
              color: #232323;
              border: 1px solid #fa27a9;
            `}
          ${props =>
            props.soft &&
            css`
              background: #FED4EE;
              color: #fa27a9;
              border: none;
            `}
      &:hover {
            background: #eb249f;
            ${props =>
            props.outlined &&
            css`
                background: #fa27a9;
                color: #fff;
              `}
            ${props =>
            props.soft &&
            css`
                background: #dcdcdc;
              `}
          }
        `
        : props.color === 'primary-pink'
          ? css`
          background: #232323;
          color: #ffffff;
          border: none;
          ${props =>
              props.outlined &&
              css`
              background: transparent;
              color: #fff;
              border: 1px solid #fa27a9;
            `}
          ${props =>
              props.soft &&
              css`
              background: #ebebeb;
              color: #232323;
              border: none;
            `}
      &:hover {
            background: #1d1d1d;
            ${props =>
              props.outlined &&
              css`
                background: #232323;
                color: #fff;
              `}
            ${props =>
              props.soft &&
              css`
                background: #dcdcdc;
              `}
          }
        `
          : props.color === 'secondary'
            ? css`
          background: #506690;
          color: white;
          border: none;
          ${props =>
                props.outlined &&
                css`
              background: transparent;
              color: #fa27a9;
              border: 1px solid #ffffff;
            `}
          ${props =>
                props.soft &&
                css`
              background: #eceef2;
              color: #506690;
              border: none;
            `}
      &:hover {
            background: #44577a;
            ${props =>
                props.outlined &&
                css`
                background: #ffffff;
                color: #fa27a9;
              `}
            ${props =>
                props.soft &&
                css`
                background: #dadde4;
              `}
          }
        `
            : props.color === 'success'
              ? css`
          background: #42ba96;
          color: white;
          border: none;
          ${props =>
                  props.outlined &&
                  css`
              background: transparent;
              color: #42ba96;
              border: 1px solid #42ba96;
            `}
          ${props =>
                  props.soft &&
                  css`
              background: #ebf5f2;
              color: #42ba96;
              border: none;
            `}
      &:hover {
            background: #389e80;
            ${props =>
                  props.outlined &&
                  css`
                background: #42ba96;
                color: #fff;
              `}
            ${props =>
                  props.soft &&
                  css`
                background: #d7ece6;
              `}
          }
        `
              : props.color === 'warning'
                ? css`
          background: #fad776;
          color: #1b2a4e;
          border: none;
          ${props =>
                    props.outlined &&
                    css`
              background: transparent;
              color: #fad776;
              border: 1px solid #fad776;
            `}
          ${props =>
                    props.soft &&
                    css`
              background: #fffcf3;
              color: #fad776;
              border: none;
            `}
      &:hover {
            background: #fbdd8b;
            ${props =>
                    props.outlined &&
                    css`
                background: #fad776;
                color: #1b2a4e;
              `}
            ${props =>
                    props.soft &&
                    css`
                background: #fef7e4;
              `}
          }
        `
                : props.color === 'danger'
                  ? css`
          background: #df4759;
          color: white;
          border: none;
          ${props =>
                      props.outlined &&
                      css`
              background: transparent;
              color: #df4759;
              border: 1px solid #df4759;
            `}
          ${props =>
                      props.soft &&
                      css`
              background: #f8eced;
              color: #df4759;
              border: none;
            `}
      &:hover {
            background: #be3c4c;
            ${props =>
                      props.outlined &&
                      css`
                background: #df4759;
                color: white;
              `}
            ${props =>
                      props.soft &&
                      css`
                background: #f2d8db;
              `}
          }
        `
                  : props.color === 'info'
                    ? css`
          background: #6f5aec;
          color: white;
          border: none;
          ${props =>
                        props.outlined &&
                        css`
              background: transparent;
              color: #6f5aec;
              border: 1px solid #6f5aec;
            `}
          ${props =>
                        props.soft &&
                        css`
              background: #f1effd;
              color: #6f5aec;
              border: none;
            `}
      &:hover {
            background: #6959cb;
            ${props =>
                        props.outlined &&
                        css`
                background: #6f5aec;
                color: white;
              `}
            ${props =>
                        props.soft &&
                        css`
                background: #e2defb;
              `}
          }
        `
                    : props.color === 'light'
                      ? css`
          background: #f9fbfd;
          color: #1b2a4e;
          border: none;
          ${props =>
                          props.outlined &&
                          css`
              background: transparent;
              color: #1b2a4e;
              border: 1px solid #f9fbfd;
            `}
          ${props =>
                          props.soft &&
                          css`
              background: #f9fbfd;
              color: #a1a9bd;
              border: none;
            `}
      &:hover {
            ${props =>
                          props.outlined &&
                          css`
                background: #f9fbfd;
              `}
          }
        `
                      : props.color === 'dark'
                        ? css`
          background: #1b2a4e;
          color: white;
          border: none;
          ${props =>
                            props.outlined &&
                            css`
              background: transparent;
              color: #1b2a4e;
              border: 1px solid #1b2a4e;
            `}
          ${props =>
                            props.soft &&
                            css`
              background: #e8e9ec;
              color: #1b2a4e;
              border: none;
            `}
      &:hover {
            background: #172442;
            ${props =>
                            props.outlined &&
                            css`
                background: #1b2a4e;
                color: white;
              `}
            ${props =>
                            props.soft &&
                            css`
                background: #d1d3d9;
              `}
          }
        `
                        : css`
          background: #fff;
          color: #1b2a4e;
        `};
        
  width: ${props => props.block && '100%'};
  ${props => props.textColor && css`
    color: ${props => props.textColor};
  `}
`
