import { navigate } from '@reach/router'
import React, { useState } from 'react'
import { FaAngleDown, FaAngleUp, FaArrowRight } from 'react-icons/fa'
import { Badge, Button, Text } from '../../../common'
import { FeatureListStyled } from './FeatureList.styles'

const Feature = ({ subInfo, anual }: any) => {
  const [showFeatures, setShowFeatures] = useState(false)

  const handleShowFeatures = () => {
    setShowFeatures(!showFeatures)
  }
  return (
    <div className="pricing-content__pricing--item">
      <div className="badge">
        <Badge color="pink" customClass="badge__element">
          {subInfo.title}
        </Badge>
      </div>
      <Text type="p" color="#BFCADA" size="14px" align="center" className="">
        {subInfo.custom && 'From'}
      </Text>
      <div className="price">
        <span className="price__dollar">$</span>
        <div>
          <Text
            type="h1"
            color="#161C2D"
            align="center"
            size="45px"
            className="price__text"
          >
            {anual ? subInfo.anualPrice : subInfo.monthPrice}
          </Text>
          <span>
            /{anual ? `${subInfo.yearlabel}` : `${subInfo.monthlabel}`}
          </span>
        </div>
      </div>
      <Text type="p" color="#BFCADA" align="center" className="includes-text">
        {subInfo.include}
      </Text>
      <div className="dropdown-features" onClick={handleShowFeatures}>
        <Text
          type="p"
          color="#161C2D"
          align="center"
          className="dropdown-features--text"
        >
          show {showFeatures ? 'less' : 'more'}
          {showFeatures ? <FaAngleUp /> : <FaAngleDown />}
        </Text>
      </div>
      <div className={`features ${showFeatures ? '' : 'responsive-features'}`}>
        <ul className="list text-base leading-10">
          {subInfo.features.map((feature: any, key: number) => (
            <li key={key}>{feature.name}</li>
          ))}
          {anual && subInfo.title !== 'Basic' ? (
            <li>{subInfo.promo}</li>
          ) : (
            <></>
          )}
        </ul>
        <Text
          type="p"
          color="#161C2D"
          align="center"
          className="dropdown-features--text"
          weight={600}
        >
          {subInfo.pro}
        </Text>
      </div>
      <div className="cac-button">
        <Button
          color="pink"
          size="base"
          soft={subInfo.cac.soft}
          onClick={() => {
            navigate(subInfo.cac.route)
          }}
        >
          {subInfo.cac.label} <FaArrowRight />
        </Button>
      </div>
    </div>
  )
}
const FeatureList = ({ anual, subscribeInfo }: any) => {
  return (
    <FeatureListStyled>
      <div className="pricing-content__pricing">
        {subscribeInfo.map((subInfo: any, key: number) => (
          <Feature key={key} anual={anual} subInfo={subInfo} />
        ))}
      </div>
    </FeatureListStyled>
  )
}

export default FeatureList
