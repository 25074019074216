import React, { createContext, useReducer, useContext, useEffect } from 'react'
import jwtDecode from 'jwt-decode'

const AuthStateContext = createContext<any>({})
const AuthDispatchContext = createContext<any>({})
let user: any = null
const token = window.localStorage.getItem('token')
if (token) {
  const decodedToken: any = jwtDecode(token)
  const expiresAt = new Date(decodedToken.exp * 1000)
  if (new Date() > expiresAt) {
    window.localStorage.removeItem('token')
  } else {
    user = decodedToken
  }
} else {
  console.log('no token found')
}

const authReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'LOGIN':
      window.localStorage.setItem('token', action.payload.token)
      return {
        ...state,
        user: action.payload,
      }
    case 'LOGOUT':
      window.localStorage.removeItem('token')
      // window.location.href = '/login'
      return {
        ...state,
        user: null,
      }
    case 'UPDATE_USER':
      // console.log(action.payload)
      return {
        ...state,
        user: action.payload,
      }
    default:
      throw new Error(`Unknown action type: ${action.type}`)
  }
}

export const AuthProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(authReducer, { user })

  const isAuthThirdParty = window.localStorage.getItem('authGoogle') === 'true'
  useEffect(() => {
    const getUserFromGoogleAuth = async () => {
      fetch(process.env.REACT_APP_BACKEND_URL + '/auth/success', {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
        },
      })
        .then(response => {
          if (response.status === 200) return response.json()
          throw new Error('authentication has been failed!')
        })
        .then(resObject => {
          window.localStorage.setItem('token', resObject.user.token)
          dispatch({ type: 'UPDATE_USER', payload: resObject.user })
          window.localStorage.removeItem('authGoogle')
          window.location.href = '/home'
        })
        .catch(err => {
          console.log(err)
        })
    }
    if (isAuthThirdParty) {
      getUserFromGoogleAuth()
    }
  }, [isAuthThirdParty])
  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  )
}

export const useAuthState = () => useContext(AuthStateContext)
export const useAuthDispatch = () => useContext(AuthDispatchContext)
