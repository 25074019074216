import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { FaCommentAlt } from 'react-icons/fa'
import { IoSend } from 'react-icons/io5'
import { Anchor, Button, Input, Loader, Text } from '../../../common'
import { useAuthState } from '../../../contexts/auth'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { SEND_FEEDBACK } from '../../../graphQL/Mutations'
import { HomeFooterStyled } from './HomeFooter.styles'

interface HomeFooterProps {
  impl: any
}

const HomeFooter = ({ impl }: HomeFooterProps) => {
  const { user } = useAuthState()
  const { darkMode }: any = useWorkspaceContext()
  const [showFeedback, setShowFeedback] = useState(false)
  const [feedbackMessage, setFeedbackMessage] = useState('')
  const [errorFeedback, setErrorFeedback] = useState(false)
  const [successFeedback, setSuccessFeedback] = useState(false)

  const [sendFeedback, { loading }] = useMutation(SEND_FEEDBACK, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCompleted: data => {
      setFeedbackMessage('')
      setSuccessFeedback(true)
      setTimeout(() => {
        setSuccessFeedback(false)
      }, 10000)
    },
    onError: err => {
      setErrorFeedback(true)
      setTimeout(() => {
        setErrorFeedback(false)
      }, 10000)
      console.log(err)
    },
  })
  const handleShowFeedback = () => {
    setShowFeedback(!showFeedback)
  }
  const handleTextArea = (value: string) => {
    setFeedbackMessage(value)
  }
  const handleSubmitFeeback = () => {
    sendFeedback({
      variables: {
        message: feedbackMessage,
      },
    })
  }
  return (
    <HomeFooterStyled darkMode={darkMode}>
      <Text color="#94A5C0" type="p">
        {impl.termsPreLabel}{' '}
        <Anchor
          type="link"
          route="/terms"
          color={darkMode ? '#F9FBFD' : '#232323'}
        >
          {impl.termsLabel}
        </Anchor>
      </Text>
      <Text type="p">
        <Anchor
          type="link"
          route="/contact"
          color={darkMode ? '#F9FBFD' : '#232323'}
        >
          {impl.contactUsLabel}
        </Anchor>
      </Text>
      <div className="feedback">
        <div className="feedback__message">
          <FaCommentAlt
            size="32px"
            color="#FA27A9"
            onClick={handleShowFeedback}
          />
          {!showFeedback && (
            <div className="feedback__tooltip">{impl.feedbackHover}</div>
          )}
          {showFeedback && (
            <div className="feedback__input">
              <Text
                align="center"
                type="h3"
                color={darkMode ? '#232323' : '#F9FBFD'}
              >
                {impl.feedbackTitle}
              </Text>
              <hr />
              <Input
                value={feedbackMessage}
                placeHolder={impl.feedbackPlaceholder}
                onChange={handleTextArea}
                validationError=""
                textArea="textarea"
                rows={10}
                cols={40}
                color={darkMode ? '#232323' : '#F9FBFD'}
              />
              <div className="feature__buttonContainer">
                {loading && <Loader size="25px" border="3px" />}
                {successFeedback && (
                  <Text
                    align="center"
                    type="p"
                    color={darkMode ? '#232323' : '#F9FBFD'}
                  >
                    {impl.feedbackSuccess(user.name.split(' ')[0])}
                  </Text>
                )}
                {errorFeedback && (
                  <Text align="center" type="p" color="#DF4759">
                    {impl.feedbackError}
                  </Text>
                )}
                <Button
                  color={darkMode ? 'primary' : 'light'}
                  outlined={darkMode}
                  size="extraSmall"
                  onClick={handleSubmitFeeback}
                  disabled={loading || feedbackMessage.length === 0}
                  className="ml-2"
                >
                  <IoSend size="25px" />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </HomeFooterStyled>
  )
}

export default HomeFooter
