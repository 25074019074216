import React, { useEffect, useState } from 'react'
import { useAuthDispatch } from '../../contexts/auth'
import { REGISTER_MUTATION } from '../../graphQL/Mutations'
import { RegisterStyled } from './Register.styles'
import ReactGA from 'react-ga'
import { useMutation } from '@apollo/client'
import { Alert, Anchor, Button, Input, Loader, Text } from '../../common'
import images from '../../assets/static/images'
import { useWindow } from '../../hooks/useWindow'
import { validateEmail, validatePassword } from '../../helpers/validators'
import { useLocation } from '@reach/router'

const Register = () => {
  const [width] = useWindow()
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [acceptTerms, setAcceptTerms] = useState(false)
  const dispatch = useAuthDispatch()
  const { search } = useLocation()
  const [errors, setErrors] = useState({})
  const [registerUser, { loading }] = useMutation(REGISTER_MUTATION, {
    update: async (_, { data: { register } }) => {
      dispatch({ type: 'LOGIN', payload: register })
      // navigate('/home')
      ReactGA.event({
        category: 'Nuevo registro',
        action: `Nuevo Usuario: ${email}`,
      })
      window.location.href = '/home'
    },
    onError: (err: any) => {
      setErrors(err.graphQLErrors[0].extensions.errors)
      setTimeout(() => {
        setErrors({})
      }, 3000)
    },
  })
  useEffect(() => {
    ReactGA.pageview('/login')
  }, [])
  const handleEmail = (value: string) => {
    // eslint-disable-next-line no-control-regex
    if (validateEmail(value)) {
      setEmailError('')
    } else {
      setEmailError('Please enter a valid email')
    }
    if (value === '') {
      setEmailError('')
    }
    setEmail(value)
  }
  const handleName = (value: string) => {
    setName(value)
  }
  const handleLastName = (value: string) => {
    setLastName(value)
  }
  const handlePassword = (value: string) => {
    if (validatePassword(value)) {
      setPasswordError('')
    } else {
      setPasswordError(
        'Password must have at least 8 characters, an uppercase, a lowercase, a number and a special character @$!%*?&#+_:,-.'
      )
    }
    if (value === '') {
      setPasswordError('')
    }
    setPassword(value)
  }
  const handleConfirmPassword = (value: string) => {
    if (password === value) {
      setConfirmPasswordError('')
    } else {
      setConfirmPasswordError('Passwords must match')
    }
    if (value === '') {
      setConfirmPasswordError('')
    }
    setConfirmPassword(value)
  }
  const handleAcceptTerms = () => {
    setAcceptTerms(!acceptTerms)
  }
  const handleSumbit = (e: any) => {
    e.preventDefault()
    registerUser({
      variables: {
        name,
        lastName,
        email,
        password,
        confirmPassword,
        referenceEmail: search.split('?')[1],
      },
    })
  }
  const handleDisabled = () => {
    if (
      name !== '' &&
      lastName !== '' &&
      email !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      emailError === '' &&
      passwordError === '' &&
      confirmPasswordError === '' &&
      acceptTerms
    ) {
      return false
    }
    return true
  }
  return (
    <div>
      <RegisterStyled backgroundImage={images.loginBackground}>
        <div className="main-content">
          <Anchor route="/landing" type="link">
            <img
              src={images.logoAuth}
              alt="logo"
              className="main-content__logo"
            />
          </Anchor>
          <Text type="h1" className="main-content__title">
            Register
          </Text>
          <form onSubmit={handleSumbit} className="login-form">
            <Input
              value={name}
              onChange={handleName}
              validationError=""
              label="Name"
              placeHolder="Enter your name"
              className="login-form__input"
            />
            <Input
              value={lastName}
              onChange={handleLastName}
              validationError=""
              label="Last Name"
              placeHolder="Enter your last name"
              className="login-form__input"
            />
            <Input
              value={email}
              onChange={handleEmail}
              validationError={emailError}
              label="Email"
              placeHolder="Enter your email address"
              className="login-form__input"
            />
            <Input
              value={password}
              onChange={handlePassword}
              validationError={passwordError}
              label="Password"
              placeHolder="Enter your password"
              type="password"
              className="login-form__input"
            />
            <Input
              value={confirmPassword}
              onChange={handleConfirmPassword}
              validationError={confirmPasswordError}
              label="Confirm your Password"
              placeHolder="Confirm your password"
              type="password"
              className="login-form__input"
            />
            <div className="login-form__input login-form__checkbox">
              <input type="checkbox" onChange={handleAcceptTerms} />
              <Anchor route="/terms" type="link" targetBlank>
                I accept terms and conditions
              </Anchor>
              <span>*To continue, please accept the terms and conditions</span>
            </div>
            <Button
              size="base"
              color="primary"
              onClick={() => {}}
              disabled={loading || handleDisabled()}
              block
              className="login-form__button"
            >
              {loading ? <Loader size="25px" border="3px" /> : 'Register'}
            </Button>
          </form>
          {Object.keys(errors).length > 0 && (
            <div className="alert">
              {Object.values(errors).map((value: any) => (
                <Alert color="danger" key={value}>
                  <p>{value}</p>
                </Alert>
              ))}
            </div>
          )}
          <div className="main-content__footer">
            <Text type="p" align="center">
              Already have an account?
            </Text>
            <div className="main-content__footer--link">
              <Anchor route="/login" type="link">
                Login
              </Anchor>
            </div>
          </div>
        </div>
        {width > 900 && <div className="side-background" />}
      </RegisterStyled>
    </div>
  )
}

export default Register
