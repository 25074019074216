import styled from 'styled-components'

export const InviteRoomStyted = styled.div`
  background-color: #232323;
  height: 100vh;
  .logo {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    padding-top: ${props => (props.logoTop ? '30vh' : '10px')};
  }
  .pre-meeting {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    place-items: center;
    p {
      margin: 20px 0px;
    }
    &__content {
      grid-column: 3/4;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .invite-room__avatar {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin: auto;
    }
    .invite-room__form {
      input {
        color: #fff;
        margin: 10px 0px;
        text-align: center;
      }
      &--button {
        display: grid;
        place-items: center;
        margin-top: 20px;
      }
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      margin: 0px 10px;
      &__content {
        grid-column: 1/2;
      }
    }
  }
  .top-alert {
    position: fixed;
    top: 85px;
    z-index: 10;
    width: 90vw;
    &__content {
      display: flex;
      align-items: center;
    }
    @media (max-width: 768px) {
      &__content {
        display: block;
      }
    }
  }
  .meeting-container {
    display: grid;
    place-items: center;
  }
  .button-continer {
    display: grid;
    place-items: center;
    margin-top: 20px;
  }
`
