import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import ReactGA from 'react-ga'
import { HomeStyled } from './Home.styles'
import { CHANGE_STATUS, SET_MESSAGING_TOKEN } from '../../graphQL/Mutations'
import { HomeFooter, HomeHeader, ZyreboxList } from '../../components/Home'
import { useWindow } from '../../hooks/useWindow'
import { ResponsiveDefault } from '..'
import { useWorkspaceContext } from '../../contexts/workspace'
import { getToken } from 'firebase/messaging'
import { messaging } from '../../firebase/init-firebase'

const Home = () => {
  const [width] = useWindow()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [workspaces, setWorkspaces] = useState([])
  const {
    impl: { HomeImpl },
    darkMode,
    handleWorkspace,
  }: any = useWorkspaceContext()
  const [updateStatus] = useMutation(CHANGE_STATUS, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCompleted: data => {},
    onError: err => {
      console.log(err)
    },
  })

  const [setMessagingToken] = useMutation(SET_MESSAGING_TOKEN, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCompleted: data => {},
    onError: err => {
      console.log(err)
    },
  })

  const statusLogout = () => {
    updateStatus({
      variables: { idWorspace: process.env.REACT_APP_WORKSPACE_ID_STATUS_NONE },
    })
  }

  const activateMessaging = useCallback(async () => {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIRE_KEY_PAIR,
    }).catch(error => console.error(error))
    const messagingToken = window.localStorage.getItem('messagingToken')
    if (token) {
      if (!messagingToken) {
        window.localStorage.setItem('messagingToken', token as string)
        // TODO SAVE TOKEN ON USER USING GRAPHQL
        setMessagingToken({
          variables: { token: token },
        })
      }
    } else if (!token) console.log('token not found')
  }, [setMessagingToken])

  useEffect(() => {
    handleWorkspace(null)
    statusLogout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // _______Anaylicits
  useEffect(() => {
    ReactGA.pageview('/home')
    activateMessaging()
  }, [activateMessaging])
  // _____
  if (width < 600) {
    return <ResponsiveDefault />
  }

  return (
    <HomeStyled darkmode={darkMode}>
      <HomeHeader impl={HomeImpl.header} />
      <ZyreboxList
        setCountWorkspaces={setWorkspaces}
        impl={HomeImpl.zyreboxList}
      />
      <HomeFooter impl={HomeImpl.footer} />
    </HomeStyled>
  )
}

export default Home
