/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useSubscription,
  useQuery,
} from '@apollo/client'
import { useAuthState } from '../../contexts/auth'
import { useWorkspaceContext } from '../../contexts/workspace'
import { GET_ONE_WORKSPACE, GET_STATICS_ROOMS } from '../../graphQL/Queries'
import {
  CHANGE_CALL_STATUS,
  NEW_CALL,
  ROOM_CREATED,
  ROOM_DELETED,
  SEND_EXISTING_CALL,
} from '../../graphQL/Subscription'
import {
  CHANGE_STATUS,
  LEAVE_WORKSPACE,
  REMOVE_STATUS,
  SET_MESSAGING_TOKEN,
} from '../../graphQL/Mutations'
import ReactGA from 'react-ga'
import {
  MainZyreboxContainer,
  BackToHome,
  MainContainer,
} from './MainZyrebox.styles'
import { MiniZyreboxLogo } from '../../assets/static/svg'
import { FaChevronLeft } from 'react-icons/fa'
import { Loader, YouDontBelong, Modal, Text, Button } from '../../common'
import {
  GroupSpaces,
  IncomingCall,
  MySpace,
  MyTeam,
  ZyreboxInfo,
  JitsiMeetComponent,
} from '../../components/MainZyrebox'
import Aplications from '../../components/MainZyrebox/Aplications'
import images from '../../assets/static/images'
import { useWindow } from '../../hooks/useWindow'
import { ResponsiveDefault } from '..'
import MainMenu from '../../components/MainZyrebox/MainMenu'
import { useLocation, useNavigate } from '@reach/router'
import { useNotificationContext } from '../../contexts/NotificationContext'
import sounds from '../../assets/static/sounds'
import { getToken } from 'firebase/messaging'
import { messaging } from '../../firebase/init-firebase'
import { Alert, Tooltip } from 'antd'
// TODO maintain status if user is still on a zyrebox
// import { useMaintainStatus } from "../../hooks/useMaintainStatus";
// import { useSyncState } from "../../hooks/useSyncState";

// import images from '../../assets/static/images'

const MainZyrebox = ({ idWorkspace }: any) => {
  const [width] = useWindow()
  const navigate = useNavigate()
  // const { maintainStatus, stopMaintaing } = useMaintainStatus()
  const {
    workspace,
    handleWorkspace,
    call,
    handleCall,
    handleRoom,
    room,
    darkMode,
    handStaticRooms,
    handleActivePopUpId,
    impl: { MainZyreboxImpl },
  }: any = useWorkspaceContext()

  // const maintainIdWorkspace = useSyncState(idWorkspace)
  const { showCommonAlert } = useNotificationContext()
  const [staticRooms, setStaticsRooms] = useState([])
  const { user } = useAuthState()
  const { search } = useLocation()
  const roomCredentials = search.slice(1)
  const arrRoomcred = roomCredentials.split('')
  const roomName = arrRoomcred.slice(0, 16).join('')
  const roomPass = arrRoomcred.slice(16).join('')

  const [setMessagingToken] = useMutation(SET_MESSAGING_TOKEN, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCompleted: data => {},
    onError: err => {
      console.log(err)
    },
  })

  const activateMessaging = useCallback(async () => {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIRE_KEY_PAIR,
    }).catch(error => console.error(error))
    const messagingToken = window.localStorage.getItem('messagingToken')
    if (token) {
      if (!messagingToken) {
        window.localStorage.setItem('messagingToken', token as string)
        // TODO SAVE TOKEN ON USER USING GRAPHQL
        setMessagingToken({
          variables: { token: token },
        })
      }
    } else if (!token) console.log('token not found')
  }, [setMessagingToken])

  const [getWorkspace, { loading, error }] = useLazyQuery(GET_ONE_WORKSPACE, {
    onCompleted: data => {
      handleWorkspace(data.getOneWorkspace)
      // getRooms({ variables: { idWorkspace: data.getOneWorkspace.idWorkspace } })
    },
    onError: err => console.log(err),
  })
  const { fetchMore } = useQuery(GET_STATICS_ROOMS, {
    variables: { idWorkspace: idWorkspace },
    onCompleted: data => {
      setStaticsRooms(data.getStaticRooms)
      handStaticRooms(data.getStaticRooms)
    },
    onError: err => console.log(err),
  })
  const [updateStatus] = useMutation(CHANGE_STATUS, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCompleted: data => {
      // console.log(data)
    },
    onError: err => {
      console.log(err)
    },
  })

  const [removeStatus] = useMutation(REMOVE_STATUS, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCompleted: data => {
      navigate('/home')
    },
    onError: err => {
      console.log(err)
    },
  })

  const { data: roomCreated, error: roomCreatedError } =
    useSubscription(ROOM_CREATED)
  const { data: roomDeleted, error: roomDeletedError } =
    useSubscription(ROOM_DELETED)
  const handleFetchMore = () => {
    fetchMore({
      variables: { idWorkspace: idWorkspace },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        setStaticsRooms(fetchMoreResult.getStaticRooms)
        return fetchMoreResult
      },
    })
  }

  useEffect(() => {
    if (roomCreatedError || roomDeletedError) {
    } else {
      handleFetchMore()
    }
  }, [roomCreated, roomCreatedError, roomDeleted, roomDeletedError])

  const { data: callData, error: callError } = useSubscription(NEW_CALL)
  const { data: callUpdateData, error: callUpdateError } =
    useSubscription(CHANGE_CALL_STATUS)
  const { data: existingCallData, error: existingCallError } =
    useSubscription(SEND_EXISTING_CALL)
  useEffect(() => {
    if (search.length > 0) {
      handleRoom({
        roomName: roomName,
        roomPassword: roomPass,
        active: true,
      })
    }
  }, [])
  useEffect(() => {
    if (callError) console.log(callError)
    if (callData) {
      const newCall = callData.newCall
      handleCall(newCall)
    }
  }, [callError, callData])
  useEffect(() => {
    if (callUpdateError) console.log(callUpdateError)
    if (callUpdateData) {
      const updatedCall = callUpdateData.changeStatusCall
      handleCall(updatedCall)
      // console.log(updatedCall)
      if (updatedCall.status === 'answered') {
        handleRoom({
          ...updatedCall.room,
          active: true,
        })
      } else {
        // TODO bug al colgar la llamada, cuando estamos en una llamada en curso, se cierra la llamada
        // antes preguntar si hay una llamada en curso
        // handleRoom({
        //   ...updatedCall.room,
        //   active: false
        // })
      }
    }
  }, [callUpdateError, callUpdateData])
  useEffect(() => {
    if (existingCallError) console.log(existingCallError)
    if (existingCallData) {
      const existingCall = existingCallData.sendExisitingCall
      handleCall(existingCall)
    }
  }, [existingCallError, existingCallData])
  useEffect(() => {
    if (!workspace.id) {
      getWorkspace({ variables: { id: idWorkspace } })
      updateStatus({ variables: { idWorspace: idWorkspace } })
    } else {
      // getRooms({ variables: { idWorkspace: workspace.id } })
      updateStatus({ variables: { idWorspace: idWorkspace } })
    }
  }, [workspace])
  // _______Anaylicits
  useEffect(() => {
    ReactGA.pageview('/workspace/')
    activateMessaging()
  }, [])
  // _____

  useEffect(() => {
    if (call.status === 'not-answered' && user.id === call.from.id) {
      showCommonAlert({
        child: (
          <Alert type="info" message={`${call.to.name} did't answered`}></Alert>
        ),
        position: 'topright',
        duration: 3000,
      })
    }
  }, [call.status])
  const userBelongs = (arr: any) => {
    const ids = arr.map((item: any) => item.id)
    return ids.includes(user.id)
  }
  const backImages = [images.zyreboxBackLightMode, images.zyreboxBackDarkMode]

  const [data, setData] = useState()
  const [showModal, setShowModal] = useState(false)
  const [leaveWorkspace, { loading: loadingDelete }] = useMutation(
    LEAVE_WORKSPACE,
    {
      onCompleted: (data: any) => {
        setData(data)
        setTimeout(() => {
          window.location.href = '/home'
        }, 3000)
      },
    }
  )
  const handleModal = () => {
    setShowModal(!showModal)
  }
  const handleLeaveWorkspace = () => {
    leaveWorkspace({ variables: { idWorkspace: workspace.id } })
  }

  if (loading)
    return (
      <div
        style={{
          height: '100vmin',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: darkMode ? '#161C2D' : '#fff',
        }}
      >
        <Loader />
      </div>
    )
  if (error) return <p>{error.graphQLErrors[0].message}</p>
  if (width < 600) {
    return <ResponsiveDefault />
  }

  const handleBackToHome = () => {
    removeStatus({ variables: { idWorspace: idWorkspace } })
  }

  const statusLogout = () => {
    // maintainIdWorkspace.set(false)
    // stopMaintaing()
    removeStatus({ variables: { idWorspace: idWorkspace } })
  }

  // TODO window on back arrow on browser
  window.addEventListener('beforeunload', () => {
    // ev.preventDefault();
    statusLogout()
    return statusLogout()
  })
  window.addEventListener('onbeforeunload', () => {
    // ev.preventDefault();
    statusLogout()
    return statusLogout()
  })

  // maintainStatus(maintainIdWorkspace.get())
  const handleBackgroundActions = () => {
    handleActivePopUpId('')
  }
  return (
    <>
      <MainContainer
        darkMode={darkMode}
        backgroundImage={darkMode ? backImages[1] : backImages[0]}
        id="main-zyrebox-container"
      >
        <div
          className="mainZyrebox-BackgroudActions"
          onClick={handleBackgroundActions}
        />
        {workspace.id && userBelongs(workspace.members) ? (
          <MainZyreboxContainer>
            <Tooltip placement="right" title={MainZyreboxImpl.backTooltip}>
              <BackToHome onClick={handleBackToHome}>
                <FaChevronLeft size="12" />
                <MiniZyreboxLogo />
              </BackToHome>
            </Tooltip>
            <ZyreboxInfo
              image={workspace.logo}
              owner={workspace.owner}
              workspaceName={workspace.name}
              workspaceId={workspace.id}
              companyName={workspace.companyName}
              description={workspace.description}
              impl={MainZyreboxImpl.zyreboxInfo}
              leaveWorkspace={handleModal}
            />
            <MySpace user={user} impl={MainZyreboxImpl.myspace} />
            <Aplications impl={MainZyreboxImpl.apps} />
            <GroupSpaces
              staticRooms={staticRooms}
              impl={MainZyreboxImpl.groupSpaces}
            />
            <MyTeam members={workspace.members} impl={MainZyreboxImpl.myTeam} />
          </MainZyreboxContainer>
        ) : (
          <YouDontBelong />
        )}

        {call.status === 'calling' && call.workspace === workspace.id && (
          <IncomingCall
            from={call.from}
            to={call.to}
            room={call.room}
            status={call.status}
            impl={MainZyreboxImpl.incommingCall}
          />
        )}
        {workspace.id && staticRooms && room.active && (
          <JitsiMeetComponent impl={MainZyreboxImpl.jitsiMeet} />
        )}
        {
          <Modal show={showModal} handleModal={handleModal} action={''}>
            <div className="leave-modal">
              <Text color="#161C2D" type="h4" align="center">
                {MainZyreboxImpl.leaveModal.question(workspace.name)}
              </Text>
              <Text color="#161C2D" type="p" align="center" size="17px">
                {MainZyreboxImpl.leaveModal.advertisement}
              </Text>
              <div className="leave-modal__buttons">
                <Button
                  onClick={handleModal}
                  color="secondary"
                  outlined
                  size="small"
                >
                  {MainZyreboxImpl.leaveModal.cancelbtn}
                </Button>
                <Button
                  onClick={handleLeaveWorkspace}
                  color="danger"
                  size="small"
                  disabled={loadingDelete}
                >
                  {data || loadingDelete ? (
                    <Loader size="25px" border="3px" color="#ffa4a4" />
                  ) : (
                    MainZyreboxImpl.leaveModal.leavebtn
                  )}
                </Button>
              </div>
            </div>
          </Modal>
        }
        <MainMenu />
        <audio id="ringingSound" loop>
          <source src={sounds.incommingCall} type="audio/ogg" />
          Your browser does not support the audio element.
        </audio>
      </MainContainer>
    </>
  )
}

export default MainZyrebox
