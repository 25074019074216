import React from 'react'

const ConfigZyreboxImpl = {
  ConfigZyreboxImpl: {
    sidebar: {
      backlabel: 'Back',
      signoutBtn: 'Sign out',
      editLabel: 'Config Profile',
      billingLabel: 'Billing',
      securityLabel: 'Security',
    },
    billing: {
      tite: 'Billing',
      subtitle: 'Improve my plan',
      currentLabel: 'Current Plan:',
      proLabel: 'Pro',
      basicLabel: 'Basic',
      planProMessage: 'Enjoy your Pro Plan  🚀',
      subscribeBtn: 'Subscribe to Pro',
    },
    accountConfig: {
      nameInput: {
        label: 'Name',
        placeholder: 'Enter your name',
      },
      lastNameInput: {
        label: 'Last Name',
        placeholder: 'Enter your last name',
      },
      emailInput: {
        label: 'Email',
        placeholder: 'Enter your email',
      },
      phoneInput: {
        label: 'Phone Number (Whatsapp)',
        placeholder: 'Enter your phone number',
      },
      saveBtn: 'Save',
      profileConfigTitle: 'Profile Configs',
      moreConfigsTitle: 'More Configs',
      videoCallSection: {
        title: 'Video Call',
        disablePreJoinLabel: 'Disable Pre Join Screen',
        prejoinEnableLabel: 'enabled',
        prejoinDisableLabel: 'disabled',
      },
    },
    security: {
      title: 'Secutirty',
      currentPassInput: {
        label: 'Current password',
        placeholder: 'Enter your current password',
      },
      newPassInput: {
        label: 'New Password',
        placeholder: 'Enter your new password',
      },
      confirmPassInput: {
        label: 'Confirm Password',
        placeholder: 'Confirm your password',
      },
      changeBtn: 'Change',
      support: {
        modalBtn: 'Support',
        modalText: (
          <>
            For support or back to basic subscripion, please contact us on{' '}
            <strong>team@zyrebox.com</strong>
          </>
        ),
      },
    },
  },
}

export default ConfigZyreboxImpl
