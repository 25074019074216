import React from 'react'
import sounds from '../assets/static/sounds'
import Drawer from './Drawer'
import GlobalAlert from './GlobalAlert'

const Layout = ({ children }: any) => {
  return (
    <div>
      <Drawer />
      <GlobalAlert />
      {children}
      <audio id="notificationSound">
        <source src={sounds.notification} type="audio/ogg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  )
}

export default Layout
