import { navigate } from '@reach/router'
import { useState } from 'react'
import { Button, Avatar, ImageUploaderAvatar } from '../../common'
import { FaChevronDown, FaChevronLeft, FaChevronUp } from 'react-icons/fa'
import { CheckoutStyledWraper } from './Checkout.styles'
import { useWorkspaceContext } from './../../contexts/workspace'
import { useMutation, useQuery } from '@apollo/client'
import { useAuthDispatch, useAuthState } from './../../contexts/auth'
import LanguageFloat from './../../common/LanguageFloat/LanguageFloat'
import { GoDash, GoPlus } from 'react-icons/go'
import { CheckoutAuthStep, CheckoutPayment } from '../../components/Checkout'
import { GET_WORKSPACES_QUERY } from '../../graphQL/Queries'
import images from '../../assets/static/images'
import { BsInfoCircle } from 'react-icons/bs'
import { message, Popover } from 'antd'
import { SUBSCRIBE_WORKPSACE } from '../../graphQL/Mutations'

const PRICE_EXTRA_MEMBER = 2
const PRICE_PLAN_PRO = 25
const PERCENTAGE_DISCOUNT = 0
const MEMBERS_INCLUDED = 10

type NewWorkspaceForm = {
  teamName: string
  companyName: string
  logo: string
}
type WorkspaceFormProps = {
  formHandler: (e: any) => void
  workspaceValues: NewWorkspaceForm
}

const WorkspaceItem = ({ workspaceToSubscript }) => {
  return (
    <>
      <Avatar image={workspaceToSubscript?.logo} className="mr-4" size="75px" />
      <div className="">
        <p className="text-lg font-bold">{workspaceToSubscript?.name}</p>
        <p className="text-md text-gray-500">
          {workspaceToSubscript?.companyName}
        </p>
      </div>
    </>
  )
}
const WorkspaceForm = ({
  formHandler,
  workspaceValues,
}: WorkspaceFormProps) => {
  return (
    <>
      <div className="flex items-start">
        <ImageUploaderAvatar
          image={workspaceValues.logo}
          className="mr-4"
          size="75px"
          handleUploadFile={e => {
            console.log(e.target.files)
          }}
          loadingUpload={false}
          name="logo"
        />
      </div>
      <div>
        <div>
          <input
            className="text-lg focus-visible:outline-none rounded-md p-1 mb-2 bg-white border-solid border-2 border-gray-200"
            placeholder="Team Name"
            onChange={formHandler}
            value={workspaceValues?.teamName}
            name="teamName"
          />
        </div>
        <div>
          <input
            className="text-md text-gray-500 focus-visible:outline-none rounded-md p-1 bg-white border-solid border-2 border-gray-200"
            placeholder="Company Name"
            onChange={formHandler}
            value={workspaceValues?.companyName}
            name="companyName"
          />
        </div>
      </div>
    </>
  )
}

const Checkout = () => {
  const dispatch = useAuthDispatch()
  const {
    handleSubscribedUser,
    handleWorkspaceToSubscript,
    workspaceToSubscript,
    handleSubscribedWorkspace,
    impl: { CheckoutImpl },
  }: any = useWorkspaceContext()
  const { user } = useAuthState()
  const [workspaces, setWorkspaces] = useState<any>([])
  const [members, setMembers] = useState(
    Number(workspaceToSubscript?.maxMembers)
  )
  const [newWorkspaceValues, setNewWorkpaceValues] = useState<NewWorkspaceForm>(
    {
      teamName: '',
      companyName: '',
      logo: images.logoCircular,
    }
  )
  const [showWorkspaces, setShowWorkspaces] = useState(false)
  const [enableWorkspaceForm, setEnableWorkspaceForm] = useState(false)
  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' })
  }

  const [subscribe] = useMutation(SUBSCRIBE_WORKPSACE, {
    onCompleted: (data: any) => {
      handleSubscribedUser(user)
      handleSubscribedWorkspace(data.subscribeWorkspace)
      handleLogout()
      message.success('Operations completed successfully')
      navigate('/subscribedLanding')
    },
    onError: (err: any) => {
      // notify with toast
      message.error('Ups, something went wrong, contact us for help', 10)
      console.error(err)
    },
  })

  const { loading } = useQuery(GET_WORKSPACES_QUERY, {
    onCompleted: data => {
      if (data.getWorkspaces.length > 0) {
        const fileteredWorkspace = data.getWorkspaces.filter(
          workspace => !workspace.subscribed && user.id === workspace.owner.id
        )
        setWorkspaces(fileteredWorkspace)
        if (!workspaceToSubscript) {
          handleWorkspaceToSubscript(fileteredWorkspace[0])
          setMembers(Number(fileteredWorkspace[0]?.maxMembers))
        }
      } else {
        setEnableWorkspaceForm(true)
      }
    },
    onError: err => console.log(err),
  })

  const subscribeCallback = () => {
    subscribe({
      variables: {
        idWorkspace: workspaceToSubscript ? workspaceToSubscript.id : null,
        maxMembers: members,
        zyreboxName: workspaceToSubscript ? null : newWorkspaceValues.teamName,
        logo: workspaceToSubscript ? null : newWorkspaceValues.logo,
        companyName: workspaceToSubscript
          ? null
          : newWorkspaceValues.companyName,
      },
    })
  }

  const toggleShowWorkspaces = () => {
    setShowWorkspaces(!showWorkspaces)
  }
  const handleEnableWorkspaceForm = status => {
    setEnableWorkspaceForm(status)
  }
  const handleNewWorkspaceForm = e => {
    setNewWorkpaceValues({
      ...newWorkspaceValues,
      [e.target.name]: e.target.value,
    } as NewWorkspaceForm)
  }

  const blockSection = () => {
    if (!user) return true
    if (!workspaceToSubscript) {
      if (
        newWorkspaceValues.companyName.length <= 0 ||
        newWorkspaceValues.teamName.length <= 0
      )
        return true
    }
    return false
  }

  const calcAdditionals = () => {
    return (members - MEMBERS_INCLUDED) * PRICE_EXTRA_MEMBER
  }
  const calcSubtotal = () => {
    return PRICE_PLAN_PRO + calcAdditionals()
  }
  const calcAmountDiscount = () => {
    // Eliminar calcAdditionals() cuando se pueda capturar el monto real de suscripción para añadir descuento real
    return calcSubtotal() * PERCENTAGE_DISCOUNT + calcAdditionals()
  }
  const calcAditionalMembers = () => {
    return {
      qty: members - 10,
      amount: calcAdditionals(),
    }
  }
  const calcAmountTotal = () => {
    return calcSubtotal() - calcAmountDiscount()
  }

  return (
    <CheckoutStyledWraper className="font-inter">
      <LanguageFloat />
      <Button
        color="light"
        size="extraSmall"
        onClick={() => {
          navigate(-1)
        }}
        className="my-6"
      >
        <FaChevronLeft /> {CheckoutImpl.backBtn}
      </Button>
      <div className="relative">
        <h3 className="font-extrabold my-8 mb-8">
          {CheckoutImpl.step1}
          <Popover
            content={
              <div className="w-64">
                <p className=" text-justify">
                  If you not see your workspace, you probably have already
                  subscribed it. Contact us if you need to modify any of your
                  subscriptions.
                </p>
                <Button
                  color="light"
                  size="extraSmall"
                  onClick={() => {
                    navigate('/contact')
                  }}
                  className="my-6"
                >
                  Contact Us
                </Button>
              </div>
            }
          >
            <BsInfoCircle size={25} className="inline ml-1 cursor-pointer" />
          </Popover>
        </h3>

        {/* {user && (
          <div className="inline-block p-4 absolute bg-purple-100 text-purple-600 right-0 -top-16 text-base rounded remember-box">
            <span className="font-bold">{CheckoutImpl.rememberTitle}:</span>
            <ul className="list-disc ml-4 leading-tight">
              {CheckoutImpl.rememberItems.map((item: string) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        )} */}
      </div>
      {user ? (
        <div>
          <div className="relative">
            <div className="flex items-center justify-between border-solid border-2 border-grey-200 pt-4 pb-4 pr-5 pl-5 rounded-md">
              <div className="flex items-center">
                {enableWorkspaceForm ? (
                  <WorkspaceForm
                    formHandler={handleNewWorkspaceForm}
                    workspaceValues={newWorkspaceValues}
                  />
                ) : (
                  <WorkspaceItem workspaceToSubscript={workspaceToSubscript} />
                )}
                <div className="w-40 flex justify-end">
                  <div
                    className="bg-pink-100 hover:bg-pink-200 active:bg-pink-300 rounded p-2 cursor-pointer w-12 grid place-items-center"
                    onClick={toggleShowWorkspaces}
                  >
                    {showWorkspaces ? (
                      <FaChevronUp size="25px" className="text-pink-500" />
                    ) : (
                      <FaChevronDown size="25px" className="text-pink-500" />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <p className="text-lg font-bold text-center pb-3">Miembros</p>
                <div className="flex justify-evenly w-40 items-center">
                  <div
                    className="bg-gray-100 w-9 h-9 grid place-items-center rounded-full cursor-pointer active:bg-gray-300 hover:bg-gray-200 "
                    onClick={() => {
                      members > 10 && setMembers(members - 1)
                    }}
                  >
                    <GoDash size="25px" className="text-gray-500" />
                  </div>
                  <input
                    className="text-xl text-gray-500 border-solid w-16 text-center focus-visible:outline-none focus-visible:underline rounded-md"
                    value={members || 10}
                    readOnly
                    type="number"
                    max={99}
                    min={10}
                    disabled={loading}
                  />
                  <div
                    className="bg-gray-100 w-9 h-9 grid place-items-center rounded-full cursor-pointer active:bg-gray-300 hover:bg-gray-200"
                    onClick={() => {
                      setMembers(members + 1)
                    }}
                  >
                    <GoPlus size="25px" className="text-gray-500" />
                  </div>
                </div>
              </div>
            </div>
            {showWorkspaces && (
              <div className="border-solid border-2 border-grey-200 absolute z-50 bg-gray-50 overflow-y-scroll max-h-64 rounded-lg">
                {workspaces.map(workspace => (
                  <div key={workspace.id}>
                    <div
                      className="flex items-center justify-between pt-4 pb-4 pr-5 pl-5 rounded-md  hover:bg-gray-100 hover:cursor-pointer"
                      onClick={() => {
                        handleWorkspaceToSubscript(workspace)
                        toggleShowWorkspaces()
                        enableWorkspaceForm && handleEnableWorkspaceForm(false)
                        setMembers(workspace.maxMembers)
                      }}
                    >
                      <div className="flex items-center">
                        <WorkspaceItem workspaceToSubscript={workspace} />
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className="flex items-center justify-between pt-4 pb-4 pr-5 pl-5 rounded-md  hover:bg-gray-100 hover:cursor-pointer"
                  onClick={() => {
                    handleEnableWorkspaceForm(true)
                    handleWorkspaceToSubscript(null)
                    toggleShowWorkspaces()
                  }}
                >
                  <div className="bg-gray-100 w-16 h-16 grid place-items-center rounded-full cursor-pointer active:bg-gray-300 hover:bg-gray-200">
                    <GoPlus size="35px" className="text-gray-500" />
                  </div>
                  <div className="">
                    <p className="text-lg font-bold">Create new Team</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="grid grid-cols-12">
            <div className="col-start-10 col-span-3 text-base">
              <div className="flex mt-8 mb-4">
                <div className="pr-4 basis-2/3 text-right">Plan Business</div>
                <div className="basis-1/3">USD {PRICE_PLAN_PRO}</div>
              </div>
              <div className="flex mb-4">
                <div className="pr-4 basis-2/3 text-right">
                  {CheckoutImpl.additional}
                </div>
                <div className="basis-1/3">USD {calcAdditionals()}</div>
              </div>
              <div className="mb-4 border border-t-gray-100"></div>
              <div className="flex">
                <div className="pr-4 basis-2/3 text-right font-bold text-pink-500">
                  Subtotal
                </div>
                <div className="basis-1/3 text-pink-500">
                  USD {calcSubtotal()}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p className="text-lg text-gray-500">
            You need to have an account to subscribe your team
          </p>
          <Button
            color="pink"
            size="large"
            className="ml-2"
            onClick={() => navigate('/register')}
          >
            Register
          </Button>
          <Button
            color="secondary"
            size="large"
            outlined
            className="ml-2"
            onClick={() => navigate('/login')}
          >
            Login
          </Button>
        </div>
      )}
      <h3 className="font-extrabold my-8">{CheckoutImpl.step2}</h3>
      <div className="relative">
        {blockSection() && (
          <div className="absolute z-10 h-full w-full bg-yellow-0 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-0"></div>
        )}
        <CheckoutAuthStep
          CheckoutImpl={CheckoutImpl}
          dispatch={dispatch}
          user={user}
        />
      </div>
      <h3 className="font-extrabold my-8">{CheckoutImpl.step3}</h3>
      <div className="relative">
        {blockSection() && (
          <div className="absolute z-10 h-full w-full bg-yellow-0 rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-0"></div>
        )}
        <CheckoutPayment
          CheckoutImpl={CheckoutImpl}
          aditionalMembers={calcAditionalMembers()}
          amountSubtotal={calcSubtotal()}
          PRICE_PLAN_PRO={PRICE_PLAN_PRO}
          amountDiscount={calcAmountDiscount()}
          amountTotal={calcAmountTotal()}
          blockPayment={blockSection()}
          subscribeCallback={subscribeCallback}
        />
      </div>
    </CheckoutStyledWraper>
  )
}

export default Checkout
