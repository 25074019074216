import React, { useEffect, useState } from 'react'
import { Avatar } from '../../../common'
import { useAuthState } from '../../../contexts/auth'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { useStatus } from '../../../hooks/useStatus'
import { Status } from '../../../types/enums'
import { TeamContainer } from './MyTeam.styles'
import PopOverContent from './PopOverContent'
import { Badge, Popover, Tooltip } from 'antd'

import {
  query,
  where,
  QueryDocumentSnapshot,
  DocumentData,
  onSnapshot,
  updateDoc,
  doc,
} from 'firebase/firestore'
import { channelsColRef } from '../../../firebase/firestore.collections'
import { db } from '../../../firebase/init-firebase'

type MemberProps = {
  member: any
  image: string
  workspaceName: string
  impl: any
}

const Member = ({
  member,
  image,
  workspaceName,
  impl,
}: MemberProps): JSX.Element => {
  const { getStatusKey }: any = useStatus(member.id)
  const { user } = useAuthState()
  const { workspace }: any = useWorkspaceContext()
  const [channel, setChannel] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null)
  const channelId = (user.email + member.email + workspace.id)
    .split('')
    .sort()
    .join('')
  // Queries
  const channelQuery = query(
    channelsColRef,
    where('channelId', '==', channelId)
  )

  const readMessages = async () => {
    const copyOfMessages = [...channel?.data().messages]
    const messagesNotReaded = copyOfMessages.filter(
      message => !message.readBy.includes(user.email)
    )
    if (messagesNotReaded.length > 0) {
      const updatedMessages = copyOfMessages.map(message => {
        if (!message.readBy.includes(user.email)) {
          message.readBy.push(user.email)
        }
        return {
          ...message,
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const docRef = doc(db, 'channels', channel ? channel.id : channelId!)
      await updateDoc(docRef, {
        messages: updatedMessages,
      })
    }
  }
  useEffect(() => {
    const unsubscribe = onSnapshot(channelQuery, channels => {
      setChannel(channels.docs[0])
    })

    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getNotReadedMessagesCount = () => {
    if (channel) {
      const notReadedMessages = channel
        ?.data()
        .messages.filter(message => !message.readBy.includes(user.email))
      return notReadedMessages.length
    }
    return 0
  }
  return (
    <>
      <TeamContainer>
        <Popover
          trigger="click"
          placement="right"
          content={
            <PopOverContent
              member={member}
              workspaceName={workspaceName}
              image={image}
              impl={impl}
              channel={channel}
              channelsColRef={channelsColRef}
              channelId={channelId}
              setChannel={setChannel}
              readMessages={readMessages}
            />
          }
        >
          <Tooltip placement="right" title={member.name.split(' ')[0]}>
            <Badge count={getNotReadedMessagesCount()} size="default">
              <Avatar
                onClick={readMessages}
                className="mb-1"
                image={member.profilePhoto}
                size="45px"
                backgroundColor="#506690"
                haveStatus
                currentStatus={getStatusKey(workspace.id) === Status.online}
              />
            </Badge>
          </Tooltip>
        </Popover>
      </TeamContainer>
    </>
  )
}

export default Member
