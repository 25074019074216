import styled from 'styled-components'

export const MyTeamStyled = styled.div`
  position: relative;
  margin-top: 70px;
  .alerts-wraper {
    position: fixed;
    width: 47.5vw;
    top: 15px;
  }
  .card {
    padding: 30px;
    margin-top: 16px;
    margin-bottom: 50px;
    min-height: 600px;
    max-height: 700px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(153, 153, 153, 0.25);
    border-radius: 5px;
    .title-section {
      display: flex;
      &__text {
        display: flex;
        div {
          margin-left: 5px;
        }
        align-items: center;
        /* margin-bottom: 20px; */
      }
      justify-content: space-between;
      align-items: center;
    }
    .tooltipWraper {
      width: 200px;
      left: -90px;
    }
    .team-form {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      .input-field {
        grid-column: 1/7;
        @media (min-width: 1200px) {
          grid-column: 1/8;
        }
        @media (min-width: 1800px) {
          grid-column: 1/8;
        }
        @media (min-width: 1900px) {
          grid-column: 1/9;
        }
      }
      .invite-button {
        grid-column: 9/11;
        display: flex;
        margin-top: 8px;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: column;
      }
    }
    .member-list {
      min-height: 400px;
      max-height: 400px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 8px; /* Tamaño del scroll en horizontal */
      }
      &::-webkit-scrollbar-thumb {
        background: #364872;
        border-radius: 50px;
      }

      /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #506690;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
      }

      /* Cambiamos el fondo cuando esté en active */
      &::-webkit-scrollbar-thumb:active {
        background-color: #335eea;
      }
    }
    .member {
      padding: 7px 0px;
      hr {
        height: 0px;
        border: 1px solid #d2dceb;
      }
      &__item {
        display: grid;
        grid-template-columns: repeat(100, 1fr);
        &--avatar {
          grid-column: 1/10;
        }
        &--info {
          grid-column: 10/90;
        }
        &--action {
          grid-column: 95/101;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
  .member-modal__content {
    padding: 60px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    p {
      margin-bottom: 40px;
    }
  }
`
