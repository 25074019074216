import styled from 'styled-components'

export const LoaderStyled = styled.div`
  border: ${props => props.border || '16px'} solid #f3f3f3;
  padding: 5px;
  border-radius: 50%;
  border-top: ${props => props.border || '16px'} solid
    ${props => props.color || '#FA27A9'};
  width: ${props => props.size || '150px'};
  height: ${props => props.size || '150px'};
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
