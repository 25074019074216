const notifyMessage = async (tokens, title, message, icon) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  const raw = JSON.stringify({
    tokens: tokens,
    title: title,
    message: message,
    icon: icon,
  })

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  }
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + '/notifications/messages',
      requestOptions as any
    )
    const data = await response.text()
    return data
  } catch (error) {
    console.log('error', error)
  }
}

export const _notificationService = {
  notifyMessage,
}
