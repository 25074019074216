import React from 'react'
import { FaCubes, FaLayerGroup } from 'react-icons/fa'
import { HiUserGroup } from 'react-icons/hi'
import { BsFillCameraVideoFill } from 'react-icons/bs'
import { BsFillChatDotsFill } from 'react-icons/bs'
import { AiFillAppstore } from 'react-icons/ai'
const LandingMainImpl = {
  LandingMainImpl: {
    hero: {
      mainTitle: '¡La revolución del trabajo remoto ha comenzado!',
      subTitle:
        'Hemos creado una plataforma fácil y efectiva para ti y tus equipos de trabajo',
      textButton: 'Empieza GRATIS ahora!',
    },
    features: {
      maintitle: 'Añade todo lo que conoces y amas en una aplicación',
      subtitle:
        'Hemos tomado lo mejor de las plataformas que conoces y lo hemos juntado en un solo lugar',
      featurelist: [
        {
          title: 'Administración de Equipos',
          description:
            'Organiza tus equipos como desees y mantente conectado con ellos.',
          icon: <FaLayerGroup size="40px" color="FA27A9" />,
        },
        {
          title: 'Espacios grupales',
          description: 'Crear espacios libres y tus miembros podrán tenerlos',
          icon: <FaCubes size="40px" color="FA27A9" />,
        },
        {
          title: 'Llamadas grupales',
          description: 'Como otro software de video llamadas',
          icon: <HiUserGroup size="40px" color="FA27A9" />,
        },
        {
          title: 'Llamadas de uno a uno',
          description:
            'Llama a un miembro de tu equipo sin enviar una invitación o incomodar a tu equipo.',
          icon: <BsFillCameraVideoFill size="40px" color="FA27A9" />,
        },
        {
          title: 'Chat augestionable',
          description:
            'Tus llamadas estarán contigo donde vayas sin perder nada.',
          icon: <BsFillChatDotsFill size="40px" color="FA27A9" />,
        },
        {
          title: 'Integra las plataformas que te gustan(Proximamente)',
          description: 'Whatsapp, Figma, Trello, Miro y ClickUp',
          icon: <AiFillAppstore size="40px" color="FA27A9" />,
        },
      ],
    },
    switchsides: {
      firstmaintitle: 'Diseñado para equipos ágiles de alto rendimiento.',
      firstdescriptionone:
        'Organiza tus espacios como prefieras. Tu puedes usar Scrum o cualquier otra metodología para definir los espacios de los grupos que prefieras para asignar llamadas con tus clientes con tus clientes y tu equipo.',
      firstdescriptiontwo:
        'Permite a tu equipo para mantener comunicación, optimizando su rendimiento',
      secondtitle: 'Gestiona varios equipos al mismo tiempo.',
      seconddescriptionone:
        'Puedes crear varios equipos y administralos al mismo tiempo. El proposito es  mantener comunicación constante entre tus equipos y tú.',
      seconddescriptiontwo:
        'Adicionalmente, puedes integrar WhatsApp como medio externo de comunicación, fuera de las videollamadas de nuestro chat.',
    },
    subscribecac: {
      maintitle: '¡Se parte de Zyrebox!',
      subtitle:
        'Se parte de la experiencia de Zyrebox. Nuestros números hablan por si solos',
      numberzyreboxcreated: '+30',
      zyreboxcreated: 'Zyrebox creados',
      numberzyreboxteams: '+20',
      zyreboxteams: 'Equipos en Zyrebox',
      numbercallsdone: '+700',
      callsrealized: 'Llamadas realizadas',
      titlepartsubscribed: '¡Lleva tu oficina al mundo digital!',
      subtitlepartsubscribed:
        'Nuestro compromiso es llevar a tus equipos al siguiente nivel',
      buttonsubscribedtopro: 'Subscribete a Pro',
    },
  },
}

export default LandingMainImpl
