import styled from 'styled-components'

export const BillingStyled = styled.div`
  position: relative;
  height: 100%;
  .config-container {
    height: 90%;
    display: block;
    margin: 0px 32px;
    padding: 32px;
    background-color: #fbfbfb;
    border-radius: 20px;
    box-shadow: 0px 1px 15px 0px rgba(179, 179, 179, 0.7);
    -webkit-box-shadow: 0px 1px 15px 0px rgba(179, 179, 179, 0.7);
    -moz-box-shadow: 0px 1px 15px 0px rgba(179, 179, 179, 0.7);
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(100, 1fr);
    &--child40 {
      grid-column: 1/40;
      display: flex;
      align-items: center;
    }
    &--100 {
      max-width: 100px;
    }
    &--child60 {
      grid-column: 60/100;
      display: grid;
      align-items: center;
    }
    &__button {
      justify-content: space-evenly;
    }
  }

  .img--rocket {
    height: 48px;
    margin-left: 16px;
  }

  .config-container--bottom {
    width: 100%;
    bottom: 0;
    margin-bottom: 32px;
    height: calc(100% - 100px);
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    font-family: 'HKGrotesk', sans-serif;
    font-style: normal;
    font-weight: 200;
    text-align: left;
    line-height: 22px;
    color: #95a7c1;
    font-size: 17px;
  }

  .inlineText {
    display: inline;
  }

  /* MODAL */
  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  .group-modal__content {
    padding: 60px 40px;
    max-width: 600px;
    .inline {
      display: inline-block;
      margin-right: 16px;
    }

    .floatRight {
      float: right;
      .inline {
        display: inline-block;
        margin-left: 16px;
        margin-right: 16px;
      }
    }
    .marginLeft__16 {
      margin-left: 16px;
    }

    .marginRight__8 {
      margin-right: 8px;
    }
  }
`
