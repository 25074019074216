import React, { useEffect, useState } from 'react'
import { FaSyncAlt, FaTeamspeak } from 'react-icons/fa'
import { Text, Button, Input } from '../../../common'
import { SecurityStyled } from './Security.styles'
import { navigate } from '@reach/router'
import ReactGA from 'react-ga'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { validatePassword } from '../../../helpers/validators'

//"HKGrotesk", sans-serif
interface SecurityProps {
  changePassword: (data: any) => void
  impl: any
}

const Security = ({ changePassword, impl }: SecurityProps): JSX.Element => {
  // _______Anaylicits
  useEffect(() => {
    ReactGA.pageview('/config')
  }, [])
  // _____
  const { darkMode }: any = useWorkspaceContext()
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordError, setNewPasswordError] = useState('')
  const [newConfirmPassword, setNewConfirmPassword] = useState('')
  const [newConfirmPasswordError, setNewConfirmPasswordError] = useState('')

  const handlePassword = (value: string) => {
    if (value === '') {
      setPasswordError('Fill the field please')
    } else {
      setPasswordError('')
    }
    setPassword(value)
  }

  const handleNewPassword = (value: string) => {
    if (validatePassword(value)) setNewPasswordError('')
    else
      setNewPasswordError(
        'Password must have at least 8 characters, an uppercase, a lowercase, a number and a special character @$!%*?&#+_:,-.'
      )
    if (value === '') setNewPasswordError('')
    setNewPassword(value)

    if (value === newConfirmPassword) {
      setNewConfirmPasswordError('')
    } else {
      setNewConfirmPasswordError('Passwords must match')
    }
  }

  const handleNewConfirmPassword = (value: string) => {
    if (value === newPassword) {
      setNewConfirmPasswordError('')
    } else {
      setNewConfirmPasswordError('Passwords must match')
    }
    if (value === '') {
      setNewConfirmPasswordError('')
    }
    setNewConfirmPassword(value)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    changePassword({
      variables: {
        password,
        newPassword,
        newConfirmPassword,
      },
    })
  }

  const handleDisabled = () =>
    !(
      password !== '' &&
      passwordError === '' &&
      newPassword !== '' &&
      newPasswordError === '' &&
      newConfirmPassword !== '' &&
      newConfirmPasswordError === ''
    )
  return (
    <SecurityStyled>
      <div className="config-container">
        <form onSubmit={handleSubmit} className="account-form">
          <div>
            <Text type="h1">{impl.title}</Text>
          </div>
          <div className="account-form__input-container">
            <div className="account-form__input-row">
              <Input
                value={password}
                onChange={handlePassword}
                validationError={passwordError}
                label={impl.currentPassInput.label}
                placeHolder={impl.currentPassInput.placeholder}
                type="password"
                className="login-form__input"
                backgroundColor="#ffffff"
              />
              <Input
                value={newPassword}
                onChange={handleNewPassword}
                validationError={newPasswordError}
                label={impl.newPassInput.label}
                placeHolder={impl.newPassInput.placeholder}
                type="password"
                className="login-form__input"
                backgroundColor="#ffffff"
              />
              <Input
                value={newConfirmPassword}
                onChange={handleNewConfirmPassword}
                validationError={newConfirmPasswordError}
                label={impl.confirmPassInput.label}
                placeHolder={impl.confirmPassInput.placeholder}
                type="password"
                className="login-form__input"
                backgroundColor="#ffffff"
              />
            </div>
          </div>

          <div className="account-form__button">
            <Button
              size="base"
              outlined
              color="primary"
              disabled={handleDisabled()}
            >
              <FaSyncAlt /> {impl.changeBtn}
            </Button>
          </div>
        </form>
      </div>
      <div className="account-form__button">
        <Button
          size="base"
          outlined={!darkMode}
          color={darkMode ? 'light' : 'primary'}
          onClick={() => {
            navigate('/support')
          }}
        >
          <FaTeamspeak /> Support
        </Button>
      </div>
    </SecurityStyled>
  )
}

export default Security
