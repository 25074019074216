const ConfigZyreboxImpl = {
  ConfigZyreboxImpl: {
    sidebar: {
      backlabel: 'Volver',
      signoutBtn: 'Cerrar Sesión',
      editLabel: 'Configurar Perfil',
      billingLabel: 'Mi Plan',
      securityLabel: 'Seguridad',
    },
    billing: {
      tite: 'Mi Plan',
      subtitle: 'Mejorar mi plan',
      currentLabel: 'Plan actual:',
      proLabel: 'Pro',
      basicLabel: 'Basic',
      planProMessage: 'Disfurta de tu plan Pro  🚀',
      subscribeBtn: 'Suscribirme a Pro',
    },
    accountConfig: {
      nameInput: {
        label: 'Nombre',
        placeholder: 'Ingresa tu nombre',
      },
      lastNameInput: {
        label: 'Apellido',
        placeholder: 'Ingresa tu apellido',
      },
      emailInput: {
        label: 'Email',
        placeholder: 'Ingresa tu email',
      },
      phoneInput: {
        label: 'Número Telefónico (Whatsapp)',
        placeholder: 'Ingresa tu número telefónico',
      },
      saveBtn: 'Guardar',
      profileConfigTitle: 'Configurar Perfil',
      moreConfigsTitle: 'Configuraciones adicionales',
      videoCallSection: {
        title: 'Video Llamadas',
        disablePreJoinLabel: 'Deshabilitar pantalla pre ingreso',
        prejoinEnableLabel: 'habilitada',
        prejoinDisableLabel: 'deshabilitada',
      },
    },
    security: {
      title: 'Seguridad',
      currentPassInput: {
        label: 'Contraseña Actual',
        placeholder: 'Ingresa tu contraseña actual',
      },
      newPassInput: {
        label: 'Nueva contraseña',
        placeholder: 'Ingresa tu nueva contraseña',
      },
      confirmPassInput: {
        label: 'Confirma la contraseña',
        placeholder: 'Confirma tu nueva contraseña',
      },
      changeBtn: 'Cambiar',
      support: {
        modalBtn: 'Soporte',
        modalText: (
          <>
            Para soporte o regresar al plan básico, por favor contáctanos a{' '}
            <strong>team@zyrebox.com</strong>
          </>
        ),
      },
    },
  },
}

export default ConfigZyreboxImpl
