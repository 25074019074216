import styled from 'styled-components'

export const SettingsZyreboxStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(100, 1fr);
  /* background-color: ${props => (props.darkMode ? '#161C2D' : '#F9FBFD')}; */
  background-color: ${props => (props.darkMode ? '#0D1117' : '#F9FBFD')};
  .settings-sidebar {
    grid-column: 1/20;
  }
  .settings-container {
    grid-column: 20/101;
    height: 100vh;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(100, 1fr);
    &--wraper {
      grid-column: 20/80;
    }
  }
`
