import styled from 'styled-components'

export const HomeHeaderStyled = styled.div`
  padding: 20px 100px 0px 60px;
  @media (min-height: 600px) {
    padding: 30px 100px 0px 60px;
  }
  @media (min-height: 800px) {
    padding: 40px 100px 0px 60px;
  }
  @media (min-height: 900px) {
    padding: 50px 100px 0px 60px;
  }
  @media (min-height: 950px) {
    padding: 60px 100px 0px 60px;
  }
  display: grid;
  grid-template-columns: repeat(60, 1fr);
  .header__logo {
    grid-column: 1/15;
    img {
      cursor: pointer;
    }
  }
  .header__content {
    grid-column: 21/42;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      min-width: 100%;
      input {
        text-align: center;
      }
    }
  }
  .header__button {
    grid-column: 45/61;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    input {
      margin-right: 15px;
    }
    &--content {
      svg {
        margin-left: 8px;
      }
    }
  }
`
