import styled from 'styled-components'

export const FindInZyreboxStyled = styled.div`
  .find-section {
    margin: 20px 0px;
  }
  .find-item {
    display: inline-flex;
    flex-direction: column;
    margin: 5px 10px;
    align-items: center;
    justify-content: center;
    &__more-items {
      border-radius: 50%;
      box-shadow: 0px 0px 14px 0px rgba(186, 186, 186, 0.75);
      -webkit-box-shadow: 0px 0px 14px 0px rgba(186, 186, 186, 0.75);
      -moz-box-shadow: 0px 0px 14px 0px rgba(186, 186, 186, 0.75);
      height: 50px;
      width: 50px;
    }
    &__text {
      max-width: 70px;
    }
    &__avatar--user {
      &:hover {
        cursor: pointer;
        &::before {
          position: absolute;
          display: grid;
          place-items: center;
          font: var(--fa-font-solid);
          content: '\f095';
          /* content: "\f007"; */
          height: 50px;
          width: 50px;
          background-color: rgba(35, 35, 35, 0.6);
          border-radius: 50%;
          color: white;
          font-size: 24px;
        }
      }
    }
    &__avatar--room {
      &:hover {
        cursor: pointer;
        &::before {
          position: absolute;
          display: grid;
          place-items: center;
          font: var(--fa-font-solid);
          content: '\f2f6';
          /* content: "\f007"; */
          height: 50px;
          width: 50px;
          background-color: rgba(35, 35, 35, 0.6);
          border-radius: 50%;
          color: white;
          font-size: 24px;
        }
      }
    }
    p {
      margin-top: 7px;
      text-align: center;
    }
  }
  .find-wrapper {
    overflow-y: scroll;
    max-height: calc(100vh - 150px);
  }
`

export const ContentAppStyled = styled.div`
  margin: 14px 0px;
  display: grid;
  gap: 18px;
  grid-template-columns: repeat(4, 1fr);
  /* UNCOMMENT WHEN WHEN APP LIST IS UNCOMMENT */
  /* grid-template-columns: repeat(3, 1fr); */
  .app-item {
    display: grid;
    place-items: center;
  }
`
