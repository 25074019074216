import styled from 'styled-components'
// import { useWorkspaceContext } from '../../../contexts/workspace'
// const { darkMode } = useWorkspaceContext()
// const content = darkMode ? '🌘' : '☀️'
export const AvatarContainer = styled.div`
  margin: 16px auto;
  display: grid;
  place-items: center;
  &:hover {
    cursor: pointer;
    .pathcard,
    .pathcard:hover {
      position: absolute;
      display: block;
      left: 50px;
      z-index: 1;
      cursor: pointer;
    }
    .hide,
    .hide:hover {
      position: absolute;
      display: block;
      left: 80px;
      cursor: pointer;
    }
  }
  .tooltipWraper {
    left: 60px;
  }
  .card-wraper {
    top: auto;
    left: 67px !important;
    display: inline-block !important;
  }
`
