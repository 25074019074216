import React, { useEffect, useState } from 'react'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { useAuthState } from '../../../contexts/auth'
import { JitsiMeetContainer } from './JitsiMeetComponent.styles'
import { MdRemoveCircle, MdAddCircle } from 'react-icons/md'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { FaShare } from 'react-icons/fa'
import { Alert, Button, JitsiMeetEmbed, Loader, Tooltip } from '../../../common'
import { useDrag } from 'react-use-gesture'
import { CgMenuRightAlt } from 'react-icons/cg'
import { BsInfoCircle } from 'react-icons/bs'

interface JitsiMeetComponentProps {
  impl: any
}

const JitsiMeetComponent = ({ impl }: JitsiMeetComponentProps): JSX.Element => {
  const { room, handleRoom, disablePreJoin, workspace }: any =
    useWorkspaceContext()
  const [shareUrl, setShareUrl] = useState(
    `${process.env.REACT_APP_PUBLIC_URL}/room-as-invited/${
      room.roomName + room.roomPassword + workspace.id
    }`
  )
  // const [shareUrl, setShareUrl] = useState(`http://localhost:3500/room-as-invited/${room.roomName + room.roomPassword + workspace.id}`)
  const [minimize, setMinimize] = useState(false)
  const [manualClose, setManualClose] = useState(false)
  const { user } = useAuthState()
  const [meetPos, setMeetPos] = useState({ x: 0, y: 0 })
  const bindMeet = useDrag(params => {
    setMeetPos({
      x: params.offset[0],
      y: params.offset[1],
    })
  })
  const jitsiWindowStyle = {
    width: '93vw',
    height: '82vh',
  }
  const roomStyle = {
    transition: 'all .2s ease-in-out',
    transformOrigin: 'top right',
    transform: minimize ? 'scale(0.2)' : 'scale(1)',
  }
  const handleMinimize = () => {
    setMinimize(!minimize)
  }

  const handleManualClose = () => {
    setManualClose(true)
    handleRoom({
      roomName: '',
      roomPassword: '',
      active: false,
    })
  }

  useEffect(() => {
    setShareUrl(
      `${process.env.REACT_APP_PUBLIC_URL}/room-as-invited/${
        room.roomName + room.roomPassword + workspace.id
      }`
    )
    // setShareUrl(`http://localhost:3500/room-as-invited/${room.roomName + room.roomPassword + workspace.id}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleShareRoom = () => {
    const tempUrl = `${process.env.REACT_APP_PUBLIC_URL}/room-as-invited/${
      room.roomName + room.roomPassword + workspace.id
    }`
    const jistiRoomUrl = `https://meet.jit.si/${room.roomName}`
    // const tempUrl = `http://localhost:3500/room-as-invited/${room.roomName + room.roomPassword + workspace.id}`
    const copyText = document.getElementById('copyText')
    const textArea = document.createElement('textarea')

    const desktopUrl = copyText?.textContent
    const mobileUrl = jistiRoomUrl
    textArea.value = impl.copyurlText(desktopUrl, mobileUrl)

    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('Copy')
    textArea.remove()
    setShareUrl('Invite Copied')
    setTimeout(() => {
      setShareUrl(tempUrl)
    }, 2000)
  }
  return (
    <div
      style={{
        position: minimize ? 'relative' : 'absolute',
        top: minimize ? meetPos.y : '2%',
        left: minimize ? meetPos.x : '0',
      }}
      {...bindMeet()}
    >
      <JitsiMeetContainer style={roomStyle}>
        {room.active && (
          <div>
            <Alert color="info" className="w-full">
              {minimize ? <CgMenuRightAlt size="75px" /> : impl.topAlert}
              <div className="top-alert-minimize">
                <div onClick={handleMinimize} title="Minimize">
                  <Tooltip
                    position="bottom"
                    content="Minimize Meeting"
                    customClass="close-meeting-tooltip"
                  >
                    {minimize ? (
                      <MdAddCircle size="75px" />
                    ) : (
                      <MdRemoveCircle size="30px" />
                    )}
                  </Tooltip>
                </div>
                <div onClick={handleManualClose}>
                  <Tooltip
                    position="bottom"
                    content="Close Meeting"
                    customClass="close-meeting-tooltip"
                  >
                    {!minimize ? (
                      <IoCloseCircleOutline size="30px" title="Close Meeting" />
                    ) : (
                      <></>
                    )}
                  </Tooltip>
                </div>
              </div>
            </Alert>
            <JitsiMeetEmbed
              jistiStyles={jitsiWindowStyle}
              onLoad={() => {
                console.log('loaded')
              }}
              loadingComponent={<Loader />}
              roomName={room.roomName}
              roomPassword={room.password}
              email={user.email}
              displayName={user.name}
              avatarUrl={user.profilePhoto}
              manualClose={manualClose}
              disablePreJoin={disablePreJoin}
              onMeetingEnd={() => {
                handleRoom({
                  roomName: '',
                  roomPassword: '',
                  active: false,
                })
              }}
            />
            <Alert color="secondary">
              <div className="flex">
                <BsInfoCircle size="25px" className="mr-2" />
                {impl.bottomAlert}
              </div>
              <div>
                <div className="bottom-alert-minimize">
                  <Tooltip
                    position="top"
                    content={
                      <div>
                        {shareUrl !== 'Invite Copied'
                          ? 'Share Meet'
                          : '¡Invite Copied!'}
                        <span id="copyText" className="tooltip-text">
                          {':\n'}
                          {shareUrl}
                        </span>
                      </div>
                    }
                    customId="copyText"
                    customClass={'close-shared-tooltip'}
                  >
                    <Button
                      color="primary"
                      size="extraSmall"
                      onClick={handleShareRoom}
                      soft
                    >
                      <FaShare color="#335EEA" size="21px" />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Alert>
          </div>
        )}
      </JitsiMeetContainer>
    </div>
  )
}

export default JitsiMeetComponent
