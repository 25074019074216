import styled from 'styled-components'

export const ImageLogoContainer = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  align-items: flex-start;
  border-radius: 50px;
  > label {
    width: 100px;
    height: 100px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(22, 28, 45, 0.3);
    border-radius: 50px;
    cursor: pointer;
  }
  > input {
    display: none;
  }
  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-size: cover;
    `}
`
