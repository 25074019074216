const PricingImpl = {
  LandingPricingImpl: {
    maintitle: '¡Suscribete y obten todos nuestros features!',
    subtitle:
      'Obten todos los beneficios que necesitas para tu equipo suscribiendote solo tu, no necesitas suscribir a todo tu equipo.',
    monthly: 'Mensual',
    anualy: 'Anual',
    soon: '(*)Herramientas que estaran listas pronto',
    featurelist: {
      subscribeInfo: [
        {
          title: 'Startup',
          custom: false,
          monthPrice: '0',
          anualPrice: '0',
          yearlabel: 'año',
          monthlabel: 'mes',
          include: 'incluye',
          features: [
            {
              name: 'Llamadas ilimitadas',
            },
            {
              name: 'Minutos ilimitados en llamadas',
            },
            {
              name: 'Mensajes ilimitados',
            },
            {
              name: 'Miembros/equipo: 10',
            },
            {
              name: 'Espacios grupales: 2',
            },
            {
              name: 'Espacio gratuito',
            },
            {
              name: 'Integracion con WhatsApp',
            },
            {
              name: 'Proveedor de video conferencia: 1',
            },
            {
              name: 'Integraciones embebidas: 3 *',
            },
            {
              name: 'Integraciones con API: 3 *',
            },
            {
              name: 'Enlaces rapidos: Ilimitados *',
            },
          ],
          cac: {
            label: 'Empieza GRATIS',
            color: 'primary',
            soft: true,
            route: '/register',
          },
        },
        {
          title: 'Business',
          custom: true,
          monthPrice: '25',
          anualPrice: '250',
          yearlabel: 'equipo/año',
          monthlabel: 'equipo/mes',
          include: 'incluye',
          features: [
            {
              name: 'Todos los beneficios del plan startup',
            },
            {
              name: 'Espacio gratuito',
            },
            {
              name: 'Espacio con acceso total',
            },
            {
              name: 'Miembros/equipo: 10',
            },
            {
              name: 'Espacios grupales: Ilimitado',
            },
            {
              name: 'Proveedor de video conferencia: Todos',
            },
            {
              name: 'Integraciones embebidas: +50 *',
            },
            {
              name: 'Integraciones con API: +50 *',
            },
            {
              name: 'Enlaces rapidos: Ilimitados *',
            },
          ],
          cac: {
            label: 'Suscribirme a Business',
            color: 'primary',
            soft: false,
            route: '/checkout',
          },
        },
      ],
    },
    optionalpricing: {
      howworks: '¿Necesitas más?',
      customplan: 'Plan Custom',
      teams: 'Equipos',
      teamsmontly: 'Equipo/mes',
      customfeatures:
        'Puedes convertir tu equipo a Business y crear todos los equipos que necesites. Por cada equipo que compres, tendras espacio para 10 miembros por equipo',
      aditionalmembers: 'Miembros adicionales',
      membermontly: 'miembro/mes',
      memberfeatures:
        ' Si necesitas mas de 10 miembros en alguno de tus equipos,puedes comprar un espacio adicional para cada uno',
      add: 'Incluye',
      allfeatures: 'Todos los features de Suscripción Business',
      buttoniwant: ' ¡Lo quiero!',
    },
    faqImpl: {
      maintitle: 'Preguntas frecuentes',
      subtitle: 'Permítenos responder las preguntas mas frecuentes.',
      frecuentquestion: [
        {
          question: '¿Debo tener una cuenta para utilizar Zyrebox?',
          answer:
            'No es necesario tener una cuenta para utilizar Zyrebox, en caso ingreses como invitado a través de un enlace proveído por un usuario que puede tener una cuenta en la plataforma. Si en caso deseas crear una reunión con Zyrebox, debes tener una cuenta en la plataforma.',
        },
        {
          question: '¿Cómo me registro en Zyrebox?',
          answer:
            'Puedes registrarte para obtener una cuenta gratuita de Zyrebox en zyrebox.com/register. No requiere ningún pago. Solo deberás confirmar tu cuenta a través de una confirmación enviada a tu correo electrónico asociado.',
        },
        {
          question: '¿Tiene algún costo usar Zyrebox?',
          answer:
            'Zyrebox tiene una versión gratuita que se puede usar sin costo. Sin embargo, puedes acceder al plan Business con funcionalidades que se adaptan a las necesidades de los equipos.',
        },
        {
          question: '¿Puedo usar Zyrebox en mi smartphone?',
          answer:
            'Si puedes hacerlo, pero de forma limitada. Pero no te preocupes, muy pronto estará disponible para tus equipos nuestra app móvil.',
        },
        {
          question: '¿Puedo agregar más de 10 personas a mis equipos?',
          answer:
            'Puedes agregar tantas personas como necesites, solo debes aumentar USD 2 al monto de tu suscripción mensual, por cada uno de los miembros adicionales que desees en tu equipo.',
        },
        {
          question: '¿Las integraciones puedo usarlas en mi cuenta gratuita?',
          answer:
            'No. Solo puedes acceder a las integraciones en caso tengas una cuenta Business. Y todas las integraciones podrán ser utilizadas por los miembros del equipo sin coste alguno.',
        },
      ],
    },
  },
}

export default PricingImpl
