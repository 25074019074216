import styled from 'styled-components'

export const JitsiMeetEmbedStyled = styled.div`
  position: relative;
  .loader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    display: grid;
    place-items: center;
  }
  .jitsi-close-btn {
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 10px;
    padding: 4px;
    min-width: 50px;
    display: grid;
    place-items: center;
    box-shadow: 0px 0px 14px 0px rgba(199, 199, 199, 0.75);
    -webkit-box-shadow: 0px 0px 14px 0px rgba(199, 199, 199, 0.75);
    -moz-box-shadow: 0px 0px 14px 0px rgba(199, 199, 199, 0.75);
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
`
