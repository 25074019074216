import styled, { css } from 'styled-components'

export const GroupSpaceContainer = styled.div`
  margin: 10px auto;
  display: grid;
  place-items: center;
  cursor: pointer;
`
export const GroupListContainer = styled.div`
  max-height: 14.5vh;
  margin: 0 7.5px;
  overflow-x: hidden;
  overflow-y: auto;
  .tooltipContainer {
    position: static;
  }
  .tooltipWraper {
    top: auto;
    left: 70px;
  }
  ${props =>
    props.showList
      ? css`
          display: inline-block;
        `
      : css`
          display: none;
        `}
`
export const SectionActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .action-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2px 6px;
    border-radius: 2px;
    cursor: pointer;
    &:hover {
      background-color: #505152;
    }
  }
`

export const PopOverCardStyled = styled.div`
  position: relative;
  .hiden {
    display: none;
  }
  .path {
    display: none;
    height: 20px;
    width: 50px;
  }
  cursor: pointer;
  ${props =>
    props.openPopup &&
    css`
      .hiden {
        position: absolute;
        display: block;
        left: 200%;
        cursor: pointer;
      }
      .path {
        position: absolute;
        display: block;
        left: 100%;
        cursor: pointer;
      }
    `}
`
export const Card = styled.div`
  max-width: 260px;
  min-width: 182px;
  color: #343434;
  background: #fff;
  filter: drop-shadow(-4px 4px 10px rgba(153, 153, 153, 0.25));
  padding: 10px 10px;
  border-radius: 3px;
  position: absolute;
  top: 0px;
  z-index: 2;
  &:hover {
    background-color: #fff !important;
  }
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #fff;
    position: absolute;
    right: 100%;
    top: 10px;
    z-index: 2;
  }
  button {
    margin-bottom: 5px;
  }
`
