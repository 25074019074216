import React, { useState } from 'react'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { AlertStyled } from './Alert.styles'

type AlertProps = {
  children: any
  color: string
  temporal?: boolean
  destroyable?: boolean
  className?: string
}
const Alert = ({
  children,
  color,
  temporal,
  destroyable,
  className,
}: AlertProps): JSX.Element => {
  const [showAlert, setShowAlert] = useState<boolean>(true)
  if (temporal) {
    setTimeout(() => {
      setShowAlert(false)
    }, 4000)
  }
  const closeAlert = () => {
    setShowAlert(false)
  }
  return (
    <>
      {showAlert && (
        <AlertStyled color={color} className={className}>
          {children}
          {destroyable && (
            <button onClick={closeAlert}>
              <IoMdCloseCircleOutline size="25px" />
            </button>
          )}
        </AlertStyled>
      )}
    </>
  )
}

export default Alert
