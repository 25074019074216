import React from 'react'
import { Button, Text } from '../../../common'
import { FaArrowRight } from 'react-icons/fa'
import { navigate } from '@reach/router'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { IntegrationsStyled } from './Integrations.styles'

const integrations: any = [
  {
    name: 'Figma',
    src: 'https://cdn.worldvectorlogo.com/logos/figma-1.svg',
    openFunction: () => {},
    activeTrigger: false,
    free: false,
  },
  {
    name: 'Trello',
    src: 'https://cdn.worldvectorlogo.com/logos/trello.svg',
    openFunction: () => {},
    activeTrigger: false,
    free: false,
  },
  {
    name: 'Miro',
    src: 'https://cdn.worldvectorlogo.com/logos/miro-2.svg',
    openFunction: () => {},
    activeTrigger: false,
    free: false,
  },
  {
    name: 'ClickUp',
    src: 'https://clickup.com/landing/images/clickup-logo-gradient.png',
    openFunction: () => {},
    activeTrigger: false,
    free: false,
  },
]
interface IntegrationsProps {
  impl: any
}

const Integrations = ({ impl }: IntegrationsProps): JSX.Element => {
  const { darkMode, workspace }: any = useWorkspaceContext()

  return (
    <div>
      <IntegrationsStyled>
        <Text color={darkMode ? '#fff' : '#161C2D'} type="h2">
          {impl.title}
        </Text>
        <Text size="17px" color="#95A7C1" type="h4">
          {impl.subtitle}
        </Text>
        <div className="card">
          <div className="title-card">
            <Text align="center" color="#95A7C1" type="h5">
              {impl.unsubscribedTitle}
            </Text>
          </div>
          <div className={'group-form'}>
            {integrations.map((integration: any, key: number) => {
              return (
                <div className="group-form-item grayscale" key={key}>
                  {<img src={integration.src} alt={integration.name} />}
                </div>
              )
            })}
          </div>
          {
            <div className="footer-card">
              <Text size="21px" align="center" color="#95A7C1" type="h4">
                {impl.unsubscribedText}
              </Text>
              <br />
              <Button
                color="primary"
                onClick={() => {
                  if (workspace.subscribed) {
                    navigate('/contact')
                  } else {
                    navigate('/subscription')
                  }
                }}
                size="small"
              >
                {impl.planBtn} <FaArrowRight className="ml-2" />
              </Button>
            </div>
          }
        </div>
      </IntegrationsStyled>
    </div>
  )
}

export default Integrations
