import styled from 'styled-components'

export const AccountConfigStyled = styled.div`
  position: relative;
  .config-container {
    display: block;
    margin: 0px 32px;
    padding: 32px;
    background-color: #fbfbfb;
    border-radius: 20px;
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    box-shadow: 0px 1px 15px 0px rgba(179, 179, 179, 0.7);
    -webkit-box-shadow: 0px 1px 15px 0px rgba(179, 179, 179, 0.7);
    -moz-box-shadow: 0px 1px 15px 0px rgba(179, 179, 179, 0.7);
  }
  .account-form {
    &__input-container {
      margin-top: 15px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    &__button {
      margin-top: 30px;
      display: grid;
      justify-content: flex-end;
    }
  }
  .more-configs {
    margin-top: 25px;
    &__section {
      margin-top: 25px;
      &--item {
        margin: 14px 0px;
        display: flex;
        justify-content: space-between;
        .toggle-action {
          display: flex;
          align-items: center;
          p {
            margin-right: 10px;
          }
        }
      }
    }
  }
`
