// import { Link } from '@reach/router'
import styled from 'styled-components'

export const MainZyreboxContainer = styled.div`
  margin: 1vh 5px;
  height: 97vh;
  width: 70px;
  padding: 5px 5px;
  overflow-y: hidden;
  @media (min-height: 600px) {
    padding: 10px 5px;
  }
  @media (min-height: 800px) {
    padding: 12px 5px;
  }
  @media (min-height: 900px) {
    padding: 17px 5px;
  }
  padding-left: auto;
  padding-right: auto;
  position: absolute;
  z-index: 10;
  background: #f9fbfd;
  box-shadow: 0px 5px 10px rgba(116, 116, 116, 0.25);
  border-radius: 5px;
`
// export const BackToHome = styled(Link)`
export const BackToHome = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #232323;
  cursor: pointer;
  width: 100%;
`
export const MainContainer = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-size: 700px;
  position: absolute;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  /* background-color: ${props => (props.darkMode ? '#161C2D' : '#fff')}; */
  background-color: ${props => (props.darkMode ? '#0D1117' : '#F9FBFD')};
  .leave-modal {
    padding: 60px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    p {
      margin-bottom: 40px;
    }
    &__buttons {
      button {
        margin: 0px 10px;
      }
    }
  }
  .menu_options-component {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }

  .mainZyrebox-BackgroudActions {
    width: 100vw;
    height: 100vh;
    z-index: -0;
    position: absolute;
  }
`
