import styled, { css } from 'styled-components'

export const AppTriggerItemStyled = styled.div`
  .app-item {
    border: 1px solid #e5ebfb;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    padding: 12px;
    &:hover {
      background-color: #383838;
      cursor: pointer;
      svg {
        color: #fff !important;
      }
    }
    img {
      width: 37px;
      height: 37px;
      border-radius: 3px;
      ${props => props.blocked && css`
      filter: grayscale(100%);
      `}
    }
  }
  .active {
    background-color: #232323;
    svg {
      color: #fff !important;
    }
  }
`
