import React from 'react'
import { HeroMainContainer } from './Hero.styles'
import { Button } from '../../../common'
import { FaArrowRight } from 'react-icons/fa'
import { navigate } from '@reach/router'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import { EffectCoverflow, Pagination } from 'swiper'
import 'swiper/css/pagination'
import images from '../../../assets/static/images'

interface HeroProps {
  implHero: any
}

const Hero = ({ implHero: { mainTitle, subTitle, textButton } }: HeroProps) => {
  return (
    <HeroMainContainer>
      <div className="main__landing">
        <div
          className="main__message lg:w-5/12 lg:text-left w-10/12"
          data-aos="fade-up"
        >
          <h2 className="main__message--title">{mainTitle}</h2>
          <p className="main__message--p">{subTitle}</p>
          <Button
            className="main__landing--button"
            onClick={() => {
              navigate('/register')
            }}
            size="small"
            color="pink"
          >
            {textButton}
            <FaArrowRight />
          </Button>
        </div>
        <div className="lg:w-7/12 w-full">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView="auto"
            autoHeight={true}
            coverflowEffect={{
              rotate: 50,
              stretch: 70,
              depth: 300,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={false}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
            initialSlide={1}
            autoplay
          >
            <SwiperSlide>
              <img src={images.heroSlide2} alt="" className="radius" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={images.heroSlide1} alt="" className="radius" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={images.heroSlide3} alt="" className="radius" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="main__landing--curve">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 144.54 17.34"
          preserveAspectRatio="none"
          fill="#F6F6F6"
        >
          <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
        </svg>
      </div>
    </HeroMainContainer>
  )
}

export default Hero
