import React from 'react'
import { AiFillMessage } from 'react-icons/ai'
import { BsFillCameraVideoFill, BsInfoCircle } from 'react-icons/bs'
import { ImPhoneHangUp } from 'react-icons/im'
import { IoCloseCircleOutline } from 'react-icons/io5'

const MainZyreboxImpl = {
  MainZyreboxImpl: {
    backTooltip: 'Atras',
    leaveModal: {
      question: (name: any) => {
        return (
          <>
            ¿Estas seguro que quieres abandonar <strong>{name}</strong> ?
          </>
        )
      },
      advertisement:
        'Esta acción es irreversible y ya no seras parte de este equipo',
      cancelbtn: 'CANCELAR',
      leavebtn: 'SI, ABANDONAR',
    },
    zyreboxInfo: {
      leaderLabel: 'Lider del equipo: ',
      teamLabel: 'Nombre del equipo: ',
      companyLabel: 'Empresa: ',
      descriptionLabel: 'Descripción: ',
      configLabel: 'Configs',
      leaveLabel: 'Abandonar equipo',
    },
    myspace: {
      badgeLabel: 'Mi Espacio',
      darkmodeLabel: 'Modo Nocturno',
    },
    apps: {
      instantCall: {
        label: 'Crear reunión',
        icon: <BsFillCameraVideoFill size="37px" color="#335EEA" />,
      },
      chat: {
        label: 'Mi Chat',
        icon: <AiFillMessage size="37px" color="#6F5AEC" />,
      },
    },
    groupSpaces: {
      badgeLabel: 'Espacios',
      addSpaces: {
        title: 'Añadir Nuevo Espacio',
        subtitle: 'Ir configuración',
      },
    },
    myTeam: {
      badgeLabel: 'Mi equipo',
      addMember: {
        title: 'Añadir Nuevo Miembro',
        methodOne: 'Invitar por correo',
        methodTwo: 'Copiar enlace público',
      },
      member: {
        fullNameLabel: 'Nombres: ',
        emailLabel: 'Email: ',
        phoneLabel: 'Tel.: ',
        actions: {
          mail: (name: string) => {
            return 'Escribir a ' + name.split(' ')[0]
          },
          call: (name: string) => {
            return `Llamar a ${name.split(' ')[0]}`
          },
          addToCall: (name: string) => {
            return `Invitar a ${name.split(' ')[0]}`
          },
          whatsapp: (name: string) => {
            return `WhatsApp ${name.split(' ')[0]}`
          },
          noWhatsapp: (name: string) => {
            return `${name.split(' ')[0]} no añadió WhatsApp`
          },
          copy: () => {
            return `Copiar`
          },
        },
      },
    },
    incommingCall: {
      incommig: (name: string) => {
        return `Llamada entrante de ${name.split(' ')[0]}`
      },
      outcomming: (name: string) => {
        return `Llamando a ${name.split(' ')[0]}`
      },
    },
    jitsiMeet: {
      topAlert: (
        <span className="top-alert">
          <BsInfoCircle size="25px" /> Por favor termina esta llamada (
          <ImPhoneHangUp size="25px" />) o cierra la ventana (
          <IoCloseCircleOutline size="25px" />) ANTES de iniciar otra 😉
        </span>
      ),
      bottomAlert: 'Puedes añadir a miembros de tu equipo a  esta llamada',
      copyurlText: (desktop: string, movile: string) => {
        return (
          '¡Hola! \n\n¡Te invito a esta reunión en mi Zyrebox!, dale click a este enlace para ingresar desde tu computadora\n' +
          desktop +
          '.\n\nO ingresa desde tu celular con este enlace\n' +
          movile
        )
      },
    },
    mainMenu: {
      buttonLabel: 'Mostrar Menu',
      title: 'Menu',
      findInZyrebox: {
        title: 'Buscar en este Zyrebox',
        description: 'Encuentra compañeros de equipo, espacios grupales y apps',
        findInput: {
          placeholder: 'Ingresa el nombre de un espacio',
        },
        peopleLabel: 'Personas',
        groudSpacesLabel: 'Espacios Grupales',
        appsLabel: 'Apps',
      },
      calendarOption: {
        title: 'Calendario (Proximamente)',
        description: 'Planifica tus reuniones y agenda proximos eventos',
      },
    },
  },
}

export default MainZyreboxImpl
