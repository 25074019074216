import styled from 'styled-components'
import { Link } from '@reach/router'
// import { Link } from 'react-router-dom'

export const AnchorRouteStyles = styled(Link)`
  color: ${({ color }) => color || '#FA27A9'} !important;
  text-decoration: ${({ decoration }) => decoration || 'underline'};
  font-size: ${({ fontSize }) => fontSize || '17px'};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
`
export const AnchorStyles = styled.a`
  color: ${({ color }) => color || '#FA27A9'};
  text-decoration: ${({ decoration }) => decoration || 'underline'};
  font-size: ${({ fontSize }) => fontSize || '17px'};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
`
