import styled from 'styled-components'

export const QnAStyled = styled.div`
  padding: 80px 0px 80px 0px;
  background-color: #F6F6F6;
  display: flex;
  justify-content: center;
  align-items: center;
  .qna{ 
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 19px;
    padding: 50px;
    display: grid;
    place-items: center;
    /* width: 60%; */
    &__badge {
      margin: 20px;
      div {
        padding: 0px 10px;
      }
    }
    &__header {
      margin-bottom: 44px;
    }
    &__questions {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
`
