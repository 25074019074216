import styled from 'styled-components'

export const SidebarStyled = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fff;
  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 25px 15px;
    p {
      margin-left: 15px;
      margin-bottom: 0px;
    }
  }
  .sidebar_item {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    color: #506690;
    display: flex;
    padding: 16px 24px;
    cursor: pointer;
    svg {
      margin-right: 20px;
    }
    &:hover {
      background-color: #ebebeb;
      color: #232323;
    }
  }

  .sidebar_item-active {
    background-color: #ebebeb;
    color: #232323;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    padding: 16px 24px;
    cursor: pointer;
    svg {
      margin-right: 20px;
      color: #fa27a9;
    }
    &:hover {
      background-color: #ebebeb;
      color: #232323;
    }
  }
`
