import styled, { css } from 'styled-components'

export const DrawerStyled = styled.div`
  padding: 15px;
  .drawer-header {
    display: flex;
    justify-content: space-between;
    ${props =>
      props.isFirstPage &&
      css`
        justify-content: center;
        border-bottom: 1px solid #ccc;
      `};
    margin: 10px 0px 16px 0px;
  }
`
