import styled from 'styled-components'

export const InvitePageStyled = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  background-color: #212121;
  .need-permisions {
    display: grid;
    place-items: center;
  }
  .need-auth {
    display: grid;
    place-items: center;
  }
`
