/* eslint-disable react-hooks/exhaustive-deps */
import { navigate } from '@reach/router'
import React, { useEffect } from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { Button, Container, LanguageFloat, Text } from '../../common'
import { SubscribeStyled, SubscribeStyledWraper } from './Subscribe.styles'
import ReactGA from 'react-ga'
import { useWorkspaceContext } from '../../contexts/workspace'
import Footer from '../../shared/Footer'
import { Navbar } from '../../shared'
import images from '../../assets/static/images'

const Subscribe = () => {
  const {
    impl: { SubscribeImpl },
  }: any = useWorkspaceContext()

  // _______Anaylicits
  useEffect(() => {
    ReactGA.pageview('/subscription')
  }, [])
  // const subscribeImages = [images.subscriptionImage2, images.subscriptionImage]

  return (
    <div>
      <Navbar />
      <SubscribeStyledWraper>
        <div className="pricing-background">
          <svg
            className="hero__container--curve"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 144.54 17.34"
            preserveAspectRatio="none"
            fill="#FFFF"
          >
            <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
          </svg>
        </div>
        <Container>
          <SubscribeStyled>
            <Button
              color="light"
              size="extraSmall"
              onClick={() => {
                navigate(-1)
              }}
            >
              <FaChevronLeft /> {SubscribeImpl.backBtn}
            </Button>
            <div className="subscribe-content">
              <div className="subscribe-content__form">
                <Text type="h1" color="#FFF" align="center" weight="bold">
                  {SubscribeImpl.title}
                </Text>
                <Text type="h2" color="#f0f0f0" align="center">
                  {SubscribeImpl.subtitle}
                </Text>
                {/* Todo form */}
                <img
                  src={images.subscriptionImage}
                  alt=""
                  className="subscribe-content__form--image"
                />
              </div>
              <div className="subscribe-content__plan">
                <div>
                  <div className="title-label">
                    {SubscribeImpl.subscribeInfo.title}
                  </div>
                  <div className="price-container">
                    <span className="dollar">$</span>
                    <Text
                      type="h1"
                      color="#000"
                      align="center"
                      size="45px"
                      className="price"
                    >
                      {SubscribeImpl.subscribeInfo.monthPrice}
                      <span className="month-label">
                        /{SubscribeImpl.monthLabel}
                      </span>
                    </Text>
                  </div>
                  <Text type="h5" color="#BFCADA" weight="600" align="center">
                    {SubscribeImpl.subscribeInfo.subtitle}
                  </Text>
                  <div className="features-list">
                    <ul>
                      {SubscribeImpl.subscribeInfo.features.map(
                        (feature: any) => (
                          <li key={feature.name}>
                            <Text type="p" color="#000">
                              {feature.name}
                            </Text>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div className="subscribe-content__plan--button">
                  <Button
                    color="pink"
                    onClick={() => {
                      navigate('/checkout')
                    }}
                    size="base"
                    block
                  >
                    {SubscribeImpl.subscribeBtnLabel}
                  </Button>
                </div>
              </div>
            </div>
          </SubscribeStyled>
        </Container>
      </SubscribeStyledWraper>
      <LanguageFloat />
      <Footer />
    </div>
  )
}

export default Subscribe
