import styled, { css } from 'styled-components'

export const AvatarStyled = styled.div`
  background: url(${({ image }) => `'${image}'` || 'https://img.icons8.com/color/48/000000/spyro.png'}),
    linear-gradient(
      0deg,
      ${props => props.backgroundColor || '#232323'},
      ${props => props.backgroundColor || '#232323'}
    );
  background-size: cover;
  width: ${({ size }) => size || '64px'};
  height: ${({ size }) => size || '64px'};
  position: relative;
  border: ${({ borderSize }) => borderSize || '0px'} solid
    ${({ borderColor }) => borderColor || '#000000'};
  border-radius: 100px;
  display: inline-block;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
  ${({ loadingUpload }) => loadingUpload && css`
      label {
        display: block;
        div {
          border: 3px solid #f3f3f3;
          border-top: 3px solid #FA2BAA;
          width: 25px;
          height: 25px;
        }
      }
  `}
  &:hover {
    ${({ enableHoverMask }) =>
    enableHoverMask &&
    css`
        > label {
          ${({ hasChangeMask }) =>
        hasChangeMask &&
        css`
              display: block;
              height: ${({ size }) => size || '64px'};
              width: ${({ size }) => size || '64px'};
              display: flex;
              flex-direction: column;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              border-radius: 50px;
              cursor: pointer;
              background: rgba(22, 28, 45, 0.3);
              color: #ffffff;
              p {
                margin: 0 !important;
              }
              div {
                border: 3px solid #f3f3f3;
                border-top: 3px solid #232323;
                width: 25px;
                height: 25px;
              }
            `}

          ${({ hasChangeMask }) =>
        !hasChangeMask &&
        css`
              display: none;
            `}
        }
      `}
  }

  > label {
    display: none;
  }

  ${({ enableHoverMask }) =>
    !enableHoverMask &&
    css`
      ${({ hasChangeMask }) =>
        hasChangeMask &&
        css`
          > label {
            display: block;
            height: ${({ size }) => size || '64px'};
            width: ${({ size }) => size || '64px'};
            display: flex;
            flex-direction: column;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            border-radius: 50px;
            cursor: pointer;
            background: rgba(22, 28, 45, 0.3);
            color: #ffffff;
            p {
              margin: 0 !important;
            }
            div {
              border: 3px solid #f3f3f3;
              border-top: 3px solid #335eea;
              width: 25px;
              height: 25px;
            }
          }
        `}
    `}

  > div {
    background-color: ${({ currentStatus }) =>
    currentStatus ? '#1FDB54' : '#506690'};
    display: grid;
    place-content: center;
    height: ${({ size }) => `${Number(size.split('p')[0]) * 0.27}px` || '17px'};
    width: ${({ size }) => `${Number(size.split('p')[0]) * 0.27}px` || '17px'};
    position: absolute;
    border-radius: 100px;
    left: 70%;
    top: 70%;
    div {
      height: ${({ size }) =>
    `${(Number(size.split('p')[0]) * 0.27) / 3}px` || '17px'};
      width: ${({ size }) =>
    `${(Number(size.split('p')[0]) * 0.27) / 3}px` || '17px'};
      background: #fff;
      border-radius: 100px;
    }
  }
`
