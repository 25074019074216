import styled, { css } from 'styled-components'

export const ListZyreboxStyled = styled.div`
  height: 300px;
  @media (min-height: 600px) {
    height: 400px;
  }
  @media (min-height: 800px) {
    height: 450px;
  }
  @media (min-height: 900px) {
    height: 550px;
  }
  @media (min-height: 950px) {
    height: 600px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  .items-container {
    width: 80vw;
    height: 300px;
    @media (min-height: 600px) {
      height: 400px;
    }
    @media (min-height: 800px) {
      height: 450px;
    }
    @media (min-height: 900px) {
      height: 550px;
    }
    @media (min-height: 950px) {
      height: 600px;
    }

    display: flex;
    align-items: center;
    ${props =>
      props.items < 10 &&
      css`
        justify-content: center;
      `}
    overflow-y: hidden;
    overflow-x: scroll;
    position: relative;
    padding: 0 100px;
  }
  .items-container::-webkit-scrollbar {
    height: 8px; /* Tamaño del scroll en horizontal */
  }
  .items-container::-webkit-scrollbar-thumb {
    background: #364872;
    border-radius: 50px;
  }

  /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
  .items-container::-webkit-scrollbar-thumb:hover {
    background: #506690;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  /* Cambiamos el fondo cuando esté en active */
  .items-container::-webkit-scrollbar-thumb:active {
    background-color: #f9fbfd;
  }
  .items-create {
    max-width: 110px;
    ${props =>
      props.items >= 1 &&
      css`
        margin-left: 25px;
      `}
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
      height: 200px;
      transition: all 0.2s ease-in-out;
      p {
        margin-top: 20px;
      }
      &:hover {
        cursor: pointer;
        p {
          color: ${props => (props.darkMode ? '#F9FBFD' : '#232323')};
        }
        transform: scale(1.2);
        ${props =>
          props.items >= 1 &&
          css`
            margin-left: 25px;
          `}
      }
    }
  }
  @media (max-width: 1400px) {
    .items-container {
      ${props =>
        props.items < 7
          ? css`
              justify-content: center;
            `
          : css`
              justify-content: flex-start;
            `}
    }
  }
`
export const LoaderContainer = styled.div`
  height: 400px;
  display: grid;
  place-items: center;
`
