import styled from 'styled-components'

export const SwitchSidesMain = styled.div`
  background-color: #ffff;
  .switch-content {
    padding: 40px 0px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas:
      '.... img__one img__one img__one text__one text__one .... ....'
      '.... img__two img__two img__two text__two text__two .... ....';
    gap: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .switch-content__text {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    @media (max-width: 780px) {
      padding: 0px;
    }
  }
  img {
    width: 80%;
  }
  .switch-content__text--title {
    width: 374px;
    height: 208px;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 47px;
    letter-spacing: -0.05em;
    color: #ffffff;
    background: #232323;
    padding: 30px 30px;
    border-radius: 20px;
    @media (max-width: 780px) {
      width: 100%;
      height: auto;
    }
  }
  .switch-content__text--description {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #232323;
    margin-top: 25px;
  }
  #text__one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: text__one;
  }
  #img__one {
    display: flex;
    flex-direction: row;
    justify-content: end;
    grid-area: img__one;
  }
  #text__two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: text__two;
  }
  #img__two {
    display: flex;
    flex-direction: row;
    justify-content: end;
    grid-area: img__two;
  }
  @media (max-width: 780px) {
    .switch-content {
      padding: 40px 20px;
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas:
        'text__one'
        'img__one'
        'text__two'
        'img__two';
    }
  }
`
