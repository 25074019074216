import styled from 'styled-components'

export const SubscribeStyledWraper = styled.div`
  position: relative;
  @media screen and (max-width: 700px) {
    .subscribe-content__form {
      padding: 25px !important;
    }
  }
  .pricing-background {
    position: absolute;
    width: 100%;
    z-index: -1;
    background-color: #232323;
    height: 70vh;
    display: flex;
    align-items: flex-end;
  }
`

export const SubscribeStyled = styled.div`
  margin-top: 100px;
  .subscribe-content {
    font-family: 'HKGrotesk', sans-serif;
    display: grid;
    @media screen and (max-width: 700px) {
      display: inherit;
    }
    grid-template-columns: repeat(100, 1fr);
    margin: 30px 0px;
    /* gap: 30px; */
    &__form {
      grid-column: 1/65;
      padding: 50px;
      &--image {
        width: 100%;
      }
      strong {
        color: #fa27a9;
      }
    }

    &__plan {
      grid-column: 65/101;
      background: #ffffff;
      border-radius: 20px;
      padding: 40px;
      color: black;
      box-shadow: 0px 4px 30px rgba(163, 163, 163, 0.3);
      .price-container {
        display: flex;
        justify-content: center;
        .dollar {
          line-height: 35px;
          color: black;
          font-size: 25px;
          margin-top: 20px;
          font-style: normal;
          font-weight: 200 !important;
        }
        .price {
          margin: 24px 0px 8px 0;
          span {
            position: relative;
            top: 4px;
            font-size: 19px;
          }
        }
      }
      .title-label {
        background: #fed4ee;
        color: #fa27a9;
        font-size: 13px;
        display: block;
        text-align: center;
        border-radius: 10px;
        margin: 0 auto;
        width: 80px;
        padding: 2px 0;
        font-weight: 600;
      }
      .features-list {
        margin: 20px 0px;
        ul {
          margin-left: 20px;
          list-style-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7.33203' cy='6.66797' r='6' fill='%23ECF8F5'/%3E%3Cg clip-path='url(%23clip0_2080_963)'%3E%3Cpath d='M9.49018 4.65544C9.60006 4.54453 9.74937 4.48163 9.90548 4.48048C10.0616 4.47933 10.2118 4.54003 10.3233 4.6493C10.4348 4.75857 10.4985 4.90754 10.5005 5.06364C10.5025 5.21975 10.4426 5.3703 10.334 5.48239L7.18981 9.413C7.13579 9.47115 7.07061 9.51783 6.99816 9.55023C6.92571 9.58264 6.84747 9.60011 6.76811 9.60161C6.68875 9.60311 6.60991 9.5886 6.53629 9.55895C6.46266 9.5293 6.39577 9.48511 6.3396 9.42904L4.25498 7.34522C4.14421 7.23439 4.08199 7.0841 4.08203 6.9274C4.08207 6.7707 4.14435 6.62043 4.25518 6.50965C4.36601 6.39888 4.51631 6.33666 4.67301 6.3367C4.82971 6.33674 4.97998 6.39902 5.09075 6.50985L6.73944 8.15894L9.47454 4.67389C9.47951 4.66754 9.48487 4.66152 9.49058 4.65584L9.49018 4.65544Z' fill='%23FA27A9'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2080_963'%3E%3Crect width='6.41667' height='6.41667' fill='white' transform='translate(4.08203 4.08203)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }
      }
    }

    @media screen and (max-width: 500px) {
      &__form {
        grid-column: 1/101;
        padding: 10px;
      }
      &__plan {
        grid-column: 1/101;
      }
    }
  }
`
