import React from 'react'
import { AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai'
/* import images from '../../assets/static/images' */
import { Anchor, Container, Text } from '../../common'
import { FooterStyled } from './Footer.styles'
import images from '../../assets/static/images'

interface FooterProps {
  dark?: boolean
}
const Footer = ({ dark }: FooterProps) => {
  const year = new Date().getFullYear()
  const footerLinks = [
    {
      title: 'Links',
      links: [
        {
          route: '/login',
          name: 'Login',
        },
        {
          route: '/register',
          name: 'Register',
        },
        {
          route: '/home',
          name: 'Home',
        },
      ],
    },
    {
      title: 'Services',
      links: [
        {
          route: '/pricing',
          name: 'Pricing',
        },
        {
          route: '/contact',
          name: 'Custom Plan',
        },
      ],
    },
    {
      title: 'Contact',
      links: [
        {
          route: '/contact',
          name: 'Conctact Us',
        },
      ],
    },
    {
      title: 'Legal',
      links: [
        {
          route: '/terms',
          name: 'Terms & Conditions',
        },
        {
          // route: '/cookies',
          route: '/terms',
          name: 'Cookies Policy',
        },
        {
          // route: '/privacy',
          route: '/terms',
          name: 'Privacy Policy',
        },
      ],
    },
  ]
  return (
    <div>
      <FooterStyled>
        <Container>
          <div>
            <hr />
            <div className="footer-main">
              <div className="footer-main__social">
              {dark ? (
                <img className='w-32' src={images.logoNav} alt="logonav"></img>
                ) : (
                  <img className='w-32' src={images.logoNavDark} alt="logonav"></img>
                )}
                {/* <img src={images.logoNavDark} alt="logo"></img> */}
                <Text type="p" color="#bac5d3" weight="300">
                  Follow us on:
                </Text>
                <div className="footer-main__social--links-container">
                  <Anchor
                    route="https://www.linkedin.com/company/zyrebox/"
                    className="social-link"
                    decoration="none"
                    targetBlank
                  >
                    <AiFillLinkedin size="34px" />
                  </Anchor>
                  <Anchor
                    route="https://twitter.com/home"
                    className="social-link"
                    decoration="none"
                    targetBlank
                  >
                    <AiFillTwitterSquare size="34px" />
                  </Anchor>
                </div>
              </div>
              {footerLinks.map((groupLink: any) => (
                <div className="footer-main__links" key={groupLink.title}>
                  <Text type="p" color="#bac5d3" weight="300">
                    {groupLink.title}
                  </Text>
                  {groupLink.links.map((link: any) => (
                    <Anchor
                      type="link"
                      route={link.route}
                      color="#6E839D"
                      className="common-link"
                      decoration="none"
                      key={link.name}
                    >
                      {link.name}
                    </Anchor>
                  ))}
                </div>
              ))}
              <div className="footer-footer">
                <Text type="p" color="#6E839D" weight="300" align="center">
                  ©Zyrebox {year} - Developed and Designed by A & L productions,
                  a division of PooPaye SAC
                </Text>
              </div>
            </div>
          </div>
        </Container>
      </FooterStyled>
    </div>
  )
}

export default Footer
