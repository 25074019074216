import React from 'react'
import { ButtonStyles } from './Button.styles'

type ButtonColor =
  | 'primary'
  | 'pink'
  | 'primary-pink'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'

type ButtonProps = {
  children: any
  onClick?: () => void
  size: string
  color: ButtonColor
  pill?: boolean
  outlined?: boolean
  soft?: boolean
  disabled?: boolean
  className?: string
  block?: boolean
  textColor?: string
}

const Button = ({
  children,
  onClick,
  size,
  color,
  pill,
  outlined,
  soft,
  disabled,
  className,
  block,
  textColor,
}: ButtonProps): JSX.Element => {
  return (
    <>
      <ButtonStyles
        onClick={onClick}
        size={size}
        color={color}
        pill={pill}
        outlined={soft ? null : outlined}
        soft={outlined ? null : soft}
        disabled={disabled}
        className={className}
        block={block}
        textColor={textColor}
      >
        <div>{children}</div>
      </ButtonStyles>
    </>
  )
}

export default Button
