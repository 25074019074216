import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  split,
} from '@apollo/client'
import { ApolloProvider as Provider } from '@apollo/client/react'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_HTTP_SERVER,
})
const authMiddleware = new ApolloLink((operation, forward) => {
  const token = window.localStorage.getItem('token')
  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
  }
  return forward(operation)
})

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_SERVER,
  options: {
    reconnect: true,
    connectionParams: {
      authorization: `Bearer ${window.localStorage.getItem('token')}`,
    },
  },
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  // httpLink
  uploadLink
)

const errorMiddleware = onError(({ networkError }) => {
  if (networkError && networkError.result.code === 'invalid_token') {
    window.localStorage.removeItem('token')
    window.location.href = '/login'
  }
})

const client = new ApolloClient({
  link: from([
    errorMiddleware,
    authMiddleware,
    splitLink,
    // uploadLink
    // authLink.concat(httpLink),
  ]),
  cache: new InMemoryCache(),
})

export default function ApolloProvider(props) {
  return <Provider client={client} {...props} />
}
