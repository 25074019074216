import React from 'react'
import { AiFillMessage } from 'react-icons/ai'
import { BsFillCameraVideoFill, BsInfoCircle } from 'react-icons/bs'
import { ImPhoneHangUp } from 'react-icons/im'
import { IoCloseCircleOutline } from 'react-icons/io5'

const MainZyreboxImpl = {
  MainZyreboxImpl: {
    backTooltip: 'Back',
    leaveModal: {
      question: (name: any) => {
        return (
          <>
            Are you sure you want to leave <strong>{name}</strong> ?
          </>
        )
      },
      advertisement:
        'This acction is unreversible and you will be no longer part of this team',
      cancelbtn: 'CANCEL',
      leavebtn: 'YES, LEAVE',
    },
    zyreboxInfo: {
      leaderLabel: 'Team Leader: ',
      teamLabel: 'Team name: ',
      companyLabel: 'Company: ',
      descriptionLabel: 'Description: ',
      configLabel: 'Settings',
      leaveLabel: 'Leave team',
    },
    myspace: {
      badgeLabel: 'My Space',
      darkmodeLabel: 'Dark Mode',
    },
    apps: {
      instantCall: {
        label: 'Instant Call',
        icon: <BsFillCameraVideoFill size="37px" color="#335EEA" />,
      },
      chat: {
        label: 'My Chat',
        icon: <AiFillMessage size="37px" color="#6F5AEC" />,
      },
    },
    groupSpaces: {
      badgeLabel: 'Spaces',
      addSpaces: {
        title: 'Add New Space',
        subtitle: 'Go to settings',
      },
    },
    myTeam: {
      badgeLabel: 'My Team',
      addMember: {
        title: 'Add New Member',
        methodOne: 'Invite by Email',
        methodTwo: 'Copy public link',
      },
      member: {
        fullNameLabel: 'Full name: ',
        emailLabel: 'Email: ',
        phoneLabel: 'Phone: ',
        actions: {
          mail: (name: string) => {
            return 'Mail ' + name.split(' ')[0]
          },
          call: (name: string) => {
            return `Call ${name.split(' ')[0]}`
          },
          addToCall: (name: string) => {
            return `Add ${name.split(' ')[0]}`
          },
          whatsapp: (name: string) => {
            return `WhatsApp ${name.split(' ')[0]}`
          },
          noWhatsapp: (name: string) => {
            return `${name.split(' ')[0]} didn't add WhatsApp`
          },
          copy: () => {
            return `Copy`
          },
        },
      },
    },
    incommingCall: {
      incommig: (name: string) => {
        return `Incomming call from ${name.split(' ')[0]}`
      },
      outcomming: (name: string) => {
        return `Calling ${name.split(' ')[0]}`
      },
    },
    jitsiMeet: {
      topAlert: (
        <span className="top-alert">
          <BsInfoCircle size="25px" /> Please finish the current call (
          <ImPhoneHangUp size="25px" />) or close the window (
          <IoCloseCircleOutline size="25px" />) BEFORE start other 😉
        </span>
      ),
      bottomAlert: 'You can add your team members to this call',
      copyurlText: (desktop: string, movile: string) => {
        return (
          "Hey! \n\nLet's have a meeting in my Zyrebox, click on this link to enter if you are in desktop \n" +
          desktop +
          '.\n\nOr click on this link to enter if you are in your phone\n' +
          movile
        )
      },
    },
    mainMenu: {
      buttonLabel: 'Show Menu',
      title: 'Menu',
      findInZyrebox: {
        title: 'Find In Zyrebox',
        description: 'Find team mates, group spaces and apps.',
        findInput: {
          placeholder: 'Enter a name here',
        },
        peopleLabel: 'Team Members',
        groudSpacesLabel: 'Group Spaces',
        appsLabel: 'Apps',
      },
      calendarOption: {
        title: 'Calendar (Comming Soon)',
        description: 'Plan your meetings and schedule your events.',
      },
    },
  },
}

export default MainZyreboxImpl
