import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import images from '../../../assets/static/images'
import { Button, Input, Loader, Text, Tooltip } from '../../../common'
import { useAuthState } from '../../../contexts/auth'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { CREATE_ROOM } from '../../../graphQL/Mutations'
import { GET_STATICS_ROOMS } from '../../../graphQL/Queries'
import GroupList from './GroupList'
import { GroupSpacesStyled } from './GroupSpaces.styles'
import { Link } from '@reach/router'
import { message } from 'antd'

interface GroupSpacesProps {
  impl: any
}

const GroupSpaces = ({ impl }: GroupSpacesProps) => {
  const { user } = useAuthState()
  const { darkMode, workspace }: any = useWorkspaceContext()
  const [staticRooms, setStaticsRoom] = useState<any>([])
  const [groupName, setRoomName] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const [roomLogo, setRoomLogo] = useState(images.dropdownIcons[0])

  const { loading, fetchMore } = useQuery(GET_STATICS_ROOMS, {
    variables: { idWorkspace: workspace.id },
    onCompleted: data => {
      setStaticsRoom(data.getStaticRooms)
    },
    onError: err => console.log(err),
  })
  const handleFetchMore = () => {
    fetchMore({
      variables: { idWorkspace: workspace.id },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        setStaticsRoom(fetchMoreResult.getStaticRooms)
        return fetchMoreResult
      },
    })
  }
  const [createRoom, { loading: createLoading }] = useMutation(CREATE_ROOM, {
    // TODO hacer el fetchMore en el deleteRoom y usar tambien en el main zyrebox
    onCompleted: () => {
      handleFetchMore()
      // handleReCallroom()
      setRoomLogo(images.dropdownIcons[0])
      setRoomName('')
      message.success('Group Space was succesfully created for your team', 10)
    },
    onError: err => {
      const errors = err.message.split('Error:').join('')
      message.error(errors, 10)
    },
  })
  const handleInput = (value: string) => {
    setRoomName(value)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    createRoom({
      variables: {
        idWorkspace: workspace.id,
        idUser: user.id,
        isStatic: true,
        name: groupName,
        logo: roomLogo,
      },
    })
  }
  const handleShowDropdown = () => {
    setShowDropdown(!showDropdown)
  }
  const handleRoomLogo = (image: string) => {
    setRoomLogo(image)
    setShowDropdown(!showDropdown)
  }
  return (
    <div>
      <GroupSpacesStyled>
        <Text color={darkMode ? '#fff' : '#161C2D'} type="h2">
          {impl.title}
        </Text>
        <Text color="#95A7C1" type="h4">
          {impl.subtitle}
        </Text>
        <div className="card">
          <div className="title-section">
            <div className="title-section__text">
              <Text color="#161C2D" type="h4">
                {impl.addSpaceInput.label}{' '}
              </Text>
              <Tooltip
                position="top"
                content={
                  <Text color="#fff" type="p" size="12px" align="justify">
                    {impl.tooltipCreate}
                  </Text>
                }
              >
                <BsInfoCircle size="15px" />
              </Tooltip>
            </div>
          </div>
          <form className="group-form" onSubmit={handleSubmit}>
            <Input
              placeHolder={impl.addSpaceInput.placeholder}
              value={groupName}
              onChange={handleInput}
              validationError={''}
              className="input-field"
              maxLength={20}
              disabled={createLoading}
            />
            <div className="drowpdow-menu">
              <div className="drowpdow-menu--container">
                <img src={roomLogo} alt="" />
                <div
                  className="drowpdow-menu--container--action"
                  onClick={handleShowDropdown}
                >
                  {showDropdown ? <FaAngleUp /> : <FaAngleDown />}
                </div>
                {showDropdown && (
                  <div className="drowpdow-menu--options">
                    {images.dropdownIcons.map((image, key) => (
                      <img
                        src={image}
                        alt=""
                        key={key}
                        onClick={() => handleRoomLogo(image)}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="create-button">
              <Button
                color="primary"
                size="small"
                disabled={
                  groupName === '' ||
                  (staticRooms.length >= 2 && !workspace.subscribed)
                  // || loading
                }
              >
                {createLoading ? (
                  <Loader size="5px" border="5px" />
                ) : (
                  `${impl.createBtn}`
                )}
              </Button>
            </div>
          </form>
          <br />
          <div className="title-section">
            <div className="title-section__text">
              <Text color="#161C2D" type="h4">
                {impl.serchSpaceInput.label}{' '}
              </Text>
              <Tooltip
                position="top"
                content={
                  <Text color="#fff" type="p" size="12px" align="justify">
                    {impl.tooltipSearch}
                  </Text>
                }
              >
                <BsInfoCircle size="15px" />
              </Tooltip>
            </div>
            <div>
              <Text type="p" color="#94A5C0" fontStyle="italic" weight="400">
                {impl.quantity} {staticRooms.length}{' '}
                {workspace.subscribed ? '' : '/ 2'}
              </Text>
              {staticRooms.length >= 2 && !workspace.subscribed && (
                <Link
                  className="p-1 mr-2 border border-pink-500 text-pink-400 hover:bg-pink-400 hover:text-white cursor-pointer rounded"
                  to={workspace.subscribed ? '/contact' : '/checkout'}
                >
                  Get more rooms
                </Link>
              )}
            </div>
          </div>
          <GroupList
            rooms={staticRooms}
            loading={loading}
            updateQuery={handleFetchMore}
          />
        </div>
      </GroupSpacesStyled>
    </div>
  )
}

export default GroupSpaces
