import React, { useState } from 'react'
import { useAuthState } from '../../../contexts/auth'
import PayPaylBtn from '../../Subscription/PayPaylBtn'

const CheckoutPayment = ({
  CheckoutImpl,
  aditionalMembers,
  PRICE_PLAN_PRO,
  amountDiscount,
  amountSubtotal,
  amountTotal,
  blockPayment,
  subscribeCallback,
}) => {
  const { user } = useAuthState()
  const [currentMethodPayment, setCurrentMethodPayment] = useState(0) //0 paypal - 1 credito

  const paypalSubscribe = (data: any, actions: any) =>
    actions.subscription.create({
      plan_id: process.env.REACT_APP_PAYPAL_PRO,
    })
  const paypalOnError = (err: any) => {
    console.log('Error', err)
  }
  const paypalOnApprove = (data: any) => {
    // call the backend api to store transaction details
    subscribeCallback()
    console.log(data.subscriptionID)
  }
  return (
    <>
      <div className="flex">
        <div className="basis-8/12">
          <div className="flex gap-2">
            <label
              className={`radioBtn border ${
                currentMethodPayment ? 'border-gray-200' : 'border-pink-500'
              } basis-6/12 rounded p-2`}
            >
              <input
                type="radio"
                value="paypal"
                name="paymentMethod"
                onChange={() => setCurrentMethodPayment(0)}
                checked={!currentMethodPayment}
              />
              <span className="checkmark"></span>
              <span className="ml-2">Paypal</span>
              <img className="ml-2 mt-2" src="/paypal_option_logo.png" />
            </label>
            <label
              className={`radioBtn border ${
                !currentMethodPayment ? 'border-gray-200' : 'border-pink-500'
              } basis-6/12 rounded p-2`}
            >
              <input
                type="radio"
                value="credit"
                name="paymentMethod"
                onChange={() => setCurrentMethodPayment(1)}
                checked={!!currentMethodPayment}
              />
              <span className="checkmark"></span>
              <span className="ml-2">{CheckoutImpl.optionCardText}</span>
              <img className="ml-2 mt-2" src="/credit_card_option.png" />
            </label>
          </div>
          <div className="relative border border-gray-200 rounded py-4 px-2 mt-6 flex flex-wrap text-base">
            {/* {!user?.id && (
              <div className="absolute w-full h-full bg-gray-200/50 top-0 left-0 z-10"></div>
            )} */}
            <div className="basis-9/12">
              <p className="mb-2 text-slate-600 font-semibold">
                {CheckoutImpl.subscriptionText}
              </p>
              {aditionalMembers.qty > 0 && (
                <p className="mb-2 text-slate-600">
                  {CheckoutImpl.additionalMembers} x {aditionalMembers.qty}
                </p>
              )}
              {aditionalMembers.qty > 0 && (
                <p className="mb-2 text-pink-400">{CheckoutImpl.discounts}</p>
              )}
            </div>
            <div className="basis-3/12 text-right">
              <p className="mb-2 text-slate-800 font-semibold">
                USD&nbsp;
                {PRICE_PLAN_PRO.toLocaleString('en-IN', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
              {aditionalMembers.qty > 0 && (
                <p className="mb-2 text-slate-600">
                  USD&nbsp;
                  {aditionalMembers.amount.toLocaleString('en-IN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              )}
              {/* retirar ternario cuando tengamos la suscripción donde capturemos el monto real */}
              {aditionalMembers.qty > 0 && (
                <>
                  <p className="mb-2 text-pink-500 font-semibold">
                    - USD{' '}
                    {amountDiscount.toLocaleString('en-IN', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </>
              )}
            </div>
            <div className="my-4 border border-t-gray-100 w-full"></div>
            <div className="flex grow text-base">
              <div className="basis-8/12 font-bold">Total</div>
              <div className="basis-4/12 text-right">
                {aditionalMembers.qty > 0 && (
                  <span className="line-through text-pink-500 font-semibold text-sm">
                    USD{' '}
                    {amountSubtotal.toLocaleString('en-IN', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                )}
                <span className="font-bold">
                  USD{' '}
                  {amountTotal.toLocaleString('en-IN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
            </div>
            <div className="w-full text-md text-slate-500 font-normal flex my-2 font-hkgrotesk">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-2"
              >
                <path
                  d="M7.5 14.0625C5.75952 14.0625 4.09032 13.3711 2.85961 12.1404C1.6289 10.9097 0.9375 9.24048 0.9375 7.5C0.9375 5.75952 1.6289 4.09032 2.85961 2.85961C4.09032 1.6289 5.75952 0.9375 7.5 0.9375C9.24048 0.9375 10.9097 1.6289 12.1404 2.85961C13.3711 4.09032 14.0625 5.75952 14.0625 7.5C14.0625 9.24048 13.3711 10.9097 12.1404 12.1404C10.9097 13.3711 9.24048 14.0625 7.5 14.0625ZM7.5 15C9.48912 15 11.3968 14.2098 12.8033 12.8033C14.2098 11.3968 15 9.48912 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0C5.51088 0 3.60322 0.790176 2.1967 2.1967C0.790176 3.60322 0 5.51088 0 7.5C0 9.48912 0.790176 11.3968 2.1967 12.8033C3.60322 14.2098 5.51088 15 7.5 15V15Z"
                  fill="#7380A8"
                />
                <path
                  d="M8.37183 6.17625L6.22495 6.44531L6.14808 6.80156L6.56996 6.87937C6.84558 6.945 6.89996 7.04437 6.83996 7.31906L6.14808 10.5703C5.96621 11.4112 6.24652 11.8069 6.90558 11.8069C7.41652 11.8069 8.00996 11.5706 8.27902 11.2462L8.36152 10.8562C8.17402 11.0212 7.90027 11.0869 7.71839 11.0869C7.46058 11.0869 7.36683 10.9059 7.43339 10.5872L8.37183 6.17625ZM8.43746 4.21875C8.43746 4.46739 8.33868 4.70585 8.16287 4.88166C7.98705 5.05748 7.7486 5.15625 7.49996 5.15625C7.25131 5.15625 7.01286 5.05748 6.83704 4.88166C6.66123 4.70585 6.56246 4.46739 6.56246 4.21875C6.56246 3.97011 6.66123 3.73165 6.83704 3.55584C7.01286 3.38002 7.25131 3.28125 7.49996 3.28125C7.7486 3.28125 7.98705 3.38002 8.16287 3.55584C8.33868 3.73165 8.43746 3.97011 8.43746 4.21875Z"
                  fill="#7380A8"
                />
              </svg>
              {CheckoutImpl.totalInfo}
            </div>
            <div className="my-4 border border-t-gray-100 w-full"></div>
            {!currentMethodPayment ? (
              <div className="font-bold text-base z-1">
                <div className="font-bold text-base mb-4">
                  {CheckoutImpl.paymentTitle}
                </div>
                <div className="grid grid-cols-3">
                  <div className="">
                    {!blockPayment && user && (
                      <PayPaylBtn
                        amount={amountTotal}
                        currency="USD"
                        createSubscription={paypalSubscribe}
                        onApprove={paypalOnApprove}
                        catchError={paypalOnError}
                        onError={paypalOnError}
                        onCancel={paypalOnError}
                      />
                    )}
                  </div>
                  <div className="col-start-3 flex justify-end items-end pb-6">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mb-1 mr-2"
                    >
                      <path
                        d="M9 1.5C6.93225 1.5 5.25 3.18225 5.25 5.25V7.5H4.5C4.10218 7.5 3.72064 7.65804 3.43934 7.93934C3.15804 8.22064 3 8.60218 3 9V15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9C15 8.60218 14.842 8.22064 14.5607 7.93934C14.2794 7.65804 13.8978 7.5 13.5 7.5H12.75V5.25C12.75 3.18225 11.0677 1.5 9 1.5ZM6.75 5.25C6.75 4.0095 7.7595 3 9 3C10.2405 3 11.25 4.0095 11.25 5.25V7.5H6.75V5.25ZM9.75 13.2922V15H8.25V13.2922C7.98779 13.1421 7.77724 12.916 7.64612 12.6438C7.515 12.3715 7.46946 12.066 7.51551 11.7673C7.56156 11.4687 7.69704 11.191 7.90407 10.9709C8.11111 10.7509 8.37999 10.5987 8.67525 10.5345C8.89456 10.486 9.12196 10.4874 9.34068 10.5385C9.5594 10.5896 9.76386 10.6891 9.93899 10.8298C10.1141 10.9704 10.2554 11.1486 10.3525 11.3511C10.4496 11.5536 10.5 11.7754 10.5 12C10.4996 12.2623 10.43 12.5199 10.2983 12.7467C10.1667 12.9736 9.97754 13.1618 9.75 13.2922Z"
                        fill="#FA4EB9"
                      />
                    </svg>
                    <span className="font-normal text-base text-slate-600">
                      {CheckoutImpl.safePayment}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="font-bold text-base text-slate-500 p-20 text-center">
                <p>{CheckoutImpl.soonCreditCardPayment}</p>
              </div>
            )}
            <div
              className="font-semibold leading-5 text-md text-slate-800 font-hkgrotesk p-4"
              dangerouslySetInnerHTML={{ __html: CheckoutImpl.terms }}
            ></div>
          </div>
        </div>
        <div className="basis-4/12 pl-8">
          <h4 className="font-extrabold">Features:</h4>
          <ul className="list text-base leading-10">
            {CheckoutImpl.features.map((feat: any) => (
              <li
                key={feat.name}
                className={feat.comingSoon ? 'leading-normal mb-1' : ''}
              >
                {feat.name}
                {feat.comingSoon && (
                  <>
                    <br />
                    <span className="bg-slate-300 text-slate-700 text-xs font-bold px-2 py-1 rounded-md">
                      {feat.comingSoon}
                    </span>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default CheckoutPayment
