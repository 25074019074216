import React, { useEffect, useState } from 'react'
import { IoIosRemoveCircle } from 'react-icons/io'
import { Avatar, Modal, Text, Input, Button } from '../../../common'
import { useMutation } from '@apollo/client'
import { REMOVE_MEMBER } from '../../../graphQL/Mutations'
import { useWorkspaceContext } from '../../../contexts/workspace'

interface TeamListProps {
  members: any
  user: any
  impl: any
}
interface RemoveModalProps {
  name: string
  id: string
  impl: any
}
const RemoveModal = ({ name, id, impl }: RemoveModalProps): JSX.Element => {
  const { workspace }: any = useWorkspaceContext()
  const [show, setShow] = useState(false)
  const [data, setData] = useState(null)
  const [errors, setErrors] = useState<any>(null)
  const handleModal = () => {
    setShow(!show)
  }
  const [removeMember] = useMutation(REMOVE_MEMBER, {
    onCompleted: data => {
      // const filteredMembers = workspaceMemebers.filter(member => member.id !== memberForRemove.id)
      setData(data.removeMember)
      // handleWorkspace({ ...workspace, members: filteredMembers })
      setErrors(null)
      // setTimeout(navigate(`/workspace/${data.createWorkspace.id}`), 7000)
    },
    onError: err => {
      setErrors(err.message)
      // console.log(err)
    },
  })
  const handleDelteMember = () => {
    console.log(data)
    console.log(errors)
    removeMember({
      variables: {
        id: workspace.id,
        idUser: id,
      },
    })
    handleModal()
  }
  return (
    <Modal
      handleModal={handleModal}
      show={show}
      action={<IoIosRemoveCircle color="#DF4759" size="24px" />}
    >
      <div className="member-modal__content">
        <Text type="p" size={'17px'}>
          {impl.deleteMessage(name)}
        </Text>
        <Button onClick={handleDelteMember} size="base" color="danger">
          {impl.removeBtn}
        </Button>
      </div>
    </Modal>
  )
}

const TeamList = ({ members, user, impl }: TeamListProps): JSX.Element => {
  const [filteredMembers, setFilteredMembers] = useState<any>([])
  const membersCopy = [...members]
  const [search, setSearch] = useState('')
  const handleFilter = (value: string) => {
    setSearch(value)
    const filtered = membersCopy.filter(
      member =>
        member.name.toLowerCase().includes(value.toLowerCase()) ||
        member.lastName.toLowerCase().includes(value.toLowerCase()) ||
        member.email.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredMembers([...filtered])
  }
  useEffect(() => {
    setFilteredMembers(members)
  }, [members])
  return (
    <div>
      <Input
        placeHolder="🔍  Search member by name, last name or email"
        value={search}
        onChange={handleFilter}
        validationError={''}
        className="input-field"
      />
      <div className="member-list">
        {filteredMembers.map((member: any, key: number) => (
          <div className="member" key={key}>
            <div className="member__item">
              <Avatar
                size="45px"
                image={member.profilePhoto}
                className="member__item--avatar"
              />
              <div className="member__item--info">
                <Text type="p">
                  {member.name} {member.lastName}
                </Text>
                <Text type="p" color="#94A5C0">
                  {member.email}
                </Text>
              </div>
              <div className="member__item--action">
                {member.id !== user.id ? (
                  <RemoveModal
                    name={member.name}
                    id={member.id}
                    impl={impl.removeMember}
                  />
                ) : (
                  <Text
                    type="p"
                    color="#94A5C0"
                    fontStyle="italic"
                    weight="400"
                  >
                    ({impl.yourself})
                  </Text>
                )}
              </div>
            </div>
            <hr className="mt-1" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default TeamList
