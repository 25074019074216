import { gql } from '@apollo/client'

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      email
      token
      name
      lastName
      subscription
      messagingToken
      createdAt
    }
  }
`

export const SET_MESSAGING_TOKEN = gql`
  mutation setMessagingToken ($token: String!){
  setMessagingToken (token: $token){
    id
    messagingToken
  }
}
`

export const REGISTER_MUTATION = gql`
  mutation register(
    $name: String!
    $lastName: String!
    $email: String!
    $password: String!
    $confirmPassword: String!
    $referenceEmail: String
  ) {
    register(
      registerInput: {
        name: $name
        lastName: $lastName
        email: $email
        password: $password
        confirmPassword: $confirmPassword
        referenceEmail: $referenceEmail
      }
    ) {
      id
      name
      lastName
      token
      email
      profilePhoto
      subscription
      messagingToken
      createdAt
    }
  }
`

export const EDIT_USER_DATA = gql`
  mutation editUserData(
    $id: String!
    $name: String
    $lastName: String
    $profilePhoto: String
    $phoneNumber: String
  ) {
    editUserData(
      id: $id
      userInput: {
        name: $name
        lastName: $lastName
        profilePhoto: $profilePhoto
        phoneNumber: $phoneNumber
      }
    ) {
      id
      email
      name
      lastName
      profilePhoto
      phoneNumber
      messagingToken
      createdAt
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $password: String!
    $newPassword: String!
    $newConfirmPassword: String!
  ) {
    changePassword(
      password: $password
      newPassword: $newPassword
      newConfirmPassword: $newConfirmPassword
    ) {
      id
    }
  }
`

export const REQUEST_RESET_PASSWORD = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      email
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($newPassword: String!, $newConfirmPassword: String!) {
    resetPassword(
      newPassword: $newPassword
      newConfirmPassword: $newConfirmPassword
    ) {
      email
    }
  }
`

export const CREATE_WORKSPACE = gql`
  mutation createWorkspace(
    $name: String!
    $logo: String!
    $companyName: String!
    $description: String!
    $members: [String!]
  ) {
    createWorkspace(
      createWorkspaceInput: {
        name: $name
        logo: $logo
        companyName: $companyName
        description: $description
        members: $members
      }
    ) {
      id
      name
      logo
      companyName
      description
      subscribed
      maxMembers
      subscribedAt
      owner {
        id
        name
        lastName
        email
        profilePhoto
        messagingToken
      }
      members {
        id
        name
        lastName
        email
        profilePhoto
        messagingToken
      }
    }
  }
`
export const EDIT_WORKSPACE = gql`
  mutation editWorkspace(
    $id: String!
    $name: String
    $logo: String
    $companyName: String
    $description: String
  ) {
    editWorkspace(
      id: $id
      editWorkspaceInput: {
        name: $name
        logo: $logo
        companyName: $companyName
        description: $description
      }
    ) {
      name
      logo
      companyName
      description
    }
  }
`
export const DELETE_WORKSPACE = gql`
  mutation deleteWorkspace($id: String!) {
    deleteWorkspace(id: $id) {
      id
      name
      logo
    }
  }
`

export const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file) {
      url
    }
  }
`

export const ADD_MEMBER = gql`
  mutation addMember($id: String!, $email: String!) {
    addMember(id: $id, email: $email) {
      name
      companyName
      logo
      description
      subscribed
      maxMembers
      subscribedAt
      members {
        id
        name
        lastName
        email
        profilePhoto
        messagingToken
      }
    }
  }
`

export const REMOVE_MEMBER = gql`
  mutation removeMember($id: String!, $idUser: String!) {
    removeMember(id: $id, idUser: $idUser) {
      name
      companyName
    }
  }
`
export const ADD_MEMBER_BY_LINK = gql`
  mutation addMemberByLink($id: String!, $publicUrl: Boolean, $email: String!) {
    addMemberByLink(id: $id, publicUrl: $publicUrl, email: $email) {
      action
      message
      data
      error
    }
  }
`
export const SEND_CALL = gql`
  mutation sendCall($to: String!, $idWorkspace: String!) {
    sendCall(to: $to, idWorkspace: $idWorkspace) {
      id
      workspace
      room {
        id
        roomName
        roomPassword
        isStatic
      }
      from {
        id
        email
        name
        lastName
      }
      to {
        id
        email
        lastName
        name
        subscription
      }
      status
    }
  }
`
export const CHANGE_CALL_STATUS = gql`
  mutation changeCallStatus($idCall: String!, $status: String!) {
    changeCallStatus(idCall: $idCall, status: $status) {
      id
      workspace
      room {
        id
        roomName
        roomPassword
        isStatic
      }
      from {
        id
        email
        name
        lastName
      }
      to {
        id
        email
        lastName
        name
        subscription
      }
      createdAt
      status
    }
  }
`

export const SEND_EXISTING_CALL = gql`
  mutation sendExisitingCall(
    $idRoom: String!
    $idWorkspace: String!
    $to: String!
  ) {
    sendExisitingCall(idRoom: $idRoom, idWorkspace: $idWorkspace, to: $to) {
      id
      workspace
      room {
        id
        roomName
        roomPassword
        isStatic
      }
      from {
        id
        email
        name
        lastName
      }
      to {
        id
        email
        lastName
        name
        subscription
      }
      createdAt
      status
    }
  }
`

export const CHANGE_STATUS = gql`
  mutation changeStatus($idWorspace: String!) {
    changeStatus(idWorspace: $idWorspace) {
      user {
        id
      }
      workspace {
        id
      }
    }
  }
`
export const REMOVE_STATUS = gql`
  mutation removeStatus($idWorspace: String!) {
    removeStatus(idWorspace: $idWorspace) {
      user {
        id
      }
      workspace {
        id
      }
    }
  }
`
export const CREATE_PREFERENCE = gql`
  mutation createPreference(
    $quantity: String!
    $description: String!
    $price: String!
  ) {
    createPreference(
      quantity: $quantity
      description: $description
      price: $price
    )
  }
`
export const SUBSCRIBE_USER = gql`
  mutation subscribe {
    subscribe {
      email
      name
      lastName
      profilePhoto
      phoneNumber
      messagingToken
      subscription
    }
  }
`
export const SEND_CONTACT_FORM = gql`
  mutation contactForm(
    $name: String!
    $email: String!
    $summary: String!
    $message: String!
  ) {
    contactUsForm(
      name: $name
      email: $email
      summary: $summary
      message: $message
    )
  }
`
export const SEND_FEEDBACK = gql`
  mutation sendFeedback($message: String!) {
    sendFeedback(message: $message)
  }
`
export const CREATE_ROOM = gql`
  mutation createRoom(
    $name: String
    $logo: String
    $idWorkspace: String!
    $idUser: String!
    $isStatic: Boolean!
  ) {
    createRoom(
      name: $name
      logo: $logo
      idWorkspace: $idWorkspace
      idUser: $idUser
      isStatic: $isStatic
    ) {
      id
      roomName
      roomPassword
      participants {
        email
        id
        name
        profilePhoto
        lastName
        messagingToken
        createdAt
      }
      workspace {
        id
        name
        logo
      }
      isStatic
    }
  }
`
export const DELETE_ROOM = gql`
  mutation removeRoom($id: String!) {
    deleteRoom(id: $id) {
      name
      id
      logo
      roomName
      roomPassword
    }
  }
`

export const SEND_SUPPORT = gql`
  mutation sendSupport(
    $fullname: String!
    $email: String!
    $subject: String!
    $message: String!
  ) {
    sendSupport(
      fullname: $fullname
      email: $email
      subject: $subject
      message: $message
    )
  }
`

export const LEAVE_WORKSPACE = gql`
  mutation leaveWorkspace($idWorkspace: String!) {
    leaveWorkspace(idWorkspace: $idWorkspace) {
      id
      name
      logo
      companyName
      description
      subscribed
      maxMembers
      subscribedAt
      owner {
        id
        email
        name
        lastName
        profilePhoto
        messagingToken
        phoneNumber
      }
      members {
        id
        email
        name
        lastName
        profilePhoto
        messagingToken
        phoneNumber
      }
    }
  }
`

export const CIPHER_TEXT = gql`
  mutation cipherText($payload: String!) {
    cipherText(payload: $payload)
  }
`

export const DECIPHER_TEXT = gql`
  mutation decipherText($payload: String!) {
    decipherText(payload: $payload)
  }
`

export const SUBSCRIBE_WORKPSACE = gql`
mutation subscribeWorkspace(
  $idWorkspace: String,
  $maxMembers: Int!,
  $zyreboxName: String,
  $logo: String,
  $companyName: String
  ) {
  subscribeWorkspace(
    idWorkspace: $idWorkspace,
    maxMembers: $maxMembers,
    zyreboxName: $zyreboxName,
    logo: $logo
    companyName: $companyName
  ) {
    id
    name
    members{
      id
    }
    logo
    subscribed
    maxMembers
    subscribedAt
  }
}
`