import { useMutation } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import images from '../../assets/static/images'
import { Alert, Anchor, Button, Input, Loader, Text } from '../../common'
import { DECIPHER_TEXT, RESET_PASSWORD } from '../../graphQL/Mutations'
import { ResetPasswordStyled } from './ResetPassword.styles'
import { useLocation } from '@reach/router'
import ReactGA from 'react-ga'
import { useWindow } from '../../hooks/useWindow'
import { ResponsiveDefault } from '..'
import { validatePassword } from '../../helpers/validators'

const ResetPassword = () => {
  const [width] = useWindow()
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordError, setNewPasswordError] = useState('')
  const [newConfirmPassword, setNewConfirmPassword] = useState('')
  const [newConfirmPasswordError, setNewConfirmPasswordError] = useState('')
  const [errors, setErrors] = useState({})
  const [token, setToken] = useState('')
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const search =
    location.search.indexOf('?token=') > -1
      ? location.search.substring(
          location.search.indexOf('?token=') + 7,
          location.search.length
        )
      : null

  const [decipherText] = useMutation(DECIPHER_TEXT, {
    onCompleted: data => {
      const dataParsed = JSON.parse(data.decipherText)
      if (!dataParsed.token) {
        window.location.href = '/404'
      } else {
        setToken(dataParsed.token)
      }
    },
  })

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    update: (_, { data: { login } }) => {
      window.localStorage.removeItem('token')
      //dispatch({ type: 'LOGIN', payload: login })
      /*ReactGA.event({
        category: 'Inicio de sesion',
        action: `Nuevo Usuario`
      })*/
      window.location.href = '/login'
    },
    onError: (err: any) => {
      setErrors(err.graphQLErrors[0].extensions.errors)
      setTimeout(() => {
        setErrors({})
      }, 3000)
    },
  })

  const getToken = useCallback(async () => {
    await decipherText({
      variables: {
        payload: search,
      },
    })
  }, [search, decipherText])

  useEffect(() => {
    ReactGA.pageview('/forgot')
    getToken()
  }, [getToken])

  const handleNewPassword = (value: string) => {
    if (validatePassword(value)) {
      setNewPasswordError('')
    } else {
      setNewPasswordError(
        'Password must have at least 8 characters, an uppercase, a lowercase, a number and a special character @$!%*?&#+_:,-.'
      )
    }
    if (value === '') {
      setNewPasswordError('')
    }
    setNewPassword(value)
  }

  const handleNewConfirmPassword = (value: string) => {
    if (newPassword === value) {
      setNewConfirmPasswordError('')
    } else {
      setNewConfirmPasswordError('Passwords must match')
    }
    if (value === '') {
      setNewConfirmPasswordError('')
    }
    setNewConfirmPassword(value)
  }

  const handleSumbit = (e: any) => {
    e.preventDefault()
    window.localStorage.setItem('token', token)
    resetPassword({
      variables: {
        newPassword,
        newConfirmPassword,
      },
    })
  }
  const handleDisabled = () => {
    if (
      newPassword !== '' &&
      newConfirmPassword !== '' &&
      newPassword === newConfirmPassword
    ) {
      return false
    }
    return true
  }
  if (width < 600) {
    return <ResponsiveDefault />
  }
  return (
    <div>
      <ResetPasswordStyled backgroundImage={images.loginBackground}>
        <div className="main-content">
          <Anchor route="/landing" type="link">
            <img
              src={images.logoAuth}
              alt="logo"
              className="main-content__logo"
            />
          </Anchor>
          <Text type="h1" className="main-content__title">
            Reset Password
          </Text>
          <form onSubmit={handleSumbit} className="login-form">
            <Input
              value={newPassword}
              onChange={handleNewPassword}
              validationError={newPasswordError}
              label="New Password"
              placeHolder="Enter your new password"
              type="password"
              className="login-form__input"
            />
            <Input
              value={newConfirmPassword}
              onChange={handleNewConfirmPassword}
              validationError={newConfirmPasswordError}
              label="Confirm New Password"
              placeHolder="Enter your new password"
              type="password"
              className="login-form__input"
            />
            <Button
              size="base"
              color="primary"
              onClick={() => undefined}
              disabled={loading || handleDisabled()}
              block
              className="login-form__button"
            >
              {loading ? <Loader size="25px" border="3px" /> : 'Reset Password'}
            </Button>
          </form>
          {Object.keys(errors).length > 0 && (
            <div className="alert">
              {Object.values(errors).map((value: any) => (
                <Alert color="danger" key={value}>
                  <p>{value}</p>
                </Alert>
              ))}
            </div>
          )}
        </div>
        <div className="side-background" />
      </ResetPasswordStyled>
    </div>
  )
}

export default ResetPassword
