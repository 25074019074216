import React from 'react'

const ForgotPasswordImpl = {
  ForgotPasswordImpl: {
    title: 'Olvide mi Contraseña',
    emailnput: {
      label: 'Correo Electrónico',
      placeholder: 'Ingresa tu correo electrónico',
    },
    sendLinkBtn: 'Enviar enlace de recuperación',
    successMessage: (
      <>
        Te hemos enviado un correo. <br />
        Revisa tu bandeja de spam.
      </>
    ),
    loginBtn: 'Iniciar Sesión',
    haveAccount: '¿Ya tienes cuenta?',
  },
}

export default ForgotPasswordImpl
