import React, { useState } from 'react'
import images from '../../assets/static/images'
import { CreateZyreboxStyled, ImageLogoContainer } from './CreateZyrebox.styles'
import Button from '../../common/Button/Button'
import { FaCamera, FaChevronLeft } from 'react-icons/fa'
import { navigate } from '@reach/router'
import { Alert, Input, Loader, Text } from '../../common'
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_WORKSPACE, UPLOAD_FILE } from '../../graphQL/Mutations'
import { BsInfoCircle } from 'react-icons/bs'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { useAuthState } from '../../contexts/auth'
import { useWindow } from '../../hooks/useWindow'
import { ResponsiveDefault } from '..'
import { useWorkspaceContext } from '../../contexts/workspace'
import { message } from 'antd'
import { GET_WORKSPACES_QUERY } from '../../graphQL/Queries'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { uploadFileValidation } from '../../helpers/fileUpload'
import { validateEmail } from '../../helpers/validators'

const CreateZyrebox = () => {
  const [width] = useWindow()
  const { user } = useAuthState()
  const { darkMode }: any = useWorkspaceContext()
  const [logo, setLogo] = useState(images.logoCircular)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyNameError, setCompanyNameError] = useState('')
  const [description, setDescription] = useState('')
  const [descriptionError, setDescriptionError] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [emailValueError, setEmailValueError] = useState('')
  const [emailList, setEmailList] = useState<any>([])
  const [data, setData] = useState()
  const [limitReached, setLimitReached] = useState(false)

  const { loading: loadingWorkspaces } = useQuery(GET_WORKSPACES_QUERY, {
    onCompleted: data => {
      // setWorkspaces(data.getWorkspaces)
      const freeWorkspaces = data.getWorkspaces
        .filter(
          workspace => !workspace.subscribed && user.id === workspace.owner.id
        )
        .filter(workspace => workspace.subscribed === false)
      if (freeWorkspaces.length > 0) {
        setLimitReached(true)
      }
    },
    onError: err => message.error(err.message, 10),
  })

  const [uploadFileMutation, { loading: loadingUpload }] = useMutation(
    UPLOAD_FILE,
    {
      onCompleted: data => {
        setLogo(data.uploadFile.url)
      },
      onError: err => {
        // setErrors(err.message)
        message.error(err.message, 10)
      },
    }
  )
  const [createWorkspace, { loading }] = useMutation(CREATE_WORKSPACE, {
    onCompleted: data => {
      setData(data)
      setTimeout(() => {
        window.location.href = `/workspace/${data.createWorkspace.id}`
      }, 4000)
    },
    onError: err => {
      // setErrors(err.graphQLErrors[0].extensions.errors)
      setLimitReached(true)
      message.error(err.message, 10)
    },
  })
  const handleCreateZyrebox = () => {
    createWorkspace({
      variables: {
        name,
        logo,
        companyName,
        description,
        members: emailList,
      },
    })
  }
  const handleUploadFile = (e: any) => {
    const file = e.target.files[0]
    if (!file) return
    uploadFileValidation(() => {
      uploadFileMutation({ variables: { file: file } })
    }, file)
  }
  const handleName = (value: string) => {
    if (value !== '') {
      setNameError('')
    } else {
      setNameError('Please fill this field')
    }
    setName(value)
  }
  const handleCompanyName = (value: string) => {
    if (value !== '') {
      setCompanyNameError('')
    } else {
      setCompanyNameError('Please fill this field')
    }
    setCompanyName(value)
  }
  const handleDescription = (value: string) => {
    if (value !== '') {
      setDescriptionError('')
    } else {
      setDescriptionError('Please fill this field')
    }
    setDescription(value)
  }
  const handleEmailValue = (value: string) => {
    setEmailValue(value)
    // eslint-disable-next-line no-control-regex
    if (validateEmail(value)) {
      setEmailValueError('')
    } else {
      setEmailValueError('Please enter a valid email')
    }
    if (value === user.email) {
      setEmailValueError("You can't invite yourself")
    }
    if (value === '') {
      setEmailValueError('')
    }
  }
  const handleEmailValidaton = () => {
    if (emailValueError === '') {
      return false
    }
    return true
  }
  const handleAddMember = (event: any) => {
    event.preventDefault()
    const members = [...emailList, emailValue]
    setEmailList(members)
    setEmailValue('')
  }
  const handleDeleteEmail = (key: number) => {
    const members = [...emailList]
    members.splice(key, 1)
    setEmailList(members)
  }
  const handleDisabled = () => {
    if (name !== '' && companyName !== '' && description !== '') {
      return false
    }
    return true
  }
  if (width < 600) {
    return <ResponsiveDefault />
  }
  return (
    <div>
      <CreateZyreboxStyled
        backgroundImage={images.createBackground}
        darkMode={darkMode}
      >
        {loadingWorkspaces || loading || data ? (
          <div className="loader-contaier">
            <div className="loader-content">
              <Loader />
              <Text
                type="h2"
                weight="bold"
                color={darkMode ? '#F9FBFD' : '#232323'}
              >
                Creating your new Zyrebox...
              </Text>
            </div>
          </div>
        ) : (
          <>
            <div className="main-content">
              <Button
                onClick={() => {
                  navigate('/home')
                }}
                color="light"
                size="extraSmall"
              >
                <FaChevronLeft /> Back
              </Button>
              {limitReached ? (
                <>
                  <div className="h-full grid place-items-center">
                    <div className="flex flex-col text-center justify-center">
                      <p
                        className={`${
                          darkMode ? 'text-white' : 'text-black-500'
                        } text-2xl mb-3 font-bold`}
                      >
                        Ups!
                      </p>
                      <p
                        className={`${
                          darkMode ? 'text-white' : 'text-black-500'
                        } text-xl mb-3 font-bold`}
                      >
                        You reach out the limit of free spaces
                      </p>
                      <p
                        className={`${
                          darkMode ? 'text-black-200' : 'text-black-300'
                        } text-md mb-3`}
                      >
                        Subscribe your current free space or subscribe a new
                        one.
                      </p>
                      <div className="mb-4 mt-4">
                        <Button
                          onClick={() => {
                            navigate('/checkout')
                          }}
                          color="pink"
                          size="base"
                        >
                          I need more{' '}
                          <HiOutlineShoppingBag size={30} className="ml-2" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="main-content__header">
                    <Text
                      type="h2"
                      weight="bold"
                      color={darkMode ? '#F9FBFD' : '#232323'}
                    >
                      Create Zyrebox
                    </Text>
                    <div className="upload-image">
                      <ImageLogoContainer image={logo}>
                        <label htmlFor="file-input">
                          {loadingUpload ? (
                            <Loader size="25px" border="3px" />
                          ) : (
                            <>
                              <FaCamera color="#fff" />
                              <p>Change</p>
                            </>
                          )}
                        </label>
                        <input
                          id="file-input"
                          type="file"
                          onChange={handleUploadFile}
                        />
                      </ImageLogoContainer>
                    </div>
                  </div>
                  <div className="main-content__form">
                    <Input
                      value={name}
                      onChange={handleName}
                      placeHolder="Zyrebox Name"
                      label="Zyrebox Name *"
                      maxLength={21}
                      validationError={nameError}
                      backgroundColor="#F9FBFD"
                      labelColor={darkMode ? '#F9FBFD' : '#232323'}
                      className="main-content__form--input"
                    />
                    <Input
                      value={companyName}
                      onChange={handleCompanyName}
                      placeHolder="Name of your organization"
                      label="Company Name *"
                      maxLength={30}
                      validationError={companyNameError}
                      backgroundColor="#F9FBFD"
                      labelColor={darkMode ? '#F9FBFD' : '#232323'}
                      className="main-content__form--input"
                    />
                    <div className="main-content__form--chips">
                      <form onSubmit={e => handleAddMember(e)}>
                        <Input
                          value={emailValue}
                          onChange={handleEmailValue}
                          placeHolder="Write an email"
                          label="Add members by email"
                          maxLength={30}
                          validationError={emailValueError}
                          backgroundColor="#F9FBFD"
                          labelColor={darkMode ? '#F9FBFD' : '#232323'}
                          className="main-content__form--chips--input"
                        />
                        <div className="main-content__form--chips--button">
                          <Button
                            color="pink"
                            size="small"
                            outlined={!darkMode}
                            disabled={handleEmailValidaton()}
                          >
                            Add
                          </Button>
                        </div>
                      </form>
                      {emailList.length > 0 && (
                        <div className="main-content__form--chips--items">
                          {emailList.map((member: string, key: number) => (
                            <div key={key} className="email-item">
                              <p>{member} </p>
                              <div
                                onClick={() => handleDeleteEmail(key)}
                                className="email-item__icon"
                              >
                                <IoMdCloseCircleOutline size="17px" />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <Input
                      value={description}
                      onChange={handleDescription}
                      placeHolder="Say something about this Zyrebox or team"
                      label="Zyrewox Description *"
                      validationError={descriptionError}
                      textArea="text-area"
                      rows={7}
                      maxLength={210}
                      labelColor={darkMode ? '#F9FBFD' : '#232323'}
                      backgroundColor="#F9FBFD"
                      className="main-content__form--input"
                    />
                    <Alert color="secondary" className="alertCustom">
                      <div className="main-content__form--alert">
                        <BsInfoCircle size="25px" />
                        <Text color="#fff" type="p" align="justify">
                          <strong>IMPORTANT:</strong> Once you&apos;ve created
                          your Zyrebox you will see only those who had already
                          an account in Zyrebox.com, for the rest we will send
                          an invitation by email.
                        </Text>
                      </div>
                    </Alert>
                    <Button
                      onClick={handleCreateZyrebox}
                      size="large"
                      color={darkMode ? 'light' : 'primary'}
                      disabled={handleDisabled()}
                    >
                      Create
                    </Button>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        <div className="side-background" />
      </CreateZyreboxStyled>
      {/* <LanguageFloat /> */}
    </div>
  )
}

export default CreateZyrebox
