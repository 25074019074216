const CreateZyreboxImpl = {
  CreateZyreboxImpl: {
    loadingCreate: 'Creating your new Zyrebox...',
    backlabel: 'Back',
    title: 'Create Zyrebox',
    nameInput: {
      label: 'Zyrebox Name *',
      placeholder: 'Zyrebox Name',
    },
    companyInput: {
      label: 'Company Name *',
      placeholder: 'Name of your organization',
    },
    addByEmailInput: {
      label: 'Add members by email',
      placeholder: 'Write an email and press add',
    },
    addBtn: 'Add',
    descriptionInput: {
      label: 'Zyrewox Description *',
      placeholder: 'Say something about your team',
    },
    createAlert: (
      <>
        <strong>IMPORTANT:</strong> Once you&apos;ve created your Zyrebox you
        will see only those who had already an account in Zyrebox.com, for the
        rest we will send an invitation by email.
      </>
    ),
    createBtn: 'Create',
  },
}

export default CreateZyreboxImpl
