import React from 'react'
import { TextStyled } from './Text.styles'
type TextProps = {
  children: any
  type: string
  align?: string
  color?: string
  fontStyle?: string
  weight?: string | number
  size?: string | number
  display?: string
  lineHeight?: string
  onClick?: () => void
  className?: string
}
const Text = ({
  children,
  type,
  align,
  color,
  fontStyle,
  weight,
  size,
  display,
  lineHeight,
  onClick,
  className,
}: TextProps): JSX.Element => {
  return (
    <>
      <TextStyled
        type={type}
        align={align}
        color={color}
        fontStyle={fontStyle}
        weight={weight}
        size={size}
        display={display}
        onClick={onClick}
        lineHeight={lineHeight}
        className={className}
      >
        {children}
      </TextStyled>
    </>
  )
}

export default Text
