import React, { useEffect, useState } from 'react'
import { ZyreboxItemStyled } from './ZyreboxItem.styles'
import { useAuthState } from '../../../contexts/auth'
import { navigate } from '@reach/router'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { Avatar, Text, Tooltip } from '../../../common'

interface ZyreboxItemProps {
  workspace: any
}

const ZyreboxItem = ({ workspace }: ZyreboxItemProps): JSX.Element => {
  const { user } = useAuthState()
  const { handleWorkspace, darkMode }: any = useWorkspaceContext()
  const [moreMembers, setMoreMembers] = useState<number>()
  const [members, setMembers] = useState<any>([])

  useEffect(() => {
    // console.log(workspace.members.length)
    if (workspace.members.length >= 6) {
      setMoreMembers(workspace.members.length - 4)
      const newMemberlist = workspace.members.filter(
        (member: any, index: number) => {
          return index < 4
        }
      )
      setMembers(newMemberlist)
    } else {
      setMembers(workspace.members)
    }
  }, [workspace])
  const setWorkspace = () => {
    handleWorkspace(workspace)
    navigate(`/workspace/${workspace.id}`)
  }
  const makeName = (name: string) => {
    const arrName = name.split(' ')
    const newName = arrName.reduce(
      (prev: string, curr: string, index: number) => {
        let name = curr
        const length = curr.length
        if (length > 7) {
          name = `${curr.slice(0, 7)} - ${curr.slice(7, length)}`
        }
        if (index > 0) {
          name = prev + ' ' + name
        } else {
          name = prev + name
        }
        return name
      },
      ''
    )
    return newName
  }
  return (
    // <Link to={`/workspace/${workspace.id}`} style={{ textDecoration: 'none' }} onClick={setWorkspace}>
    <ZyreboxItemStyled darkMode={darkMode}>
      <div className="workspace-container">
        <Avatar
          image={workspace.logo}
          size="100px"
          onClick={setWorkspace}
          className="workspace-container-avatar"
        />
        <Text align="center" color="#fff" type="p" size="21px">
          {makeName(workspace.name)}
        </Text>
        {user.id === workspace.owner.id && (
          <div className="workspace-owner">
            <Tooltip position="top" content="Owner">
              <Avatar
                image={user.profilePhoto}
                size="34px"
                borderColor="#fff"
                borderSize="3px"
              />
            </Tooltip>
          </div>
        )}
        <div className="workspace-members">
          {members.length > 0 &&
            members.map((member: any, key: number) => (
              <Avatar image={member.profilePhoto} size="25px" key={key} />
            ))}
          {moreMembers && (
            <div className="workspace-moremembers">
              {moreMembers >= 99 ? '+99' : <>{'+' + moreMembers}</>}
            </div>
          )}
        </div>
      </div>
    </ZyreboxItemStyled>
  )
}

export default ZyreboxItem
