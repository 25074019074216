import styled from 'styled-components'

export const FeatureListStyled = styled.div`
  .pricing-content {
    &__pricing {
      display: grid;
      padding: 0px 10%;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      padding-bottom: -10px;

      @media (max-width: 788px) {
        grid-template-columns: repeat(1, 1fr);
      }
      &--item {
        box-shadow: 0px 4px 30px rgba(163, 163, 163, 0.25);
        background-color: #fff;
        border-radius: 7px;
        height: fit-content;
        .badge {
          max-width: 20%;
          margin: 25px auto;
          &__element {
            border-radius: 20px;
          }
        }
        .price {
          display: flex;
          justify-content: center;
          &__dollar {
            font-size: 19px;
            margin-top: 5px;
          }
          &__text {
            font-size: 37px;
            span {
              font-size: 19px;
              line-height: 25px;
            }
          }
        }
        .includes-text {
          margin: 15px 0px 25px 0px;
        }
        .dropdown-features {
          display: none;
          @media (max-width: 788px) {
            display: block;
          }
          &--text {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .features {
          margin: 0px 25px;
          p {
            margin-top: 16px;
            font-size: 17px;
            line-height: 22px;
            display: flex;
            padding-top: 10px;
            padding-left: 10px;
          }
        }
        @media (max-width: 788px) {
          .responsive-features {
            display: none;
          }
        }
        .cac-button {
          margin: 20px 0px;
          display: grid;
          place-items: center;
          button {
            max-width: 200px;
          }
        }
      }
    }
  }
`
