import styled from 'styled-components'

export const SubscribeCACStyled = styled.div`
  display: relative;
  background: #232323;
  margin-top: 150px;
  .skew-div {
    position: relative;
    top: -150px;
    margin-bottom: -110px;
    margin-top: 180px;
  }
  .subscribeCAC-content {
    display: absolute;
    margin-top: 140px;
    padding-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin: 20px 0px;
    }
    &__numbers {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(3, 1fr);
      @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  .section-image {
    background: linear-gradient(
        180deg,
        rgba(35, 35, 35, 0.75) 0%,
        rgba(35, 35, 35, 0.75) 85.12%
      ),
      url(${props => props.image}), #232323;
    width: 100%;
    height: 450px;
    background-size: cover;
    margin-bottom: 2.5rem;
    background-position-y: center;
    transform: skewY(-8deg);
    @media (max-width: 600px) {
      height: 650px;
    }
  }
  .subscribeText {
    position: absolute;
    top: -131px;
    width: 100%;
  }
  .subscribeCAC-buttontopro {
    background-color: #232323;
    padding-bottom: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 115px;
    @media (max-width: 780px) {
      padding: 80px 50px;
      margin-top: 0;
    }
    &_title {
      padding-bottom: 20px;
    }
    &_subtitle {
      padding-bottom: 20px;
    }
  }
`
