import { Tooltip } from 'antd'
import React from 'react'
import { AppItemType } from '../../types/workspaceTypes'
import { AppTriggerItemStyled } from './AppTriggerItem.styles'

interface AppTriggerItemProps {
  app: AppItemType
}

const AppTriggerItem = ({ app }: AppTriggerItemProps) => {
  return (
    <Tooltip placement="top" title={app.blocked ? 'Comming Soon' : app.name}>
      <AppTriggerItemStyled blocked={app.blocked}>
        <div
          className={`app-item ${app.activeTrigger && 'active'}`}
          onClick={() => !app.blocked && app.openFunction()}
        >
          {typeof app.src === 'string' ? (
            <img src={app.src} alt={app.name} />
          ) : (
            app.src
          )}
        </div>
      </AppTriggerItemStyled>
    </Tooltip>
  )
}

export default AppTriggerItem
