import { message } from 'antd'

type ImageTypes = 'image/png' | 'image/jpeg' | 'image/jpg'

function isOfImageTypes(keyInput: string): keyInput is ImageTypes {
  return ['image/png', 'image/jpeg', 'image/jpg'].includes(keyInput)
}

export const uploadFileValidation = (callback, file) => {
  if (isOfImageTypes(file.type)) {
    if (file.size < 150000) {
      callback()
    } else {
      message.error('File size exceeds 150 kb', 5)
    }
  } else {
    message.error('File type not supported', 5)
  }
}
