import React, { useState } from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { Button, Input, Loader, Text, Tooltip } from '../../../common'
import { useAuthState } from '../../../contexts/auth'
import { useWorkspaceContext } from '../../../contexts/workspace'
import TeamList from '../MyTeam/TeamList'
import { useMutation } from '@apollo/client'
import { ADD_MEMBER } from '../../../graphQL/Mutations'
import { MyTeamStyled } from '../MyTeam/MyTeam.styles'
import { message } from 'antd'
import { Link } from '@reach/router'

interface MyTeamProps {
  impl: any
}

const MyTeam = ({ impl }: MyTeamProps): JSX.Element => {
  const { user } = useAuthState()
  const { darkMode, workspace }: any = useWorkspaceContext()
  const [email, setEmail] = useState('')
  const [inputError, setInputError] = useState('')
  const [addMember, { loading }] = useMutation(ADD_MEMBER, {
    onCompleted: () => {
      message.success(email + ' was succesfully added to your team', 10)
      setEmail('')
    },
    onError: err => {
      const errorMessage = err.message.split('Error: ')[2]
      if (
        errorMessage.includes(
          "don't have an account, an invitation have been sent"
        )
      ) {
        message.info(errorMessage, 10)
      } else {
        message.error(errorMessage, 10)
      }
    },
  })
  const handleSubmit = (e: any) => {
    e.preventDefault()
    addMember({
      variables: {
        id: workspace.id,
        email: email,
      },
    })
  }
  // (?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])
  const handleInput = (value: string) => {
    // eslint-disable-next-line no-control-regex
    if (
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        value
      )
    ) {
      setInputError('')
    } else {
      setInputError('Please enter a valid email')
    }
    if (value === user.email) {
      setInputError("You can't invite yourself")
    }
    if (value === '') {
      setInputError('')
    }
    setEmail(value)
  }
  return (
    <div>
      <MyTeamStyled>
        <Text color={darkMode ? '#fff' : '#161C2D'} type="h2">
          {impl.title}
        </Text>
        <Text color="#95A7C1" type="h4">
          {impl.subtitle}
        </Text>
        <div className="card">
          <div className="title-section">
            <div className="title-section__text">
              <Text color="#161C2D" type="h4">
                {impl.addMemberInput.label}{' '}
              </Text>
              <Tooltip
                position="top"
                content={
                  <Text color="#fff" type="p" size="12px" align="justify">
                    {impl.tooltipInvite}
                  </Text>
                }
              >
                <BsInfoCircle size="15px" />
              </Tooltip>
            </div>
          </div>
          <form className="team-form" onSubmit={handleSubmit}>
            <Input
              placeHolder={impl.addMemberInput.placeholder}
              value={email}
              onChange={handleInput}
              validationError={inputError}
              className="input-field"
              disabled={loading}
            />
            <div className="invite-button">
              <Button
                onClick={() => {}}
                color="primary"
                size="small"
                disabled={
                  inputError !== '' ||
                  email === '' ||
                  loading ||
                  workspace.members.length >= workspace.maxMembers
                }
              >
                {loading ? <Loader size="5px" border="5px" /> : impl.inviteBtn}
              </Button>
            </div>
          </form>
          <br />
          <div className="title-section">
            <div className="title-section__text">
              <Text color="#161C2D" type="h4">
                {impl.filterMemberInput.label}{' '}
              </Text>
              <Tooltip
                position="top"
                content={
                  <Text color="#fff" type="p" size="12px" align="justify">
                    {impl.tooltipSearch}
                  </Text>
                }
              >
                <BsInfoCircle size="15px" />
              </Tooltip>
            </div>
            <div className="">
              <Text type="p" color="#94A5C0" fontStyle="italic" weight="400">
                {impl.quantity} {workspace.members.length} /{' '}
                {workspace.maxMembers}
              </Text>
              {workspace.members.length >= workspace.maxMembers && (
                <Link
                  className="p-1 mr-2 border border-pink-500 text-pink-400 hover:bg-pink-400 hover:text-white cursor-pointer rounded"
                  to={workspace.subscribed ? '/contact' : '/checkout'}
                >
                  Get more space
                </Link>
              )}
            </div>
          </div>
          <TeamList
            members={workspace.members}
            user={user}
            impl={impl.teamListComponent}
          />
        </div>
      </MyTeamStyled>
    </div>
  )
}

export default MyTeam
