import React, { useState } from 'react'
import { Badge, Button, Loader, Modal, Text } from '../../../common'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { AdvanceSettingsStyled } from './AdvanceSettings.styles'
import { Link, navigate } from '@reach/router'
import { FaArrowRight } from 'react-icons/fa'
import { useMutation } from '@apollo/client'
import { DELETE_WORKSPACE } from '../../../graphQL/Mutations'

interface AdvanceSettingsProps {
  impl: any
}

const AdvanceSettings = ({ impl }: AdvanceSettingsProps) => {
  const { darkMode, workspace }: any = useWorkspaceContext()
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState()
  const [deleteWorkspace, { loading: loadingDelete }] = useMutation(
    DELETE_WORKSPACE,
    {
      onCompleted: (data: any) => {
        setData(data)
        setTimeout(() => {
          window.location.href = '/home'
        }, 3000)
      },
    }
  )
  const handleModal = () => {
    setShowModal(!showModal)
  }
  const handleDeleteWorkspace = () => {
    deleteWorkspace({ variables: { id: workspace.id } })
  }
  return (
    <div>
      <AdvanceSettingsStyled>
        <Text color={darkMode ? '#fff' : '#161C2D'} type="h2">
          {impl.title}
        </Text>
        <Text color="#95A7C1" type="h4">
          {impl.subtitle}
        </Text>
        <div className="card">
          <div className="title-section">
            <Text color="#161C2D" type="h3" weight="bold">
              {impl.deleteTitle}
            </Text>
            <Text color="#95A7C1" type="p" align="justify">
              {impl.deleteText}
            </Text>
          </div>
          <div className="advance-delete">
            <Modal
              className="advance-delete__action"
              show={showModal}
              handleModal={handleModal}
              action={
                <Button onClick={handleModal} color="danger" size="base">
                  {impl.deleteBtn}
                </Button>
              }
            >
              <div className="advance-modal-content">
                <Text color="#161C2D" type="h4" align="center">
                  {impl.deleteModalText(workspace.name)}
                </Text>
                <Button
                  onClick={handleDeleteWorkspace}
                  color="danger"
                  size="base"
                  disabled={loadingDelete}
                >
                  {data || loadingDelete ? (
                    <Loader size="25px" border="3px" color="#ffa4a4" />
                  ) : (
                    `${impl.deleteModalBtn}`
                  )}
                </Button>
              </div>
            </Modal>
          </div>
          <div className="title-section">
            <div className="title-section__text">
              <Text color="#161C2D" type="h4" weight="bold">
                {impl.planTitle}
              </Text>
            </div>
            <div className="improve-plan">
              <div className="improve-plan__current">
                <Text type="p" color="#95A7C1">
                  {impl.planLabel}
                </Text>
                <div className="improve-plan__current--badge">
                  <Badge color={'primary'} fontSize="17px">
                    {workspace.subscribed
                      ? `${impl.planBadgePro}`
                      : `${impl.planBadgeBasic}`}
                  </Badge>
                </div>
              </div>
              {workspace.subscribed ? (
                <div>
                  <Text fontStyle="italic" type="p" color="#95A7C1">
                    {impl.planProMessage}
                  </Text>
                  <Link
                    className="p-1 mr-2 border border-pink-500 text-pink-400 hover:bg-pink-400 hover:text-white cursor-pointer rounded"
                    to={'/contact'}
                  >
                    🤗 Upgrade my plan
                  </Link>
                </div>
              ) : (
                <Button
                  color="primary"
                  onClick={() => navigate('/subscription')}
                  size="small"
                >
                  {impl.planBtn} <FaArrowRight className="ml-2" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </AdvanceSettingsStyled>
    </div>
  )
}

export default AdvanceSettings
