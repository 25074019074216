import { navigate } from '@reach/router'
import { Avatar, Button, Text } from '../../../common'
import { useAuthState } from '../../../contexts/auth'
import {
  ZyreboxInfoContainer,
  CardHeader,
  CardContentStyles,
  CardBody,
} from './Zyreboxinfo.styles'
import { GoSignOut } from 'react-icons/go'
import { RiSettingsFill } from 'react-icons/ri'
import { Popover, Tooltip } from 'antd'

interface ZyreboxInfoProps {
  image: string
  workspaceName?: string
  workspaceId?: string
  companyName?: string
  description?: string
  owner?: any
  impl: any
  leaveWorkspace: () => void
}

const CardContent = ({
  image,
  workspaceName,
  workspaceId,
  companyName,
  description,
  owner,
  leaveWorkspace,
  impl: {
    leaderLabel,
    teamLabel,
    companyLabel,
    descriptionLabel,
    configLabel,
    leaveLabel,
  },
}: any) => {
  const { user } = useAuthState()
  const handleNavigate = () => {
    navigate(`/workspace/edit/${workspaceId}`)
  }
  return (
    <>
      {owner && (
        <CardContentStyles>
          <CardHeader>
            <Avatar image={owner.profilePhoto} size="45px" />
            <div className="header-content">
              <Text type="p" weight="semibold" size="12px" lineHeight="14px">
                {leaderLabel}
              </Text>
              <Text
                type="p"
                color="rgba(134, 154, 184, 1)"
                weight="normal"
                size="12px"
                lineHeight="14px"
              >{`${owner.name} ${owner.lastName}`}</Text>
            </div>
            <img src={image} alt={workspaceName} />
          </CardHeader>
          <CardBody>
            <br />
            <Text type="p" size="12px" display="inline" lineHeight="15px">
              {teamLabel}
            </Text>
            <Text
              type="p"
              size="12px"
              display="inline"
              color="rgba(134, 154, 184, 1)"
              lineHeight="15px"
            >
              {workspaceName}
            </Text>
            <br />
            <Text type="p" size="12px" display="inline" lineHeight="15px">
              {companyLabel}
            </Text>
            <Text
              type="p"
              size="12px"
              display="inline"
              color="rgba(134, 154, 184, 1)"
              lineHeight="15px"
            >
              {companyName}
            </Text>
            <br />
            <Text type="p" size="12px" display="inline" lineHeight="15px">
              {descriptionLabel}{' '}
            </Text>
            <Text
              type="p"
              size="12px"
              color="rgba(134, 154, 184, 1)"
              lineHeight="15px"
              align="justify"
            >
              {description}
            </Text>
            {user.id === owner.id ? (
              <>
                <hr className=" mt-2 mb-2" />
                <div className="button-container">
                  <Button
                    onClick={handleNavigate}
                    color="primary"
                    size="extraSmall"
                  >
                    {configLabel} <RiSettingsFill />
                  </Button>
                </div>
              </>
            ) : (
              <>
                <hr />
                <div className="button-container">
                  <Button
                    onClick={leaveWorkspace}
                    color="danger"
                    size="extraSmall"
                    className="leave-button"
                  >
                    {leaveLabel} <GoSignOut />
                  </Button>
                </div>
              </>
            )}
          </CardBody>
        </CardContentStyles>
      )}
    </>
  )
}
const ZyreboxInfo = ({
  image,
  workspaceName,
  workspaceId,
  companyName,
  description,
  owner,
  leaveWorkspace,
  impl,
}: ZyreboxInfoProps): JSX.Element => {
  return (
    <>
      <ZyreboxInfoContainer>
        <Popover
          trigger="click"
          placement="right"
          content={
            <CardContent
              owner={owner}
              image={image}
              workspaceName={workspaceName}
              workspaceId={workspaceId}
              companyName={companyName}
              description={description}
              leaveWorkspace={leaveWorkspace}
              impl={impl}
            />
          }
        >
          <Tooltip placement="right" title={'Zyrebox Info'}>
            <div>
              <Avatar image={image} size="45px" />
            </div>
          </Tooltip>
        </Popover>
      </ZyreboxInfoContainer>
    </>
  )
}

export default ZyreboxInfo
