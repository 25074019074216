import React, { useState } from 'react'
import { FormsContainer } from './Forms.styles'
import { Input, Button, Loader, Text } from './../../../common'
import { useMutation } from '@apollo/client'
import { SEND_CONTACT_FORM } from '../../../graphQL/Mutations'
import { Alert } from 'antd'
interface implFormsProps {
  implForms: any
}

const Forms = ({
  implForms: {
    alertgood,
    alertbad,
    maintitle,
    subtitle,
    nameform,
    nameplaceholder,
    emailform,
    emailplaceholder,
    summaryform,
    summaryplaceholder,
    messageform,
    messageplaceholder,
    sendform,
  },
}: implFormsProps) => {
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [summary, setSummary] = useState('')
  const [summaryError, setSummaryError] = useState('')
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const [sendContactForm, { loading }] = useMutation(SEND_CONTACT_FORM, {
    onCompleted: data => {
      console.log('[res]', data)
      setSuccess(true)
      setName('')
      setEmail('')
      setSummary('')
      setMessage('')
      setTimeout(() => {
        setSuccess(false)
      }, 10000)
    },
    onError: err => {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 10000)
      console.log(err)
    },
  })

  const handleSubmit = (e: any) => {
    e.preventDefault()
    sendContactForm({
      variables: {
        name,
        email,
        summary,
        message,
      },
    })
  }

  const handleDisable = () => {
    if (
      name !== '' &&
      email !== '' &&
      summary !== '' &&
      message !== '' &&
      nameError === '' &&
      emailError === '' &&
      summaryError === '' &&
      messageError === ''
    ) {
      return false
    }
    if (loading) {
      return true
    }
    return true
  }

  const handleName = (value: string) => {
    if (value !== '') {
      setNameError('')
    } else {
      setNameError('Please fill this field')
    }
    setName(value)
  }
  const handleEmail = (value: string) => {
    // eslint-disable-next-line no-control-regex
    if (
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        value
      )
    ) {
      setEmailError('')
    } else {
      setEmailError('Please enter a valid email')
    }
    setEmail(value)
  }
  const handlesummary = (value: string) => {
    if (value !== '') {
      setSummaryError('')
    } else {
      setSummaryError('Please fill this field')
    }
    setSummary(value)
  }
  const handleMessage = (value: string) => {
    if (value !== '') {
      setMessageError('')
    } else {
      setMessageError('Please fill this field')
    }
    setMessage(value)
  }
  return (
    <div>
      <FormsContainer>
        <div className="relative top-96">
          {success && (
            <Alert
              message={alertgood}
              type="success"
              closable
              className="bg-green-500"
            />
          )}
          {error && <Alert message={alertbad} type="error" closable />}
        </div>
        <div className="forms-header">
          <Text
            type="h1"
            color="#fff"
            align="center"
            className="forms-header__title"
            size="55px"
          >
            {maintitle}
          </Text>
          <p className="forms-description">{subtitle}</p>
        </div>
        <form className="forms-container" onSubmit={handleSubmit}>
          <div>
            {/*  <p className='forms-container__title'>Name and Surname</p> */}
            <Input
              value={name}
              onChange={handleName}
              placeHolder={nameplaceholder}
              validationError={nameError}
              className="forms-container__input"
              label={nameform}
            />
          </div>
          <div>
            {/*  <p className='forms-container__title'>Email</p> */}
            <Input
              value={email}
              onChange={handleEmail}
              placeHolder={emailplaceholder}
              validationError={emailError}
              className="forms-container__input"
              label={emailform}
            />
          </div>
          <div>
            {/*  <p className='forms-container__title'>Summary</p> */}
            <Input
              value={summary}
              onChange={handlesummary}
              placeHolder={summaryplaceholder}
              validationError={summaryError}
              className="forms-container__input"
              label={summaryform}
            />
          </div>
          <div>
            {/*   <p className='forms-container__title'>Message</p> */}
            <Input
              value={message}
              onChange={handleMessage}
              placeHolder={messageplaceholder}
              validationError={messageError}
              textArea="text-area"
              rows={7}
              maxLength={210}
              className="forms-container__input"
              label={messageform}
            />
          </div>
          <div className="forms-container__submit">
            {loading && <Loader size="25px" border="5px" />}
            <Button
              onClick={() => {}}
              size="base"
              color="primary"
              disabled={handleDisable() || loading}
              className="forms-container__submit--button ml-2"
            >
              {sendform}
            </Button>
          </div>
        </form>
      </FormsContainer>
    </div>
  )
}

export default Forms
