import styled, { css } from 'styled-components'
import images from '../../../assets/static/images'

export const ZyreboxItemStyled = styled.div`
  /* background-image: url(${props =>
    props.backgroundImage || images.logoCircular}) */
  display: flex;
  align-items: center;
  margin: 0px 25px;
  height: 100%;
  .workspace-container {
    position: relative;
    height: 200px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &-avatar {
      border: 5px solid #f9fbfd;
      /* box-shadow: 0px 1px 15px 0px rgba(250,39,169,0.4);
      -webkit-box-shadow: 0px 1px 15px 0px rgba(250,39,169,0.4);
      -moz-box-shadow: 0px 1px 15px 0px rgba(250,39,169,0.4); */
      box-shadow: 0px 1px 15px 0px rgba(179, 179, 179, 0.7);
      -webkit-box-shadow: 0px 1px 15px 0px rgba(179, 179, 179, 0.7);
      -moz-box-shadow: 0px 1px 15px 0px rgba(179, 179, 179, 0.7);
      ${props => props.darkMode && css``}
    }
    p {
      font-weight: 300;
      color: ${props => (props.darkMode ? '#5C6067' : '#232323')};
    }
    .workspace-members {
      display: none;
      position: absolute;
    }
    &:hover {
      transform: scale(1.2);
      margin: 0px 15px;
      p {
        font-weight: 500;
        color: ${props => (props.darkMode ? '#F9FBFD' : '#232323')};
      }
      .workspace-members {
        padding: 10px 9px;
        background-color: #f9fbfd;
        ${props =>
          !props.darkMode &&
          css`
            box-shadow: 0px 1px 11px 0px rgba(179, 179, 179, 0.5);
            -webkit-box-shadow: 0px 1px 11px 0px rgba(179, 179, 179, 0.5);
            -moz-box-shadow: 0px 1px 11px 0px rgba(179, 179, 179, 0.5);
          `}
        display: flex;
        justify-content: center;
        min-width: 130px;
        max-width: 130px;
        top: -10%;
        right: -20px;
        border-radius: 3px;

        @media (min-height: 600px) {
          top: -20%;
        }
        @media (min-height: 800px) {
          top: -32%;
        }
        @media (min-height: 900px) {
          top: -32%;
        }
        @media (min-height: 950px) {
          top: -32%;
        }
        &::before {
          content: '';
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #fff;
          position: absolute;
          left: 45%;
          top: 100%;
          z-index: 1;
        }
      }
    }
    p {
      max-width: 110px;
      margin-top: 20px;
    }
    .workspace-owner {
      position: absolute;
      top: -7%;
      right: 0;
    }
    .workspace-moremembers {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: grid;
      place-items: center;
      box-shadow: 5px 6px 5px -4px rgba(143, 143, 143, 0.75);
      -webkit-box-shadow: 5px 6px 5px -4px rgba(143, 143, 143, 0.75);
      -moz-box-shadow: 5px 6px 5px -4px rgba(143, 143, 143, 0.75);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #161c2d;
    }
  }
`
