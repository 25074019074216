/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-children-prop */
import React, { useState } from 'react'
import { Tooltip } from 'antd'
import { MdVideoCall, MdVideocam } from 'react-icons/md'
import { Avatar } from '../../../common'
import { useMutation } from '@apollo/client'
import { SEND_CALL, SEND_EXISTING_CALL } from '../../../graphQL/Mutations'
import ReactGA from 'react-ga'
import { useAuthState } from '../../../contexts/auth'
import { useWorkspaceContext } from '../../../contexts/workspace'
// import { IoSend } from 'react-icons/io5'
import InputEmoji from 'react-input-emoji'
import {
  addDoc,
  QueryDocumentSnapshot,
  serverTimestamp,
  DocumentData,
  CollectionReference,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
} from 'firebase/firestore'
import { db } from '../../../firebase/init-firebase'
import { MemberType } from '../../../types/workspaceTypes'
import moment from 'moment'

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { _notificationService } from '../../../services/NotificationServices'
import { FaRegCopy } from 'react-icons/fa'
import { RiWhatsappFill } from 'react-icons/ri'

type ChatMessage = {
  readBy: string[]
  text: string
  sender: MemberType
  createdAt: number
}

type PopOverContentProps = {
  member: MemberType
  image: string
  workspaceName: string
  channel: QueryDocumentSnapshot<DocumentData> | null
  channelsColRef: CollectionReference<DocumentData>
  channelId: string
  readMessages: () => Promise<void>
  setChannel: React.Dispatch<
    React.SetStateAction<QueryDocumentSnapshot<DocumentData> | null>
  >
  impl: any
}

const PopOverContent = ({
  member,
  channel,
  channelsColRef,
  channelId,
  setChannel,
  readMessages,
  // image,
  // workspaceName,
  impl,
}: PopOverContentProps): JSX.Element => {
  const { user } = useAuthState()
  const { workspace, room, darkMode }: any = useWorkspaceContext()

  // to be use when the channel is created
  const [messageTextData, setMessageTextData] = useState('')

  const [sendCall] = useMutation(SEND_CALL, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCompleted: data => {
      // console.log(data)
      // _______Anaylicits
      ReactGA.event({
        category: 'Llamadas',
        action: `[Llamada]: ${user.email} a ${member.email}`,
      })
      // _____
    },
    onError: err => {
      console.log(err)
    },
  })
  const [sendExisitingCall] = useMutation(SEND_EXISTING_CALL, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCompleted: data => {},
    onError: err => {
      console.log(err)
    },
  })
  const handleSendExistCall = (id: string) => {
    sendExisitingCall({
      variables: {
        idRoom: room.id,
        idWorkspace: workspace.id,
        to: id,
      },
    })
  }
  const handleSendCall = () => {
    if (room.active === false) {
      // sendExistingCall
      sendCall({ variables: { to: member.id, idWorkspace: workspace.id } })
    } else {
      handleSendExistCall(member.id)
    }
  }
  const notifyMessage = (message: ChatMessage) => {
    _notificationService.notifyMessage(
      [...member.messagingToken!],
      `New message from ${user.name.split(' ')[0]}`,
      message.text,
      user.profilePhoto
    )
  }
  async function sendMessageOnFirebase(
    message: ChatMessage,
    channelId?: string
  ) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const docRef = doc(db, 'channels', channel ? channel.id : channelId!)
    await updateDoc(docRef, {
      messages: channel ? [message, ...channel?.data().messages] : [message],
    })
    // pass an array of all the users on this conversation
    return notifyMessage(message)
  }

  const createNewChannel = async (channelId, message) => {
    const createdChannel = await addDoc(channelsColRef, {
      channelId: channelId,
      members: [user, member],
      zyreboxId: workspace.id,
      createdAt: serverTimestamp(),
      messages: [],
    })
    const channelQuery = query(
      channelsColRef,
      where('channelId', '==', channelId)
    )
    const channels = await getDocs(channelQuery)
    if (channels.docs.length > 0) {
      setChannel(channels.docs[0])
    } else {
      setChannel(null)
    }
    const dateNow = new Date()
    await sendMessageOnFirebase(
      {
        readBy: [user.email],
        text: message,
        sender: user,
        createdAt: dateNow.getTime(),
      },
      createdChannel.id
    )
  }

  const handleMessageInput = async text => {
    if (text.trim() === '') return
    // ask if channel exist
    if (channel) {
      // ask if channel have messages
      const dateNow = new Date()
      await sendMessageOnFirebase({
        readBy: [user.email],
        text: text,
        sender: user,
        createdAt: dateNow.getTime(),
      })
    } else {
      await createNewChannel(channelId, text)
    }
  }
  const copyEmailToClipBoard = () => {
    const copyText: any = document.getElementById('emailInput' + member.email)
    copyText?.select()
    copyText?.setSelectionRange(0, 99999)
    navigator.clipboard.writeText(copyText?.value)
  }
  const openWhatsappChat = () => {
    if (member.phoneNumber) {
      window.open(`https://wa.me/${member.phoneNumber}`, '_blank')?.focus()
    }
  }
  return (
    <div>
      <div className="min-w-full w-96 min-h-40 flex flex-col justify-between">
        <div className="flex justify-between">
          <div className="flex justify-between">
            <Avatar image={member.profilePhoto} size="45px" />
            <div className="ml-2">
              <p className="text-base w-full">{`${member.name} ${member.lastName}`}</p>
              <div className="flex">
                <input
                  value={member.email}
                  id={'emailInput' + member.email}
                  className="hidden"
                  readOnly
                />
                <Tooltip placement="top" title={impl.actions.mail(member.name)}>
                  <a
                    className="text-sm hover:text-pink-400 text-gray-400"
                    href={`mailto:${member.email}`}
                  >
                    {member.email}
                  </a>
                </Tooltip>
                <Tooltip placement="top" title={impl.actions.copy()}>
                  <FaRegCopy
                    size="18px"
                    onClick={copyEmailToClipBoard}
                    className={`ml-2 text-gray-300 cursor-pointer hover:text-pink-300 focus:text-black-500 active:text-black-400`}
                  />
                </Tooltip>
                <Tooltip
                  placement="top"
                  title={
                    member.phoneNumber
                      ? impl.actions.whatsapp(member.name)
                      : impl.actions.noWhatsapp(member.name)
                  }
                >
                  <RiWhatsappFill
                    size="22px"
                    onClick={openWhatsappChat}
                    className={`ml-2 text-gray-300 cursor-pointer ${
                      member.phoneNumber
                        ? 'hover:text-green-500'
                        : 'hover:text-gray-300'
                    } focus:text-black-300 active:text-black-300`}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <Tooltip
            placement="top"
            title={
              <p className="text-sm">
                {room.active === false
                  ? impl.actions.call(member.name)
                  : impl.actions.addToCall(member.name)}
              </p>
            }
          >
            <div
              onClick={handleSendCall}
              className="drop-shadow-lg w-17 h-17 bg-slate-300 p-2 rounded cursor-pointer hover:bg-slate-400"
            >
              {room.active === false ? (
                <MdVideocam href="#" size="32px" color="#506690" />
              ) : (
                <MdVideoCall href="#" size="32px" color="#506690" />
              )}
            </div>
          </Tooltip>
        </div>
        <div>
          <div className="border-b py-2">
            <p className="text-base">Hey there! 👋🏻</p>
          </div>
          <div onClick={readMessages}>
            <div className="overflow-y-scroll max-h-96 py-1 flex flex-col-reverse">
              {channel?.data().messages.map((message, key) => (
                <div key={key} className="grid grid-cols-12 gap-5 mt-4 w-full">
                  <Avatar
                    image={message.sender.profilePhoto}
                    size="30px"
                    className="mr-2 col-auto"
                  />
                  <div className="col-span-11">
                    <div className="flex justify-between w-full">
                      <p className="text-sm text-black-300">
                        {message.sender.name}
                      </p>
                      <p className="text-sm text-black-300">
                        {moment(message.createdAt).fromNow()}
                      </p>
                    </div>
                    <ReactMarkdown
                      children={message.text}
                      remarkPlugins={[remarkGfm]}
                      components={{
                        code({ inline, className, children, ...props }) {
                          const match = /language-(\w+)/.exec(className || '')
                          return !inline && match ? (
                            <SyntaxHighlighter
                              children={String(children).replace(/\n$/, '')}
                              style={dark}
                              language={match[1]}
                              PreTag="div"
                              {...props}
                            />
                          ) : (
                            <code className={className} {...props}>
                              {children}
                            </code>
                          )
                        },
                      }}
                    />
                    {/* <p className="text-md">{message.text}</p> */}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-between items-center">
              <InputEmoji
                value={messageTextData}
                onChange={setMessageTextData}
                cleanOnEnter
                theme={darkMode ? 'dark' : 'light'}
                onEnter={handleMessageInput}
                placeholder={`Say something to ${member.name.split(' ')[0]}`}
                onFocus={readMessages}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopOverContent
