import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  Sidebar,
  AccountConfig,
  Security,
  // Billing,
} from '../../components/ConfigZyrebox'
import { ConfigZyreboxStyled } from './ConfigZyrebox.styles'
import {
  FaChevronLeft,
  FaRegUserCircle,
  // FaMoneyBillAlt,
  FaKey,
} from 'react-icons/fa'
import { useWorkspaceContext } from '../../contexts/workspace'
import { useLocation } from '@reach/router'
import { navigate } from '@reach/router'
import { GET_CURREN_USER } from '../../graphQL/Queries'
import { EDIT_USER_DATA, CHANGE_PASSWORD } from '../../graphQL/Mutations'
import { useAuthDispatch, useAuthState } from '../../contexts/auth'
import { Loader, Button, Container, LanguageFloat } from '../../common'
import { useWindow } from '../../hooks/useWindow'
import { ResponsiveDefault } from '..'
import { message } from 'antd'

const ConfigZyrebox = ({ idWorkspace }: any) => {
  const [width] = useWindow()
  const [currentUserData, setCurrentUserData] = useState<any>({})
  const { user } = useAuthState()
  const [currentIndex, setCurrentindex] = useState(0)
  const location = useLocation()
  const search = location.search
  const {
    darkMode,
    impl: { ConfigZyreboxImpl },
  }: any = useWorkspaceContext()

  const dispatch = useAuthDispatch()

  const [values, setValues] = useState(user)

  const { loading, error } = useQuery(GET_CURREN_USER, {
    onCompleted: data => {
      const userTemp = { ...data.getUser }
      setCurrentUserData(userTemp)
      // updateUserDate(userTemp)
    },
  })

  const updateUserDate = (payload: any) => {
    payload.phoneCode =
      payload.phoneNumber.indexOf('.') >= 0
        ? payload.phoneNumber.split('.')[0]
        : '+51'
    payload.phoneNumber =
      payload.phoneNumber.indexOf('.') >= 0
        ? payload.phoneNumber.split('.')[1]
        : payload.phoneNumber
    setCurrentUserData(payload)
    setValues(payload)
  }

  const [editUserDataFunc] = useMutation(EDIT_USER_DATA, {
    onCompleted: data => {
      const payload = {
        ...user,
        name: data.editUserData.name,
        lastName: data.editUserData.lastName,
        profilePhoto: data.editUserData.profilePhoto,
        phoneCode:
          data.editUserData.phoneNumber.indexOf('.') >= 0
            ? data.editUserData.phoneNumber.split('.')[0]
            : data.editUserData.phoneCode || '+51',
        phoneNumber:
          data.editUserData.phoneNumber.indexOf('.') >= 0
            ? data.editUserData.phoneNumber.split('.')[1]
            : data.editUserData.phoneNumber,
      }
      //phoneCode: data.editUserData.phoneNumber.indexOf('.') >= 0 ? data.editUserData.phoneNumber.split('.')[0] : '+51',
      //phoneNumber: data.editUserData.phoneNumber.indexOf('.') >= 0 ? data.editUserData.phoneNumber.split('.')[1] : data.editUserData.phoneNumber
      dispatch({ type: 'UPDATE_USER', payload: payload })
      updateUserDate(payload)
      message.success('Your data was updated correctly', 7)
    },
    onError: (err: any) => {
      message.error(
        'Ups! An error occurred, please check your connection and try again',
        7
      )
      message.error(err.message)
    },
  })

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCompleted: data => {
      message.success('Your data was updated correctly', 7)
    },
    onError: (err: any) => {
      message.error(
        'Ups! An error occurred, please check your connection and try again',
        7
      )
      message.error(err.message)
    },
  })

  const settingsList = [
    {
      id: 0,
      title: ConfigZyreboxImpl.sidebar.editLabel,
      search: '?account-info',
      icon: <FaRegUserCircle size="25px" />,
      component: (
        <AccountConfig
          values={values}
          setValues={setValues}
          currentUserData={currentUserData}
          setCurrentUserData={setCurrentUserData}
          editUserDataFunc={editUserDataFunc}
          impl={ConfigZyreboxImpl.accountConfig}
        />
      ),
    },
    /* {
    //   id: 1,
    //   title: ConfigZyreboxImpl.sidebar.billingLabel,
    //   search: '?billing',
    //   icon: <FaMoneyBillAlt size="25px" />,
    //   component: (
    //     <Billing
    //       subscription={currentUserData.subscription}
    //       impl={ConfigZyreboxImpl.billing}
    //     />
    //   ),
     },*/
    {
      id: 2,
      title: ConfigZyreboxImpl.sidebar.securityLabel,
      search: '?security',
      icon: <FaKey size="25px" />,
      component: (
        <Security
          changePassword={changePassword}
          impl={ConfigZyreboxImpl.security}
        />
      ),
    },
  ]
  const handleCurrentIndex = (index: number) => {
    setCurrentindex(index)
  }

  const handleBackButton = () => {
    navigate(`/home`)
  }

  if (loading)
    return (
      <div className="global-loader-wraper">
        <Loader />
      </div>
    )
  if (error) return <p>{error.graphQLErrors[0].message}</p>
  if (width < 600) {
    return <ResponsiveDefault />
  }
  return (
    <ConfigZyreboxStyled darkMode={darkMode}>
      <div className="grid">
        <div className="grid--child20">
          <Button color="light" onClick={handleBackButton} size="extraSmall">
            <FaChevronLeft /> {ConfigZyreboxImpl.sidebar.backlabel}
          </Button>
        </div>
      </div>
      <Container>
        <div className="config-main">
          <Sidebar
            menuItems={settingsList}
            currentIndex={currentIndex}
            handleCurrentIndex={handleCurrentIndex}
            className="settings-sidebar"
            idWorkspace={idWorkspace}
            search={search}
            currentUserData={currentUserData}
            editUserDataFunc={editUserDataFunc}
            userId={user.id}
            impl={ConfigZyreboxImpl.sidebar}
          />
          <div className="settings-container">
            {settingsList[currentIndex].component}
          </div>
        </div>
      </Container>
      <LanguageFloat />
    </ConfigZyreboxStyled>
  )
}

export default ConfigZyrebox
