import React from 'react'

const ForgotPasswordImpl = {
  ForgotPasswordImpl: {
    title: 'Forgot your Password',
    emailnput: {
      label: 'Email',
      placeholder: 'Enter your email address',
    },
    sendLinkBtn: 'Send me a recovery link',
    successMessage: (
      <>
        We have sent you an email. <br />
        Check your spam tray.
      </>
    ),
    loginBtn: 'Log In',
    haveAccount: 'Already have an account?',
  },
}

export default ForgotPasswordImpl
