import React from 'react'
import { useNotificationContext } from '../../contexts/NotificationContext'
import { GlobalAlertStyled } from './GlobalAlert.styles'

const GlobalAlert = () => {
  const { child, isOpen, position } = useNotificationContext()

  return (
    <GlobalAlertStyled position={position}>
      {isOpen && <>{child}</>}
    </GlobalAlertStyled>
  )
}

export default GlobalAlert
