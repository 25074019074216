import { navigate } from '@reach/router'
import React, { useState } from 'react'
import { FaUserCircle } from 'react-icons/fa'
import images from '../../../assets/static/images'
import { Button, FloatingDarkMode, Input, Text } from '../../../common'
import { useAuthState } from '../../../contexts/auth'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { HomeHeaderStyled } from './HomeHeader.styles'

interface HomeHeaderProps {
  impl: any
}

const HomeHeader = ({ impl }: HomeHeaderProps) => {
  const { handListOfWorkspaces, staticWorkspaces, darkMode }: any =
    useWorkspaceContext()
  const { user } = useAuthState()

  const [search, setSearch] = useState<string>('')
  const handleSearchInput = (value: string) => {
    // if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
    //   setValidationError('')
    // }else{
    //   setValidationError('Formato incorrecto')
    // }
    setSearch(value)
    const filteredList = staticWorkspaces.filter((workspace: any) =>
      workspace.name.toLowerCase().includes(value.toLowerCase())
    )
    handListOfWorkspaces(filteredList)
  }

  const handleProfileRoute = () => {
    navigate('/config')
  }
  const handleLogoRoute = () => {
    navigate('/landing')
  }
  return (
    <HomeHeaderStyled>
      <div className="header__logo">
        <img
          src={darkMode ? images.zyreboxHomeWhite : images.zyreboxHomeNormal}
          alt="Logo"
          onClick={handleLogoRoute}
          className="h-16"
        />
      </div>
      <div className="header__content">
        <Text
          type="h2"
          color={darkMode ? '#F9FBFD' : '#232323'}
          weight="normal"
          align="center"
        >
          {impl.welcomeTitle}
        </Text>
        <Text
          type="h2"
          color={darkMode ? '#F9FBFD' : '#232323'}
          weight="normal"
        >
          {user.name.split(' ')[0] + ' ' + user.lastName.split(' ')[0]}
        </Text>
        <div>
          <Input
            placeHolder={impl.inputPlaceholder}
            borderColor="#94A5C0"
            color={darkMode ? '#94A5C0' : '#232323'}
            value={search}
            onChange={handleSearchInput}
            validationError=""
          />
        </div>
      </div>
      <div className="header__button">
        <FloatingDarkMode />
        <div>
          <Button
            size="small"
            color={darkMode ? 'light' : 'primary'}
            onClick={handleProfileRoute}
          >
            <div className="header__button--content">
              <Text
                type="p"
                weight="400"
                size="17px"
                color={darkMode ? '#232323' : '#fff'}
              >
                {impl.profileBtn}
              </Text>
              <FaUserCircle size="25px" color="#FA27A9" />
            </div>
          </Button>
        </div>
      </div>
    </HomeHeaderStyled>
  )
}

export default HomeHeader
