const SubscribeImpl = {
  SubscribeImpl: {
    backBtn: 'Volver',
    title: 'Suscríbete Ahora',
    subtitle: (
      <>
        ¡Lleva a tus equipos al siguiente nivel con el{' '}
        <strong>Plan Business!</strong>
      </>
    ),
    planName: 'Business',
    yearLabel: 'año',
    monthLabel: 'mes',
    subscribeInfo: {
      title: 'Business',
      subtitle: 'incluye:',
      monthPrice: '25',
      anualPrice: '250',
      features: [
        {
          name: 'Todos los beneficios del plan startup',
        },
        {
          name: 'Espacio gratuito',
        },
        {
          name: 'Espacio con acceso total',
        },
        {
          name: 'Miembros/equipo: 10',
        },
        {
          name: 'Espacios grupales: Ilimitado',
        },
        {
          name: 'Proveedor de video conferencia: Todos',
        },
        {
          name: 'Integraciones embebidas: +50 *',
        },
        {
          name: 'Integraciones con API: +50 *',
        },
        {
          name: 'Enlaces rapidos: Ilimitados *',
        },
      ],
    },
    subscribeBtnLabel: 'Suscribirme',
  },
}

export default SubscribeImpl
