import React, { useState } from 'react'
import { useDrawerContext } from '../../contexts/DrawerContext'
import { useWorkspaceContext } from '../../contexts/workspace'
import { LaguageFloatStyled } from './LanguageFloat.styles'

interface LanguageFloatProps {
  onDrawer?: boolean
}

const LanguageFloat = ({ onDrawer }: LanguageFloatProps) => {
  const { language, handleLanguage }: any = useWorkspaceContext()
  const { closeDrawer } = useDrawerContext()
  const [showOptions, setShowOptions] = useState(false)
  const handleSetLanguage = (option: string) => {
    handleLanguage(option)
    setShowOptions(!showOptions)
    if (onDrawer) {
      closeDrawer()
      // window.location.reload()
    }
  }
  const handleShowOptions = () => {
    setShowOptions(!showOptions)
  }
  return (
    <LaguageFloatStyled>
      <div className="float" onClick={handleShowOptions}>
        {language === 'EN' ? (
          <img
            src="https://img.icons8.com/color/32/000000/usa-circular.png"
            alt="flag"
          />
        ) : (
          <img
            src="https://img.icons8.com/color/32/000000/spain2-circular.png"
            alt="flag"
          />
        )}
      </div>
      {showOptions && (
        <div className="float__options">
          <div
            className="float__options--item"
            onClick={() => {
              handleSetLanguage('EN')
            }}
            title="English"
          >
            <img
              src="https://img.icons8.com/color/32/000000/usa-circular.png"
              alt="flag"
            />
          </div>
          <div
            className="float__options--item"
            onClick={() => {
              handleSetLanguage('ES')
            }}
            title="Español"
          >
            <img
              src="https://img.icons8.com/color/32/000000/spain2-circular.png"
              alt="flag"
            />
          </div>
        </div>
      )}
    </LaguageFloatStyled>
  )
}

export default LanguageFloat
