import { navigate } from '@reach/router'
import React from 'react'
import images from '../../assets/static/images'
import { Button, Text } from '../../common'
import { ResponsiveDefaultStyled } from './ResponsiveDefault.styles'

const ResponsiveDefault = () => {
  return (
    <div>
      <ResponsiveDefaultStyled>
        <img src={images.logoAuth} alt="" />
        <img src={images.appCommingSoon} alt="" className="responsive__image" />
        <Text type="h3" align="center">
          Please, access from your PC o Mac to start{' '}
          <strong>Zyrebox Experience.</strong> We are working on a brand new App
          for you.{' '}
        </Text>
        <Text type="h3" align="center">
          <strong>Stay Tuned!</strong>
        </Text>
        <Button
          onClick={() => {
            navigate('/landing')
          }}
          color="primary"
          size="large"
        >
          Back to Lading
        </Button>
      </ResponsiveDefaultStyled>
    </div>
  )
}

export default ResponsiveDefault
