import styled, { css } from 'styled-components'

export const TeamContainer = styled.div`
  margin: 10px 0px 0px 0px;
  display: grid;
  place-items: center;
  cursor: pointer;
`
export const TeamListContainer = styled.div`
  max-height: 30vh;
  @media (min-height: 600px) {
    max-height: 42vh;
  }
  @media (min-height: 800px) {
    max-height: 48vh;
  }
  @media (min-height: 900px) {
    max-height: 53vh;
  }
  width: 100%;
  overflow-y: auto;

  .tooltipContainer {
    position: static;
  }
  .tooltipWraper {
    top: auto;
    left: 70px;
  }
  .popoverContainer {
    position: static;
    &:hover {
    }
    .pathcard {
      /* display: none; */
    }
  }
  .card-wraper {
    top: auto;
    left: 80px !important;
    display: inline-block !important;
  }
  ${props =>
    props.showList
      ? css`
          display: inline-block;
        `
      : css`
          display: none;
        `}
`

export const SectionActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .action-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2px 6px;
    border-radius: 2px;
    cursor: pointer;
    &:hover {
      background-color: #505152;
    }
  }
`

export const PopOverCardStyled = styled.div`
  position: relative;
  .hiden {
    display: none;
  }
  .path {
    display: none;
    height: 20px;
    width: 50px;
  }
  cursor: pointer;
  ${props =>
    props.openPopup &&
    css`
      .hiden {
        position: absolute;
        display: block;
        left: 200%;
        cursor: pointer;
      }
      .path {
        position: absolute;
        display: block;
        left: 100%;
        cursor: pointer;
      }
    `}
`
export const Card = styled.div`
  max-width: 260px;
  min-width: 220px;
  color: #343434;
  background: #fff;
  filter: drop-shadow(-4px 4px 10px rgba(153, 153, 153, 0.25));
  padding: 10px 10px;
  border-radius: 3px;
  position: absolute;
  top: 0px;
  z-index: 2;
  &:hover {
    background-color: #fff !important;
  }
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #fff;
    position: absolute;
    right: 100%;
    top: 10px;
    z-index: 2;
  }
  button {
    margin: 5px;
  }
  .actions-container {
    display: flex;
    justify-content: space-between;
  }
`

export const CardContentStyles = styled.div`
  width: 400px;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-content {
    min-width: 140px;
    max-width: 140px;
  }
  img {
    width: 22px;
    height: 22px;
  }
`
export const CardBody = styled.div`
  display: block;
  .button-container {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
  }
  a {
    color: rgba(134, 154, 184, 1);
    text-decoration: none;
    &:hover {
      color: #fa27a9;
    }
  }
`
