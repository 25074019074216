const SubscribeImpl = {
  SubscribeImpl: {
    backBtn: 'Back',
    title: 'Subscribe Now',
    subtitle: (
      <>
        Take your teams to the next level with <strong>Business Plan!</strong>
      </>
    ),
    planName: 'Business',
    yearLabel: 'year',
    monthLabel: 'month',
    subscribeInfo: {
      title: 'Business',
      subtitle: 'includes:',
      monthPrice: '25',
      anualPrice: '250',
      features: [
        {
          name: 'All the benefits of the startup plan',
        },
        {
          name: 'Free team space',
        },
        {
          name: 'Full access space',
        },
        {
          name: 'Members: 10/team',
        },
        {
          name: 'Group spaces: Unlimited',
        },
        {
          name: 'Video Conference provider: All',
        },
        {
          name: 'Embed integrations: +50 *',
        },
        {
          name: 'API integrations: +50 *',
        },
        {
          name: 'Fast links: Unlimited *',
        },
      ],
    },
    subscribeBtnLabel: 'Subscribe',
  },
}

export default SubscribeImpl
