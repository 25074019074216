const RegisterImpl = {
  RegisterImpl: {
    title: 'Registro',
    nameInput: {
      label: 'Nombre',
      placeholder: 'Ingresa tu nombre',
    },
    lastNameInput: {
      label: 'Apellido',
      placeholder: 'Ingresa tu apellido',
    },
    emailnput: {
      label: 'Correo Electrónico',
      placeholder: 'Ingresa tu correo electrónico',
    },
    passwordnput: {
      label: 'Contraseña',
      placeholder: 'Ingresa una contraseña',
    },
    confirmPasswordnput: {
      label: 'Confirma tu contraseña',
      placeholder: 'Confirma tu contraseña',
    },
    registerBtn: 'Registrar',
    acceptTermsLabel: 'Acepto los terminos y condiciones',
    requireCheckLabel:
      '*Para continuar, por favor acepta los terminos y condiciones',
    loginBtn: 'Iniciar Sesión',
    haveAccount: '¿Ya tienes cuenta?',
  },
}

export default RegisterImpl
