import React, { useEffect, useState } from 'react'
import { Anchor, Button } from '../../common'
import { NavbarStyled } from './Navbar.styles'
import images from '../../assets/static/images'
import { FaBars } from 'react-icons/fa'
import { useWindow } from '../../hooks/useWindow'
import { useAuthState } from '../../contexts/auth'
import { navigate, useLocation } from '@reach/router'

interface NavbarProps {
  dark?: boolean
}
const Navbar = ({ dark }: NavbarProps) => {
  const { user } = useAuthState()
  const [showItems, setShowItems] = useState(false)
  const [width] = useWindow()
  const handleClass = () => {
    setShowItems(!showItems)
  }

  const location = useLocation()
  const goToFeatures = () => {
    const elem = document.getElementById('features-landing')

    if (elem)
      window.scroll({
        top: elem?.offsetTop,
        behavior: 'smooth',
      })
  }

  useEffect(() => {
    if (location.hash.indexOf('features-landing') >= 0) goToFeatures()
    return () =>
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <NavbarStyled dark={dark} showItems={showItems} id="navbar-init">
        <Anchor
          color={dark ? '#FFF' : '#161C2D'}
          type="link"
          route="/landing"
          fontSize="40px"
          decoration="none"
          className="menu__item menu_link"
          // onClick={scrollTwoSection}
        >
          {dark ? (
            <img src={images.logoNavDark} alt="logonav"></img>
          ) : (
            <img src={images.logoNav} alt="logonav"></img>
          )}
        </Anchor>
        <div className="menu__item">
          {}
          <Anchor
            color={dark ? '#FFF' : '#161C2D'}
            type="link"
            route="/landing#features-landing"
            fontSize="40px"
            decoration="none"
            className="menu__item menu_link"
            onClick={goToFeatures}
          >
            Features
          </Anchor>
          {/* <button className='sc-gKXOVf iWVZfB menu__item menu_link'  onClick={scrollTwoSection}>Features</button> */}
          {/*         <Anchor color={dark ? "#FFF" : "#161C2D"} type='link' route='/tutorials' fontSize='40px' decoration='none'className='menu__item menu_link'>
          Tutorials
        </Anchor> */}
          <Anchor
            color={dark ? '#FFF' : '#161C2D'}
            type="link"
            route="/pricing"
            fontSize="40px"
            decoration="none"
            className="menu__item menu_link"
          >
            Pricing
          </Anchor>
          <Anchor
            color={dark ? '#FFF' : '#161C2D'}
            type="link"
            route="/contact"
            fontSize="40px"
            decoration="none"
            className="menu__item menu_link"
          >
            Contact Us
          </Anchor>
          {width <= 600 && (
            <Anchor
              color={dark ? '#FFF' : '#161C2D'}
              type="link"
              route="/login"
              fontSize="40px"
              decoration="none"
              className="menu__item menu_link"
            >
              {user ? 'Home' : 'Sign In'}
            </Anchor>
          )}
        </div>
        {width > 600 && (
          <div className="menu__item">
            <Button
              size="small"
              color="primary"
              onClick={() => {
                navigate('/login')
              }}
              className="menu__button"
            >
              {user ? 'Home' : 'Sign In'}
            </Button>
          </div>
        )}
        <div className="icon" onClick={handleClass}>
          <FaBars color={dark ? '#FFF' : '#161C2D'} size="25px" />
        </div>
      </NavbarStyled>
    </div>
  )
}

export default Navbar
