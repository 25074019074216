import React from 'react'
import { AnchorRouteStyles, AnchorStyles } from './Anchor.styles'

interface AnchorProps {
  children: any
  type?: string
  route: string
  targetBlank?: boolean
  color?: string
  decoration?: string
  fontSize?: number | string
  fontWeight?: number | string
  className?: string
  onClick?: () => void
}

const Anchor = ({
  children,
  type,
  route,
  targetBlank,
  color,
  decoration,
  fontSize,
  fontWeight,
  className,
  onClick,
}: AnchorProps): JSX.Element => {
  return (
    <>
      {type === 'link' ? (
        <AnchorRouteStyles
          to={route}
          target={targetBlank ? '_blank' : '_self'}
          color={color}
          decoration={decoration}
          fontSize={fontSize}
          fontWeight={fontWeight}
          className={className}
          onClick={onClick}
        >
          {children}
        </AnchorRouteStyles>
      ) : (
        <AnchorStyles
          href={route}
          target={targetBlank ? '_blank' : '_self'}
          color={color}
          decoration={decoration}
          fontSize={fontSize}
          fontWeight={fontWeight}
          className={className}
          onClick={onClick}
        >
          {children}
        </AnchorStyles>
      )}
    </>
  )
}

export default Anchor
