import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../../contexts/auth'
import { Text, Button, Input, ToggleSwitch } from '../../../common'
import { AccountConfigStyled } from './AccountConfig.styles'
import ReactGA from 'react-ga'
import { FaSyncAlt } from 'react-icons/fa'
import { useWorkspaceContext } from '../../../contexts/workspace'

interface AccountConfigProps {
  values: any
  setValues: (value: any) => void
  currentUserData: any
  setCurrentUserData: (value: any) => void
  editUserDataFunc: (value: any) => void
  impl: any
}

const AccountConfig = ({
  editUserDataFunc,
  impl,
}: AccountConfigProps): JSX.Element => {
  const { user }: any = useAuthState()
  const { handleDisablePreJoin, disablePreJoin }: any = useWorkspaceContext()
  const [name, setName] = useState(user.name)
  const [nameError, setNameError] = useState('')
  const [lastName, setLastName] = useState(user.lastName)
  const [lastNameError, setLastNameError] = useState('')
  const [email, setEmail] = useState(user.email)
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)
  const [phoneNumberError, setPhoneNumberError] = useState('')

  const handleName = (value: string) => {
    if (value === '') {
      setNameError('Fill the field please')
    } else {
      setNameError('')
    }
    setName(value)
  }
  const handleLastName = (value: string) => {
    if (value === '') {
      setLastNameError('Fill the field please')
    } else {
      setLastNameError('')
    }
    setLastName(value)
  }
  const handleEmail = (value: string) => {
    // field is disabled
    setEmail(value)
  }
  const handlePhoneNumber = (value: string) => {
    if (value === '') {
      setPhoneNumberError('Fill the field please')
    } else {
      setPhoneNumberError('')
    }
    setPhoneNumber(value)
  }
  // _______Anaylicits
  useEffect(() => {
    ReactGA.pageview('/config')
  }, [])
  // _____

  const handleDisabled = () => {
    if (
      name === user.name &&
      lastName === user.lastName &&
      email === user.email &&
      phoneNumber === user.phoneNumber
    ) {
      return true
    }
    return false
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    editUserDataFunc({
      variables: {
        name,
        lastName,
        phoneNumber,
        id: user.id,
      },
    })
  }
  return (
    <AccountConfigStyled>
      <div className="config-container">
        <Text type="h3" weight={600}>
          {impl.profileConfigTitle}
        </Text>
        <form onSubmit={handleSubmit} className="account-form">
          <Text type="h1">{impl.title}</Text>
          <div className="account-form__input-container">
            <Input
              value={name}
              onChange={handleName}
              validationError={nameError}
              label={impl.nameInput.label}
              placeHolder={impl.nameInput.placeholder}
              className="login-form__input"
              backgroundColor="#ffffff"
            />
            <Input
              value={lastName}
              onChange={handleLastName}
              validationError={lastNameError}
              label={impl.lastNameInput.label}
              placeHolder={impl.lastNameInput.placeholder}
              className="login-form__input"
              backgroundColor="#ffffff"
            />
            <Input
              value={email}
              onChange={handleEmail}
              validationError={''}
              label={impl.emailInput.label}
              placeHolder={impl.emailInput.placeholder}
              className="login-form__input"
              backgroundColor="#ffffff"
              disabled
            />
            <Input
              value={phoneNumber}
              onChange={handlePhoneNumber}
              validationError={phoneNumberError}
              label={impl.phoneInput.label}
              placeHolder={impl.phoneInput.placeholder}
              className="login-form__input"
              backgroundColor="#ffffff"
            />
          </div>
          <div className="account-form__button">
            <Button
              size="base"
              outlined
              color="primary"
              disabled={handleDisabled()}
            >
              <FaSyncAlt /> {impl.saveBtn}
            </Button>
          </div>
        </form>
        <div className="more-configs">
          <Text type="h3" weight={600}>
            {impl.moreConfigsTitle}
          </Text>
          <div className="more-configs__section">
            <Text type="h4" weight={500}>
              {impl.videoCallSection.title}
            </Text>
            <div className="more-configs__section--item">
              <Text type="p">{impl.videoCallSection.disablePreJoinLabel}</Text>
              <div className="toggle-action">
                <Text type="p" fontStyle="italic" color="#6E839D">
                  {disablePreJoin
                    ? impl.videoCallSection.prejoinDisableLabel
                    : impl.videoCallSection.prejoinEnableLabel}
                </Text>
                <ToggleSwitch
                  handleChange={handleDisablePreJoin}
                  checked={disablePreJoin}
                  backgroundColorActive="#389E80"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AccountConfigStyled>
  )
}

export default AccountConfig
