const images = {
  heroSlide1: 'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/zyrebox-screen1.png',
  heroSlide2: 'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/zyrebox-screen2.png',
  heroSlide3: 'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/zyrebox-screen3.png',
  appCommingSoon:
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/Appcomming.jpg?alt=media&token=875a5098-336b-4cbe-ab99-298b2a3c86ad',
  subscriptionImage:
    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/zyrebox-subscribe-page-img.png',
  // 'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/5752311.jpg?alt=media&token=95fee36b-77ea-4bfb-bb47-f6e0c37af76d',
  subscriptionImage2:
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/4814043.jpg?alt=media&token=6d2d3d4f-4432-40d0-b1ac-51cfc0b2e8a4',
  zyreboxLogoMid:
    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/ZYREBOX-LOGO-OFICIAL_BLANCO-home.png',
  logoBig:
    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/ZYREBOX-LOGO-OFICIAL_normal.png',
  logoAuth:
    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/ZYREBOX-LOGO-OFICIAL_AuthPages.png',
  zyreboxBackDarkMode:
    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/zyrebox-back-dark-mode.png',
  zyreboxBackLightMode:
    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/zyrebox-back-light-mode.png',
  zyreboxHomeNormal:
    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/Logo+oficial+normal.png',
  zyreboxHomeWhite:
    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/Logo+Oficial+Blanco.png',
  logoCircular:
    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/ZYREBOX-ISOTIPO+1.png',
  logoSettings:
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/logoSettings.png?alt=media&token=3a7795d0-15ab-409b-861b-280d3324bb64',
  logoNav:
    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/ZYREBOX-LOGO-OFICIAL_RGB+1.png',
  logoNavDark:
    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/ZYREBOX-LOGO-OFICIAL_BLANCO+3.png',
  createBackground:
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/createBackground.svg?alt=media&token=035dbc35-05f5-4b3f-a1ae-ff2eefa1faae',
  loginBackground:
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/landingSectionImage.svg?alt=media&token=8f463081-cfe0-4711-8836-8e34ca25294c',
  landingSectionImage:
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/landingSectionImage.svg?alt=media&token=8f463081-cfe0-4711-8836-8e34ca25294c',
  icons: {
    umbrella:
      'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/umbrella.png?alt=media&token=aea8edcd-70f5-41b1-abcf-f49a6ca50a43',
    coffeMaker:
      'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/coffe-maker.png?alt=media&token=68bd3b79-1f77-473c-97b5-f0e99b92a243',
  },
  commonIcons: {
    plus: 'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/iconPlus.png?alt=media&token=94044da4-f609-4738-b538-ba078615c4b4',
  },
  landingMainPhoto:
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/LandingMainPhoto.png?alt=media&token=79620492-faa1-49d6-95b0-52453eeb381b',
  clienteBackup:
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/ClientesBackup.png?alt=media&token=bdbc50fc-379a-4987-a42d-1633abb12643',
  switchSideOne:
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/SectionOne.png?alt=media&token=9cfefd29-9e72-4cee-9700-4cc8f913c086',
  switchSideTwo:
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/SectionTwo.png?alt=media&token=0407f0df-05af-4056-ad81-9e55232c03fb',
  dropdownIcons: [
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FUmbrella.svg?alt=media&token=6fc948b0-a866-44f3-b196-c47cb17a6ee9',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FCoffeIcon.svg?alt=media&token=59f3956d-21e9-439a-bf66-37613d21f357',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FAppleIcon.svg?alt=media&token=e842fead-4373-4c07-b7b4-35db00e80a27',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FBanannaIcon.svg?alt=media&token=cac2ee68-cf74-49a1-a361-ee9964cf2510',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FBeerIcon.svg?alt=media&token=6129f159-49c8-4023-abc2-dcc6a8468c63',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FBirthIcon.svg?alt=media&token=956272d1-c3ac-45c8-8f9d-359f18eb576c',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FBurgerIcon.svg?alt=media&token=c16716a2-b67a-4b71-87fb-d993f6aec7b4',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FChrismasIcon.svg?alt=media&token=00c1e587-3796-4c99-9eef-0e3d09874acf',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FDoorIcon.svg?alt=media&token=4cea194f-db22-4bb0-9db1-0470623da016',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FFriesIcon.svg?alt=media&token=664343d3-8273-4877-a11d-55699ab2edb4',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FGranMa.svg?alt=media&token=ffefd820-4eb0-4343-b2c0-96eadfc77ba5',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FGranPa.svg?alt=media&token=103355e2-3d01-454e-9645-846c0737399b',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FHotDogIcon.svg?alt=media&token=47842840-a860-4d6a-a36b-5797f2f4e4aa',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FIceCreamIcon.svg?alt=media&token=714dccc3-d22f-46a3-baa9-4b091388dd27',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FPizzaIcon.svg?alt=media&token=834070ce-dd3d-4ff9-973b-ed59d9e14b76',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FPoliceIcon.svg?alt=media&token=9d6f2bbb-d94f-42c5-b864-77ae59332fe2',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FRaceIcon.svg?alt=media&token=976bf39f-2225-45f5-95cd-e848dfd559f9',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FTrainIcon.svg?alt=media&token=5f8fde69-72ca-423c-8f1b-3652737bb7de',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FTreeIcon.svg?alt=media&token=ed6e8470-d789-4405-a83b-4b6f234619cf',
    'https://firebasestorage.googleapis.com/v0/b/tumbba-app.appspot.com/o/icons%2FVanIcon.svg?alt=media&token=273a14ba-3b92-4b8d-af15-3cd6396a7f63',
  ],
}

export default images
