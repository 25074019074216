import React, { useState } from 'react'
import { Avatar, Badge, Button, Text } from '../../../common'
import {
  FaChevronUp,
  FaChevronDown,
  FaPlusCircle,
  FaBuffer,
} from 'react-icons/fa'
import {
  GroupSpaceContainer,
  GroupListContainer,
  SectionActions,
} from './GroupSpaces.styles'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { useAuthState } from '../../../contexts/auth'
import { navigate } from '@reach/router'
import { Popover, Tooltip } from 'antd'

interface GroupSpacesProps {
  staticRooms: any[]
  impl: any
}
const GroupSpaces = ({ staticRooms, impl }: GroupSpacesProps): JSX.Element => {
  const { handleRoom, workspace }: any = useWorkspaceContext()
  const { user } = useAuthState()
  const [showList, setShowList] = useState(true)
  const handleActivateRoom = (
    roomId: string,
    roomName: string,
    roomPassword: string
  ) => {
    handleRoom({
      roomName: '',
      roomPassword: '',
      active: false,
    })
    const newRoom = {
      id: roomId,
      roomName,
      roomPassword,
      active: true,
    }
    handleRoom(newRoom)
  }

  const handleShowList = () => {
    setShowList(!showList)
  }

  $(function () {
    // whenever we hover over a menu item that has a submenu
    $('.groupSpace-item').on('mouseover', function () {
      const $menuItem = $(this),
        $submenuWrapper = $('.tooltipWraper', $menuItem)

      // grab the menu item's position relative to its positioned parent
      const menuItemPos = $menuItem.position()

      // place the submenu in the correct position relevant to the menu item
      $submenuWrapper.css({
        top: menuItemPos.top,
        left: menuItemPos.left + Math.round(55),
      })
    })
  })
  const handleAddNewSpace = () => {
    navigate(`/workspace/edit/${workspace.id}?group-spaces`)
  }

  return (
    <div className={showList ? '' : 'mb-10'}>
      <Badge
        color={'primary'}
        backchildren={
          <SectionActions>
            <div onClick={handleShowList} className="action-button">
              {showList ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {workspace.owner && user.id === workspace.owner.id && '|'}
            {workspace.owner && user.id === workspace.owner.id && (
              <Popover
                content={
                  <div>
                    <Text type="h5" align="center">
                      {impl.addSpaces.title}
                    </Text>
                    <div className="grid place-items-center">
                      <Button
                        onClick={handleAddNewSpace}
                        soft
                        color="primary"
                        size="extraSmall"
                      >
                        <FaBuffer size="18px" />
                      </Button>
                    </div>
                    <Text
                      type="p"
                      align="center"
                      color="#869AB8"
                      weight="400"
                      size="12px"
                    >
                      {impl.addSpaces.subtitle}
                    </Text>
                  </div>
                }
                trigger="click"
                placement="right"
              >
                <div className="action-button ">
                  <FaPlusCircle />
                </div>
              </Popover>
            )}
          </SectionActions>
        }
      >
        {impl.badgeLabel}
      </Badge>
      <GroupListContainer showList={showList}>
        {staticRooms.map(room => (
          <GroupSpaceContainer key={room.id}>
            <Tooltip placement="right" title={room.name}>
              <div>
                <Avatar
                  image={room.logo}
                  size="45px"
                  backgroundColor="#506690"
                  onClick={() =>
                    handleActivateRoom(
                      room.id,
                      room.roomName,
                      room.roomPassword
                    )
                  }
                />
              </div>
            </Tooltip>
          </GroupSpaceContainer>
        ))}
      </GroupListContainer>
    </div>
  )
}

export default GroupSpaces
