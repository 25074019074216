import { Link } from '@reach/router'
import React from 'react'
import { BsCalendar2Date, BsThreeDots } from 'react-icons/bs'
import { FaSearch } from 'react-icons/fa'
import { Button, LanguageFloat, Text, Tooltip } from '../../../common'
import { useAuthState } from '../../../contexts/auth'
import { useDrawerContext } from '../../../contexts/DrawerContext'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { MainMenuOption, MainMenuStyled } from './MainMenu.styles'
import FindInZyrebox from './MenuOptions/FindInZyrebox'

export interface MenuItemProps {
  icon: React.ReactNode
  title: string
  description: string
  onClick: () => void
  blocked: boolean
}

interface MenuOptionListProps {
  optionList: MenuItemProps[]
}

const MenuItem = ({
  icon,
  title,
  description,
  onClick,
  blocked,
}: MenuItemProps) => {
  return (
    <MainMenuOption onClick={onClick} blocked={blocked}>
      {icon}
      <div className="menu-item-content">
        <Text type="h4">{title}</Text>
        <Text type="p" color="#99A3B4">
          {description}
        </Text>
      </div>
    </MainMenuOption>
  )
}

const MenuOptionList = ({ optionList }: MenuOptionListProps) => {
  return (
    <div>
      <div data-aos="fade-left">
        {optionList.map(option => (
          <MenuItem {...option} key={option.title} />
        ))}
      </div>
      <LanguageFloat onDrawer />
    </div>
  )
}

const MainMenu = () => {
  const { openDrawer, nextPage } = useDrawerContext()
  const { user }: any = useAuthState()
  const { workspace }: any = useWorkspaceContext()
  const {
    impl: {
      MainZyreboxImpl: { mainMenu: impl },
    },
  }: any = useWorkspaceContext()
  const menuOptions = [
    {
      icon: <FaSearch size={25} color="#1B2A4E" />,
      title: impl.findInZyrebox.title,
      description: impl.findInZyrebox.description,
      onClick: () => {
        nextPage({
          nextChild: <FindInZyrebox />,
          title: impl.findInZyrebox.title,
        })
      },
      blocked: false,
    },
    {
      icon: <BsCalendar2Date size={25} color="#1B2A4E" />,
      title: impl.calendarOption.title,
      description: impl.calendarOption.description,
      onClick: () => undefined,
      blocked: true,
    },
  ]
  const rendeMenuOptions = () => {
    openDrawer({
      nextChild: <MenuOptionList optionList={menuOptions} />,
      title: impl.title,
    })
  }
  return (
    <MainMenuStyled>
      <div className="right-16 top-4 absolute">
        {workspace.owner?.id === user?.id && (
          <Link
            className="p-1 mr-2 border border-pink-500 text-pink-400 hover:bg-pink-400 hover:text-white cursor-pointer rounded"
            to={workspace.subscribed ? '/contact' : '/checkout'}
          >
            {workspace.subscribed ? '👀 Need more?' : '🤗 Upgrade now'}
          </Link>
        )}
      </div>
      <Tooltip
        content={impl.buttonLabel}
        position="left"
        customClass="menu_options-component"
      >
        <Button
          size="small"
          color="light"
          className="menu_options-button"
          onClick={rendeMenuOptions}
        >
          <BsThreeDots size={25} />
        </Button>
      </Tooltip>
    </MainMenuStyled>
  )
}

export default MainMenu
