import * as React from 'react'

function SvgComponent(props: any) {
  return (
    <svg
      width={45}
      height={45}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.5 34.93c6.904 0 12.5-1.666 12.5-3.721 0-2.056-5.596-3.723-12.5-3.723S10 29.153 10 31.21c0 2.055 5.596 3.722 12.5 3.722z"
        fill="#3B88C3"
      />
      <path
        d="M33.48 29.817c0 1.122-1.736 1.797-10.056 1.797-7.587 0-9.453-.675-9.453-1.797 0-1.122 3.946-2.032 9.453-2.032 5.506 0 10.056.91 10.056 2.032z"
        fill="#88C9F9"
      />
      <path
        d="M14.861 29.619c0-.695.695-3.473 7.64-3.473 8.332 0 10.416 2.778 10.416 3.473 0 .694 0 1.389-9.722 1.389-8.334 0-8.334-.695-8.334-1.39z"
        fill="#F4900C"
      />
      <path
        d="M24.32 29.214a.89.89 0 01-1.622.293.892.892 0 01-.121-.671l3.53-18.045a.895.895 0 011.066-.712.892.892 0 01.676 1.09L24.32 29.214z"
        fill="#66757F"
      />
      <path
        d="M26.915 11.27c-5.293-1.15-10.317 1.3-11.222 5.47 0 0-.252 1.161.33 1.288.58.126 1.994-.784 1.994-.784l14.52 3.152s.908 1.414 1.49 1.54c.58.127.832-1.035.832-1.035.906-4.171-2.651-8.483-7.944-9.631z"
        fill="#DD2E44"
      />
      <path
        d="M26.916 11.27c-4.01-.87-7.994 1.805-8.899 5.974 0 0-.252 1.162.91 1.414 1.162.253 3.156-.531 3.156-.531l6.388 1.386s1.49 1.54 2.652 1.793c1.162.251 1.414-.91 1.414-.91.904-4.17-1.612-8.256-5.621-9.126z"
        fill="#FFE8B6"
      />
      <path
        d="M26.915 11.268c-2.085-.452-3.928 2.687-4.833 6.857 0 0 1.49 1.54 2.651 1.792l.582.127c1.162.251 3.155-.532 3.155-.532.906-4.17.53-7.791-1.555-8.244z"
        fill="#DD2E44"
      />
      <path
        d="M21.004 29.409c.326-.297 1.215-.226 1.736-.418.52-.192.847-.436 1.28-.261.435.174.564.435.977.47.413.036.716.28.542.471-.173.192-1.54.262-2.452.262-.91 0-2.485-.157-2.083-.524z"
        fill="#FFAC33"
      />
    </svg>
  )
}

export default SvgComponent
