import styled, { css } from 'styled-components'

export const ZyreboxInfoStyled = styled.div`
  position: relative;
  margin-top: 70px;
  .alerts-wraper {
    position: fixed;
    width: 100%;
    top: 15px;
    /* left: 0; */
  }
  .card {
    padding: 30px;
    margin-top: 16px;
    margin-bottom: 50px;
    min-height: 600px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(153, 153, 153, 0.25);
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(100, 1fr);
    .upload-image {
      grid-column: 1/20;
      display: flex;
      justify-content: center;
    }
    .info-form {
      grid-column: 25/100;
    }
    .submit-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
`
export const ImageLogoContainer = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  align-items: flex-end;
  border-radius: 50px;
  > label {
    width: 100px;
    height: 100px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(22, 28, 45, 0.3);
    border-radius: 50px;
    cursor: pointer;
  }
  > input {
    display: none;
  }
  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-size: cover;
    `}
`
