import React, { useState } from 'react'
import { AppTriggerItem, Avatar, Input, Text } from '../../../../common'
import { useAuthState } from '../../../../contexts/auth'
import { useDrawerContext } from '../../../../contexts/DrawerContext'
import { useWorkspaceContext } from '../../../../contexts/workspace'
import { useStatus } from '../../../../hooks/useStatus'
import {
  useCreateRoom,
  useSendCall,
} from '../../../../services/MainZyreboxServices'
import { MemberType, RoomType } from '../../../../types/workspaceTypes'
import { Status } from '../../../../types/enums'
import { ContentAppStyled, FindInZyreboxStyled } from './FindInZyrebox.styles'

interface MemberItemProps {
  member: MemberType
}
interface RoomItemProps {
  room: RoomType
}
const FindInZyrebox = () => {
  const {
    impl: {
      MainZyreboxImpl: {
        mainMenu: { findInZyrebox: impl },
        apps: appsImpl,
      },
    },
  }: any = useWorkspaceContext()
  const [search, setSearch] = useState<string>('')
  const { user } = useAuthState()
  const { workspace, staticRooms, room }: any = useWorkspaceContext()
  const { closeDrawer } = useDrawerContext()
  const [members, setMembers] = useState(workspace.members)
  const [grouSpaces, setGroupSpaces] = useState(staticRooms)
  const [apps, setApps] = useState(generateApps())
  const [createRoom] = useCreateRoom()
  const handleSearch = (value: string) => {
    const membersFiltered = workspace.members.filter(
      (member: MemberType) =>
        member.name.toLowerCase().includes(value.toLowerCase()) ||
        member.lastName.toLowerCase().includes(value.toLowerCase()) ||
        member.email.toLowerCase().includes(value.toLowerCase())
    )
    const roomsFiltered = staticRooms.filter((member: RoomType) =>
      member.name.toLowerCase().includes(value.toLowerCase())
    )

    const appsFiltered = generateApps().filter((app: any) =>
      app.name.toLowerCase().includes(value.toLowerCase())
    )
    setMembers(membersFiltered)
    setGroupSpaces(roomsFiltered)
    setApps(appsFiltered)
    setSearch(value)
  }

  function handleCreateRoom() {
    createRoom({
      variables: {
        idWorkspace: workspace.id,
        idUser: user.id,
        isStatic: false,
      },
    })
    closeDrawer()
  }

  function generateApps() {
    const apps: any = [
      {
        name: appsImpl.instantCall.label,
        src: appsImpl.instantCall.icon,
        openFunction: handleCreateRoom,
        activeTrigger: room.active,
        free: true,
        blocked: false,
      },
      {
        name: 'Trello',
        src: 'https://cdn.worldvectorlogo.com/logos/trello.svg',
        openFunction: () => {},
        activeTrigger: false,
        free: false,
        blocked: true,
      },
      {
        name: 'Figma',
        src: 'https://cdn.worldvectorlogo.com/logos/figma-1.svg',
        openFunction: () => {},
        activeTrigger: false,
        free: false,
        blocked: true,
      },
      {
        name: 'Miro',
        src: 'https://cdn.worldvectorlogo.com/logos/miro-2.svg',
        openFunction: () => {},
        activeTrigger: false,
        free: false,
        blocked: true,
      },
      {
        name: 'ClickUp',
        src: 'https://clickup.com/landing/images/clickup-logo-gradient.png',
        openFunction: () => {},
        activeTrigger: false,
        free: false,
        blocked: true,
      },
    ]

    return apps
  }

  const MemberItem = ({ member }: MemberItemProps) => {
    const [handleSendCall] = useSendCall(member)
    const { getStatusKey }: any = useStatus(member.id)
    const handleOnClickMember = () => {
      handleSendCall()
      closeDrawer()
    }
    return (
      <div className="find-item">
        <Avatar
          key={member.id}
          image={member.profilePhoto}
          size="50px"
          className="find-item__avatar--user"
          onClick={handleOnClickMember}
          haveStatus
          currentStatus={getStatusKey(workspace.id) === Status.online}
        />
        <Text type="p" className="find-item__text">
          {member.name}
        </Text>
      </div>
    )
  }

  const RoomItem = ({ room }: RoomItemProps) => {
    const { handleRoom }: any = useWorkspaceContext()
    const handleActivateRoom = () => {
      handleRoom({
        roomName: '',
        roomPassword: '',
        active: false,
      })
      const newRoom = {
        id: room.id,
        roomName: room.roomName,
        roomPassword: room.roomPassword,
        active: true,
      }
      handleRoom(newRoom)
      closeDrawer()
    }
    return (
      <div className="find-item">
        <Avatar
          key={room.id}
          image={room.logo}
          size="50px"
          className="find-item__avatar--room"
          backgroundColor="#506690"
          onClick={handleActivateRoom}
        />
        <Text type="p" className="find-item__text">
          {room.name.slice(0, 14)}
          {room.name.length > 14 && '...'}
        </Text>
      </div>
    )
  }
  return (
    <FindInZyreboxStyled data-aos="fade-left">
      <Input
        onChange={handleSearch}
        value={search}
        placeHolder={impl.findInput.placeholder}
        validationError={''}
      />
      <div className="find-wrapper">
        <div className="find-section">
          {members.length > 0 && (
            <>
              <Text type="h3">Team</Text>
              {members
                .filter((member: MemberType) => member.id !== user.id)
                .map(
                  (member: any, key: number) =>
                    key < 10 && <MemberItem key={key} member={member} />
                )}
            </>
          )}
          {members.length > 10 && (
            <div className="find-item find-item__more-items">
              + {members.length - 10}
            </div>
          )}
        </div>
        <div className="find-section">
          {grouSpaces.length > 0 && (
            <>
              <Text type="h3">Group Spaces</Text>
              {grouSpaces.map((room: any, key: number) => (
                <RoomItem key={key} room={room} />
              ))}
            </>
          )}
        </div>
        <div className="find-section">
          {apps.length > 0 && (
            <>
              <Text type="h3">Apps</Text>
              <ContentAppStyled>
                {workspace.owner &&
                  apps.map((app: any, key: number) => (
                    <AppTriggerItem app={app} key={key} />
                  ))}
              </ContentAppStyled>
            </>
          )}
        </div>
      </div>
    </FindInZyreboxStyled>
  )
}

export default FindInZyrebox
