/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useSubscription } from '@apollo/client'
import { useEffect, useState } from 'react'
import { GET_STATUS } from '../graphQL/Queries'
import { CHANGED_STATUS } from '../graphQL/Subscription'
import { WorkspaceType } from '../types/workspaceTypes'
import { Status } from '../types/enums'

interface StatusInterface {
  id: string
  user: any
  workspace: [WorkspaceType]
}

export const useStatus = (id: any) => {
  const [status, setStatus] = useState<StatusInterface>()
  const { data: statusData, error: statusError } =
    useSubscription(CHANGED_STATUS)
  const [getStatus] = useLazyQuery(GET_STATUS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setStatus(data.getStatus)
    },
    onError: err => console.log('[status error]', err),
  })
  useEffect(() => {
    if (statusError) console.log(statusError)
    if (statusData) {
      const status = statusData.changedStatus
      if (status.user.id === id) {
        setStatus(status)
      }
      // console.log(statusData)
      // console.log(status.user.id === id && status.workspace.id === workspace.id)
    }
  }, [statusError, statusData])
  useEffect(() => {
    try {
      if (id) {
        getStatus({ variables: { idUser: id } })
      }
    } catch (err) {
      console.log(err)
    }
  }, [])
  // console.log(status)
  const getStatusKey = (workspaceId: string) => {
    if (status?.workspace.find(workspace => workspace.id === workspaceId)) {
      return Status.online
    } else {
      return Status.offline
    }
  }
  return { status, getStatusKey }
}
