const LoginImpl = {
  LoginImpl: {
    title: 'Iniciar Sesión',
    emailnput: {
      label: 'Correo Electrónico',
      placeholder: 'Ingresa tu correo electrónico',
    },
    passwordnput: {
      label: 'Contraseña',
      placeholder: 'Ingresa tu contraseña',
    },
    loginBtn: 'Iniciar Sesión',
    loginGoogleBtn: 'Iniciar con Google',
    forgotLabel: '¿Olvidaste tu contraseña?',
    noaccountLabel: '¿Aún no tienes cuenta?',
    registerBtn: 'Regístrate',
    termsLabel: 'Terminos & Condiciones',
  },
}

export default LoginImpl
