import styled, { css } from 'styled-components'
// import { useWorkspaceContext } from '../../../contexts/workspace'
// const { darkMode } = useWorkspaceContext()
// const content = darkMode ? '🌘' : '☀️'
export const MySpacePopOverStyled = styled.div`
  .darkmode {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .darkmode .label {
    font-weight: 800;
    margin-bottom: 0.5rem;
    font-size: 16px;
  }

  .switch {
    appearance: none;
    height: 2em;
    width: 4em;
    background-color: #c7c7c7;
    cursor: pointer;
    border-radius: 1em;
    position: relative;
    transition: background-color 0.3s ease;
  }

  .switch::before {
    ${({ darkMode }) =>
      darkMode
        ? css`
            content: '🌞';
          `
        : css`
            content: '🌜';
          `};
    height: 100%;
    width: 50%;
    background-color: white;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    /* border: #c7c7c7 solid 0.2em; */
    transition: transform 0.3s ease, border-color 0.3s ease;
  }

  .switch:checked {
    background-color: #0d374b;
  }

  .switch:checked::before {
    border-color: #0d374b;
    transform: translateX(100%);
  }

  .avatar__user-info {
    font-size: 12px;
  }
  .avatar__user-info .username {
    font-weight: semibold;
    font-size: 16px;
  }
  .avatar__user-info .email {
    color: rgba(134, 154, 184, 1);
  }

  .avatar__separator {
    border-bottom: 1px rgba(134, 154, 184, 1) solid;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 500px) {
    .switch {
      outline: none;
    }
  }
`
