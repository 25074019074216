import React, {
  Reducer,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'
import { GenericAction } from '../types/contextTypes'

const DrawerContext = React.createContext<DrawerContextType>(
  undefined as unknown as DrawerContextType
)

interface NewPageType {
  nextChild: React.ReactNode
  title: string
}

interface DrawerState {
  isOpen: boolean
  children: NewPageType[]
  activeChild: NewPageType | null
}

export enum DrawerActionsTypes {
  CLOSE_DRAWER = 'DRW_closeDrawer',
  OPEN_DRAWER = 'DRW_openDrawer',
  SET_ACTIVE_CHILD = 'DRW_setActiveChild',
  ADD_NEW_CHILDREN = 'DRW_addNewChildren',
  CLEAR_CONTEXT = 'DRW_clearContext',
  REMOVE_LAST_CHILD = 'DRW_removeLastChild',
}

type DrawerAction =
  | GenericAction<DrawerActionsTypes.CLOSE_DRAWER>
  | GenericAction<DrawerActionsTypes.OPEN_DRAWER>
  | GenericAction<DrawerActionsTypes.CLEAR_CONTEXT>
  | GenericAction<DrawerActionsTypes.SET_ACTIVE_CHILD>
  | GenericAction<DrawerActionsTypes.REMOVE_LAST_CHILD>
  | GenericAction<DrawerActionsTypes.ADD_NEW_CHILDREN, NewPageType>

export type DrawerContextType = Readonly<DrawerState> & {
  dispatch: React.Dispatch<DrawerAction>
  closeDrawer: () => void
  openDrawer: ({ nextChild, title }: NewPageType) => void
  nextPage: ({ nextChild, title }: NewPageType) => void
  backToPreviousPage: () => void
}

const DrawerDafultState: DrawerState = {
  isOpen: false,
  children: [],
  activeChild: null,
}

const drawerReducer: Reducer<DrawerState, DrawerAction> = (state, action) => {
  switch (action.type) {
    case DrawerActionsTypes.CLOSE_DRAWER:
      return {
        ...state,
        isOpen: false,
      }
    case DrawerActionsTypes.OPEN_DRAWER:
      return {
        ...state,
        isOpen: true,
      }
    case DrawerActionsTypes.SET_ACTIVE_CHILD:
      return {
        ...state,
        activeChild: state.children[state.children.length - 1],
      }
    case DrawerActionsTypes.ADD_NEW_CHILDREN:
      return {
        ...state,
        children: [...state.children, action.payload],
      }
    case DrawerActionsTypes.REMOVE_LAST_CHILD:
      const childrenCopy = [...state.children]
      childrenCopy.pop()
      return {
        ...state,
        children: [...childrenCopy],
      }
    case DrawerActionsTypes.CLEAR_CONTEXT:
      return {
        ...state,
        ...DrawerDafultState,
      }
    default:
      return state
  }
}

export function DrawerProvider({ children }: any) {
  const [state, dispatch] = useReducer(drawerReducer, DrawerDafultState)

  const closeDrawer = useCallback<DrawerContextType['closeDrawer']>(() => {
    dispatch({ type: DrawerActionsTypes.CLEAR_CONTEXT })
    dispatch({ type: DrawerActionsTypes.CLOSE_DRAWER })
  }, [])

  const nextPage = useCallback<DrawerContextType['nextPage']>(
    (nextPage: NewPageType) => {
      dispatch({ type: DrawerActionsTypes.ADD_NEW_CHILDREN, payload: nextPage })
      dispatch({ type: DrawerActionsTypes.SET_ACTIVE_CHILD })
    },
    []
  )

  const backToPreviousPage = useCallback<
    DrawerContextType['backToPreviousPage']
  >(() => {
    dispatch({ type: DrawerActionsTypes.REMOVE_LAST_CHILD })
    dispatch({ type: DrawerActionsTypes.SET_ACTIVE_CHILD })
  }, [])

  const openDrawer = useCallback<DrawerContextType['openDrawer']>(
    (nextPage: NewPageType) => {
      dispatch({ type: DrawerActionsTypes.OPEN_DRAWER })
      dispatch({ type: DrawerActionsTypes.ADD_NEW_CHILDREN, payload: nextPage })
      dispatch({ type: DrawerActionsTypes.SET_ACTIVE_CHILD })
    },
    []
  )

  const value = useMemo<DrawerContextType>(
    () => ({
      ...state,
      closeDrawer,
      dispatch,
      openDrawer,
      nextPage,
      backToPreviousPage,
    }),
    [state, closeDrawer, dispatch, openDrawer, nextPage, backToPreviousPage]
  )

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  )
}

export function useDrawerContext(): DrawerContextType {
  return useContext(DrawerContext)
}
