import React from 'react'
import { Navbar } from '../../shared'
import { Container } from '../../common'
import { ContactUsContainer } from './LandingContactUs.styles'
import { Forms } from '../../components/LandingContactUs'
import { useWorkspaceContext } from '../../contexts/workspace'
import { LanguageFloat } from '../../common'
import Footer from '../../shared/Footer'

const LandingContactUs = () => {
  const {
    impl: { ContactUsImpl },
  }: any = useWorkspaceContext()
  return (
    <div>
      <Navbar />
      <ContactUsContainer>
        <div className="pricing-background">
          <svg
            className="hero__container--curve"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 144.54 17.34"
            preserveAspectRatio="none"
            fill="#FFFF"
          >
            <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
          </svg>
        </div>
        <Container>
          <div>
            <Forms implForms={ContactUsImpl.form} />
          </div>
        </Container>
      </ContactUsContainer>
      <Footer />
      <LanguageFloat />
    </div>
  )
}

export default LandingContactUs
