import styled from 'styled-components'

export const ListVideoContainer = styled.div`
  background-color: white;
  .list-video {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 87px;
    @media only screen and (max-width: 750px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .list-video__general-1 {
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(73, 73, 73, 0.25);
    border-radius: 0px 0px 10px 10px;
    margin-right: 25px;
    margin-bottom: 50px;
    @media only screen and (max-width: 750px) {
      margin-right: 0px;
      width: 100%;
    }
  }

  .list-video__general-2 {
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(73, 73, 73, 0.25);
    border-radius: 0px 0px 10px 10px;
    margin-left: 25px;
    margin-bottom: 50px;
    @media only screen and (max-width: 750px) {
      margin-left: 0px;
      width: 100%;
    }
  }

  .list-video__general--video {
    width: 100%;
  }

  .list-video__general---title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #869ab8;
    padding-left: 10px;
  }
  .list-video__general--description {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    color: #161c2d;
    padding-left: 10px;
    padding-bottom: 20px;
  }
`
