import React, { useEffect } from 'react'
import { Avatar, Button, Text } from '../../common'
import { SubscribeLandingStyeld } from './SubscribeLanding.styles'
import ReactGA from 'react-ga'
import { useWorkspaceContext } from '../../contexts/workspace'
import { navigate } from '@reach/router'
import images from '../../assets/static/images'
import { FaArrowRight } from 'react-icons/fa'

const SubscribeLanding = () => {
  const { subscribedUser, subscribedWorkspace }: any = useWorkspaceContext()
  // _______Anaylicits
  useEffect(() => {
    ReactGA.pageview('/subscribedLanding')
  }, [])
  // _____
  return (
    <div>
      <SubscribeLandingStyeld>
        <div className="confetti">
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
        </div>
        <div className="congrats">
          <div className="congrats__content">
            <img src={images.logoBig} alt="" />
            <div className="congrats__content--avatar">
              <Avatar
                image={subscribedUser ? subscribedWorkspace.logo : ''}
                size="200px"
              ></Avatar>
            </div>
            <Text type="h1" align="center" weight="bold">
              Congratulations {subscribedUser ? subscribedUser.name : 'nombre'}{' '}
              for subscribibe {subscribedWorkspace.name}, enjoy it!
            </Text>
            <Text type="h3" align="center" color="#869AB8" weight="bold">
              Please login, to start using your Plan Business
            </Text>
            <Text type="p" align="center" size="14px">
              Check your email to see your PayPal subscription details.
            </Text>
            <Button
              onClick={() => {
                navigate('/login')
              }}
              size="large"
              color="primary"
              className="congrats__content--button"
            >
              Log In <FaArrowRight />
            </Button>
          </div>
        </div>
      </SubscribeLandingStyeld>
    </div>
  )
}

export default SubscribeLanding
