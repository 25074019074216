import styled from 'styled-components'

export const FeatureMainContainer = styled.div`
  background: #f6f6f6;

  .features__intro {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .features__intro--title {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 47px;
    letter-spacing: -0.05em;
    color: #4c4c4c;
    margin-top: 80px;
    width: 500px;
    text-align: center;
    @media screen and (max-width: 780px) {
      width: 100%;
    }
  }
  .features__intro--subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #6e839d;
    margin-top: 21px;
    width: 466px;
    text-align: center;
    @media screen and (max-width: 780px) {
      width: 100%;
    }
  }
  .features__list {
    margin-top: 50px;
    margin-bottom: 70px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    @media screen and (min-width: 1200px) {
      gap: 30px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .features__list--general {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 19px;
      background-color: #ffffff;
      border-radius: 10px;
      div {
        margin: 10px 10px;
        display: flex;
        align-items: left;
        justify-content: left;
      }
      @media screen and (min-width: 1200px) {
        width: 100%;
        height: 100%;
      }
    }

    .features__list-list {
      display: flex;
      flex-direction: column;
    }

    .features__list--title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: -0.05em;
      color: #232323;
    }
    .features__list--description {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: #232323;
      margin-top: 9px;
    }
  }
`
