import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { Button, Text } from '../../common'
import { useDrawerContext } from '../../contexts/DrawerContext'
import { DrawerStyled } from './Drawer.styles'

const DrawerComponent = () => {
  const { children, activeChild, isOpen, closeDrawer, backToPreviousPage } =
    useDrawerContext()
  return (
    <div>
      <Drawer
        open={isOpen}
        onClose={closeDrawer}
        direction="right"
        className="bla bla bla"
        size={400}
      >
        <DrawerStyled isFirstPage={children.length === 1}>
          <div className="drawer-header" data-aos="fade-left">
            {children.length > 1 ? (
              <>
                <Button
                  size="extraSmall"
                  color="light"
                  onClick={backToPreviousPage}
                >
                  <FaChevronLeft /> Back
                </Button>
                <Text type="h4" weight={600}>
                  {activeChild?.title}
                </Text>
              </>
            ) : (
              <Text type="h3" weight={600}>
                {activeChild?.title}
              </Text>
            )}
          </div>
          {activeChild?.nextChild}
        </DrawerStyled>
      </Drawer>
    </div>
  )
}

export default DrawerComponent
