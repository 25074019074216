const LoginImpl = {
  LoginImpl: {
    title: 'Login',
    emailnput: {
      label: 'Email',
      placeholder: 'Enter your email address',
    },
    passwordnput: {
      label: 'Password',
      placeholder: 'Enter your password',
    },
    loginBtn: 'Log In',
    loginGoogleBtn: 'Sign In with Google',
    forgotLabel: 'Forgot your password?',
    noaccountLabel: "Don't have an account?",
    registerBtn: 'Register',
    termsLabel: 'Terms & Conditions',
  },
}

export default LoginImpl
