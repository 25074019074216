const CheckoutImpl = {
  CheckoutImpl: {
    backBtn: 'Back',
    step1: 'Step 1: Select or create a team',
    step2: 'Step 2: Choose an account',
    step3: 'Step 3: Select Payment Method',
    rememberTitle: 'Remember Pro Plan',
    rememberItems: [
      '5 Teams with 15 members each included',
      'USD 1 per extra member in any team',
      'USD 25 per extra team',
    ],
    tableHeader1: '#Team ID',
    tableHeader2: 'Default Members',
    tableHeader3: 'Extra Members',
    tableHeader4: 'Total Members',
    tableHeader5: 'Price / extra members',
    planName: 'Plan Pro',
    additional: 'Additional',
    loggedTag: 'Logged as ',
    logginGoogle: 'Login with Google',
    changeLinkText: 'Change Account',
    optionCardText: 'Credit Card',
    subscriptionText: 'Subscription Plan Business',
    additionalMembers: 'Additional members',
    additionalTeams: 'Additional teams',
    discounts: 'Discounts: "Free additionals x 3 first months"',
    totalInfo:
      'The total amount will be the fixed monthly amount for your subscription',
    paymentTitle: 'Make payment',
    safePayment: 'Safe payments',
    terms:
      'By continuing with the purchase you accept our <a href="/terms" className="underline">Terms of Service</a>. We will process your personal data for the fulfillment of your order and other purposes in accordance with our <a href="/" className="underline">Privacy Policy</a>.',
    soonCreditCardPayment:
      'Soon you will be able to pay here directly with your credit or debit card safely. 😉',
    register: {
      nameInput: {
        label: 'Name',
        placeholder: 'Enter your name',
      },
      lastNameInput: {
        label: 'Lastname',
        placeholder: 'Enter your lastname',
      },
      emailInput: {
        label: 'Email',
        placeholder: 'Enter your email',
      },
      passwordInput: {
        label: 'Password',
        placeholder: 'Enter your password',
      },
      confirmPasswordInput: {
        label: 'Confirm password',
        placeholder: 'Enter your password again',
      },
      registerBtn: 'Register',
      acceptTermsLabel: 'Accept terms and conditions',
    },
    features: [
      {
        name: 'All the benefits of the startup plan',
      },
      {
        name: 'Free team space',
      },
      {
        name: 'Full access space',
      },
      {
        name: 'Members: 10/team',
      },
      {
        name: 'Group spaces: Unlimited',
      },
      {
        name: 'Video Conference provider: All',
      },
      {
        name: 'Embed integrations: +50 *',
        comingSoon: 'Coming soon',
      },
      {
        name: 'API integrations: +50 *',
        comingSoon: 'Coming soon',
      },
      {
        name: 'Fast links: Unlimited *',
        comingSoon: 'Coming soon',
      },
    ],
    promoMsg: 'Get 20% of discount subscribing annually',
    promoBtn: 'Contact Sales',
  },
}

export default CheckoutImpl
