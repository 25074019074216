import styled, { css } from 'styled-components'

export const TooltipStyled = styled.div`
  position: relative;
  display: inline-block;
  .tooltipWraper {
    /* visibility: hidden; */
    min-width: 100px;
    background-color: #161c2d;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 5px;
    position: absolute;
    display: inline-block;
    display: none;
    /* top: 22%;
    left: 110%; */
    z-index: 2;
    ${({ position }) =>
      position === 'right'
        ? css`
            top: 22%;
            left: 110%;
            &::before {
              content: '';
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-right: 5px solid #161c2d;
              position: absolute;
              right: 100%;
              top: 33%;
              z-index: 2;
            }
          `
        : position === 'left'
        ? css`
            top: 22%;
            right: 110%;
            &::before {
              content: '';
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 5px solid #161c2d;
              position: absolute;
              left: 100%;
              top: 33%;
              z-index: 2;
            }
          `
        : position === 'top'
        ? css`
            bottom: 110%;
            right: 0%;
            &::before {
              content: '';
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #161c2d;
              position: absolute;
              left: 45%;
              top: 100%;
              z-index: 2;
            }
          `
        : position === 'bottom'
        ? css`
            top: 110%;
            right: 0%;
            &::before {
              content: '';
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 5px solid #161c2d;
              position: absolute;
              left: 45%;
              bottom: 100%;
              z-index: 2;
            }
          `
        : css`
            top: 22%;
            left: 110%;
            &::before {
              content: '';
            }
          `}
  }
  &:hover .tooltipWraper {
    /* visibility: visible; */
    display: inline;
  }
`
