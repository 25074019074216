import { useMutation } from '@apollo/client'
import { useAuthState } from '../contexts/auth'
import { useWorkspaceContext } from '../contexts/workspace'
import {
  CREATE_ROOM,
  SEND_CALL,
  SEND_EXISTING_CALL,
} from '../graphQL/Mutations'
import ReactGA from 'react-ga'
import { MemberType } from '../types/workspaceTypes'

export const useCreateRoom = () => {
  const { handleRoom }: any = useWorkspaceContext()
  const [createRoom] = useMutation(CREATE_ROOM, {
    onCompleted: data => {
      const room = data.createRoom
      handleRoom({
        id: room.id,
        roomName: room.roomName,
        roomPassword: room.roomPassword,
        active: true,
      })
    },
    onError: err => {
      console.log(err)
    },
  })

  return [createRoom]
}

export const useSendCall = (member: MemberType) => {
  const { user } = useAuthState()
  const { workspace, room }: any = useWorkspaceContext()
  const [sendCall] = useMutation(SEND_CALL, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCompleted: data => {
      // console.log(data)
      // _______Anaylicits
      ReactGA.event({
        category: 'Llamadas',
        action: `[Llamada]: ${user.email} a ${member.email}`,
      })
      // _____
    },
    onError: err => {
      console.log(err)
    },
  })
  const [sendExisitingCall] = useMutation(SEND_EXISTING_CALL, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCompleted: data => {},
    onError: err => {
      console.log(err)
    },
  })
  const handleSendExistCall = (id: string) => {
    sendExisitingCall({
      variables: {
        idRoom: room.id,
        idWorkspace: workspace.id,
        to: id,
      },
    })
  }
  const handleSendCall = () => {
    if (room.active === false) {
      // sendExistingCall
      sendCall({ variables: { to: member.id, idWorkspace: workspace.id } })
    } else {
      handleSendExistCall(member.id)
    }
  }

  return [handleSendCall]
}
