const CheckoutImpl = {
  CheckoutImpl: {
    backBtn: 'Volver',
    step1: 'Step 1: Elige o crea un equipo',
    step2: 'Step 2: Selecciona una cuenta',
    step3: 'Step 3: Selecciona el método de pago',
    rememberTitle: 'Rercuerda Pro Plan:',
    rememberItems: [
      '5 equipos con 15 miembros incluidos',
      'USD 1 por miembro extra en cualquier equipo',
      'USD 25 por equipo extra',
    ],
    tableHeader1: '#Team ID',
    tableHeader2: 'Miembros base',
    tableHeader3: 'Miembros Extra',
    tableHeader4: 'Total de Miembros',
    tableHeader5: 'Precio / miembros extra',
    planName: 'Plan Pro',
    additional: 'Adicionales',
    loggedTag: 'Inicio sesion como ',
    logginGoogle: 'Login with Google',
    changeLinkText: 'Cambiar de cuenta',
    optionCardText: 'Tarjeta de credito',
    subscriptionText: 'Suscripcion Plan Business',
    additionalMembers: 'Miembros adicionales',
    additionalTeams: 'Equipos adicionales',
    discounts: 'Descuentos: "Adicionales Gratis x 3 primeros meses"',
    totalInfo: 'El monto total será el monto fijo mensual de tu suscripción.',
    paymentTitle: 'Realizar pago',
    safePayment: 'Pagos seguros ',
    terms:
      'Al continuar con la compra aceptas nuestros <a href="/terms">Términos del Servicio</a>. Procesaremos tus datos personales para el cumplimiento de tu pedido y otros propósitos según nuestra <a href="/">Política de privacidad</a>.',
    soonCreditCardPayment:
      'Muy pronto podras pagar aqui directamente con tu tarjeta de credito o debito de manera segura.  😉',
    register: {
      nameInput: {
        label: 'Nombre',
        placeholder: 'Ingresa tu nombre',
      },
      lastNameInput: {
        label: 'Apellido',
        placeholder: 'Ingresa tu apellido',
      },
      emailInput: {
        label: 'Correo Electrónico',
        placeholder: 'Ingresa tu correo electrónico',
      },
      passwordInput: {
        label: 'Contraseña',
        placeholder: 'Ingresa una contraseña',
      },
      confirmPasswordInput: {
        label: 'Confirma tu contraseña',
        placeholder: 'Confirma tu contraseña',
      },
      registerBtn: 'Registrar',
      acceptTermsLabel: 'Acepto los terminos y condiciones',
    },
    features: [
      {
        name: 'Todos los beneficios del plan startup',
      },
      {
        name: 'Espacio gratuito',
      },
      {
        name: 'Espacio con acceso total',
      },
      {
        name: 'Miembros/equipo: 10',
      },
      {
        name: 'Espacios grupales: Ilimitado',
      },
      {
        name: 'Proveedor de video conferencia: Todos',
      },
      {
        name: 'Integraciones embebidas: +50 *',
        comingSoon: 'Pronto',
      },
      {
        name: 'Integraciones con API: +50 *',
        comingSoon: 'Pronto',
      },
      {
        name: 'Enlaces rapidos: Ilimitados *',
        comingSoon: 'Pronto',
      },
    ],
    promoMsg: 'Obten 20% de descuento suscribiendote anualmente',
    promoBtn: 'Contactar a Ventas',
  },
}

export default CheckoutImpl
