import React from 'react'
import { BadgeStyled } from './Badge.styles'

interface BadgeProps {
  children: any
  color: string
  fontSize?: string
  lineHeight?: string
  backchildren?: any
  customClass?: string
  padding?: string
  margin?: string
}

const Badge = ({
  children,
  color,
  backchildren,
  fontSize,
  lineHeight,
  customClass,
  padding,
  margin,
}: BadgeProps): JSX.Element => {
  return (
    <BadgeStyled
      backchildren={backchildren}
      fontSize={fontSize}
      lineHeight={lineHeight}
      className={customClass}
      padding={padding}
      margin={margin}
      color={color}
    >
      <div className="frontside">{children}</div>
      <div className="backside">{backchildren}</div>
    </BadgeStyled>
  )
}

export default Badge
