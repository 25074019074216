import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import images from '../../assets/static/images'
import {
  Alert,
  Anchor,
  Button,
  Input,
  LanguageFloat,
  Loader,
  Text,
} from '../../common'
import { useAuthDispatch } from '../../contexts/auth'
import { LOGIN_MUTATION } from '../../graphQL/Mutations'
import { LoginStyled } from './Login.styles'
import ReactGA from 'react-ga'
import { useWindow } from '../../hooks/useWindow'
import { useWorkspaceContext } from '../../contexts/workspace'
// import { FaGoogle } from 'react-icons/fa'

const Login = () => {
  const [width] = useWindow()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useAuthDispatch()
  const {
    impl: { LoginImpl: impl },
  }: any = useWorkspaceContext()
  const [errors, setErrors] = useState({})
  const [loginUser, { loading }] = useMutation(LOGIN_MUTATION, {
    update: (_, { data: { login } }) => {
      dispatch({ type: 'LOGIN', payload: login })
      ReactGA.event({
        category: 'Inicio de sesion',
        action: `Nuevo Usuario: ${email}`,
      })
      window.location.href = '/home'
    },
    onError: (err: any) => {
      setErrors(err.graphQLErrors[0].extensions.errors)
      setTimeout(() => {
        setErrors({})
      }, 3000)
    },
  })
  useEffect(() => {
    ReactGA.pageview('/login')
  }, [])
  const handleEmail = (value: string) => {
    if (
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        value
      )
    ) {
      setEmailError('')
    } else {
      setEmailError('Please enter a valid email')
    }
    if (value === '') {
      setEmailError('')
    }
    setEmail(value)
  }
  const handlePassword = (value: string) => {
    setPassword(value)
  }
  const handleSumbit = (e: any) => {
    e.preventDefault()
    loginUser({
      variables: {
        email,
        password,
      },
    })
  }

  // const handleLoginGoogle = () => {
  //   window.localStorage.setItem('authGoogle', 'true')
  //   window.open(
  //     process.env.REACT_APP_BACKEND_URL + '/auth/login/google',
  //     '_self'
  //   )
  // }
  const handleDisabled = () => {
    if (email !== '' && password !== '' && emailError === '') {
      return false
    }
    return true
  }
  return (
    <div>
      <LoginStyled backgroundImage={images.loginBackground}>
        <div className="main-content">
          <Anchor route="/landing" type="link">
            <img
              src={images.logoAuth}
              alt="logo"
              className="main-content__logo"
            />
          </Anchor>
          <Text type="h1" className="main-content__title">
            {impl.title}
          </Text>
          <form onSubmit={handleSumbit} className="login-form">
            <Input
              value={email}
              onChange={handleEmail}
              validationError={emailError}
              label={impl.emailnput.label}
              placeHolder={impl.emailnput.placeholder}
              className="login-form__input"
            />
            <Input
              value={password}
              onChange={handlePassword}
              validationError={''}
              label={impl.passwordnput.label}
              placeHolder={impl.passwordnput.placeholder}
              type="password"
              className="login-form__input"
            />
            <div className="login-form__input">
              <Anchor route="/forgot" type="link" targetBlank>
                {impl.forgotLabel}
              </Anchor>
            </div>
            <Button
              size="base"
              color="primary"
              onClick={() => {}}
              disabled={loading || handleDisabled()}
              block
              className="login-form__button"
            >
              {loading ? (
                <Loader size="25px" border="3px" />
              ) : (
                `${impl.loginBtn}`
              )}
            </Button>
          </form>
          <br />
          {/* {loading ? <Loader size="25px" border="3px" /> : `${impl.loginBtn}`} */}
          {/* <Button
            size="base"
            color="danger"
            onClick={handleLoginGoogle}
            disabled={false}
            block
            outlined
          >
            <div className="loading__google">
              {impl.loginGoogleBtn}
              <FaGoogle />
            </div>
          </Button> */}
          {Object.keys(errors).length > 0 && (
            <div className="alert">
              {Object.values(errors).map((value: any) => (
                <Alert color="danger" key={value}>
                  <p>{value}</p>
                </Alert>
              ))}
            </div>
          )}
          <div className="main-content__footer">
            <Text type="p" align="center">
              {impl.noaccountLabel}
            </Text>
            <div className="main-content__footer--link">
              <Anchor route="/register" type="link">
                {impl.registerBtn}
              </Anchor>
            </div>
            <div className="main-content__footer--link main-content__footer--footer">
              <Anchor route="/terms" type="link">
                {impl.termsLabel}
              </Anchor>
            </div>
          </div>
        </div>
        {width > 900 && <div className="side-background" />}
      </LoginStyled>
      <LanguageFloat />
    </div>
  )
}

export default Login
