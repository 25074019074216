const HomeImpl = {
  HomeImpl: {
    header: {
      welcomeTitle: '!Bienvenido de nuevo!',
      inputPlaceholder: '🔍  Buscar Zyrebox por nombre',
      profileBtn: 'Mi Perfil',
    },
    zyreboxList: {
      newZyrebox: 'Nuevo Zyrebox',
    },
    footer: {
      termsPreLabel: 'Lea nuestros',
      termsLabel: 'Terminos & Condiciones',
      contactUsLabel: 'Contáctanos',
      feedbackHover: 'Déjanos tu feedback',
      feedbackTitle: '!Gracias por ser parte de Zyrebox! 🎉',
      feedbackPlaceholder:
        'Déjanos tu feedback sobre nuestro servicio, asi podremos mejorar tu experiencia.',
      feedbackSuccess: (name: string) => {
        return `!Gracias por tu feedback ${name}! 😉`
      },
      feedbackError: 'Lo sentimos, no hemos podido enviar tu feedback 🥺',
    },
  },
}

export default HomeImpl
