import styled, { css } from 'styled-components'

export const AppsContainerStyled = styled.div`
  .card-wraper {
    top: auto;
    left: 75px !important;
    display: inline-block !important;
  }
  .tooltip {
    width: 100%;
  }
  .tooltipWraper {
    top: 1%;
    left: 70px;
  }
`
export const ApplicationStyled = styled.div`
  width: 100%;
  background: #1b2a4e;
  border-radius: 2px;
  padding: 3px 0px;
  margin-bottom: 16px;
  color: #ffffff;
  display: grid;
  place-items: center;
`

export const ContentAppStyled = styled.div`
  margin: 14px 0px;
  display: grid;
  gap: 18px;
  /* UNCOMMENT WHEN WHEN APP LIST IS UNCOMMENT */
  grid-template-columns: repeat(3, 1fr);
  /* ${props =>
    props.subscribed
      ? css`
          grid-template-columns: repeat(3, 1fr);
        `
      : css`
          grid-template-columns: repeat(2, 1fr);
        `} */

  .app-item {
    border: 1px solid #e5ebfb;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    padding: 12px;
    display: grid;
    place-items: center;
    &:hover {
      background-color: #383838;
      svg {
        color: #fff !important;
      }
    }
    img {
      width: 37px;
      height: 37px;
      border-radius: 3px;
    }
  }
  .active {
    background-color: #232323;
    svg {
      color: #fff !important;
    }
  }
`
