import styled, { css } from 'styled-components'
import { fadeIn } from '../../styles/animation'

export const AlertStyled = styled.div`
  padding: 4px 10px;
  @media (min-height: 600px) {
    padding: 7px 10px;
  }
  @media (min-height: 800px) {
    padding: 10px 15px;
  }
  @media (min-height: 900px) {
    padding: 12px 20px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'HKGrotesk', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  width: 100%;
  border-radius: 5px;
  ${fadeIn({ time: '0.2s' })};
  button {
    cursor: pointer;
  }
  ${({ color }) =>
    color === 'primary'
      ? css`
          background-color: #232323;
          color: #fff;
          button {
            color: #fff;
          }
        `
      : color === 'secondary'
        ? css`
          background-color: #506690;
          color: #fff;
          button {
            color: #fff;
          }
        `
        : color === 'success'
          ? css`
          background-color: #42ba96;
          color: #fff;
          button {
            color: #fff;
          }
        `
          : color === 'warning'
            ? css`
          background-color: #fad776;
          color: #1b2a4e;
          button {
            color: #1b2a4e;
          }
        `
            : color === 'danger'
              ? css`
          background-color: #df4759;
          color: #fff;
          button {
            color: #fff;
          }
        `
              : color === 'info'
                ? css`
          background-color: #6f5aec;
          color: #fff;
          button {
            color: #fff;
          }
        `
                : color === 'light'
                  ? css`
          background-color: #f9fbfd;
          color: #1b2a4e;
          button {
            color: #1b2a4e;
          }
        `
                  : color === 'dark'
                    ? css`
          background-color: #1b2a4e;
          color: #fff;
          button {
            color: #fff;
          }
        `
                    : css`
          background-color: #fff;
          color: #000;
          button {
            color: #fff;
          }
        `}
`
