import styled from 'styled-components'

export const TextStyled = styled.p`
  font-family: 'HKGrotesk', sans-serif;
  font-style: ${props => (props.fontStyle ? props.fontStyle : 'normal')};
  font-weight: ${props => (props.weight ? props.weight : '400')};
  text-align: ${props => (props.align ? props.align : 'left')};
  line-height: ${props =>
    props.type === 'h1'
      ? '48px'
      : props.type === 'h2'
      ? '40px'
      : props.type === 'h3'
      ? '27px'
      : props.type === 'h4'
      ? '25px'
      : props.type === 'h5'
      ? '22px'
      : props.type === 'h6'
      ? '16px'
      : '22px'};
  color: ${props => (props.color ? props.color : '#161C2D')};
  font-size: ${props =>
    props.type === 'h1'
      ? '37px'
      : props.type === 'h2'
      ? '31px'
      : props.type === 'h3'
      ? '21px'
      : props.type === 'h4'
      ? '19px'
      : props.type === 'h5'
      ? '17px'
      : props.type === 'h6'
      ? '12px'
      : props.size};
  margin-bottom: ${props =>
    props.type === 'h1'
      ? '16px'
      : props.type === 'h2'
      ? '16px'
      : props.type === 'h3'
      ? '8px'
      : props.type === 'h4'
      ? '8px'
      : props.type === 'h5'
      ? '8px'
      : props.type === 'h6'
      ? '8px'
      : '8px'};
  margin-bottom: ${props => props.size && '0px'};
  line-height: ${props => props.lineHeight && props.lineHeight};
  display: ${props => props.display && props.display};
`
