import React from 'react'
import images from '../../../assets/static/images'
import { SwitchSidesMain } from './SwitchSides.styles'

interface SwitchsidesProps {
  implSwitchsides: any
}

const SwitchSides = ({
  implSwitchsides: {
    firstmaintitle,
    firstdescriptionone,
    firstdescriptiontwo,
    secondtitle,
    seconddescriptionone,
    seconddescriptiontwo,
  },
}: SwitchsidesProps) => {
  return (
    <div>
      <SwitchSidesMain>
        <div className="switch-content">
          <div
            className="switch-content__text"
            id="text__one"
            data-aos="zoom-in"
          >
            <h3 className="switch-content__text--title">{firstmaintitle}.</h3>
            <p className="switch-content__text--description">
              {firstdescriptionone}
            </p>
            <p className="switch-content__text--description">
              {firstdescriptiontwo}
            </p>
          </div>
          <div className="switch-content__img" id="img__one" data-aos="zoom-in">
            <img src={images.switchSideOne} alt="switchSideOne" />
          </div>
          <div
            className="switch-content__text"
            id="text__two"
            data-aos="zoom-in"
          >
            <h3 className="switch-content__text--title">{secondtitle}</h3>
            <p className="switch-content__text--description">
              {seconddescriptionone}{' '}
            </p>
            <p className="switch-content__text--description">
              {seconddescriptiontwo}
            </p>
          </div>
          <div className="switch-content__img" id="img__two" data-aos="zoom-in">
            <img src={images.switchSideTwo} alt="switchSideOne" />
          </div>
        </div>
      </SwitchSidesMain>
    </div>
  )
}

export default SwitchSides
