const ContactUsImpl = {
  ContactUsImpl: {
    form: {
      alertgood: 'Message sent successfully',
      alertbad: 'Ups! An error has occurred. Try Again',
      maintitle: 'Contact Us!',
      subtitle:
        'Write us throught this channel and our team will resolve all your doubts',
      nameform: 'Name and Surname',
      nameplaceholder: 'Enter your Name and Surname',
      emailform: 'Email',
      emailplaceholder: 'Enter a Email',
      summaryform: 'Summary',
      summaryplaceholder:
        'Enter a summary. Ex. "Custom plan", "Application", "Back to basic"',
      messageform: 'Message',
      messageplaceholder: 'Enter a message',
      sendform: 'Send message',
    },
  },
}

export default ContactUsImpl
