import styled from 'styled-components'

export const FormsContainer = styled.div`
  .forms-alerts-wraper {
    position: fixed;
    width: 47.5vw;
    top: 100px;
    /* left: 0; */
  }
  .forms-header {
    text-align: center;
  }

  .forms-header__title {
    font-style: normal;
    line-height: 72px;
    text-align: center;
    font-size: 55px;
    font-weight: 500;
    letter-spacing: -0.05em;
    color: #ffffff;
    margin: 78px;
    margin-top: 100px;
  }
  .forms-description {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    margin-top: 47px;
  }
  .forms-container {
    margin-top: 78px;
    margin-bottom: 165px;
    padding: 50px 50px;
    background: #ffffff;
    box-shadow: 0px 4px 30px rgba(163, 163, 163, 0.25);
    border-radius: 7px;
  }
  .forms-container__title {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 8px;
    color: #161c2d;
  }
  .forms-container__input {
    margin-bottom: 18px;
    border-color: #fa27a9;
  }
  .forms-container__submit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 36px;
  }
  .forms-container__submit--button {
    margin-left: 15px;
  }
  @media (max-width: 600px) {
    .forms-header__title {
      font-style: normal;
      line-height: 72px;
      text-align: center;
      font-size: 55px;
      font-weight: 500;
      letter-spacing: -0.05em;
      color: #ffffff;
      margin: 0px;
      margin-top: 150px;
    }
  }
`
