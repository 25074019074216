import styled, { css } from 'styled-components'

export const ActionStyled = styled.div`
  display: inline;
`

export const ModalStyled = styled.div`
  display: none;
  position: fixed;
  z-index: 50;
  /* padding-top: 25em !important; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  .modal-content {
    background-color: #f9fbfd;
    border-radius: 5px;
    padding: 20px;
  }
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  ${props =>
    props.show &&
    css`
      display: grid;
      place-items: center;
    `}
`
