const ContactUsImpl = {
  ContactUsImpl: {
    form: {
      alertgood: 'Mensaje enviado exitosamente',
      alertbad: 'Ups! Un error ocurrio, intente nuevamente',
      maintitle: 'Contactanos!',
      subtitle:
        'Escribenos directamente por este medio y nuestro equipo resolvera todas tus dudas',
      nameform: 'Nombre y apellidos',
      nameplaceholder: 'Ingresa tu nombre y apellidos',
      emailform: 'Email',
      emailplaceholder: 'Ingresa tu Email',
      summaryform: 'Asunto',
      summaryplaceholder:
        'Ingresa un asunto. Ej. "Plan Personalizado", "Postular", "Volver a basico"',
      messageform: 'Mensaje',
      messageplaceholder: 'Ingresa tu mensaje',
      sendform: 'Enviar mensaje',
    },
  },
}

export default ContactUsImpl
