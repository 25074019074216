import styled from 'styled-components'

export const FooterStyled = styled.div`
  background-color: #232323;
  hr {
    height: 0;
    border: 1px solid #576980;
  }
  .footer-main {
    p {
      margin-top: 10px;
    }
    margin-top: 48px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media screen and (min-width: 600px) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }
    &__social {
      &--links-container {
        display: flex;
        gap: 5px;
        .social-link {
          cursor: pointer;
          color: rgba(110, 131, 157, 1);
        }
      }
      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    &__links {
      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .common-link {
      margin-top: 18px;
      display: block;
      font-family: 'HKGrotesk', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;

      color: #6e839d;
    }
    .footer-footer {
      margin-top: 120px;
      grid-column: 1/2;
      @media screen and (min-width: 600px) {
        grid-column: 2/5;
      }
    }
  }
`
