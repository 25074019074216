import React from 'react'
import { Text } from '..'
import { useWorkspaceContext } from '../../contexts/workspace'
import { YouDontBelongStyled } from './YouDontBelong.styles'

const YouDontBelong = () => {
  const { darkMode }: any = useWorkspaceContext()
  return (
    <YouDontBelongStyled>
      {darkMode ? (
        <>
          <Text type="h1" color="#fff" align="center">
            Ups! :(
          </Text>
          <Text type="h3" color="#fff" align="center">
            Seems like you don&apos;t have access to this Zyrebox
          </Text>
        </>
      ) : (
        <>
          <Text type="h1" color="#161C2D" align="center">
            Ups! :(
          </Text>
          <Text type="h3" color="#161C2D" align="center">
            Seems like you don&apos;t have access to this Zyrebox
          </Text>
        </>
      )}
    </YouDontBelongStyled>
  )
}

export default YouDontBelong
