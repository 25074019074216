import React, { useEffect, useState } from 'react'
import { IoIosRemoveCircle } from 'react-icons/io'
import { Button, Input, Modal, Text } from '../../../common'
import Avatar from '../../../common/Avatar/Avatar'
import Loader from '../../../common/Loader/Loader'
import { DELETE_ROOM } from '../../../graphQL/Mutations'
import { useMutation } from '@apollo/client'
import { message } from 'antd'

interface GroupListProps {
  rooms: any
  loading: any
  updateQuery: () => void
}
interface RemoveModalProps {
  name: string
  id: string
  updateQuery: () => void
}
const RemoveModal = ({
  name,
  id,
  updateQuery,
}: RemoveModalProps): JSX.Element => {
  const [show, setShow] = useState(false)
  const handleModal = () => {
    setShow(!show)
  }
  const [removeMember] = useMutation(DELETE_ROOM, {
    onCompleted: () => {
      // const filteredMembers = workspaceMemebers.filter(member => member.id !== memberForRemove.id)
      message.info('Room deleted successfully')
      updateQuery()
      // handleWorkspace({ ...workspace, members: filteredMembers })
      // setTimeout(navigate(`/workspace/${data.createWorkspace.id}`), 7000)
    },
    onError: err => {
      message.error(err.message)
      // console.log(err)
    },
  })
  const handleDelteMember = () => {
    removeMember({
      variables: {
        id: id,
      },
    })
    handleModal()
  }
  return (
    <Modal
      handleModal={handleModal}
      show={show}
      action={<IoIosRemoveCircle color="#DF4759" size="24px" />}
    >
      <div className="group-modal__content">
        <Text type="p" size={'17px'}>
          Are you sure you want to remove <strong>{name}</strong> from your
          Zyrebox?
        </Text>
        <Button onClick={handleDelteMember} size="base" color="danger">
          DELETE
        </Button>
      </div>
    </Modal>
  )
}

const GroupList = ({ rooms, loading, updateQuery }: GroupListProps) => {
  const [filteredRooms, setFilteredRooms] = useState<any>([])
  const roomsCopy = [...rooms]
  const [search, setSearch] = useState('')
  const handleFilter = (value: string) => {
    setSearch(value)
    const filtered = roomsCopy.filter(room =>
      room.name.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredRooms([...filtered])
  }
  useEffect(() => {
    setFilteredRooms(rooms)
  }, [rooms])
  return (
    <div>
      <Input
        placeHolder="🔍  Search room by name, last name or email"
        value={search}
        onChange={handleFilter}
        validationError={''}
        className="input-field"
      />
      <div className="group-list">
        {!loading ? (
          filteredRooms.map((room: any, key: number) => (
            <div className="group" key={key}>
              <div className="group__item">
                <Avatar
                  size="45px"
                  image={room.logo}
                  className="group__item--avatar"
                  backgroundColor="#506690"
                />
                <div className="group__item--info">
                  <Text type="p">{room.name}</Text>
                </div>
                <div className="group__item--action">
                  <RemoveModal
                    name={room.name}
                    id={room.id}
                    updateQuery={updateQuery}
                  />
                </div>
              </div>
              <hr className="mt-1" />
            </div>
          ))
        ) : (
          <div className="loader">
            <Loader />
          </div>
        )}
      </div>
    </div>
  )
}

export default GroupList
