import styled, { css } from 'styled-components'
import { fadeIn } from '../../../styles/animation'

export const IncomingCallContainer = styled.div`
  ${fadeIn({ time: '0.2s' })};
  position: absolute;
  width: 450px;
  height: 80px;
  background: #fcfdff;
  box-shadow: 0px 4px 30px #e1edff;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  right: 4vw;
  top: 7vh;
  z-index: 10;
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #34343a;
  }
  animation: 1.5s ease 0s infinite beat;
  @media (min-width: 1400px) {
    right: 4vw;
  }
  @keyframes beat {
    0%,
    50%,
    100% {
      transform: scale(1, 1);
    }
    30%,
    80% {
      transform: scale(0.94, 0.95);
    }
  }
`

export const UserImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 100px;
  ${props =>
    props.image &&
    css`
      background: url(${props.image});
      background-size: cover;
    `}
`

export const ActionsContainer = styled.button`
  width: 110px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .answer {
    padding: 10px;
    background-color: #ebf5f2;
    border-radius: 100px;
    &:hover {
      background-color: #d4fbef;
      cursor: pointer;
    }
  }
  .hangUp {
    padding: 10px;
    background-color: #f8eced;
    border-radius: 100px;
    &:hover {
      background-color: #fad0d4;
      cursor: pointer;
    }
  }
`
