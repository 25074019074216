import LandingMainImpl from './LandingMain'
import MainZyreboxImpl from './MainZyrebox'
import ContactUsImpl from './LandingContactUs'
import PricingImpl from './LandingPricing'
import ConfigZyreboxImpl from './ConfigZyrebox'
import CreateZyreboxImpl from './CreateZyrebox'
import ForgotPasswordImpl from './ForgotPassword'
import HomeImpl from './Home'
import LoginImpl from './Login'
import RegisterImpl from './Register'
import SettingsZyreboxImpl from './SettingsZyrebox'
import SubscribeImpl from './Subscribe'
import CheckoutImpl from './Checkout'
import TermsImpl from './Terms'

const ImplES = {
  ...MainZyreboxImpl,
  ...LandingMainImpl,
  ...ContactUsImpl,
  ...PricingImpl,
  ...SettingsZyreboxImpl,
  ...ConfigZyreboxImpl,
  ...HomeImpl,
  ...CreateZyreboxImpl,
  ...SubscribeImpl,
  ...LoginImpl,
  ...RegisterImpl,
  ...ForgotPasswordImpl,
  ...CheckoutImpl,
  ...TermsImpl,
}

export default ImplES
