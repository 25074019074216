import styled from 'styled-components'

export const HomeFooterStyled = styled.div`
  margin-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  .feedback {
    position: absolute;
    z-index: 1;
    top: -150%;
    right: 100px;
    .feedback__message {
      height: 60px;
      width: 60px;
      background-color: ${props => (props.darkMode ? '#F9FBFD' : '#232323')};
      border-radius: 50px;
      display: grid;
      place-items: center;
      cursor: pointer;
      position: relative;
      .feedback__tooltip {
        display: none;
        position: absolute;
        right: 100%;
        color: ${props => (props.darkMode ? '#232323' : '#F9FBFD')};
        background-color: ${props => (props.darkMode ? '#F9FBFD' : '#232323')};
        padding: 10px;
        z-index: 2;
        width: 200px;
        border-radius: 50px;
        text-align: center;
      }
      &:hover {
        .feedback__tooltip {
          display: block;
        }
      }
      .feedback__input {
        position: absolute;
        right: 100%;
        bottom: 100%;
        background-color: ${props => (props.darkMode ? '#F9FBFD' : '#232323')};
        padding: 30px;
        z-index: 2;
        min-width: 350px;
        border-radius: 10px;
        text-align: center;
        hr {
          margin: 10px 0px;
          height: 0px;
          border: 1px solid #94a5c0;
        }
        .feature__buttonContainer {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
`
