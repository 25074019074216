import styled from 'styled-components'

export const AdvanceSettingsStyled = styled.div`
  position: relative;
  margin-top: 70px;
  .card {
    padding: 30px;
    margin-top: 16px;
    margin-bottom: 50px;
    min-height: 600px;
    max-height: 700px;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(153, 153, 153, 0.25);
    border-radius: 5px;
    .advance-delete {
      margin: 24px 0px 28px 0px;
      &__action {
        display: inline;
      }
    }
    .improve-plan {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      &__current {
        display: flex;
        align-items: center;
        margin: 0px;
        .improve-plan__current--badge {
          width: 100px;
          height: 28px;
          margin-left: 24px;
          div {
            padding: 5px 0px;
          }
        }
      }
    }
  }
  .advance-modal-content {
    padding: 60px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    p {
      margin-bottom: 40px;
    }
  }
`
