import styled, { css } from 'styled-components'

export const PopOverCardStyled = styled.div`
  position: relative;
  &:hover {
    cursor: pointer;
    .pathcard,
    .pathcard:hover {
      position: absolute;
      display: block;
      left: 100%;
      z-index: 1;
      cursor: pointer;
    }
    .hide,
    .hide:hover {
      position: absolute;
      display: block;
      left: 130%;
      cursor: pointer;
    }
  }
  .pathcard {
    ${props =>
    props.pathSize === 'small' &&
    css`
        width: 70px;
        height: 30px;
      `}
  }
`
export const PathCard = styled.div`
  width: 90px;
  height: 50px;
  position: absolute;
  top: 0px;
  z-index: 1;
`

export const Card = styled.div`
  min-width: 260px;
  display: none;
  color: #343434;
  background: #fff;
  filter: drop-shadow(-4px 4px 10px rgba(153, 153, 153, 0.25));
  padding: 25px 25px;
  border-radius: 3px;
  /* position: absolute; */
  position: fixed;
  top: 0px;
  z-index: 2;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #fff;
    position: absolute;
    right: 100%;
    top: 10px;
    z-index: 2;
  }
  ${props =>
    props.customClass &&
    css`
      &::after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 40px solid transparent;
        border-right: 40px solid transparent;
        position: absolute;
        right: 90%;
        top: 10px;
        z-index: 2;
      }
    `}
`
