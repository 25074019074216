import React from 'react'

const CreateZyreboxImpl = {
  CreateZyreboxImpl: {
    loadingCreate: 'Creando tu nuevo Zyrebox...',
    backlabel: 'Volver',
    title: 'Crear Zyrebox',
    nameInput: {
      label: 'Nombre del Zyrebox *',
      placeholder: 'Nombre del Zyrebox',
    },
    companyInput: {
      label: 'Nombre de Empresa *',
      placeholder: 'Nombra tu organización',
    },
    addByEmailInput: {
      label: 'Añadir miembros por correo',
      placeholder: 'Escribe un correo y presiona en Agregar',
    },
    addBtn: 'Agregar',
    descriptionInput: {
      label: 'Descripción del Zyrebox *',
      placeholder: 'Escribe algo sobre tu equipo',
    },
    createAlert: (
      <>
        <strong>IMPORTANTE:</strong> Una vez hayas creado tu Zyrebox, solo
        podrás ver a aquellos que tengan una cuenta en Zyrebox. A los demás, les
        enviaremos un correo con tu invitación a este Zyrebox.
      </>
    ),
    createBtn: 'Crear',
  },
}

export default CreateZyreboxImpl
