import React from 'react'
import { Container } from '../../../common'
import { FeatureMainContainer } from './Features.styles'

interface FeaturesProps {
  implFeatures: any
}

const Features = ({
  implFeatures: { featurelist, maintitle, subtitle },
}: FeaturesProps) => {
  return (
    <div id="features-landing">
      <FeatureMainContainer>
        <Container>
          <div>
            <div className="features__intro">
              <h2 className="features__intro--title">{maintitle}</h2>
              <p className="features__intro--subtitle">{subtitle}</p>
            </div>
            <div className="features__list">
              {featurelist.map((feature: any, key: number) => (
                // <div data-aos="flip-left" data-aos-duration="2500" key={key}>
                <div
                  className="features__list--general"
                  data-aos="flip-left"
                  key={key}
                >
                  <div>
                    <div className="features__list--icon">
                    {feature.icon}
                    </div> 
                    <div className="features__list-list">
                    <p className="features__list--title">{feature.title}</p>
                    <p className="features__list--description">
                      {feature.description}
                    </p>
                    </div>  
                  </div>
                  {/* </div> */}
                </div>
              ))}
            </div>
          </div>
        </Container>
      </FeatureMainContainer>
    </div>
  )
}

export default Features
