import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  @font-face {
  font-family: 'HKGrotesk';
  src: url('../assets/fonts/hk-grotesk/HKGrotesk-Black.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-Bold.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-BoldItalic.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-BoldLegacy.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-BoldLegacyItalic.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-ExtraBold.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-Italic.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-LegacyItalic.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-Light.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-LightItalic.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-LightLegacy.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-LightLegacyItalic.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-Medium.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-MediumItalic.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-MediumLegacy.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-MediumLegacyItalic.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-Regular.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-RegularLegacy.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-SemiBold.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-SemiBoldItalic.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-SemiBoldLegacy.otf') format('otf'),
  url('../assets/fonts/hk-grotesk/HKGrotesk-SemiBoldLegacyItalic.otf') format('otf');
  }
  html {scroll-behavior: smooth;
    font-family: 'HKGrotesk', sans-serif;
  }
  /* *, *:before, *:after {
    box-sizing: inherit;
  } */
  body{ margin: 0}
  ul, li, h1, h2, h3, h4, p, button { margin: 0; padding: 0; }
  ul { list-style: none; }
  textarea{font-family: 'HKGrotesk', sans-serif;}
  button { background: transparent; border: 0; outline: 0 }
  .mt-10{
    margin-top: 10px
  }
  .mb-10{
    margin-bottom: 10px
  }
  .global-loader-wraper{
    height: 100vh;
    display: grid;
    place-items: center;
  }
`
