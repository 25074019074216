import styled from 'styled-components'

export const LandingPricingStyles = styled.div`
  position: relative;
  .header {
    position: relative;
  }
  .pricing-background {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: -1;
    background-color: #232323;
    display: flex;
    align-items: flex-end;
  }
  .pricing-content {
    background: transparent;
    padding-bottom: 100px;
    &__header {
      margin: 50px 0px;
      display: grid;
      place-items: center;
      &--title {
        padding: 50px;
      }
      &--subtitle {
        margin: 0px 0px 50px 0px;
        width: 500px;
        @media (max-width: 788px) {
          width: auto;
        }
      }
      &--switch {
        display: flex;
        justify-content: center;
        span {
          margin: 0px 20px;
          font-style: normal;
          font-weight: normal;
          font-size: 21px;
          line-height: 27px;
          text-align: center;
          color: #ffffff;
        }
        .switch {
          appearance: none;
          height: 2em;
          width: 4em;
          background-color: #dadde4;
          cursor: pointer;
          border-radius: 1em;
          position: relative;
          transition: background-color 0.3s ease;
        }

        .switch::before {
          content: '';
          height: 100%;
          width: 50%;
          background-color: white;
          position: absolute;
          border-radius: 50%;
          text-align: center;
          /* border: #FA27A9; solid 0.2em; */
          transition: transform 0.3s ease, border-color 0.3s ease;
        }

        .switch:checked {
          background-color: #fa27a9;
        }

        .switch:checked::before {
          border-color: #fa27a9;
          transform: translateX(100%);
        }
        @media screen and (max-width: 500px) {
          .switch {
            appearance: none;
            height: 2em;
            width: 4em;
            background-color: #dadde4;
            cursor: pointer;
            border-radius: 1em;
            position: relative;
            transition: background-color 0.3s ease;
          }

          .switch::before {
            content: '';
            height: 100%;
            width: 50%;
            background-color: white;
            position: absolute;
            border-radius: 50%;
            text-align: center;
            /* border: #335EEA; solid 0.2em; */
            transition: transform 0.3s ease, border-color 0.3s ease;
          }

          .switch:checked {
            background-color: #fa27a9;
          }

          .switch:checked::before {
            border-color: #335eea;
            transform: translateX(100%);
          }
          @media screen and (max-width: 500px) {
            .switch {
              outline: none;
            }
          }
        }
      }
    }
  }
  .pricing-special {
    padding-bottom: -5px;
    background-color: #2b2a2a;
    &_title {
      width: 160px;
      display: flex;
      justify-items: center;
      align-content: center;
      margin: 0 auto;
      div {
        padding: 5px;
        border-radius: 18px;
      }
    }
    &_subtitle {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      display: grid;
      justify-content: center;
      align-items: center;
      padding: 12px 16px;
      margin: 0;
      text-align: center;
      border-radius: 5px;
      background-color: #fed4ee;
      color: #fa27a9;
      width: 100%;
    }
    &_divided {
      font-weight: normal;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      color: white;
      gap: 30px;
      text-align: center;
      padding: 20px;
      margin: 20px;
      @media (max-width: 788px) {
        grid-template-columns: repeat(1, 1fr);
      }
      &--section {
        &_title {
          color: white;
          font-size: 24px;
          font-weight: normal;
          margin-bottom: 30px;
        }
        &_price {
          font-size: 64px;
          font-weight: 600;
          color: white;
        }
        &_subtitle {
          color: white;
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 30px;
        }
        &_description {
          color: #bfcada;
          font-size: 18px;
          font-weight: 400;
          padding-right: 10px;
        }
      }
    }
    &_includes {
      color: #bfcada;
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }
    &_pro {
      color: white;
      font-size: 20px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
    }
    &_button {
      margin-top: 20px;
    }
    &_curvetwo {
      margin: -3px;
    }
  }
`
