import styled, { css } from 'styled-components'

export const MainMenuStyled = styled.div`
  .menu_options-button {
    padding: 10px;
    min-width: 50px;
    display: grid;
    place-items: center;
    box-shadow: 0px 0px 14px 0px rgba(199, 199, 199, 0.75);
    -webkit-box-shadow: 0px 0px 14px 0px rgba(199, 199, 199, 0.75);
    -moz-box-shadow: 0px 0px 14px 0px rgba(199, 199, 199, 0.75);
    /* svg{
      margin-right: 10px;
    } */
  }
`
export const MainMenuOption = styled.div`
  padding: 14px;
  border: 1px solid #e5ebfb;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 14px;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  .menu-item-content {
    grid-column: 3/19;
  }
  ${props => props.blocked && css`
  background-color: rgba(0, 0, 0, 0.09);
  `}
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`
