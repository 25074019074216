import { navigate, Redirect } from '@reach/router'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import {
  Anchor,
  Button,
  Input,
  JitsiMeetEmbed,
  Loader,
  Text,
} from '../../common'
import { InviteRoomStyted } from './InviteRoom.styles'
import { GET_WORKSPACES_QUERY } from '../../graphQL/Queries'
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { useAuthState } from '../../contexts/auth'
import images from '../../assets/static/images'

const InviteRoom = ({ roomcred }: any) => {
  const { user }: any = useAuthState()
  const [showMeet, setShowMeet] = useState(false)
  const [name, setName] = useState('')
  const [showRegister, setShowRegister] = useState(false)
  const arrRoomcred = roomcred.split('')
  const roomName = arrRoomcred.slice(0, 16).join('')
  const roomPass = arrRoomcred.slice(16, 32).join('')
  const idWorkspaceFromUrl = arrRoomcred.slice(32).join('')
  const [manualClose, setManualClose] = useState(false)
  const [idWorkspaceUrl, setIdWorkspaceUrl] = useState('')
  const [isParticipant, setIsParticipant] = useState(false)

  const {} = useQuery(GET_WORKSPACES_QUERY, {
    onCompleted: data => {
      const workspaceIds = data.getWorkspaces.map(workspace => workspace.id)
      console.log('includes: ---> ', workspaceIds.includes(idWorkspaceFromUrl))
      if (workspaceIds.includes(idWorkspaceFromUrl)) {
        setIsParticipant(true)
        setIdWorkspaceUrl(
          '/workspace/' + String(idWorkspaceFromUrl + '?' + roomcred)
        )
      }
    },
  })

  const handleManualClose = (): any => {
    setManualClose(true)
    setShowMeet(false)
    setShowRegister(true)
  }
  const handleInput = (value: any) => {
    setName(value)
  }
  useEffect(() => {
    handleInput
  }, [name])

  // _______Anaylicits
  useEffect(() => {
    ReactGA.pageview('/room-as-invited/')

    // Check and set variables if the user is logged in
    if (user) {
      setShowMeet(true)
    }
  }, [user])

  // _____
  const jitsiWindowStyle = {
    width: '100vw',
    height: '100vh',
  }
  const handleStartMeeting = (e: any) => {
    e.preventDefault()
    setShowMeet(true)
  }

  return (
    <div>
      {isParticipant && idWorkspaceUrl ? (
        <>
          <Redirect to={idWorkspaceUrl} />
        </>
      ) : (
        <> </>
      )}
      <InviteRoomStyted logoTop={!showMeet}>
        {!showMeet && !showRegister ? (
          <div className="pre-meeting" data-aos="fade-up">
            <div className="pre-meeting__content">
              <Anchor type="link" route="/landing" className="logo">
                <img
                  src={
                    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/ZYREBOX-LOGO-OFICIAL_BLANCO-home.png'
                  }
                  alt=""
                ></img>
              </Anchor>
              <Text type="h3" color="#fff" align="center">
                Welcome to your meeting
              </Text>
              <div className="invite-room__avatar">
                <img
                  src={`https://avatars.dicebear.com/api/bottts/${name}.svg`}
                  alt=""
                ></img>
              </div>
              <form className="invite-room__form" onSubmit={handleStartMeeting}>
                <Input
                  onChange={handleInput}
                  value={name}
                  placeHolder="Enter your name"
                  validationError={''}
                />
                <div className="invite-room__form--button">
                  <Button
                    onClick={() => {}}
                    color="pink"
                    size="base"
                    disabled={name === ''}
                  >
                    Start Meeting
                  </Button>
                </div>
              </form>
            </div>
          </div>
        ) : showMeet && !showRegister ? (
          <div className="meeting-container relative">
            <img
              src={images.zyreboxHomeWhite}
              alt="logo"
              className="absolute opacity-50 top-14 left-3 z-50 h-16"
            />
            <JitsiMeetEmbed
              jistiStyles={jitsiWindowStyle}
              onLoad={() => {
                console.log('loaded')
              }}
              loadingComponent={<Loader />}
              roomName={roomName}
              roomPassword={roomPass}
              email={''}
              displayName={user ? user.name : name}
              manualClose={manualClose}
              selfManualClose={manualClose}
              avatarUrl={
                user
                  ? user.profilePhoto
                  : `https://avatars.dicebear.com/api/bottts/${name}.svg`
              }
              onMeetingEnd={() => {
                setShowMeet(false)
                setShowRegister(true)
              }}
              canSelfClose={true}
              handleSetManualClose={handleManualClose}
            />
          </div>
        ) : (
          <div className="pre-meeting">
            <div className="pre-meeting__content">
              <Anchor type="link" route="/landing" className="logo">
                <img
                  src={
                    'https://zyrebox-static-storage.s3.us-west-1.amazonaws.com/ZYREBOX-LOGO-OFICIAL_BLANCO-home.png'
                  }
                  alt=""
                ></img>
              </Anchor>
              <Text type="h3" color="#fff" align="center">
                Did you enjoy this call?
              </Text>
              <div className="button-continer">
                {!isParticipant && user ? (
                  <>
                    <Button
                      onClick={() => window.location.reload()}
                      color="pink"
                      size="base"
                    >
                      Join Back
                    </Button>
                    <br />
                    <Button
                      onClick={() => navigate('/register')}
                      color="primary-pink"
                      outlined={true}
                      size="base"
                    >
                      Close the window
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => navigate('/register')}
                      color="pink"
                      size="base"
                    >
                      Register Now! 🤗
                    </Button>
                    <br />
                    <Button
                      onClick={() => window.location.reload()}
                      color="primary-pink"
                      outlined={true}
                      size="base"
                    >
                      Join Back
                    </Button>
                  </>
                )}
                <br />
                <a href="/landing">
                  <Text type="h6" size="17" color="#FA27A9" align="center">
                    {' '}
                    {user ? '' : 'Close this window'}{' '}
                  </Text>
                </a>
              </div>
            </div>
          </div>
        )}
      </InviteRoomStyted>
    </div>
  )
}

export default InviteRoom
