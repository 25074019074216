const HomeImpl = {
  HomeImpl: {
    header: {
      welcomeTitle: 'Welcome back!',
      inputPlaceholder: '🔍  Search Zyrebox by name',
      profileBtn: 'My Profile',
    },
    zyreboxList: {
      newZyrebox: 'New Zyrebox',
    },
    footer: {
      termsPreLabel: 'Read our',
      termsLabel: 'Terms & Conditions',
      contactUsLabel: 'Contact Us',
      feedbackHover: 'Give us feedback',
      feedbackTitle: 'Thanks for being part of Zyrebox! 🎉',
      feedbackPlaceholder:
        'Give us some feedback about our service, so we can improve your experience.',
      feedbackSuccess: (name: string) => {
        return `Thanks for your feedback! ${name} 😉`
      },
      feedbackError: "Sorry, we couldn't send your feedback! 🥺",
    },
  },
}

export default HomeImpl
