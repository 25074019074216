import React from 'react'
import {
  Hero,
  SubscribeCAC,
  Features,
  SwitchSides,
} from '../../components/MainLanding'
import { Navbar } from '../../shared'
import Footer from '../../shared/Footer'
import { MainLandingStyled } from './MainLanding.styles'
import { useWorkspaceContext } from '../../contexts/workspace'
import { LanguageFloat } from '../../common'

const MainLanding = () => {
  const {
    impl: { LandingMainImpl },
  }: any = useWorkspaceContext()
  return (
    <div>
      <MainLandingStyled>
        <Navbar dark />
        <Hero implHero={LandingMainImpl.hero} />
        <Features implFeatures={LandingMainImpl.features} />
        <SwitchSides implSwitchsides={LandingMainImpl.switchsides} />
        <SubscribeCAC implSubscribeCAC={LandingMainImpl.subscribecac} />
        <Footer />
        <LanguageFloat />
      </MainLandingStyled>
    </div>
  )
}

export default MainLanding
