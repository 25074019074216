import React from 'react'
import { ToggleSwitchStyled } from './ToggleSwitch.styles'

interface ToggleSwitchProps {
  content?: string
  backgroundColor?: string
  backgroundColorActive?: string
  switchColor?: string
  height?: string
  width?: string
  handleChange: () => void
  checked: boolean
}

const ToggleSwitch = ({
  content,
  backgroundColor,
  switchColor,
  backgroundColorActive,
  height,
  width,
  handleChange,
  checked,
}: ToggleSwitchProps) => {
  return (
    <ToggleSwitchStyled
      content={content}
      backgroundColor={backgroundColor}
      switchColor={switchColor}
      backgroundColorActive={backgroundColorActive}
      height={height}
      width={width}
    >
      <input
        className="switch"
        type="checkbox"
        id="darkmodeSwitch"
        onChange={handleChange}
        checked={checked}
      />
    </ToggleSwitchStyled>
  )
}

export default ToggleSwitch
