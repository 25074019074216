import styled from 'styled-components'

export const CheckoutStyledWraper = styled.div`
  input[type='text'] {
    border: 1px solid #dcdcdc;
    background-color: white;
  }
  .remember-box {
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-top: 8px solid rgba(111, 90, 236, 0.23);
      position: absolute;
      top: 100%;
      right: 40px;
      z-index: 2;
    }
  }
`
