import styled from 'styled-components'

export const LaguageFloatStyled = styled.div`
  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #fff;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    display: grid;
    place-items: center;
    cursor: pointer;
    &:hover {
      background-color: #232323;
    }
  }
  .float__options {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 170px;
    right: 35px;
    &--item {
      background-color: #cacaca;
      color: #fff;
      border-radius: 50px;
      width: 50px;
      height: 50px;
      display: grid;
      place-items: center;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        background-color: #232323;
      }
    }
  }
  .my-float {
    margin-top: 22px;
  }
`
