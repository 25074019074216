import styled, { css } from 'styled-components'

export const ZyreboxInfoContainer = styled.div`
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  margin: 15px auto;
  &:hover {
    cursor: pointer;
    .pathcard,
    .pathcard:hover {
      position: absolute;
      display: block;
      left: 50px;
      z-index: 1;
      cursor: pointer;
    }
    .hide,
    .hide:hover {
      position: absolute;
      display: block;
      left: 80px;
      cursor: pointer;
    }
  }
  .tooltipWraper {
    left: 60px;
  }
  .card-wraper {
    top: auto;
    left: 67px !important;
    display: inline-block !important;
  }
  ${props =>
    props.image &&
    css`
      background: url(${props.image});
      background-size: cover;
    `}
`

export const CardContentStyles = styled.div`
  width: 250px;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-content {
    min-width: 175px;
    max-width: 175px;
  }
  img {
    width: 22px;
    height: 22px;
  }
`
export const CardBody = styled.div`
  display: block;
  .button-container {
    display: flex;
    justify-content: flex-end;
    .leave-button {
      display: flex;
      align-items: center;
      svg {
        margin: 3px 5px 0px 5px;
      }
    }
  }
`
