import React from 'react'
import { Avatar, Badge } from '../../../common'
import { useWorkspaceContext } from '../../../contexts/workspace'
import { useStatus } from '../../../hooks/useStatus'
import { useEffect } from 'react'
import { AvatarContainer } from './MySpace.styles'
import { Status } from '../../../types/enums'
import { Popover, Tooltip } from 'antd'
import MySpacePopOver from './MySpacePopOver'

interface MySpaceProps {
  user: any
  impl: any
}

const MySpace = ({ user, impl }: MySpaceProps): JSX.Element => {
  const { status, getStatusKey }: any = useStatus(user.id)
  const { workspace, handleDarkMode, darkMode }: any = useWorkspaceContext()
  useEffect(() => {}, [status])
  return (
    <div>
      <Badge color={'primary'}>{impl.badgeLabel}</Badge>
      <AvatarContainer darkMode={darkMode}>
        <Popover
          content={
            <MySpacePopOver
              user={user}
              impl={impl}
              handleDarkMode={handleDarkMode}
              darkMode={darkMode}
            />
          }
          trigger="click"
          placement="right"
        >
          <Tooltip placement="right" title={'Actions'}>
            <div>
              <Avatar
                image={user.profilePhoto}
                size={'45px'}
                haveStatus
                currentStatus={getStatusKey(workspace.id) === Status.online}
              />
            </div>
          </Tooltip>
        </Popover>
      </AvatarContainer>
    </div>
  )
}

export default MySpace
