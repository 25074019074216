import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { FaGoogle } from 'react-icons/fa'
import { Alert, Anchor, Avatar, Button, Input, Loader } from '../../../common'
import { REGISTER_MUTATION } from '../../../graphQL/Mutations'

const CheckoutAuthStep = ({ CheckoutImpl, dispatch, user }) => {
  const [errors, setErrors] = useState({}) //registering errors
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [acceptTerms, setAcceptTerms] = useState(false)
  const handleEmail = (value: string) => {
    // eslint-disable-next-line no-control-regex
    if (
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        value
      )
    ) {
      setEmailError('')
    } else {
      setEmailError('Please enter a valid email')
    }
    if (value === '') {
      setEmailError('')
    }
    setEmail(value)
  }

  const handleName = (value: string) => {
    setName(value)
  }
  const handleLastName = (value: string) => {
    setLastName(value)
  }
  const handlePassword = (value: string) => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#+.])[A-Za-z\d@$!%*?&#+.]{8,}$/.test(
        value
      )
    ) {
      setPasswordError('')
    } else {
      setPasswordError(
        'Password must have at least 8 characters, an uppercase, a lowercase, a number and a special character'
      )
    }
    if (value === '') {
      setPasswordError('')
    }
    setPassword(value)
  }
  const handleConfirmPassword = (value: string) => {
    if (password === value) {
      setConfirmPasswordError('')
    } else {
      setConfirmPasswordError('Passwords must match')
    }
    if (value === '') {
      setConfirmPasswordError('')
    }
    setConfirmPassword(value)
  }
  const handleAcceptTerms = () => {
    setAcceptTerms(!acceptTerms)
  }

  const [registerUser, { loading }] = useMutation(REGISTER_MUTATION, {
    update: async (_, { data: { register } }) => {
      dispatch({ type: 'LOGIN', payload: register })
      // navigate('/home')
      window.location.href = '/home'
    },
    onError: (err: any) => {
      setErrors(err.graphQLErrors[0].extensions.errors)
      setTimeout(() => {
        setErrors({})
      }, 3000)
    },
  })

  const handleLoginGoogle = () => {
    window.localStorage.setItem('authGoogle', 'true')
    window.open(
      process.env.REACT_APP_BACKEND_URL + '/auth/login/google',
      '_self'
    )
  }

  const handleSumbit = (e: any) => {
    e.preventDefault()
    registerUser({
      variables: {
        name: name,
        lastName: lastName,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
      },
    })
  }
  const handleDisabled = () => {
    if (
      name !== '' &&
      lastName !== '' &&
      email !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      emailError === '' &&
      passwordError === '' &&
      confirmPasswordError === '' &&
      acceptTerms
    ) {
      return false
    }
    return true
  }
  return (
    <>
      {!user?.id ? (
        <form className="flex" onSubmit={handleSumbit}>
          <div className="basis-5/12 pt-8">
            <Input
              value={name}
              onChange={handleName}
              validationError={''}
              label={CheckoutImpl.register.nameInput.placeholder}
              placeHolder={CheckoutImpl.register.nameInput.placeholder}
            />
            <Input
              value={lastName}
              onChange={handleLastName}
              validationError={''}
              label={CheckoutImpl.register.lastNameInput.placeholder}
              placeHolder={CheckoutImpl.register.lastNameInput.placeholder}
            />
            <Input
              value={email}
              onChange={handleEmail}
              validationError={emailError}
              label={CheckoutImpl.register.emailInput.placeholder}
              placeHolder={CheckoutImpl.register.emailInput.placeholder}
            />
            <Button
              size="base"
              color="primary"
              onClick={() => {}}
              disabled={loading || handleDisabled()}
              block
              className="login-form__button mt-8"
            >
              {loading ? (
                <Loader size="25px" border="3px" />
              ) : (
                CheckoutImpl.register.registerBtn
              )}
            </Button>
            {Object.keys(errors).length > 0 && (
              <div className="alert">
                {Object.values(errors).map((value: any) => (
                  <Alert color="danger" key={value}>
                    <p>{value}</p>
                  </Alert>
                ))}
              </div>
            )}
          </div>
          <div className="basis-2/12 flex justify-center items-end pb-4">
            <div className=" ">or</div>
          </div>
          <div className="basis-5/12 mt-8">
            <Input
              value={password}
              onChange={handlePassword}
              validationError={passwordError}
              type="password"
              label={CheckoutImpl.register.passwordInput.placeholder}
              placeHolder={CheckoutImpl.register.passwordInput.placeholder}
              className="bg-white"
              borderColor="#dcdcdc"
            />
            <Input
              value={confirmPassword}
              onChange={handleConfirmPassword}
              validationError={confirmPasswordError}
              type="password"
              label={CheckoutImpl.register.confirmPasswordInput.placeholder}
              placeHolder={
                CheckoutImpl.register.confirmPasswordInput.placeholder
              }
              className="bg-white"
              borderColor="#dcdcdc"
            />
            <div className="my-12">
              <input
                type="checkbox"
                className="mr-2"
                onChange={handleAcceptTerms}
              />
              <Anchor route="/terms" type="link" targetBlank>
                {CheckoutImpl.register.acceptTermsLabel}
              </Anchor>
            </div>
            <Button
              size="base"
              color="danger"
              onClick={handleLoginGoogle}
              disabled={false}
              block
              outlined
              className="mt-1"
            >
              <div>
                <FaGoogle />
                {CheckoutImpl.logginGoogle}
              </div>
            </Button>
          </div>
        </form>
      ) : (
        <div className="flex">
          <div className="basis-5/6 flex items-center">
            <Avatar image={user?.profilePhoto} className="mr-4" size="65px" />
            {CheckoutImpl.loggedTag}&nbsp;
            <span className="font-bold">
              {user?.name} {user?.lastName}
            </span>
          </div>
          <div className="basis-1/6 flex items-center">
            <Anchor route="#" onClick={handleLoginGoogle} type="link">
              {CheckoutImpl.changeLinkText}
            </Anchor>
          </div>
        </div>
      )}
    </>
  )
}

export default CheckoutAuthStep
