import styled, { css } from 'styled-components'

export const NavbarStyled = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: ${props => (props.dark ? '#151B29' : '#FFF')} ; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 140px;
    vertical-align: middle;
  }
  .menu__item {
    float: left;
    display: block;
    position: relative;
  }
  .menu__button {
    margin-right: 10px;
    background-color: #fa27a9;
    width: 87px;
    height: 45px;
  }
  .menu_link {
    text-align: center;
    padding: 14px 10px;
    text-decoration: none;
    font-size: 17px;
  }
  .icon {
    display: none;
  }
  .navbar__box {
    display: inline-block;
  }

  .navbarbutton {
    margin-right: 10px;
  }
  @media screen and (max-width: 600px) {
    display: block;
    .menu__item:not(:first-child) {
      display: none;
    }
    .icon {
      float: right;
      margin: 20px;
      display: block;
    }
  }

  ${props =>
    props.showItems &&
    css`
      position: relative;
      .icon {
        position: absolute;
        right: 0;
        top: 0;
      }
      .menu__item {
        float: none;
        display: block !important;
        text-align: left;
      }
    `}
  ${props =>
    props.dark
      ? css`
          background-color: #232323;
        `
      : css`
          background-color: #fff;
        `}
`
