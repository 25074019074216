import React from 'react'
import { TooltipStyled } from './Tooltip.styles'

interface TooltipProps {
  children: JSX.Element
  position: string
  content: any
  customClass?: string
  customId?: string
}

const Tooltip = ({
  children,
  content,
  position,
  customClass,
  customId,
}: TooltipProps): JSX.Element => {
  return (
    <>
      <TooltipStyled
        position={position}
        className={`tooltip tooltipContainer ${customClass}`}
        id={customId}
      >
        <div className="tooltipWraper">
          <span className="tooltipContent">{content}</span>
        </div>
        {children}
      </TooltipStyled>
    </>
  )
}

export default Tooltip
